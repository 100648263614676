import { Container } from 'unstated';
import {
  DadosFuncionario,
  TipoPerfil,
  NivelPermissao,
  TipoHorario,
  TipoPlano,
  TipoCameraFotoPonto
} from '../modules/types';

export interface FuncionarioState {
  dados: DadosFuncionario;
}

export class FuncionarioContainer extends Container<FuncionarioState> {
  funcionarioInitialState: DadosFuncionario = {
    id: 0,
    nome: '',
    empresa: '',
    funcao: '',
    numeroIdentificador: '',
    email: '',
    celular: '',
    cidade: '',
    uf: '',
    cpf: '',
    perfil: TipoPerfil.Funcionario,
    dadosPerfilFuncionario: {
      tipoPerfil: TipoPerfil.Funcionario,
      controleSolicitacoes: 0,
      telasOcultar: [],
      colunasOcultar: [],
      restringirAjustePontos: false,
      permitirLigarFuncionariosAusentes: false
    },
    nivelPermissao: NivelPermissao.PermiteAlteracoes,
    podeIncluirPontoManual: false,
    assinaturaDigitalCartaoPontoHabilitada: false,
    usaPonto10Batidas: false,
    usaExigenciaComprovanteEndereco: false,
    bancoHorasHabilitado: false,
    listaHorarios: [{ periodo: '', horario: '' }],
    dadosHorario: {
      tipo: TipoHorario.Semanal,
      itens: [],
      tipoCompensacao: 0
    },
    plano: TipoPlano.Gateway,
    funcionalidades: [],
    configuracoesIncluirPontoOffline: {
      justificativaAutomatica: false,
      exigirCapturaFotoPonto: false,
      tipoCameraCapturaFotoPonto: TipoCameraFotoPonto.LivreEscolhaFuncionario,
      permitirInclusaoPontoOffline: false
    },
    configuracoesEspeciaisAcesso: {
      tecpontoIntero: false
    },
    receberEmailComprovantePonto: false,
    listaDispositivosAutorizadosIdentificacao: [],
    permiteInclusaoPontoDispositivosAutorizados: false,
    loginEnderecoIp: '',
    loginDadosNavegador: '',
    permiteInclusaoPontoSemLocalizacao: false,
    possuiSolicitacaoAtualizacaoDadosCadastraisPendente: false,
    usaOutraLegislacao: false,
    podeIncluirAtividade: false,
    ehClienteSecullum: false,
    aceitouTermosLgpd: false,
    desativarVerificacaoLocalFicticio: false
  };

  state: FuncionarioState = {
    dados: this.funcionarioInitialState
  };

  setarDadosFuncionario = (dados: DadosFuncionario) => {
    this.setState({ dados });
  };

  limparDadosFuncionario = () => {
    this.setState({
      dados: this.funcionarioInitialState
    });
  };
}

export default new FuncionarioContainer();
