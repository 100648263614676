import * as React from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import { translate, translateFirstUpper } from 'secullum-i18n';
import {
  Card,
  DatePicker,
  DropDown,
  ErrorMessage,
  Message,
  Space,
  TextBox,
  TimePicker,
  isTablet,
  FilePicker,
  RangeDatePicker
} from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import {
  excluirEstadoJustificarAusenciaAsync,
  salvarEstadoJustificarAusenciaAsync,
  carregarImagemPendenteEstadoJustificarAusencia,
  verificarImagePickerErrorResult
} from '../../../native/modules/imagemPendente';
import { ButtonBar } from '../../components/ButtonBar';
import CardAviso from '../../components/CardAviso';
import { FuncionarioContainer } from '../../containers/FuncionarioContainer';
import Api from '../../modules/api';
import { manipulateImageAsync } from '../../modules/image';
import { getTheme } from '../../modules/layout';
import { verificarExibirTela } from '../../modules/perfilFuncionario';
import { primeiraLetraMaisculaCadaPalavra } from '../../modules/string';
import { Telas } from '../../modules/telas';
import {
  DadosFuncionario,
  DadosListagemFuncionariosGerente,
  Justificativa,
  NivelPermissao,
  SolicitacaoDados,
  TipoPlano,
  TipoSolicitacao,
  TipoAusencia
} from '../../modules/types';
import {
  converterDataServidorParaDataLocal,
  getDataSemHoras
} from '../../modules/utils';

export interface JustificarAusenciaState {
  resultado: 'sucesso' | 'erro' | null;
  dados: SolicitacaoDados;
  erroGeral: string;
  erros: { [key: string]: string };
  listaJustificativas: Justificativa[];
  bloquearSalvar: boolean;
  enviarImagem: boolean;
  funcionariosGerente: DadosListagemFuncionariosGerente[];
}

interface Props {
  data: Date;
  dados: DadosFuncionario;
  onSuccess(funcionarioSelecionadoId?: number): void;
  onEnviarFoto(): void;
  onErroConexao?: (mensagem: string, onTentarNovamente: () => void) => void;
  funcionarioId?: number;
}

class CardJustificarAusencia extends React.Component<
  Props,
  JustificarAusenciaState
> {
  state: JustificarAusenciaState = {
    resultado: null,
    dados: {
      data: new Date(),
      justificativaId: null,
      entrada1: '',
      saida1: '',
      entrada2: '',
      saida2: '',
      entrada3: '',
      saida3: '',
      entrada4: '',
      saida4: '',
      entrada5: '',
      saida5: '',
      observacoes: '',
      tipo: TipoSolicitacao.JustificativaDiaInteiro,
      foto: null,
      temFoto: false,
      registroPendente: false,
      existePeriodoEncerrado: false,
      tipoAusencia: TipoAusencia.DiaEspecifico,
      dataInicioAfastamento: new Date(),
      dataFimAfastamento: new Date()
    },
    erroGeral: '',
    erros: {},
    listaJustificativas: [],
    bloquearSalvar: false,
    enviarImagem: false,
    funcionariosGerente: []
  };

  async componentDidMount() {
    const { data } = this.props;

    if (await this.carregarImagemPendente()) {
      return;
    }

    const api = new Api();

    // Atualiza a data no state pois caso a justificativa de ponto seja chamada pela tela de cartão ponto a data será passada pela propriedade,
    // se a data passada pela propriedade não tiver solicitação, o state não será atualizado e a data correta não será exibida na tela
    this.setState(state => ({
      dados: {
        ...state.dados,
        data: data,
        dataInicioAfastamento: data,
        dataFimAfastamento: data
      },
      enviarImagem: api.funcionario.state.dados.plano >= TipoPlano.Pro
    }));

    await this.carregarDadosGerais();
  }

  componentDidUpdate(prevProps: Props) {
    const { data } = prevProps;
    const newDate = this.props.data;

    if (
      data &&
      typeof data === Date() &&
      data.toLocaleDateString() !== newDate.toLocaleDateString()
    ) {
      this.setState(
        {
          dados: { ...this.state.dados, data: newDate }
        },
        () => this.carregarDadosGerais()
      );
    }
  }

  carregarDadosGerais = async () => {
    const { data, funcionarioId } = this.props;
    const api = new Api();

    // Se informar o id de outro funcionário, é porque está acessando como gerente
    // Nesse caso deve carregar a lista dos funcionários que o gerente tem acesso
    if (funcionarioId) {
      await api.getListaFuncionariosGerente({
        onSuccess: funcionariosGerente => {
          this.setState({
            funcionariosGerente
          });
        },
        onError: err => {
          if (err.name === 'TimeoutError' && this.props.onErroConexao) {
            this.props.onErroConexao(err.message, () =>
              this.carregarDadosGerais()
            );
          } else {
            this.setState({
              resultado: 'erro',
              erroGeral: err.message
            });
          }
        }
      });
    }

    await this.carregarDadosSolicitacao(new Date(data), funcionarioId, true);
  };

  // Este método é usado externamente e foi criado para que seja possível
  // atualizar a tela de justificativa de ausência (ao puxar a mesma para baixo no app)
  // mesmo quando a data seja alterada
  atualizarSolicitacao = () => {
    const { data, funcionarioId } = this.state.dados;

    this.carregarDadosSolicitacao(
      data,
      this.props.funcionarioId ? funcionarioId : undefined
    );
  };

  carregarImagemPendente = async () => {
    try {
      const dados = await carregarImagemPendenteEstadoJustificarAusencia();

      if (!dados) {
        return false;
      }

      const { image, justificarAusenciaEstado } = dados;
      const dadosMemoria = { ...justificarAusenciaEstado.dados };

      if (!verificarImagePickerErrorResult(image)) {
        if (!image.canceled) {
          dadosMemoria.temFoto = true;

          dadosMemoria.foto = await manipulateImageAsync(image.assets[0].uri);
        }
      } else {
        this.onErrorFoto(translate('Não foi possível carregar a imagem.'));
      }

      this.setState({
        funcionariosGerente: justificarAusenciaEstado.funcionariosGerente,
        erros: justificarAusenciaEstado.erros,
        bloquearSalvar: justificarAusenciaEstado.bloquearSalvar,
        enviarImagem: justificarAusenciaEstado.enviarImagem,
        listaJustificativas: justificarAusenciaEstado.listaJustificativas,
        erroGeral: justificarAusenciaEstado.erroGeral,
        resultado: justificarAusenciaEstado.resultado,
        dados: dadosMemoria
      });
    } catch (error) {
      this.onErrorFoto(translate('Não foi possível carregar a imagem.'));
    } finally {
      await excluirEstadoJustificarAusenciaAsync();
    }

    return true;
  };

  carregarDadosSolicitacao = async (
    data: Date,
    funcionarioId?: number,
    cargaGeral: boolean = false
  ) => {
    const api = new Api();
    const dados = { data, funcionarioId };

    await api.getListaJustificativas({
      onSuccess: resp => {
        this.setState({
          listaJustificativas: resp
        });
      },
      onError: err => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(
            err.message,
            cargaGeral
              ? () => this.carregarDadosGerais()
              : () =>
                  this.carregarDadosSolicitacao(data, funcionarioId, cargaGeral)
          );
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      }
    });

    await api.getSolicitacao(dados, {
      onSuccess: resp => {
        if (resp.tipo === TipoSolicitacao.AlteracaoDePonto) {
          resp.tipo = TipoSolicitacao.JustificativaDiaInteiro;
        }

        this.setState({
          dados: resp
        });
      },
      onError: err => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(
            err.message,
            cargaGeral
              ? () => this.carregarDadosGerais()
              : () =>
                  this.carregarDadosSolicitacao(data, funcionarioId, cargaGeral)
          );
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      }
    });
  };

  handleDataInicioRangeDatePickerChange = (
    value: Date,
    name: keyof JustificarAusenciaState['dados']
  ) => {
    this.setState({
      ...this.state,
      dados: { ...this.state.dados, [name]: value }
    });
  };

  handleRangeDatePickerChange = (
    value: Date,
    name: keyof JustificarAusenciaState['dados']
  ) => {
    const dados = {
      ...this.state.dados,
      [name]: value
    };

    // Caso o funcionário tenha selecionado uma data maior como data inicial, é feito a troca da dataFim pela dataInicio para ficar correto
    if (dados.dataInicioAfastamento! > dados.dataFimAfastamento!) {
      const dataInicio = dados.dataInicioAfastamento;
      const dataFim = dados.dataFimAfastamento;

      dados.dataInicioAfastamento = dataFim;
      dados.dataFimAfastamento = dataInicio;
    }

    this.setState({
      dados: dados
    });
  };

  handleTipoAusenciaChange = (
    name: keyof JustificarAusenciaState['dados'],
    value: TipoAusencia
  ) => {
    const dados = {
      ...this.state.dados,
      [name]: value
    };

    // Caso o cliente altere de 'Período de Afastamento' para 'Dia Específico'
    // definimos o tipo para Dia Inteiro pois o tipo Afastamento não existe na tela de Dia Específico.
    if (value === TipoAusencia.PeriodoAfastamento) {
      dados.tipo = TipoSolicitacao.Afastamento;

      // Definimos uma nova data para o afastamento, devido ao fato que ao abrirmos a tela é feito uma requisição que sobrepõe o state para null
      const dataAtual = getDataSemHoras();
      dados.dataInicioAfastamento = dataAtual;
      dados.dataFimAfastamento = dataAtual;
    } else {
      dados.tipo = TipoSolicitacao.JustificativaDiaInteiro;
    }

    this.setState({
      dados: dados
    });
  };

  handleChange = (name: string, value: any) => {
    const erros = name === 'data' ? {} : { ...this.state.erros, [name]: '' };

    const dados = {
      ...this.state.dados,
      [name]: value
    };

    if (name === 'tipo' && value === TipoSolicitacao.AjusteParcial) {
      dados.entrada1 = '';
      dados.saida1 = '';
    }

    const justificativa = this.state.listaJustificativas.find(
      ({ id }) => id === dados.justificativaId
    );

    // Sec-issues#9835: Atualiza o state para evitar de salvar a foto, caso o funcionário
    // tenha trocado para uma justificativa que não exige foto
    // após ter carregado o dia que tenha uma solicitação de justificativa com foto
    if (justificativa && !justificativa.exigirFotoAtestado) {
      dados.temFoto = false;
      dados.foto = null;
    }

    this.setState({ dados, erros }, async () => {
      if (name === 'data' || name === 'funcionarioId') {
        const { data, funcionarioId } = this.state.dados;

        await this.carregarDadosSolicitacao(
          data,
          // Só informa o id do funcionário se a tela for acessada pelo gerente (quando tem funcionarioId nas props)
          this.props.funcionarioId ? funcionarioId : undefined
        );
      }
    });
  };

  handleEnviar = () => {
    const api = new Api();

    if (this.state.bloquearSalvar) {
      return;
    }

    this.setState({
      bloquearSalvar: true
    });

    api.postSolicitacao(this.state.dados, {
      onSuccess: () => {
        this.setState({
          resultado: 'sucesso',
          bloquearSalvar: false
        });
      },
      onError: err => {
        this.setState({
          bloquearSalvar: false
        });

        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () => this.handleEnviar());
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      },
      onValidationError: (_, errorObj) => {
        this.setState({
          erros: errorObj,
          bloquearSalvar: false
        });
      }
    });
  };

  handleSucesso = () => {
    const funcionarioSelecionadoId = this.props.funcionarioId
      ? this.state.dados.funcionarioId
      : undefined;

    this.setState({ resultado: null });

    this.props.onSuccess(funcionarioSelecionadoId);
  };

  onErrorFoto = (mensagemErro: string) => {
    this.setState({ resultado: 'erro', erroGeral: mensagemErro });
  };

  incluirFoto = (foto: string) => {
    this.setState({ dados: { ...this.state.dados, foto: foto } });
  };

  exibirCampoAtestado = () => {
    const { listaJustificativas, enviarImagem, dados } = this.state;

    var justificativa = listaJustificativas.find(
      ({ id }) => id === dados.justificativaId
    );

    return enviarImagem && justificativa && justificativa.exigirFotoAtestado;
  };

  carregarPeriodos = (usa10Batidas: boolean) => {
    const periodos = [
      {
        nativeID: 'justificar-periodo-ausencia-dia-inteiro',
        label: translate('Dia Inteiro'),
        value: TipoSolicitacao.JustificativaDiaInteiro
      },
      {
        nativeID: 'justificar-periodo-ausencia-manha',
        label: `${translateFirstUpper('Período')} 1`,
        value: TipoSolicitacao.Periodo1
      },
      {
        nativeID: 'justificar-periodo-ausencia-tarde',
        label: `${translate('Período')} 2`,
        value: TipoSolicitacao.Periodo2
      },
      {
        nativeID: 'justificar-periodo-ausencia-noite',
        label: `${translate('Período')} 3`,
        value: TipoSolicitacao.Periodo3
      }
    ];

    if (usa10Batidas) {
      periodos.push({
        nativeID: 'justificar-periodo-4',
        label: `${translate('Período')} 4`,
        value: TipoSolicitacao.Periodo4
      });
      periodos.push({
        nativeID: 'justificar-periodo-5',
        label: `${translate('Período')} 5`,
        value: TipoSolicitacao.Periodo5
      });
    }

    periodos.push({
      nativeID: 'justificar-periodo-ausencia-periodo-especifico',
      label: translate('Período Específico'),
      value: TipoSolicitacao.AjusteParcial
    });

    return periodos;
  };

  renderOpcoesPeriodoAfastamento() {
    const { dados, erros } = this.state;

    return (
      <>
        <RangeDatePicker
          nativeID="justificar-periodo-afastamento-data"
          label={translate('Insira o Período')}
          startDate={new Date(dados.dataInicioAfastamento!)}
          endDate={new Date(dados.dataFimAfastamento!)}
          onStartDateChange={date =>
            this.handleDataInicioRangeDatePickerChange(
              date,
              'dataInicioAfastamento'
            )
          }
          onEndDateChange={date =>
            this.handleRangeDatePickerChange(date, 'dataFimAfastamento')
          }
        />
        <ErrorMessage
          nativeID="justificar-erro-data"
          message={erros.dataInicioAfastamento || erros.dataFimAfastamento}
        />
      </>
    );
  }

  renderOpcoesDiaEspecifico() {
    const { dados, erros } = this.state;

    const dataAjustada = converterDataServidorParaDataLocal(dados.data);

    const listaPeriodos = this.carregarPeriodos(
      this.props.dados.usaPonto10Batidas
    );

    return (
      <>
        <DatePicker
          nativeID="justificar-periodo-data"
          label={translate('Data')}
          value={dataAjustada}
          onChange={data => this.handleChange('data', data)}
          clearable={false}
        />
        <ErrorMessage nativeID="justificar-erro-data" message={erros.data} />
        <Space />
        <DropDown
          nativeID="justificar-periodo-ausencia"
          label={translate('Período da Ausência')}
          disabled={dados.existePeriodoEncerrado}
          items={listaPeriodos.map(x => ({
            nativeID: x.nativeID,
            label: x.label,
            value: x.value
          }))}
          value={dados.tipo}
          onChange={tipo => this.handleChange('tipo', tipo)}
        />
      </>
    );
  }

  render() {
    const {
      resultado,
      dados,
      erros,
      listaJustificativas,
      funcionariosGerente
    } = this.state;

    const { funcionarioId } = this.props;
    const dadosFuncionario = this.props.dados;

    const listaPeriodoAusencia = [
      {
        nativeID: 'justificar-periodo-ausencia-em-dia-especifico',
        label: primeiraLetraMaisculaCadaPalavra(translate('Dia específico')),
        value: TipoAusencia.DiaEspecifico
      },
      {
        nativeID: 'justificar-periodo-ausencia-em-periodo-afastamento',
        label: translate('Período de Afastamento'),
        value: TipoAusencia.PeriodoAfastamento
      }
    ];

    const listaMotivos = listaJustificativas.map(x => ({
      nativeID: 'justificar-motivo-' + x.nomeCompleto,
      label: x.nomeCompleto,
      value: x.id
    }));

    return (
      <>
        {dados.registroPendente && (
          <>
            <CardAviso
              nativeID="aviso-inclusoes-processamento"
              icone="hourglass-half"
              texto={`${translate(
                'Existem inclusões de ponto em processamento neste dia'
              )}.`}
            />
            <Space />
          </>
        )}
        {dados.existePeriodoEncerrado && (
          <>
            <CardAviso
              nativeID="aviso-periodo-encerrado"
              icone="exclamation-triangle"
              texto={`${translate(
                'Não é possível alterar o ponto neste dia, pois o período está encerrado'
              )}.`}
            />
            <Space />
          </>
        )}
        <Card>
          <Card.Header
            nativeID="justificar-texto-explicativo"
            title={`${translate(
              'A justificativa de ausência deve ser utilizada caso você fique ausente do trabalho por um dia ou período específico'
            )}.`}
            titleStyle={styles.titleHeader}
          />
          <Card.Section>
            <View style={Platform.OS === 'web' && styles.centralizeView}>
              {funcionarioId && (
                <Subscribe to={[FuncionarioContainer]}>
                  {(funcionario: FuncionarioContainer) => {
                    // Só deve listar o próprio gerente na lista de funcionários caso ele tenha permissões na tela de Justificativa de Ausência
                    const gerentePossuiPermissaoJustificarAusencia =
                      verificarExibirTela(
                        Telas.JustificarAusencia,
                        funcionario.state.dados.dadosPerfilFuncionario
                          .telasOcultar
                      );
                    const funcionariosGerenteFiltrado =
                      gerentePossuiPermissaoJustificarAusencia
                        ? funcionariosGerente
                        : funcionariosGerente.filter(
                            x => x.id != funcionario.state.dados.id
                          );
                    return (
                      <>
                        <DropDown
                          nativeID="ajustar-ponto-funcionarios"
                          label={translate('Funcionário')}
                          value={dados.funcionarioId}
                          items={funcionariosGerenteFiltrado.map(
                            (x, indice) => ({
                              value: x.id,
                              label: x.nome,
                              nativeID: `funcionario-${indice + 1}`
                            })
                          )}
                          onChange={funcionarioId =>
                            this.handleChange('funcionarioId', funcionarioId)
                          }
                        />
                        <Space />
                      </>
                    );
                  }}
                </Subscribe>
              )}
              <DropDown
                nativeID="justificar-ausencia-em"
                label={translate('Ausência em')}
                disabled={dados.existePeriodoEncerrado}
                items={listaPeriodoAusencia.map(x => ({
                  nativeID: x.nativeID,
                  label: x.label,
                  value: x.value
                }))}
                value={dados.tipoAusencia}
                onChange={tipoAusencia =>
                  this.handleTipoAusenciaChange('tipoAusencia', tipoAusencia)
                }
              />
              <Space />
              {dados.tipoAusencia === TipoAusencia.PeriodoAfastamento
                ? this.renderOpcoesPeriodoAfastamento()
                : this.renderOpcoesDiaEspecifico()}
              <Space />
              {dados.tipo == TipoSolicitacao.AjusteParcial && (
                <>
                  <TimePicker
                    nativeID="justificar-horario-inicio"
                    label={translate('Horário de Início')}
                    value={dados.entrada1}
                    onChange={entrada1 =>
                      this.handleChange('entrada1', entrada1)
                    }
                  />
                  <ErrorMessage
                    nativeID="justificar-erro-entrada1"
                    message={erros.entrada1}
                  />
                  <Space />
                  <TimePicker
                    nativeID="justificar-horario-fim"
                    label={translate('Horário de Fim')}
                    value={dados.saida1}
                    onChange={saida1 => this.handleChange('saida1', saida1)}
                  />
                  <ErrorMessage
                    nativeID="justificar-erro-saida1"
                    message={erros.saida1}
                  />
                  <Space />
                </>
              )}
              <DropDown
                nativeID="justificar-motivo"
                label={translate('Motivo')}
                items={listaMotivos}
                value={dados.justificativaId}
                emptyMessage={translate('Não há justificativas cadastradas')}
                disabled={dados.existePeriodoEncerrado}
                onChange={justificativaId =>
                  this.handleChange('justificativaId', justificativaId)
                }
              />
              <ErrorMessage
                nativeID="justificar-erro-motivo"
                message={erros.justificativaId}
              />
              <Space />
            </View>
            <TextBox
              nativeID="justificar-observacoes"
              label={translate('Observação')}
              value={dados.observacoes || ''}
              onChange={observacoes =>
                this.handleChange('observacoes', observacoes)
              }
              multiline
              maxLength={
                dados.tipoAusencia === TipoAusencia.PeriodoAfastamento
                  ? 100
                  : 200
              }
              returnKeyType={'done'}
              editable={!dados.existePeriodoEncerrado}
              inputStyle={Platform.OS === 'web' && { minHeight: 60 }}
            />
            <ErrorMessage
              nativeID="justificar-erro-observacoes"
              message={erros.observacoes}
            />
            {this.exibirCampoAtestado() && (
              <>
                <Space />
                <FilePicker
                  label={
                    dados.foto || dados.temFoto
                      ? translate('Foto adicionada com sucesso.')
                      : translate('Enviar uma foto do atestado.')
                  }
                  icon={dados.foto || dados.temFoto ? 'check' : 'camera'}
                  onPress={async () => {
                    await salvarEstadoJustificarAusenciaAsync(this.state);
                    this.props.onEnviarFoto();
                  }}
                />
                <ErrorMessage
                  nativeID="justificar-erro-foto"
                  message={erros.foto}
                />
              </>
            )}
            <Space />
            <ButtonBar
              buttonBarStyle={Platform.OS === 'web' && styles.centralizeView}
              leftButton={{
                nativeID: 'justificar-cancelar',
                text: translate('Cancelar'),
                primary: false,
                onPress: this.handleSucesso
              }}
              rightButton={
                dadosFuncionario.nivelPermissao ===
                  NivelPermissao.NaoPermiteAlteracoes ||
                dados.existePeriodoEncerrado
                  ? null
                  : {
                      nativeID: 'justificar-enviar',
                      text: translate('Enviar'),
                      onPress: () => this.handleEnviar()
                    }
              }
            />
          </Card.Section>
        </Card>
        <Message
          nativeID="justificar-sucesso"
          message={translate('Solicitação de ajuste efetuada com sucesso')}
          visible={resultado === 'sucesso'}
          onRequestClose={() => this.handleSucesso()}
        />
        <Message
          nativeID="justificar-erro"
          type="warning"
          message={this.state.erroGeral}
          visible={resultado === 'erro'}
          onRequestClose={() =>
            this.setState({ resultado: null, erroGeral: '' })
          }
        />
      </>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  titleHeader: {
    color: theme.textColor2,
    fontSize: isTablet() ? 16 : 12,
    fontFamily: theme.fontFamily2
  },
  centralizeView: {
    margin: 'auto',
    maxWidth: 350,
    width: '100%'
  },
  fotoView: {
    borderRadius: 3,
    backgroundColor: theme.backgroundColor2,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default CardJustificarAusencia;
