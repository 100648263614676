import * as React from 'react';
import { Platform, StyleSheet } from 'react-native';
import { Modal } from 'secullum-react-native-ui';
import { isHighResolution } from '../../modules/layout';
import {
  QualidadeVidaTrabalho,
  TipoRespostaQualidadeVidaTrabalho
} from '../../modules/types';
import QualidadeVidaTrabalhoOpcoes from './QualidadeVidaTrabalhoOpcoes';
import QualidadeVidaTrabalhoOpcoesWeb from './QualidadeVidaTrabalhoOpcoesWeb';

interface Props {
  visivel: boolean;
  dados: QualidadeVidaTrabalho;
  onConcluir: (resposta: TipoRespostaQualidadeVidaTrabalho) => void;
}

class ModalQualidadeVidaTrabalho extends React.Component<Props> {
  render() {
    const { visivel, dados, onConcluir } = this.props;

    return (
      <Modal visible={visivel} overlayStyle={styles.overlay}>
        {Platform.OS === 'web' && isHighResolution() ? (
          <QualidadeVidaTrabalhoOpcoesWeb
            dados={dados}
            onConcluir={onConcluir}
          />
        ) : (
          <QualidadeVidaTrabalhoOpcoes dados={dados} onConcluir={onConcluir} />
        )}
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    alignItems: 'center'
  }
});

export default ModalQualidadeVidaTrabalho;
