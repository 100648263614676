import * as React from 'react';
import { StyleSheet } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Card,
  RangeDatePicker,
  DropDown,
  Space,
  Message
} from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import AssinaturaDigitalCartaoPontoHeader from '../../../shared/components/AssinaturaDigitalCartaoPontoHeader';
import AssinaturaDigitalCartaoPontoLinha from '../../../shared/components/AssinaturaDigitalCartaoPontoLinha';
import AssinaturaDigitalCartaoPontoProcessamento from '../../../shared/components/AssinaturaDigitalCartaoPontoProcessamento';
import { ButtonBar } from '../../../shared/components/ButtonBar';
import {
  AssinaturaDigitalCartaoPontoContainer,
  getCorStatus,
  getIconeStatus
} from '../../../shared/containers/AssinaturaDigitalCartaoPontoContainer';
import { ProcessandoContainer } from '../../../shared/containers/ProcessandoContainer';
import withContainer from '../../../shared/containers/withContainer';
import { getTheme } from '../../../shared/modules/layout';
import {
  AssinaturaDigitalCartaoPontoFiltrosStatus,
  TipoAlteracaoAssinaturaDigitalCartaoPonto,
  AssinaturaDigitalCartaoPontoDados,
  AssinaturaDigitalCartaoPontoStatus
} from '../../../shared/modules/types';
import {
  converterDataParaTimezoneBrasil,
  getDataInicioDiasAtras
} from '../../../shared/modules/utils';
import LoggedInScreen from '../../components/LoggedInScreen';
import AssinaturasDigitaisCartaoPontoDetalhes from './AssinaturasDigitaisCartaoPontoDetalhes';

interface Props {
  container: AssinaturaDigitalCartaoPontoContainer;
}

interface State {
  filtros: {
    dataInicio: Date;
    dataFinal: Date;
    status: AssinaturaDigitalCartaoPontoFiltrosStatus;
  };
  erroGeral: string;
  assinaturaDigitalCartaoPonto: AssinaturaDigitalCartaoPontoDados | null;
  todasSelecionadas: boolean;
  listaIdsSelecionados: Array<number>;
  tipoAcao: TipoAlteracaoAssinaturaDigitalCartaoPonto | null;
  assinaturaDigitalCartaoPontoAtual: AssinaturaDigitalCartaoPontoDados | null;
}

class AssinaturaDigitalCartaoPonto extends React.Component<Props, State> {
  state: State = {
    filtros: {
      dataInicio: converterDataParaTimezoneBrasil(getDataInicioDiasAtras(14)),
      dataFinal: converterDataParaTimezoneBrasil(new Date()),
      status: AssinaturaDigitalCartaoPontoFiltrosStatus.Pendentes
    },
    erroGeral: '',
    assinaturaDigitalCartaoPonto: null,
    todasSelecionadas: false,
    listaIdsSelecionados: [],
    tipoAcao: null,
    assinaturaDigitalCartaoPontoAtual: null
  };

  listaAssinaturasDigitaisCartaoPontoAtualAcao: AssinaturaDigitalCartaoPontoDados[] =
    [];

  async componentDidMount() {
    await this.props.container.loadAsync(this.state.filtros);
  }

  handleDateInicioChange = (value: Date, name: keyof State['filtros']) => {
    this.setState({
      ...this.state,
      filtros: { ...this.state.filtros, dataInicio: value, dataFinal: value },
      erroGeral: ''
    });
  };

  handleChange = (value: string | Date, name: keyof State['filtros']) => {
    const novosFiltros = {
      ...this.state.filtros,
      [name]: value
    };
    this.setState(
      {
        ...this.state,
        listaIdsSelecionados: [],
        filtros: novosFiltros,
        erroGeral: ''
      },
      async () => {
        if (
          this.state.filtros.dataInicio &&
          this.state.filtros.dataFinal &&
          this.state.filtros.dataInicio > this.state.filtros.dataFinal
        ) {
          this.setState({
            ...this.state,
            erroGeral: translate('Selecione o período corretamente')
          });

          return;
        }
        await this.props.container.loadAsync(this.state.filtros);
      }
    );
  };

  iniciarProcessamento = (
    tipoAcao: TipoAlteracaoAssinaturaDigitalCartaoPonto
  ) => {
    const { listaIdsSelecionados } = this.state;
    const { lista } = this.props.container.state;

    this.setState({ tipoAcao });

    this.listaAssinaturasDigitaisCartaoPontoAtualAcao = lista.filter(x =>
      listaIdsSelecionados.find(y => x.id == y)
    );

    this.processarAssinaturaDigitalCartaoPontoAtual();
  };

  processarAssinaturaDigitalCartaoPontoAtual = () => {
    if (this.listaAssinaturasDigitaisCartaoPontoAtualAcao.length === 0) {
      this.finalizarProcessamento();
      return;
    }

    this.setState({
      assinaturaDigitalCartaoPontoAtual:
        this.listaAssinaturasDigitaisCartaoPontoAtualAcao[0]
    });
  };

  finalizarProcessamento = () => {
    this.listaAssinaturasDigitaisCartaoPontoAtualAcao = [];

    this.setState(
      {
        tipoAcao: null,
        assinaturaDigitalCartaoPontoAtual: null,
        todasSelecionadas: false
      },
      async () => await this.props.container.loadAsync(this.state.filtros)
    );
  };

  concluirEnvioSucesso = () => {
    const { assinaturaDigitalCartaoPontoAtual } = this.state;

    if (assinaturaDigitalCartaoPontoAtual) {
      this.setState({
        listaIdsSelecionados: this.state.listaIdsSelecionados.filter(
          item => item !== assinaturaDigitalCartaoPontoAtual.id
        )
      });
    }

    this.listaAssinaturasDigitaisCartaoPontoAtualAcao.shift();
    this.processarAssinaturaDigitalCartaoPontoAtual();
  };

  handleSelecionarTodas = (checked: boolean) => {
    const { container } = this.props;
    this.setState({
      todasSelecionadas: checked,
      listaIdsSelecionados: checked
        ? container.state.lista
            .filter(
              x => x.estado === AssinaturaDigitalCartaoPontoStatus.NaoRespondido
            )
            .map(y => y.id)
        : []
    });
  };

  handleSelecionar = (assinaturaDigitalCartaoPontoId: number) => {
    const { listaIdsSelecionados } = this.state;

    this.setState({
      listaIdsSelecionados: listaIdsSelecionados.includes(
        assinaturaDigitalCartaoPontoId
      )
        ? listaIdsSelecionados.filter(
            item => item !== assinaturaDigitalCartaoPontoId
          )
        : [...this.state.listaIdsSelecionados, assinaturaDigitalCartaoPontoId]
    });
  };

  render() {
    const { container } = this.props;
    const {
      filtros,
      erroGeral,
      todasSelecionadas,
      listaIdsSelecionados,
      assinaturaDigitalCartaoPontoAtual,
      assinaturaDigitalCartaoPonto,
      tipoAcao
    } = this.state;

    const leftButtonAprovar = {
      disabled: listaIdsSelecionados.length === 0,
      text: translate('Aprovar'),
      onPress: () =>
        this.iniciarProcessamento(
          TipoAlteracaoAssinaturaDigitalCartaoPonto.Aprovar
        ),
      textStyle: styles.textButton,
      style: [styles.button, { backgroundColor: theme.successColor }],
      nativeID: 'botao-aprovar-assinatura-digital-cartao-ponto'
    };

    const rightButton = {
      disabled: listaIdsSelecionados.length === 0,
      text: translate('Reprovar'),
      onPress: () =>
        this.iniciarProcessamento(
          TipoAlteracaoAssinaturaDigitalCartaoPonto.Rejeitar
        ),
      textStyle: styles.textButton,
      style: [styles.button, { backgroundColor: theme.errorColor }],
      nativeID: 'botao-reprovar-assinatura-digital-cartao-ponto'
    };

    return (
      <LoggedInScreen
        screenTitle={translate('Assinatura Eletrônica de Cartão Ponto')}
      >
        <Card>
          <Card.Header title={translate('Filtrar')} />
          <Card.Section style={styles.section}>
            <DropDown
              nativeID="assinatura-digital-cartao-ponto-status"
              label={translate('Status')}
              items={container.itensStatusAssinaturaDigitalCartaoPonto}
              value={filtros.status}
              onChange={status => this.handleChange(status, 'status')}
              style={styles.campoFiltro}
            />
            <RangeDatePicker
              nativeID="assinatura-digital-cartao-ponto-data"
              style={styles.campoFiltro}
              label={translate('Insira o Período')}
              startDate={filtros.dataInicio!}
              endDate={filtros.dataFinal!}
              onStartDateChange={date =>
                this.handleDateInicioChange(date, 'dataInicio')
              }
              onEndDateChange={date => this.handleChange(date, 'dataFinal')}
            />
            <ButtonBar
              buttonBarStyle={styles.buttons}
              leftButton={leftButtonAprovar}
              rightButton={rightButton}
            />
          </Card.Section>
        </Card>
        <Space height={10} />
        <Card>
          <Card.Section style={styles.headerSection}>
            {container.state.lista.length > 0 && (
              <AssinaturaDigitalCartaoPontoHeader
                checked={todasSelecionadas}
                showChecked={
                  container.state.precisaProcessarAssinaturasDigitaisCartaoPonto
                }
                onChecked={checked => this.handleSelecionarTodas(checked)}
              />
            )}
          </Card.Section>
        </Card>
        <Space height={10} />
        {container.state.lista.map((assinaturaDigitalCartaoPonto, index) => (
          <AssinaturaDigitalCartaoPontoLinha
            key={assinaturaDigitalCartaoPonto.id}
            assinaturaDigitalCartaoPontoNativeId={`assinatura-digital-cartao-ponto-${
              index + 1
            }`}
            exibirSeparador={index > 0}
            assinaturaDigitalCartaoPonto={assinaturaDigitalCartaoPonto}
            checked={listaIdsSelecionados.includes(
              assinaturaDigitalCartaoPonto.id
            )}
            corAssinaturaDigitalCartaoPonto={getCorStatus(
              assinaturaDigitalCartaoPonto.estado
            )}
            iconeAssinaturaDigitalCartaoPonto={getIconeStatus(
              assinaturaDigitalCartaoPonto.estado
            )}
            onChecked={() =>
              this.handleSelecionar(assinaturaDigitalCartaoPonto.id)
            }
            onNavigate={() => {
              this.setState({ assinaturaDigitalCartaoPonto });
            }}
          />
        ))}
        {assinaturaDigitalCartaoPonto && (
          <AssinaturasDigitaisCartaoPontoDetalhes
            modalVisible={assinaturaDigitalCartaoPonto !== null}
            assinaturaDigitalCartaoPonto={assinaturaDigitalCartaoPonto}
            onClose={() => {
              this.finalizarProcessamento();
              this.setState({ assinaturaDigitalCartaoPonto: null });
            }}
          />
        )}
        <Subscribe to={[ProcessandoContainer]}>
          {(processando: ProcessandoContainer) =>
            processando.state.quantidade === 0 &&
            assinaturaDigitalCartaoPontoAtual ? (
              <AssinaturaDigitalCartaoPontoProcessamento
                assinaturaDigitalCartaoPontoAtual={
                  assinaturaDigitalCartaoPontoAtual
                }
                tipoAcao={tipoAcao}
                concluirEnvioSucesso={this.concluirEnvioSucesso}
                finalizarProcessamento={this.finalizarProcessamento}
                concluirEnvioErro={(erros: string) =>
                  this.setState(
                    { erroGeral: erros },
                    this.finalizarProcessamento
                  )
                }
              />
            ) : null
          }
        </Subscribe>
        <Message
          type="warning"
          visible={erroGeral != ''}
          message={erroGeral}
          onRequestClose={() => this.setState({ erroGeral: '' })}
        />
      </LoggedInScreen>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  headerSection: {
    padding: 0
  },
  campoFiltro: {
    flex: 1,
    minWidth: 250,
    maxWidth: 350,
    marginTop: 10,
    marginLeft: 16
  },
  section: {
    flexDirection: 'row',
    flex: 8,
    flexWrap: 'wrap',
    minHeight: 88,
    paddingTop: 6,
    paddingLeft: 0
  },
  buttons: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 350,
    minWidth: 220,
    marginLeft: 16,
    marginTop: 10
  },
  button: {
    height: 42,
    maxWidth: 138
  },
  textButton: {
    fontSize: 14
  }
});

export default withContainer(AssinaturaDigitalCartaoPontoContainer)(
  AssinaturaDigitalCartaoPonto
);
