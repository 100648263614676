import * as React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { translate } from 'secullum-i18n';
import { Card, CheckBox, Text } from 'secullum-react-native-ui';
import { getTheme, select } from '../modules/layout';

interface Props {
  checked?: boolean;
  showChecked?: boolean;
  onChecked?: (value: boolean) => void;
  showValidity?: boolean;
}

class ArquivoHeader extends React.Component<Props> {
  render() {
    const { checked, showChecked, onChecked, showValidity } = this.props;

    const fontSize = select({
      web: 15,
      tablet: 14,
      defaultValue: 12
    });

    return (
      <Card.Section style={styles.headerContainer}>
        {showChecked ? (
          <CheckBox
            nativeID="solicitacao-checkbox-todos"
            value={checked!}
            onChange={onChecked}
          />
        ) : (
          <View style={styles.placeholderCheckbox} />
        )}
        <Text
          bold
          flex={1}
          size={fontSize}
          color={theme.textColor3}
          style={styles.headerText}
        >
          {translate('Arquivo')}
        </Text>
        <Text
          bold
          flex={1}
          size={fontSize}
          color={theme.textColor3}
          style={styles.headerText}
        >
          {translate('Tipo')}
        </Text>
        <Text
          bold
          style={[styles.textData, showValidity ? { marginRight: 25 } : null]}
          size={fontSize}
          color={theme.textColor3}
        >
          {translate('Data')}
        </Text>
        {showValidity && (
          <Text
            bold
            style={styles.textData}
            size={fontSize}
            color={theme.textColor3}
          >
            {translate('Validade')}
          </Text>
        )}
        <Text
          align="center"
          bold
          style={styles.textStatus}
          size={fontSize}
          color={theme.textColor3}
        >
          {translate('Status')}
        </Text>
      </Card.Section>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  headerContainer: {
    paddingVertical: 6,
    flexDirection: 'row'
  },
  headerText: {
    paddingRight: 5
  },
  placeholderCheckbox: {
    width: 24
  },
  textData: {
    width: Platform.OS === 'web' ? 185 : 60
  },
  textStatus: {
    width: Platform.OS === 'web' ? 150 : 50
  }
});

export default ArquivoHeader;
