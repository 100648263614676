import * as React from 'react';
import { StyleSheet, Pressable, View, Platform } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { translate } from 'secullum-i18n';
import {
  isTablet,
  Space,
  formatDate,
  Card,
  CheckBox,
  Text
} from 'secullum-react-native-ui';
import { getTheme } from '../../shared/modules/layout';
import { isHighResolution } from '../modules/layout';
import { SolicitacoesStatus, SolicitacoesDados } from '../modules/types';
import { ehSolicitacaoAfastamento } from '../modules/utils';

interface Props {
  solicitacao: SolicitacoesDados;
  iconeSolicitacao: string;
  corSolicitacao: string;
  solicitacaoNativeId?: string;
  checked: boolean;
  exibirSeparador: boolean;
  onNavigate: () => void;
  onChecked?: () => void;
}

class SolicitacaoLinha extends React.Component<Props> {
  static defaultProps = {
    checked: false
  };

  render() {
    const {
      solicitacao,
      corSolicitacao,
      iconeSolicitacao,
      exibirSeparador,
      checked,
      onChecked,
      solicitacaoNativeId
    } = this.props;

    const fontSize = Platform.OS == 'web' ? 14 : isTablet() ? 15 : 12;

    const webDesktop = Platform.OS === 'web' && isHighResolution();
    let larguraCampoData: number;
    let valorCampoData: string;

    if (Platform.OS === 'web') {
      const formatoData = webDesktop ? 'eeee, dd/MM/yyyy' : 'dd/MM/yy';

      larguraCampoData = webDesktop ? 200 : 70;
      valorCampoData = this.formatarData(solicitacao, formatoData);
    } else if (isTablet()) {
      const formatoData = 'dd/MM/yy';

      larguraCampoData = 75;
      valorCampoData = this.formatarData(solicitacao, formatoData);
    } else {
      const formatoData = onChecked ? 'dd/MM' : 'dd/MM/yy';

      larguraCampoData = onChecked ? 45 : 65;
      valorCampoData = this.formatarData(solicitacao, formatoData);
    }

    return (
      <>
        {exibirSeparador && <Space height={10} />}
        <Card nativeID={solicitacaoNativeId}>
          <Card.Section>
            <Pressable
              style={({ pressed }) => [
                { opacity: pressed ? 0.7 : 1 },
                styles.linhaTouchable
              ]}
              onPress={() => this.props.onNavigate()}
            >
              {onChecked &&
                (solicitacao.status === SolicitacoesStatus.Pendente ? (
                  <CheckBox
                    nativeID={`solicitacao-checkbox-${solicitacao.id}`}
                    value={checked}
                    onChange={onChecked}
                  />
                ) : (
                  <View style={styles.placeholderCheckbox} />
                ))}
              <Text
                bold
                flex={webDesktop ? 1 : undefined}
                size={fontSize}
                style={[styles.linhaText, { width: larguraCampoData }]}
              >
                {valorCampoData}
              </Text>
              {onChecked && (
                <Text bold flex={2} size={fontSize} style={styles.linhaText}>
                  {solicitacao.funcionarioNome}
                </Text>
              )}
              <Text bold flex={1} size={fontSize} style={styles.linhaText}>
                {solicitacao.tipo}
              </Text>
              {!onChecked && (
                <Text
                  bold
                  flex={webDesktop ? 1 : undefined}
                  size={fontSize}
                  align="center"
                  style={[
                    styles.linhaText,
                    { width: Platform.OS == 'web' ? 65 : isTablet() ? 70 : 60 }
                  ]}
                >
                  {solicitacao.motivo || '-'}
                </Text>
              )}
              <FontAwesome
                testID={iconeSolicitacao}
                name={iconeSolicitacao}
                color={corSolicitacao}
                size={20}
                style={[
                  styles.icon,
                  {
                    width: Platform.OS == 'web' || isTablet() ? 50 : 40,
                    flex: webDesktop ? 1 : undefined
                  }
                ]}
              />
            </Pressable>
          </Card.Section>
        </Card>
      </>
    );
  }

  formatarData = (solicitacao: SolicitacoesDados, formatoData: string) => {
    const usarDataFormatoAfastamento =
      ehSolicitacaoAfastamento(solicitacao.tipoSolicitacao) &&
      solicitacao.dataFim;

    if (Platform.OS === 'web' && usarDataFormatoAfastamento) {
      return `${formatDate(solicitacao.data, formatoData)} ${translate(
        'Até'
      ).toLowerCase()} ${formatDate(solicitacao.dataFim!, formatoData)}`;
    }

    if (usarDataFormatoAfastamento) {
      return (
        formatDate(solicitacao.data, formatoData) +
        '\n' +
        formatDate(solicitacao.dataFim!, formatoData)
      );
    }

    return formatDate(solicitacao.data, formatoData);
  };
}

const theme = getTheme();

const styles = StyleSheet.create({
  linhaTouchable: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1
  },
  placeholderCheckbox: {
    width: 24
  },
  linhaText: {
    paddingRight: 5,
    ...(Platform.OS === 'web' ? { wordBreak: 'break-all' } : {}),
    color: theme.textColor1
  },
  icon: {
    textAlign: 'center'
  }
});

export default SolicitacaoLinha;
