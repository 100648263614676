import * as React from 'react';
import { StyleSheet, Platform, View } from 'react-native';
import { translate } from 'secullum-i18n';
import { isTablet, Card, CheckBox, Text } from 'secullum-react-native-ui';
import { getTheme, isHighResolution } from '../modules/layout';

interface Props {
  checked?: boolean;
  showChecked?: boolean;
  onChecked?: (value: boolean) => void;
}

class AssinaturaDigitalCartaoPontoHeader extends React.Component<Props> {
  render() {
    const { onChecked, checked, showChecked } = this.props;

    const fontSize = Platform.OS == 'web' ? 14 : isTablet() ? 15 : 12;

    const webDesktop = Platform.OS === 'web' && isHighResolution();

    return (
      <Card.Section style={styles.headerContainer}>
        {showChecked ? (
          <CheckBox
            nativeID="solicitacao-checkbox-todos"
            value={checked!}
            onChange={onChecked}
          />
        ) : (
          <View style={styles.placeholderCheckbox} />
        )}
        <Text
          bold
          flex={webDesktop ? 1 : undefined}
          size={fontSize}
          color={theme.textColor3}
          style={[styles.headerText, { flex: 0.4 }]}
        >
          {translate('Número')}
        </Text>
        <Text
          bold
          flex={webDesktop ? 1 : undefined}
          size={fontSize}
          color={theme.textColor3}
          style={[styles.headerText, { flex: 1 }]}
        >
          {translate('Descrição')}
        </Text>
        {Platform.OS === 'web' ? (
          <>
            <Text
              bold
              flex={webDesktop ? 1 : undefined}
              size={fontSize}
              color={theme.textColor3}
              align="left"
              style={[
                styles.headerText,
                { width: Platform.OS == 'web' || isTablet() ? 50 : 40 }
              ]}
            >
              {translate('Período')}
            </Text>
            <Text
              bold
              flex={webDesktop ? 1 : undefined}
              size={fontSize}
              color={theme.textColor3}
              align="center"
              style={[
                styles.headerText,
                { width: Platform.OS == 'web' || isTablet() ? 50 : 40 }
              ]}
            >
              {translate('Status')}
            </Text>
          </>
        ) : (
          <Text
            bold
            flex={webDesktop ? 1 : undefined}
            size={fontSize}
            color={theme.textColor3}
            align="center"
            style={[styles.headerText, { width: isTablet() ? 50 : 40 }]}
          >
            {translate('Status')}
          </Text>
        )}
      </Card.Section>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    paddingVertical: 6
  },
  headerText: {
    paddingRight: 5
  },
  placeholderCheckbox: {
    width: 24
  }
});

export default AssinaturaDigitalCartaoPontoHeader;
