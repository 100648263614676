import React from 'react';
import {
  Pressable,
  Platform,
  StyleSheet,
  StyleProp,
  ViewStyle
} from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { Card } from 'secullum-react-native-ui';
import { getTheme, isHighResolution } from '../modules/layout';

interface CardIncluirIndicadorProps {
  onClickIncluirIndicador: () => void;
  customStyles?: StyleProp<ViewStyle>;
  customContainerStyles?: StyleProp<ViewStyle>;
  cardHeight?: number;
}

export default class CardIncluirIndicador extends React.Component<CardIncluirIndicadorProps> {
  render() {
    const { customStyles, cardHeight } = this.props;
    return (
      <Pressable
        onPress={() => this.props.onClickIncluirIndicador()}
        style={({ pressed }) => [
          { opacity: pressed ? 0.7 : 1 },
          {
            width: Platform.OS === 'web' && isHighResolution() ? 342 : '100%',
            height:
              Platform.OS == 'web' && isHighResolution() && cardHeight
                ? cardHeight
                : 'auto',
            marginTop: 10
          },
          this.props.customContainerStyles
        ]}
      >
        <Card style={[styles.cardIncluirIndicador, customStyles]}>
          <Card.Section style={styles.containerIconeIndicador}>
            <FontAwesome
              id="incluir-indicador"
              name="plus"
              style={styles.iconeIndicador}
            />
          </Card.Section>
        </Card>
      </Pressable>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  cardIncluirIndicador: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  containerIconeIndicador: {
    padding: Platform.OS == 'web' ? 0 : 30
  },
  iconeIndicador: {
    fontSize: Platform.OS == 'web' ? 126.75 : 80,
    color: theme.disabledColor
  }
});
