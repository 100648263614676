import * as React from 'react';
import { StyleSheet } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { Card, Text, getTheme } from 'secullum-react-native-ui';

interface Props {
  icone: string;
  iconeCor?: string;
  texto: string;
  nativeID: string;
}

const theme = getTheme();

class CardAviso extends React.Component<Props> {
  static defaultProps = {
    iconeCor: theme.warningColor
  };

  render() {
    const { icone, iconeCor, texto, nativeID } = this.props;

    return (
      <Card nativeID={nativeID}>
        <Card.Section style={styles.section}>
          <FontAwesome name={icone} color={iconeCor} size={24} />
          <Text style={styles.text}>{texto}</Text>
        </Card.Section>
      </Card>
    );
  }
}

const styles = StyleSheet.create({
  section: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  text: {
    marginLeft: 10,
    marginRight: 10
  }
});

export default CardAviso;
