import * as React from 'react';
import {
  Dimensions,
  StyleSheet,
  View,
  Platform,
  Pressable
} from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { translate, translateFirstUpper } from 'secullum-i18n';
import {
  Card,
  RadioGroup,
  RangeDatePicker,
  isTablet,
  Text,
  ErrorMessage
} from 'secullum-react-native-ui';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory-native';
import Api from '../modules/api';
import { getTheme, isHighResolution } from '../modules/layout';
import {
  IndicadoresDados,
  CorTextoIndicadorCustomizado,
  IndicadorCustomizadoFuncionarioResultado
} from '../modules/types';
import {
  convertDecimalHourToHour,
  converterDataParaTimezoneBrasil
} from '../modules/utils';

interface CardIndicadorFuncionarioProps {
  titulo: string;
  nomeColunaIndicador: string;
  visualizacoes: IndicadoresDados;
  nativeID?: string;
  dataInicial: Date;
  dataFinal: Date;
  corTexto: CorTextoIndicadorCustomizado;
  indicadorId: number;
  onEditarIndicador: () => void;
  onErroConexao?: (mensagem: string, onTentarNovamente: () => void) => void;
}

interface State {
  tipoVisualizacao: string | null;
  width: number;
  limiteFiltro: number;
  camposFiltro: {
    dataInicial: Date;
    dataFinal: Date;
  };
  erroIndicador: string;
  visualizacoes: IndicadoresDados;
}

class CardIndicadorFuncionario extends React.Component<
  CardIndicadorFuncionarioProps,
  State
> {
  state: State = {
    tipoVisualizacao: null,
    width: Dimensions.get('window').width - 16 * 2,
    limiteFiltro: 11,
    camposFiltro: {
      dataInicial: this.props.dataInicial,
      dataFinal: this.props.dataFinal
    },
    erroIndicador: '',
    visualizacoes: this.props.visualizacoes
  };

  componentDidMount() {
    const dimension = Dimensions.get('window').width;

    if (dimension < 360) {
      this.setState({ limiteFiltro: 7 });
    }
  }

  handleDateChange = (value: Date, name: keyof State['camposFiltro']) => {
    this.setState(
      {
        camposFiltro: {
          ...this.state.camposFiltro,
          [name]: value,
          [name.replace('dataInicial', 'dataFinal')]: value // Alteração necessária para que o calendário não marque as datas a partir da última data final escolhida
        },
        erroIndicador: ''
      },
      () => {
        const { dataInicial, dataFinal } = this.state.camposFiltro;
        if (dataInicial > dataFinal) {
          this.setState({
            erroIndicador: translate('Selecione o período corretamente')
          });
        } else {
          this.buscarDadosIndicador();
        }
      }
    );
  };

  handleOnEndDateCancel = () => {
    this.buscarDadosIndicador();
  };

  handleTipoVisualizacaoChange = (tipoVisualizacao: string) => {
    this.setState({ tipoVisualizacao });
  };

  handleOnLayout = () => {
    this.setState({ width: Dimensions.get('window').width - 16 * 2 });
  };

  buscarDadosIndicador = async () => {
    const { dataInicial, dataFinal } = this.state.camposFiltro;
    const { indicadorId } = this.props;
    const api = new Api();

    await api.getDadosIndicadoresFuncionario(
      {
        dataInicio: dataInicial,
        dataFinal,
        indicadorId
      },
      {
        onSuccess: (resp: Array<IndicadorCustomizadoFuncionarioResultado>) => {
          if (resp && resp.length > 0) {
            this.setState({
              visualizacoes: resp[0].dadosIndicador,
              erroIndicador: ''
            });
          } else {
            this.setState({
              erroIndicador: ''
            });
          }
        },
        onError: (err: Error) => {
          if (err.name === 'TimeoutError' && this.props.onErroConexao) {
            this.props.onErroConexao(err.message, () =>
              this.buscarDadosIndicador()
            );
          } else {
            this.setState({
              erroIndicador: err.message
            });
          }
        }
      }
    );
  };

  buscarTipoVisualizacao = (): string => {
    const { limiteFiltro, tipoVisualizacao } = this.state;
    const { visualizacoes } = this.props;

    if (
      tipoVisualizacao !== null &&
      visualizacoes[tipoVisualizacao].valores.length < limiteFiltro
    ) {
      return tipoVisualizacao;
    }

    if (visualizacoes['dia'].valores.length < limiteFiltro) {
      return 'dia';
    }

    if (visualizacoes['semana'].valores.length < limiteFiltro) {
      return 'semana';
    }

    return 'mes';
  };

  buscarCorTexto = () => {
    const { corTexto } = this.props;
    if (corTexto == CorTextoIndicadorCustomizado.Amarelo) {
      return theme.warningColor;
    } else if (corTexto == CorTextoIndicadorCustomizado.Vermelho) {
      return theme.errorColor;
    } else {
      return theme.successColor;
    }
  };

  renderCardHeader() {
    const { nativeID, titulo } = this.props;
    return (
      <Card.Header
        nativeID={nativeID}
        title={titulo}
        numberOfLines={1}
        containerStyle={{ flex: 1 }}
      />
    );
  }

  render() {
    const { limiteFiltro, width, camposFiltro, visualizacoes } = this.state;
    const { titulo, nativeID, onEditarIndicador, nomeColunaIndicador } =
      this.props;
    const tiposVisualizacoes: Array<{ label: string; value: string }> = [
      { label: translate('Dia'), value: 'dia' },
      { label: translate('Semana'), value: 'semana' },
      { label: translateFirstUpper('Mês'), value: 'mes' }
    ];

    const visualizacao = visualizacoes[this.buscarTipoVisualizacao()];
    const total = visualizacao.valores.reduce((acc, x) => acc + x.y, 0);
    const cor = this.buscarCorTexto();
    const disabledList = tiposVisualizacoes
      .filter(x => visualizacoes[x.value].valores.length > limiteFiltro)
      .map(x => x.value);

    return (
      <>
        <Card
          style={[
            styles.cardHorasPeriodoMargin,
            Platform.OS === 'web' && styles.cardHorasPeriodo,
            {
              width: Platform.OS === 'web' && isHighResolution() ? 342 : '100%'
            }
          ]}
        >
          <View style={styles.headerIndicador}>
            {Platform.OS == 'web' ? (
              <div title={titulo} style={{ maxWidth: 300 }}>
                {this.renderCardHeader()}
              </div>
            ) : (
              this.renderCardHeader()
            )}
            <Pressable
              onPress={onEditarIndicador}
              style={({ pressed }) => [{ opacity: pressed ? 0.7 : 1 }]}
            >
              <FontAwesome
                testID="editar-indicador"
                name="edit"
                style={styles.editarIndicador}
              />
            </Pressable>
          </View>
          <Card.Section style={styles.section}>
            <View style={styles.wrapperCampos}>
              <RadioGroup
                items={tiposVisualizacoes}
                value={this.buscarTipoVisualizacao()}
                onChange={this.handleTipoVisualizacaoChange}
                disabled={disabledList}
              />
            </View>
            {/* Case 136088: Erro ao filtrar um indicador personalizado na Central do Funcionário App */}
            {total > 0 ? (
              <>
                <View style={styles.totalHorasContainer}>
                  <FontAwesome name="clock-o" size={18} color={cor} />
                  <Text
                    nativeID={`${nativeID}-total`}
                    bold
                    color={cor}
                    size={isTablet() ? 28 : 24}
                    style={styles.totalHorasValor}
                  >
                    {visualizacoes.exibirHorasEmDecimal
                      ? total.toFixed(2).replace('.', ',')
                      : convertDecimalHourToHour(total)}
                  </Text>
                  <Text
                    bold
                    color={theme.textColor3}
                    size={isTablet() ? 16 : 12}
                    style={styles.totalHorasTexto}
                  >
                    {nomeColunaIndicador} {translate('no Período')}
                  </Text>
                </View>
                <View
                  onLayout={this.handleOnLayout}
                  style={{ pointerEvents: 'none' }}
                >
                  <VictoryChart
                    padding={
                      Platform.OS === 'web'
                        ? { top: 5, right: 15, bottom: 26, left: 45 }
                        : { top: 16, right: 25, bottom: 32, left: 40 }
                    }
                    height={Platform.OS === 'web' ? 146 : 200}
                    width={
                      Platform.OS === 'web' && isHighResolution() ? 342 : width
                    }
                    domainPadding={10}
                  >
                    <VictoryAxis
                      style={{
                        axis: {
                          stroke: 'transparent'
                        },
                        tickLabels: {
                          fill: theme.textColor3,
                          fontSize: Platform.OS === 'web' ? 10 : 8,
                          //@ts-ignore
                          angle: 45
                        }
                      }}
                    />
                    <VictoryAxis
                      dependentAxis
                      tickFormat={(datum: any) =>
                        convertDecimalHourToHour(datum)
                      }
                      tickValues={total > 5 ? undefined : [0, 1, 2, 3, 4, 5]}
                      tickCount={8}
                      style={{
                        axis: {
                          stroke: 'transparent'
                        },
                        grid: {
                          stroke: theme.textColor3,
                          strokeWidth: 0.5
                        },
                        tickLabels: {
                          fill: theme.textColor3,
                          fontSize: Platform.OS === 'web' ? 12 : 8
                        }
                      }}
                    />
                    <VictoryBar
                      data={visualizacao.valores}
                      barWidth={8}
                      style={{
                        data: {
                          fill: cor
                        }
                      }}
                    />
                  </VictoryChart>
                </View>
              </>
            ) : (
              <Text
                bold
                align={'center'}
                size={isTablet() ? 24 : 20}
                style={styles.mensagemGrafico}
              >
                {translate('Não há dados suficientes para montar o gráfico')}
              </Text>
            )}
            <View style={styles.wrapperCampos}>
              <RangeDatePicker
                nativeID={`${nativeID}-data`}
                label={translate('Insira o Período')}
                startDate={converterDataParaTimezoneBrasil(
                  camposFiltro.dataInicial
                )}
                endDate={converterDataParaTimezoneBrasil(
                  camposFiltro.dataFinal
                )}
                onStartDateChange={value =>
                  this.handleDateChange(value, 'dataInicial')
                }
                onEndDateChange={value =>
                  this.handleDateChange(value, 'dataFinal')
                }
                onEndDateCancel={this.handleOnEndDateCancel}
              />
              <ErrorMessage message={this.state.erroIndicador} />
            </View>
          </Card.Section>
        </Card>
      </>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  cardHorasPeriodoMargin: {
    marginTop: 16
  },
  cardHorasPeriodo: {
    height: 342,
    marginRight: 16
  },
  section: {
    padding: 0
  },
  wrapperCampos: {
    padding: Platform.OS === 'web' ? 8 : isTablet() ? 20 : 16
  },
  totalHorasContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: isTablet() ? 20 : 16
  },
  totalHorasValor: {
    lineHeight: isTablet() ? 28 : 24,
    marginHorizontal: isTablet() ? 10 : 5
  },
  totalHorasTexto: {
    lineHeight: isTablet() ? 20 : 16
  },
  headerIndicador: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  editarIndicador: {
    fontSize: 20,
    marginRight: 8
  },
  mensagemGrafico: {
    paddingVertical: 5,
    paddingHorizontal: 16,
    height: Platform.OS === 'web' ? 170 : undefined,
    display: Platform.OS === 'web' ? 'flex' : undefined,
    alignItems: Platform.OS === 'web' ? 'center' : undefined
  }
});

export default CardIndicadorFuncionario;
