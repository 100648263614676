import React from 'react';
import {
  StyleSheet,
  Pressable,
  Linking,
  Platform,
  View,
  ScrollView
} from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { translate } from 'secullum-i18n';
import {
  Card,
  Text,
  Modal,
  ImageButton,
  Message,
  DetailsHeader
} from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import { getTheme } from '../../shared/modules/layout';
import { FuncionarioContainer } from '../containers/FuncionarioContainer';
import { DadosFuncionarioIndicadorCustomizadoGerente } from '../modules/types';

interface ModalIndicadorListaFuncionariosProps {
  funcionarios: Array<DadosFuncionarioIndicadorCustomizadoGerente>;
  corTextoHora: string;
  titulo: string;
  exibirModal: boolean;
  onClose: () => void;
}

interface State {
  alertaFuncionario: string;
}

export default class ModalIndicadorListaFuncionarios extends React.Component<
  ModalIndicadorListaFuncionariosProps,
  State
> {
  state: State = {
    alertaFuncionario: ''
  };

  handlePhoneClick = (celular: string) => {
    if (!celular) {
      this.setState({
        alertaFuncionario: translate(
          'Não há número celular cadastrado para este funcionário'
        )
      });

      return;
    }
    Linking.openURL(`tel:${celular}`);
  };

  renderListaFuncionarios() {
    const { funcionarios, corTextoHora } = this.props;

    return (
      <ScrollView>
        {funcionarios && funcionarios.length > 0 ? (
          funcionarios.map((funcionario, key) => (
            <Card
              nativeID={`viewFuncionarioLinha-${key}`}
              key={`funcionario-item-${key}`}
              style={styles.cardFuncionario}
            >
              <Card.Section style={styles.containerFuncionarios} key={key}>
                <Subscribe to={[FuncionarioContainer]}>
                  {(funcionarioContainer: FuncionarioContainer) => {
                    const permitirLigarFuncionariosAusentes =
                      funcionarioContainer.state.dados.dadosPerfilFuncionario
                        .permitirLigarFuncionariosAusentes;

                    return (
                      <Pressable
                        style={({ pressed }) => [
                          { opacity: pressed ? 0.7 : 1 },
                          styles.ausenteSection
                        ]}
                        onPress={() =>
                          permitirLigarFuncionariosAusentes &&
                          this.handlePhoneClick(funcionario.celular)
                        }
                      >
                        <Text bold flex={1} color={theme.textColor1}>
                          {funcionario.nome}
                        </Text>

                        {funcionario.totalHorasFuncionario != '' && (
                          <Text
                            bold
                            color={corTextoHora}
                            style={styles.horasFuncionario}
                          >
                            {funcionario.totalHorasFuncionario}
                          </Text>
                        )}
                        {permitirLigarFuncionariosAusentes && (
                          <FontAwesome name="phone" size={25} />
                        )}
                      </Pressable>
                    );
                  }}
                </Subscribe>
              </Card.Section>
            </Card>
          ))
        ) : (
          <View nativeID="viewMensagemNaoHaFuncionarios">
            <Text>{translate('Não há funcionários')}</Text>
          </View>
        )}
      </ScrollView>
    );
  }

  render() {
    const { titulo, exibirModal, onClose } = this.props;
    return (
      <Modal
        visible={exibirModal}
        overlayStyle={Platform.OS === 'web' ? styles.modalWeb : styles.modal}
      >
        <View
          style={Platform.OS === 'web' ? styles.containerWeb : styles.container}
          nativeID="viewListaFuncionariosIndicador"
        >
          <View style={styles.modalIcons}>
            <ImageButton
              style={styles.imageButtonLeft}
              icon="close"
              nativeID="viewListaFuncionariosIndicadorFechar"
              onPress={onClose}
            />
          </View>
          <View style={styles.headerContainer}>
            <DetailsHeader text={titulo} />
          </View>
          {this.renderListaFuncionarios()}
          <Message
            type="warning"
            message={this.state.alertaFuncionario}
            visible={this.state.alertaFuncionario !== ''}
            onRequestClose={() => this.setState({ alertaFuncionario: '' })}
          />
        </View>
      </Modal>
    );
  }
}
const theme = getTheme();

const styles = StyleSheet.create({
  cardFuncionario: {
    marginVertical: 1.5,
    marginHorizontal: 1.5
  },
  headerContainer: {
    marginBottom: 2
  },
  modal: {
    flex: 1,
    justifyContent: 'center'
  },
  modalWeb: {
    overflow: 'scroll'
  },
  modalIcons: {
    flexDirection: 'row'
  },
  ausenteSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 16
  },
  horasFuncionario: {
    marginRight: 10
  },
  containerWeb: {
    margin: 'auto',
    padding: 10,
    width: '90%',
    maxWidth: 366,
    backgroundColor: theme.backgroundColor2,
    borderRadius: 10,
    justifyContent: 'center'
  },
  imageButtonLeft: {
    borderWidth: 0,
    marginLeft: 'auto'
  },
  container: {
    backgroundColor: theme.backgroundColor1,
    marginHorizontal: 16,
    borderRadius: 5,
    maxHeight: '95%',
    padding: 10
  },
  containerFuncionarios: {
    padding: 0
  }
});
