import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { withRouter, matchPath } from 'react-router-dom';
import { translate } from 'secullum-i18n';
import { AppShell, Message, Space, Text } from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import AvisoCamposImportantes from '../../shared/components/AvisoCamposImportantes';
import AvisoDadosSensiveis from '../../shared/components/AvisoDadosSensiveis';
import LogoHorizontal from '../../shared/components/svg/SecullumPontoHorizontalWeb.jsx';
import TelaCamposImportantes, {
  AvisoCamposImportantesContainer
} from '../../shared/containers/AvisoCamposImportantesContainer';
import { AvisoDadosSensiveisLgpdContainer } from '../../shared/containers/AvisoDadosSensiveisLgpdContainer';
import { FuncionarioContainer } from '../../shared/containers/FuncionarioContainer';
import Notificacoes, {
  NotificacoesContainer
} from '../../shared/containers/NotificacoesContainer';
import Api from '../../shared/modules/api';
import { excluirDadosLoginAsync } from '../../shared/modules/auth';
import { getNaoPerguntarNovamenteCamposImportantesAsync } from '../../shared/modules/configuracoes';
import { getTheme, isDesktop } from '../../shared/modules/layout';
import { retornarMenu } from '../../shared/modules/menu';
import {
  retornarRotaWeb,
  retornarTelaPorPath,
  Telas
} from '../../shared/modules/telas';
import { salvarUltimaTelaAcessadaAsync } from '../../shared/modules/telasIniciais';
import { DadosFuncionario, DadosPeriodo } from '../../shared/modules/types';
import { navegar, RouteComponentComBancoIdProps } from '../modules/routes';

interface Props extends RouteComponentComBancoIdProps {
  screenTitle: string;
  children: React.ReactNode;
  exibirBotaoNotificacao?: boolean;
  carregarNotificacoes?: boolean;
}

interface State {
  mensagemErro: string;
  exibirAvisoCamposImportantes: boolean;
  carregouInformacoes: boolean;
}

class LoggedInScreen extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    exibirBotaoNotificacao: true,
    carregarNotificacoes: true
  };

  state: State = {
    mensagemErro: '',
    exibirAvisoCamposImportantes: false,
    carregouInformacoes: false
  };

  async componentDidMount() {
    if (this.props.carregarNotificacoes) {
      await Notificacoes.loadUltimosTrintaDiasNotificacoesAsync();
    }

    const api = new Api();
    const deveExibirAvisoCamposImportantes =
      await getNaoPerguntarNovamenteCamposImportantesAsync(
        api.funcionario.state.dados.id
      );

    this.setState({
      exibirAvisoCamposImportantes: !deveExibirAvisoCamposImportantes,
      carregouInformacoes: true
    });
  }

  handleMenuPress = async (
    path: string,
    dadosFuncionario: DadosFuncionario
  ) => {
    // Salva a tela que está sendo acessada no momento antes de mudar de tela
    const telaSelecionada = retornarTelaPorPath(
      path.replace('/:bancoId(\\d+)?', '')
    );

    if (telaSelecionada) {
      await salvarUltimaTelaAcessadaAsync(telaSelecionada, dadosFuncionario);
    }

    TelaCamposImportantes.manterTelaFechada({ manter: path !== '/logout' });

    if (path === '/logout') {
      await excluirDadosLoginAsync();
      Notificacoes.limparNotificacoes();

      // Removi o callback porque ao executar o método limparDadosFuncionario,
      // o contexto da API não estava mais disponível e o callback não seria
      // executado corretamente.
      new Api().funcionario.limparDadosFuncionario();

      navegar({ props: this.props, path: '/' });

      return;
    }

    navegar({ props: this.props, path });
  };

  render() {
    const { screenTitle, match, children } = this.props;
    const { exibirAvisoCamposImportantes, carregouInformacoes } = this.state;

    const descricaoHorario = (horario: DadosPeriodo) => {
      let descricao = horario.periodo;

      if (horario.horario) {
        descricao = descricao + ': ' + horario.horario;
      }

      return descricao;
    };

    return (
      <Subscribe
        to={[
          FuncionarioContainer,
          NotificacoesContainer,
          AvisoCamposImportantesContainer,
          AvisoDadosSensiveisLgpdContainer
        ]}
      >
        {(
          funcionario: FuncionarioContainer,
          notificacoes: NotificacoesContainer,
          avisoCamposImportantes: AvisoCamposImportantesContainer,
          avisoTermosLgpd: AvisoDadosSensiveisLgpdContainer
        ) => (
          <AppShell
            logoHeader={() => (
              <View style={styles.logoHeader}>
                <View style={styles.logo}>
                  <LogoHorizontal />
                </View>

                <View style={[styles.headerSistema, { alignSelf: 'center' }]}>
                  <Text style={styles.logoText}>
                    {translate('Central do Funcionário')}
                  </Text>
                </View>
              </View>
            )}
            logoMenu={() => (
              <View style={{ height: 50 }}>
                <View style={styles.logo}>
                  <LogoHorizontal />
                </View>

                <View style={[styles.headerSistema, styles.logoTextBaixo]}>
                  <Text style={styles.logoText}>
                    {translate('Central do Funcionário')}
                  </Text>
                </View>
              </View>
            )}
            greeting={{
              message: `${translate('Olá')}, ${funcionario.state.dados.nome}`,
              style: {
                color: theme.textColor2
              }
            }}
            screenTitle={
              avisoCamposImportantes.deveAbrirTela(funcionario.state.dados) &&
              exibirAvisoCamposImportantes
                ? translate('Aviso')
                : screenTitle
            }
            rightButton={
              this.props.exibirBotaoNotificacao
                ? {
                    nativeID: 'btnVisualizarNotificacoes',
                    icon: 'bell',
                    counter:
                      notificacoes.retornarQuantidadeNotificacoesNaoVisualizadas(),
                    onPress: () => {
                      navegar({ props: this.props, path: '/notificacoes' });
                    },
                    buttonStyle: {
                      color: theme.textColor3
                    }
                  }
                : undefined
            }
            renderUserData={() => (
              <>
                <Text
                  nativeID="dados-funcionario-nome"
                  size={18}
                  style={styles.funcionarioNome}
                >
                  {funcionario.state.dados.nome}
                </Text>
                <Text
                  nativeID="dados-funcionario-empresa"
                  style={styles.nomeEmpresaFuncao}
                  bold
                >
                  {funcionario.state.dados.empresa}
                </Text>
                <Text
                  nativeID="dados-funcionario-funcao"
                  style={styles.nomeEmpresaFuncao}
                >
                  {funcionario.state.dados.funcao}
                </Text>
                {funcionario.state.dados.listaHorarios.map((horario, index) => (
                  <Text
                    nativeID={`dados-funcionario-empresa-horario-${index}`}
                    key={index}
                    style={styles.horario}
                  >
                    {descricaoHorario(horario)}
                  </Text>
                ))}
                {isDesktop() && <Space />}
              </>
            )}
            menu={retornarMenu(funcionario.state.dados, false)}
            onMenuPress={path =>
              this.handleMenuPress(path, funcionario.state.dados)
            }
            isCurrentMenuPath={menuPath =>
              matchPath(match.url, { path: menuPath }) !== null
            }
            headerStyle={styles.headerStyle}
          >
            {carregouInformacoes &&
              (avisoTermosLgpd.abrirTelaDadosSensiveis(
                funcionario.state.dados.aceitouTermosLgpd
              ) ? (
                <AvisoDadosSensiveis />
              ) : avisoCamposImportantes.deveAbrirTela(
                  funcionario.state.dados
                ) && exibirAvisoCamposImportantes ? (
                <AvisoCamposImportantes
                  onCadastrar={mensagemErro => {
                    if (mensagemErro) {
                      this.setState({ mensagemErro });
                      return;
                    }

                    this.handleMenuPress(
                      retornarRotaWeb(Telas.DadosCadastrais),
                      funcionario.state.dados
                    );
                  }}
                />
              ) : (
                children
              ))}
            <Message
              type="warning"
              visible={this.state.mensagemErro !== ''}
              message={this.state.mensagemErro}
              onRequestClose={() => this.setState({ mensagemErro: '' })}
            />
          </AppShell>
        )}
      </Subscribe>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  funcionarioNome: {
    marginBottom: 5,
    fontFamily: theme.fontFamily1
  },
  horario: {
    color: theme.textColor3
  },
  nomeEmpresaFuncao: {
    color: theme.textColor2
  },
  logo: {
    width: 240,
    paddingLeft: 10
  },
  headerStyle: {
    backgroundColor: theme.backgroundColor2
  },
  headerSistema: {
    borderWidth: 2,
    borderRadius: 3,
    borderStyle: 'solid',
    borderColor: theme.backgroundColor3,
    backgroundColor: theme.backgroundColor3,
    marginLeft: 10
  },
  logoHeader: {
    flexDirection: 'row'
  },
  logoText: {
    fontFamily: theme.fontFamily2,
    color: theme.textColor4,
    fontSize: 13,
    paddingHorizontal: 10
  },
  logoTextBaixo: {
    position: 'absolute',
    marginTop: 30,
    marginLeft: 58
  }
});

export default withRouter(LoggedInScreen);
