import * as base64 from 'base-64';
import { Platform } from 'react-native';
import { getLanguage, translate } from 'secullum-i18n';
import { formatDate } from 'secullum-react-native-ui';
import config from '../../config.json';
import { fazerRequisicaoNativaAsync } from '../../native/modules/requisicaoNativa';
import Funcionario, {
  FuncionarioContainer
} from '../containers/FuncionarioContainer';
import Processando, {
  ProcessandoContainer
} from '../containers/ProcessandoContainer';
import { carregarDadosLoginAsync } from './auth';
import { salvarDataHoraServidorAsync } from './modificacaoDataHora';
import { carregarTempoMaximoRespostaAsync, fetchTimeout } from './timeout';
import {
  LoginDados,
  DadosEmpresa,
  DadosFuncionario,
  DadosListagemFuncionariosGerente,
  DadosAdicionaisFuncionario,
  CartaoPontoDados,
  PontoDiarioDados,
  BatidasResposta,
  InclusaoPontoResposta,
  DadosIncluirPonto,
  RegistroPontoPendencia,
  Justificativa,
  SolicitacaoDados,
  SolicitacoesFiltros,
  SolicitacoesDados,
  SolicitacaoFoto,
  SolicitacaoCarregarDados,
  AtualizarSenhaDados,
  IndicadoresDados,
  TotalSolicitacoesPendentes,
  DadosAjuda,
  NotificacaoDados,
  FiltroNotificacoes,
  OpcoesCartaoPonto,
  IndicadorCustomizado,
  IndicadorCustomizadoFuncionarioResultado,
  IndicadorCustomizadoGerenteResultado,
  TipoIndicadorCustomizado,
  IndicadorCustomizadoFiltro,
  ItemListaColunaCalculoIndicaor,
  ConfiguracoesNotificacoesDadosServidor,
  AssinaturaDigitalCartaoPontoDados,
  AssinaturaDigitalCartaoPontoFiltros,
  LoginFuncionarioDados,
  DefaultList,
  ResumoDiarioResultado,
  QualidadeVidaTrabalhoResposta,
  TipoInclusao,
  ArquivosFiltros,
  ArquivosDados,
  ArquivosFiltroData,
  DadosRecuperacaoSenha,
  DadosConfirmacaoCodigo,
  DadosConfirmacaoEmail,
  RepositorioArquivoAssinaturasDados,
  RepositorioArquivoAssinaturasDadosAprovar,
  RepositorioArquivoAssinaturasDadosRejeitar
} from './types';
import { ehSolicitacaoAfastamento } from './utils';

interface ValidationError {
  property: string;
  message: string;
}

interface RequestData {
  endpoint: string;
  method?: string;
  jsonBody?: any;
  bancoId?: string;
  esconderSpinner?: boolean;
  tipoRetorno?: 'json' | 'text';
}

export interface RequestResponse<TResult> {
  onSuccess: (result: TResult) => void;
  onValidationError?: (
    errorList: ValidationError[],
    errorObj: { [key: string]: string }
  ) => void;
  onUnauthorizedError?: () => void;
  onError?: (error: Error) => void;
}

export const getUrlApi = () => {
  // Guardamos o valor da variável de ambiente pois em algum momento ela é
  // redefinida e a aplicação acaba utilizando o valor que está no config.json
  const urlApiEnv = process.env.REACT_APP_URL_API;

  return urlApiEnv || config.urlApi;
};

class Api {
  funcionario: FuncionarioContainer;
  processando: ProcessandoContainer;
  testarDataHora: boolean;

  constructor(testarDataHora: boolean = false) {
    this.funcionario = Funcionario;
    this.processando = Processando;
    this.testarDataHora = testarDataHora;
  }

  getDadosEmpresaPrincipal = async (
    bancoId: string,
    resposta: RequestResponse<DadosEmpresa>
  ) => {
    await this.request(
      { endpoint: '/Empresas/Principal', bancoId: bancoId },
      resposta
    );
  };

  postLogin = async (
    dados: LoginDados,
    resposta: RequestResponse<DadosFuncionario>
  ) => {
    const jsonBody = {
      usuario: dados.usuario,
      senha: dados.senha,
      tokenDispositivo: dados.tokenDispositivo,
      plataformaLogin: dados.plataformaLogin,
      apelidoDispositivo: dados.apelidoDispositivo,
      identificacaoDispositivo: dados.identificacaoDispositivo,
      UsuarioAutenticacao: dados.UsuarioAutenticacao
    };

    await this.request(
      { endpoint: '/Login', method: 'POST', jsonBody, bancoId: dados.bancoId },
      resposta
    );
  };

  postLogout = async (resposta: RequestResponse<{}>) => {
    await this.request({ endpoint: '/Logout', method: 'POST' }, resposta);
  };

  getDadosFuncionario = async (
    loginFuncionarioDados: LoginFuncionarioDados,
    resposta: RequestResponse<DadosFuncionario>
  ) => {
    let endpoint = '/Funcionarios/' + loginFuncionarioDados.id;

    if (
      loginFuncionarioDados.apelidoDispositivo &&
      loginFuncionarioDados.identificacaoDispositivo
    ) {
      endpoint += `/${encodeURIComponent(
        loginFuncionarioDados.apelidoDispositivo
      )}`;
      endpoint += `/${encodeURIComponent(
        loginFuncionarioDados.identificacaoDispositivo
      )}`;
      endpoint += `/${loginFuncionarioDados.plataformaLogin}`;
    }

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getListaFuncionariosGerente = async (
    resposta: RequestResponse<DadosListagemFuncionariosGerente[]>
  ) => {
    await this.request(
      { endpoint: '/Funcionarios/ListarFuncionariosGerente', method: 'GET' },
      resposta
    );
  };

  getDadosAdicionaisFuncionario = async (
    id: number,
    resposta: RequestResponse<DadosAdicionaisFuncionario>
  ) => {
    const endpoint = '/Funcionarios/DadosAdicionais/' + id;

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  postAtualizarSenha = async (
    dados: AtualizarSenhaDados,
    resposta: RequestResponse<DadosFuncionario>
  ) => {
    await this.request(
      {
        endpoint: '/Funcionarios',
        method: 'POST',
        jsonBody: dados
      },
      resposta
    );
  };

  postDeletarConta = async (senha: string, resposta: RequestResponse<void>) => {
    await this.request(
      {
        endpoint: '/Funcionarios/DeletarConta',
        method: 'POST',
        jsonBody: senha
      },
      resposta
    );
  };

  postDadosAjuda = async (
    campos: DadosAjuda,
    resposta: RequestResponse<any>
  ) => {
    const endpoint = '/ServicoOnline/GravarPedidoAjuda';

    await this.request(
      {
        endpoint: endpoint,
        method: 'POST',
        jsonBody: campos
      },
      resposta
    );
  };

  postOpcoesCartaoPonto = async (
    campos: OpcoesCartaoPonto,
    resposta: RequestResponse<void>
  ) =>
    await this.request(
      { endpoint: '/RelatorioCartaoPonto', method: 'POST', jsonBody: campos },
      resposta
    );

  getCartaoPonto = async (
    dados: CartaoPontoDados,
    resposta: RequestResponse<BatidasResposta>
  ) => {
    let endpoint =
      '/Batidas/' +
      this.formatDate(dados.dataInicio) +
      '/' +
      this.formatDate(dados.dataFinal);

    if (dados.funcionarioId) {
      endpoint += `/${dados.funcionarioId}`;
    }

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getPontoDiario = async (
    dados: PontoDiarioDados,
    resposta: RequestResponse<BatidasResposta>
  ) => {
    const endpoint = '/Batidas/' + this.formatDate(dados.data);

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getListaUltimasPendencias = async (
    tipoInclusao: TipoInclusao,
    resposta: RequestResponse<Array<RegistroPontoPendencia>>
  ) => {
    const endpoint = `/IncluirPonto/ListarUltimasPendenciasFuncionario/${tipoInclusao}`;

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getInformacoesNovaInclusaoManual = async (
    resposta: RequestResponse<InclusaoPontoResposta>
  ) => {
    const endpoint = '/IncluirPonto';

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  postIncluirRespostaQualidadeVidaTrabalho = async (
    dados: QualidadeVidaTrabalhoResposta,
    resposta: RequestResponse<void>
  ) => {
    await this.request(
      {
        endpoint: '/QualidadeVidaTrabalho',
        method: 'POST',
        jsonBody: dados
      },
      resposta
    );
  };

  postIncluirPonto = async (
    dados: DadosIncluirPonto,
    opcoes: { esconderSpinner: boolean },
    resposta: RequestResponse<void>
  ) => {
    // Ao salvar uma batida offline no sqlite, o react-native corretamente
    // grava a data como UTC, ou seja, +3 horas do que estamos acostumados,
    // e só altera na hora de exibir. Porém, como salvamos no banco do pontoweb
    // a data sempre no fuso horário BRT, se eu mandar na maior vai salvar
    // sempre 3 horas pra frente. Por isso chamo esse "formatIsoDate",
    // que vai mandar a string de "exibição" pro servidor, ou seja, BRT.
    const jsonBody = {
      ...dados,
      dataHora: dados.dataHora ? this.formatIsoDate(dados.dataHora) : undefined
    };

    const queryParams = ['funcionarioId=' + this.funcionario.state.dados.id];

    await this.request(
      {
        endpoint: '/IncluirPonto?' + queryParams.join('&'),
        method: 'POST',
        jsonBody,
        esconderSpinner: opcoes.esconderSpinner
      },
      resposta
    );
  };

  getListaJustificativas = async (
    resposta: RequestResponse<Justificativa[]>
  ) => {
    const endpoint = '/Justificativas';

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getListaFiltro1 = async (resposta: RequestResponse<DefaultList[]>) => {
    const endpoint = '/Filtro/ListarFiltro1';

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getListaFiltro2 = async (resposta: RequestResponse<DefaultList[]>) => {
    const endpoint = '/Filtro/ListarFiltro2';

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getListaEscolaridade = async (resposta: RequestResponse<DefaultList[]>) => {
    const endpoint = '/Escolaridade';

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getEscolaridade = async (
    id: number,
    resposta: RequestResponse<DefaultList>
  ) => {
    const endpoint = `/Escolaridade/${id}`;

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getSolicitacao = async (
    dados: SolicitacaoCarregarDados,
    resposta: RequestResponse<SolicitacaoDados>
  ) => {
    let endpoint = `/Solicitacoes/${this.formatDate(dados.data)}`;

    if (dados.funcionarioId) {
      endpoint += `/${dados.funcionarioId}`;
    }

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  postSolicitacao = async (
    dados: SolicitacaoDados,
    resposta: RequestResponse<void>
  ) => {
    // Quando for solicitação de afastamento, formatamos a data antes de enviar para o servidor
    // para evitar que seja adicionado 3 horas a mais que o necessário
    // Data recebida no servidor sem formatação: 27/03/2024 03:00:00
    // Data recebida no servidor com formatação: 27/03/2024 00:00:00
    const jsonBody = {
      ...dados,
      dataInicioAfastamento: dados.dataInicioAfastamento
        ? this.formatIsoDate(dados.dataInicioAfastamento)
        : undefined,
      dataFimAfastamento: dados.dataFimAfastamento
        ? this.formatIsoDate(dados.dataFimAfastamento)
        : undefined
    };

    const endPoint = ehSolicitacaoAfastamento(dados.tipo)
      ? '/Solicitacoes/Afastamento'
      : '/Solicitacoes';

    await this.request(
      {
        endpoint: endPoint,
        method: 'POST',
        jsonBody: jsonBody
      },
      resposta
    );
  };

  postSalvarIndicador = async (
    dadosIndicadorCustomizado: IndicadorCustomizado,
    resposta: RequestResponse<void>
  ) => {
    await this.request(
      {
        endpoint: '/Indicadores/SalvarIndicador',
        method: 'POST',
        jsonBody: dadosIndicadorCustomizado
      },
      resposta
    );
  };

  postSolicitacaoDadosCadastrais = async (
    campos: DadosAdicionaisFuncionario,
    resposta: RequestResponse<void>
  ) => {
    await this.request(
      {
        endpoint: '/Solicitacoes/DadosCadastrais',
        method: 'POST',
        jsonBody: campos
      },
      resposta
    );
  };

  getSolicitacoes = async (
    dados: SolicitacoesFiltros,
    resposta: RequestResponse<Array<SolicitacoesDados>>
  ) => {
    const endpoint =
      '/Solicitacoes/' +
      (dados.dataInicio ? this.formatDate(dados.dataInicio) : 'null') +
      '/' +
      (dados.dataFinal ? this.formatDate(dados.dataFinal) : 'null') +
      '/' +
      dados.status +
      '/' +
      dados.perfil +
      (dados.solicitacaoId ? '/' + dados.solicitacaoId : '/0');

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getArquivos = async (
    dados: ArquivosFiltros,
    resposta: RequestResponse<Array<ArquivosDados>>
  ) => {
    let endpoint = '/RepositorioArquivo/' + dados.funcionarioId;

    if (dados.filtrosData == ArquivosFiltroData.PorPeriodo) {
      endpoint +=
        '/' +
        this.formatDate(dados.dataInicio) +
        '/' +
        this.formatDate(dados.dataFinal);
    }

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getFile = async (id: number, resposta: RequestResponse<any>) => {
    const endpoint = `/RepositorioArquivo/GetFile/${id}`;

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getFileType = async (id: number, resposta: RequestResponse<string>) => {
    const endpoint = `/RepositorioArquivo/GetType/${id}`;

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getArquivoNotificacao = async (
    arquivoId: number,
    resposta: RequestResponse<ArquivosDados>
  ) => {
    const endpoint = `/RepositorioArquivo/ArquivoNotificacao/${arquivoId}`;

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getSolicitacaoFoto = async (
    solicitacaoFotoId: number,
    resposta: RequestResponse<SolicitacaoFoto>
  ) => {
    const endpoint = `/Solicitacoes/FotoAtestado/${solicitacaoFotoId}`;

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getSolicitacaoFotoFuncionario = async (
    solicitacaoFotoId: number,
    resposta: RequestResponse<SolicitacaoFoto>
  ) => {
    const endpoint = `/Solicitacoes/FotoFuncionario/${solicitacaoFotoId}`;

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getSolicitacaoComprovanteEndereco = async (
    solicitacaoId: number,
    resposta: RequestResponse<SolicitacaoFoto>
  ) => {
    const endpoint = `/Solicitacoes/ComprovanteEndereco/${solicitacaoId}`;

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getTotalSolicitacoesPendentes = async (
    resposta: RequestResponse<TotalSolicitacoesPendentes>
  ) => {
    await this.request(
      { endpoint: '/Indicadores/TotalSolicitacoesPendentes', method: 'GET' },
      resposta
    );
  };

  getResumoDiario = async (
    resposta: RequestResponse<Array<ResumoDiarioResultado>>
  ) => {
    await this.request(
      { endpoint: '/Indicadores/ResumoDiario', method: 'GET' },
      resposta
    );
  };

  getIndicadoresHorasFaltantes = async (
    dados: CartaoPontoDados,
    resposta: RequestResponse<IndicadoresDados>
  ) => {
    const endpoint =
      '/Indicadores/HorasFaltantes/' +
      this.formatDate(dados.dataInicio) +
      '/' +
      this.formatDate(dados.dataFinal);

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getIndicadoresHorasExtras = async (
    dados: CartaoPontoDados,
    resposta: RequestResponse<IndicadoresDados>
  ) => {
    const endpoint =
      '/Indicadores/HorasExtras/' +
      this.formatDate(dados.dataInicio) +
      '/' +
      this.formatDate(dados.dataFinal);

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getIndicadoresSaldoBancoDeHoras = async (
    dados: CartaoPontoDados,
    resposta: RequestResponse<IndicadoresDados>
  ) => {
    const endpoint =
      '/Indicadores/SaldoBancoDeHoras/' +
      this.formatDate(dados.dataInicio) +
      '/' +
      this.formatDate(dados.dataFinal);

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getDadosIndicadoresFuncionario = async (
    dados: IndicadorCustomizadoFiltro,
    resposta: RequestResponse<Array<IndicadorCustomizadoFuncionarioResultado>>
  ) => {
    const endpoint =
      '/Indicadores/IndicadoresFuncionario/' +
      this.formatDate(dados.dataInicio) +
      '/' +
      this.formatDate(dados.dataFinal) +
      (dados.indicadorId ? '/' + dados.indicadorId : '');

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getDadosIndicadoresGerente = async (
    resposta: RequestResponse<Array<IndicadorCustomizadoGerenteResultado>>
  ) => {
    const endpoint = '/Indicadores/IndicadoresGerente/';
    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getIndicadorCustomizado = async (
    indicadorId: number,
    resposta: RequestResponse<IndicadorCustomizado>
  ) => {
    const endpoint = `/Indicadores/IndicadorCustomizado/${indicadorId}`;
    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getListaColunasCalculoIndicador = async (
    tipoIndicador: TipoIndicadorCustomizado,
    resposta: RequestResponse<Array<ItemListaColunaCalculoIndicaor>>
  ) => {
    const endpoint = `/Indicadores/ListarColunasCalculoIndicador/${tipoIndicador}`;
    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  excluirIndicador = async (
    indicadorId: number,
    resposta: RequestResponse<void>
  ) => {
    await this.request(
      {
        endpoint: `/Indicadores/ExcluirIndicador/${indicadorId}`,
        method: 'DELETE'
      },
      resposta
    );
  };

  postAprovarSolicitacao = async (
    dados: SolicitacoesDados,
    resposta: RequestResponse<SolicitacoesDados>
  ) => {
    const endPoint = ehSolicitacaoAfastamento(dados.tipoSolicitacao)
      ? '/Solicitacoes/AceitarAfastamento'
      : '/Solicitacoes/Aceitar';

    await this.request(
      {
        endpoint: endPoint,
        method: 'POST',
        jsonBody: dados
      },
      resposta
    );
  };

  postMarcarVistoSolicitacao = async (
    dados: SolicitacoesDados,
    resposta: RequestResponse<SolicitacoesDados>
  ) => {
    await this.request(
      {
        endpoint: '/Solicitacoes/MarcarVisto',
        method: 'POST',
        jsonBody: dados
      },
      resposta
    );
  };

  postDesmarcarVistoSolicitacao = async (
    dados: SolicitacoesDados,
    resposta: RequestResponse<SolicitacoesDados>
  ) => {
    await this.request(
      {
        endpoint: '/Solicitacoes/DesmarcarVisto',
        method: 'POST',
        jsonBody: dados
      },
      resposta
    );
  };

  postReprovarSolicitacao = async (
    dados: SolicitacoesDados,
    resposta: RequestResponse<SolicitacoesDados>
  ) => {
    const endPoint = ehSolicitacaoAfastamento(dados.tipoSolicitacao)
      ? '/Solicitacoes/DescartarAfastamento'
      : '/Solicitacoes/Descartar';

    await this.request(
      {
        endpoint: endPoint,
        method: 'POST',
        jsonBody: dados
      },
      resposta
    );
  };

  getAssinaturasDigitaisCartaoPonto = async (
    filtros: AssinaturaDigitalCartaoPontoFiltros,
    resposta: RequestResponse<AssinaturaDigitalCartaoPontoDados[]>
  ) => {
    const endpoint = `/AssinaturaDigitalCartaoPonto/${this.formatDate(
      filtros.dataInicio
    )}/${this.formatDate(filtros.dataFinal)}/${filtros.status}`;

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  getAssinaturaDigitalCartaoPonto = async (
    assinaturaId: number,
    resposta: RequestResponse<AssinaturaDigitalCartaoPontoDados>
  ) => {
    const endpoint = `/AssinaturaDigitalCartaoPonto/CarregarAssinatura/${assinaturaId}`;

    await this.request({ endpoint, method: 'GET' }, resposta);
  };

  postAprovarAssinaturaDigitalCartaoPonto = async (
    dados: AssinaturaDigitalCartaoPontoDados,
    resposta: RequestResponse<AssinaturaDigitalCartaoPontoDados>
  ) => {
    await this.request(
      {
        endpoint: '/AssinaturaDigitalCartaoPonto/Aprovar',
        method: 'POST',
        jsonBody: dados
      },
      resposta
    );
  };

  postReprovarAssinaturaDigitalCartaoPonto = async (
    dados: AssinaturaDigitalCartaoPontoDados,
    resposta: RequestResponse<AssinaturaDigitalCartaoPontoDados>
  ) => {
    await this.request(
      {
        endpoint: '/AssinaturaDigitalCartaoPonto/Descartar',
        method: 'POST',
        jsonBody: dados
      },
      resposta
    );
  };

  postAprovarAssinaturaDigitalArquivos = async (
    dados: RepositorioArquivoAssinaturasDadosAprovar,
    resposta: RequestResponse<RepositorioArquivoAssinaturasDados>
  ) => {
    await this.request(
      {
        endpoint: '/RepositorioArquivoAssinatura/Aprovar',
        method: 'POST',
        jsonBody: dados
      },
      resposta
    );
  };

  postReprovarAssinaturaDigitalArquivos = async (
    dados: RepositorioArquivoAssinaturasDadosRejeitar,
    resposta: RequestResponse<RepositorioArquivoAssinaturasDados>
  ) => {
    await this.request(
      {
        endpoint: '/RepositorioArquivoAssinatura/Rejeitar',
        method: 'POST',
        jsonBody: dados
      },
      resposta
    );
  };

  postMarcarNotificacaoVisualizada = async (
    notificacaoId: number,
    visualizada: boolean,
    resposta: RequestResponse<void>
  ) => {
    await this.request(
      {
        endpoint:
          '/Notificacoes/MarcarNotificacaoVisualizada/' +
          notificacaoId +
          '/' +
          visualizada,
        method: 'POST'
      },
      resposta
    );
  };

  getVerificarBancoValidoAsync = async (
    bancoId: string,
    resposta: RequestResponse<boolean>
  ) => {
    await this.request(
      {
        endpoint: '/Login/VerificarBancoValido/',
        method: 'GET',
        jsonBody: undefined,
        bancoId: bancoId
      },
      resposta
    );
  };

  getNotificacoes = async (
    resposta: RequestResponse<NotificacaoDados[]>,
    filtros: FiltroNotificacoes
  ) => {
    let endpoint = '/Notificacoes/';

    if (filtros) {
      endpoint =
        endpoint +
        this.formatDate(filtros.dataInicio) +
        '/' +
        this.formatDate(filtros.dataFinal);
    }

    await this.request(
      {
        endpoint,
        method: 'GET'
      },
      resposta
    );
  };

  postConfiguracoesNotificacoes = async (
    dados: ConfiguracoesNotificacoesDadosServidor,
    resposta: RequestResponse<void>
  ) => {
    await this.request(
      {
        endpoint: '/Configuracoes',
        method: 'POST',
        jsonBody: dados
      },
      resposta
    );
  };

  postConfiguracoesReceberComprovantePonto = async (
    receberEmailComprovantePonto: boolean,
    resposta: RequestResponse<void>
  ) => {
    await this.request(
      {
        endpoint: '/Configuracoes/ReceberComprovante',
        method: 'POST',
        jsonBody: { receberEmailComprovantePonto }
      },
      resposta
    );
  };

  getTermoDadosSensiveis = async (resposta: RequestResponse<string>) => {
    await this.request(
      {
        endpoint: '/TermoLgpd',
        method: 'GET',
        tipoRetorno: 'text'
      },
      resposta
    );
  };

  salvarAceiteTermosLgpd = async (resposta: RequestResponse<string>) => {
    await this.request(
      {
        endpoint: `/Funcionarios/SalvarAceiteTermosLgpd`,
        method: 'POST'
      },
      resposta
    );
  };

  getEmailValidoBancoUsuarioAsync = async (
    dados: {
      bancoId: string;
      usuario: string;
      usuarioAutenticacao: string;
    },
    resposta: RequestResponse<string>
  ) => {
    const jsonBody = {
      usuario: dados.usuario,
      UsuarioAutenticacao: dados.usuarioAutenticacao
    };

    await this.request(
      {
        endpoint: '/RecuperacaoSenha/ObterEmailOcultoFuncionario',
        method: 'POST',
        jsonBody,
        bancoId: dados.bancoId
      },
      resposta
    );
  };

  postEnviarTokenAsync = async (
    jsonBody: DadosConfirmacaoEmail,
    resposta: RequestResponse<string>
  ) => {
    await this.request(
      {
        endpoint: `/RecuperacaoSenha/ConfirmarEmail`,
        method: 'POST',
        jsonBody,
        bancoId: jsonBody.bancoId
      },
      resposta
    );
  };

  postConfirmarCodigoAsync = async (
    jsonBody: DadosConfirmacaoCodigo,
    resposta: RequestResponse<string>
  ) => {
    await this.request(
      {
        endpoint: `/RecuperacaoSenha/ConfirmarCodigo`,
        method: 'POST',
        jsonBody,
        bancoId: jsonBody.bancoId
      },
      resposta
    );
  };

  postTrocarSenhaAsync = async (
    jsonBody: DadosRecuperacaoSenha,
    resposta: RequestResponse<string>
  ) => {
    await this.request(
      {
        endpoint: `/RecuperacaoSenha/TrocarSenha`,
        method: 'POST',
        jsonBody,
        bancoId: jsonBody.bancoId
      },
      resposta
    );
  };

  private request = async <TResult>(
    {
      endpoint,
      method,
      jsonBody,
      bancoId,
      esconderSpinner,
      tipoRetorno = 'json'
    }: RequestData,
    {
      onSuccess,
      onValidationError,
      onUnauthorizedError,
      onError
    }: RequestResponse<TResult>
  ) => {
    const urlApiBase = getUrlApi();

    const handleSuccessResponse = async (response: Response) => {
      if (Platform.OS !== 'web') {
        const dataHoraServidorUtc = response.headers.get(
          'X-Sec-DataHoraServidorUtc'
        );

        if (dataHoraServidorUtc != null) {
          await salvarDataHoraServidorAsync(dataHoraServidorUtc);
        }
      }

      const content = await response.text();

      if (content) {
        switch (tipoRetorno) {
          case 'text':
            onSuccess(content as any);
            break;
          default:
            onSuccess(JSON.parse(content));
            break;
        }
      } else {
        onSuccess(undefined as any);
      }
    };

    const handleErrorResponse = async (response: Response) => {
      if (response.status === 401) {
        this.funcionario.limparDadosFuncionario();

        if (!onUnauthorizedError) {
          return;
        }

        onUnauthorizedError();
      } else if (response.status === 400 && onValidationError) {
        const listaErros = await response.json();

        const objetoErros = listaErros.reduce(
          (
            acc: { [key: string]: string },
            x: { property: string; message: string }
          ) => {
            return {
              ...acc,
              [x.property]: x.message
            };
          },
          {}
        );

        onValidationError(listaErros, objetoErros);
      } else {
        throw new Error(response.status + ' - ' + response.statusText);
      }
    };

    try {
      const headers = new Headers();

      const options: RequestInit = {
        method,
        headers
      };

      headers.append('Accept-Language', getLanguage());
      headers.append('pragma', 'no-cache');
      headers.append('cache-control', 'no-cache');

      const dadosLogin = await carregarDadosLoginAsync();

      if (dadosLogin !== null) {
        // Se tiver sido informado bancoId manualmente, dá prioridade pra ele
        // Senão, busca o do usuário logado
        if (!bancoId) {
          bancoId = dadosLogin.bancoId;
        }

        const basicAuth = base64.encode(
          dadosLogin.funcionarioNumero +
            ':' +
            dadosLogin.funcionarioSenha +
            ':' +
            dadosLogin.tipoUsuarioAutenticacao
        );

        headers.append('Authorization', `Basic ${basicAuth}`);
      }

      if (jsonBody) {
        headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(jsonBody);
      }

      if (!bancoId) {
        // Preenche algo só para dar match na rota
        bancoId = '0';
      }

      const versaoApp = process.env.REACT_APP_VERSION;

      // Adicionado um header contendo a versão atual do app para caso seja
      // necessário fazer qualquer tipo de tratamento no servidor no futuro,
      // ou então apenas uma informação extra para jogar em logs na hora de
      // investigar eventuais problemas.
      if (versaoApp) {
        headers.append('X-Sec-CentralFuncionarioVersao', versaoApp);
      }

      const url = urlApiBase + bancoId + endpoint;

      let requisicao: Promise<Response>;

      if (Platform.OS === 'web') {
        requisicao = fetch(url, options);
      } else {
        const timeout = await carregarTempoMaximoRespostaAsync();

        requisicao = fetchTimeout(url, options, timeout);
      }

      if (!esconderSpinner) {
        this.processando.atualizarQuantidade(requisicao);
      }

      const response = await requisicao;

      if (response.ok) {
        await handleSuccessResponse(response);
      } else {
        await handleErrorResponse(response);
      }
    } catch (error) {
      if (!onError) {
        return;
      }

      // issue 7833 (case 136037),
      // algum dispositivos apresentavam erro no certificado ssl (https), foi constatado que ocorria quando
      // o dispositivo estava com data desatualizados,
      // porém, as vezes ocorre falha com a mensagem 'network request failed', que é bastante genérica,
      // com isso, fizemos um módulo nativo para android para fazer esta consulta,
      // este módulo apresenta mensagens mais específicas em caso de falha,
      // para não duplicar as consultas em caso de erro, criamos uma flag para que este teste seja executado somente uma vez desde a abertura do app.
      if (this.testarDataHora && Platform.OS === 'android') {
        try {
          const urlVerificarDataAtualizada =
            urlApiBase.replace('https:', 'http:') +
            bancoId +
            '/Login/VerificarDataAtualizada' +
            '/' +
            new Date(Date.now()).toISOString();

          const dataHoraAtualizada = await fazerRequisicaoNativaAsync(
            urlVerificarDataAtualizada
          );

          if (dataHoraAtualizada == 'false') {
            onError(
              new Error(
                translate(
                  'A data do seu dispositivo está incorreta. Ajuste o relógio e tente novamente.'
                )
              )
            );
            return;
          }
        } catch (erro: any) {
          if (erro.toString() == 'Error: timeout') {
            erro = translate('Sem resposta do servidor') + '.';
          } else if (
            erro.toString().startsWith('Error: Failed to connect to') ||
            erro.toString().startsWith('Error: Unable to resolve host')
          ) {
            erro = translate('Sem conexão com a Internet') + '.';
          }

          onError(
            new Error(
              translate('Falha ao conectar com o servidor') + ': ' + erro
            )
          );

          return;
        }
      }

      const msg = translate(
        'Falha ao conectar com o servidor. Verifique sua conexão com a internet.'
      );

      if (
        //@ts-ignore
        error.name === 'TimeoutError' ||
        //@ts-ignore
        (error.name === 'TypeError' &&
          //@ts-ignore
          error.message === 'Network request failed')
      ) {
        //@ts-ignore
        error.name = 'TimeoutError';

        //@ts-ignore
        error.message = msg;
        //@ts-ignore
        await onError(error);
      } else {
        await onError(new Error(msg));
      }
    }
  };

  private formatDate = (date: Date | string) => {
    return formatDate(date, 'yyyy-MM-dd');
  };

  private formatIsoDate = (date: Date) => {
    return formatDate(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
  };
}

export default Api;
