import { Coordenadas, PerimetroAutorizado } from './types';

export async function reverseGeocodeAsync(lat: number, lng: number) {
  try {
    const response = await fetch(
      'https://geolocalizacao.secullum.com.br/Reverse?latitude=' +
        lat +
        '&longitude=' +
        lng
    );

    const data = await response.json();

    if (!data) {
      return '';
    }

    return data.endereco;
  } catch {
    // Caso ocorra algum erro na requisição, deve ficar sem endereço.
    return '';
  }
}

export const validarBatidaDentroPerimetro = (
  lat: number,
  lng: number,
  perimetrosAutorizados: PerimetroAutorizado[]
) => {
  return perimetrosAutorizados.some(perimetro => {
    if (perimetro.circulo && perimetro.radius) {
      const circulo = perimetro.coordenadas[0];

      if (estaDentroCirculo(lat, lng, circulo, perimetro.radius)) {
        return true;
      }
    } else if (temInterseccao(lat, lng, perimetro.coordenadas)) {
      return true;
    }

    return false;
  });
};

const temInterseccao = (
  lat: number,
  lng: number,
  coordenadas: Coordenadas[]
) => {
  let interseccao = false;

  for (let i = 0, j = coordenadas.length - 1; i < coordenadas.length; j = i++) {
    const xi = coordenadas[i].lat;
    const yi = coordenadas[i].lng;
    const xj = coordenadas[j].lat;
    const yj = coordenadas[j].lng;
    const iMaiorQueLng = yi > lng;
    const jMaiorQueLng = yj > lng;

    if (
      iMaiorQueLng !== jMaiorQueLng &&
      lat < ((xj - xi) * (lng - yi)) / (yj - yi) + xi
    ) {
      interseccao = !interseccao;
    }
  }

  return interseccao;
};

const estaDentroCirculo = (
  lat: number,
  lng: number,
  coordenadas: Coordenadas,
  raio: number
) => {
  return (
    distanciaEntrePontos(coordenadas.lat, coordenadas.lng, lat, lng) <= raio
  );
};

const distanciaEntrePontos = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
) => {
  if (lat1 === lat2 && lng1 === lng2) {
    return 0;
  }

  const thetaRad = grauParaRadiano(lng1 - lng2);
  const lat1Rad = grauParaRadiano(lat1);
  const lat2Rad = grauParaRadiano(lat2);

  let distance =
    Math.sin(lat1Rad) * Math.sin(lat2Rad) +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.cos(thetaRad);

  distance = radianoParaGrau(Math.acos(distance)) * 60 * 1.1515;

  // Converte para metros
  return distance * 1.609344 * 1000;
};

const grauParaRadiano = (deg: number) => {
  return (deg * Math.PI) / 180.0;
};

const radianoParaGrau = (rad: number) => {
  return (rad / Math.PI) * 180.0;
};
