import * as React from 'react';
import Clock from 'react-clock-interval';
import { DimensionValue, StyleSheet, Pressable, View } from 'react-native';
import ElevatedView from 'react-native-elevated-view';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { translate } from 'secullum-i18n';
import { formatDate, isTablet, Space, Text } from 'secullum-react-native-ui';
import { getTheme } from '../../shared/modules/layout';
import { converterDataHoraParaTimezoneBrasil } from '../../shared/modules/utils';
import { LeafletMap } from './LeafletMap';

export interface Coordenadas {
  latitude: number;
  longitude: number;
  accuracy: number | null;
}

export interface MapaBatidaProps {
  coordenadas: Coordenadas | null;
  nomeBatida?: string;
  data: Date;
  endereco: string | null;
  scrollEnabled: boolean;
  mostrarRelogio: boolean;
  alturaMapa?: DimensionValue;
  mapId?: string;
  foraDoPerimetro?: boolean;
  utilizaLocalizacaoFicticia?: boolean;
}

interface State {
  exibirBotao: boolean;
}

class MapaBatida extends React.Component<MapaBatidaProps, State> {
  leafletMap = React.createRef<LeafletMap>();

  state: State = {
    exibirBotao: false
  };

  static defaultProps = {
    scrollEnabled: true,
    mostrarRelogio: false
  };

  onMapReady = () => {
    this.setState({ exibirBotao: true });
  };

  renderizarBotaoPosicaoAtual = () => {
    if (this.props.scrollEnabled && this.state.exibirBotao) {
      return (
        <ElevatedView elevation={1} style={styles.botaoPosicaoAtual}>
          <Pressable
            onPress={() => this.leafletMap.current!.center()}
            hitSlop={{ bottom: 16, top: 16, right: 16, left: 16 }}
            style={({ pressed }) => [{ opacity: pressed ? 0.7 : 1 }]}
          >
            <FontAwesome name="crosshairs" size={24} color={theme.textColor3} />
          </Pressable>
        </ElevatedView>
      );
    }

    return null;
  };

  render() {
    const {
      coordenadas,
      nomeBatida,
      data,
      endereco,
      mostrarRelogio,
      scrollEnabled,
      alturaMapa,
      mapId,
      foraDoPerimetro,
      utilizaLocalizacaoFicticia
    } = this.props;

    const exibirMensagemInclusaoPontoSuspeita =
      foraDoPerimetro || utilizaLocalizacaoFicticia;

    const mensagemIncluaoPontoSuspeita = () => {
      if (utilizaLocalizacaoFicticia) {
        return translate(
          'O local da marcação pode estar sendo simulado pelo dispositivo.'
        );
      }

      if (foraDoPerimetro) {
        return translate('Fora do perímetro permitido pela empresa') + '.';
      }

      return '';
    };

    return (
      <>
        <View
          // Necessário informar `renderToHardwareTextureAndroid` para evitar crash
          // ao dar scroll até o fim da tela de inclusão de ponto.
          // https://github.com/react-native-webview/react-native-webview/issues/811
          renderToHardwareTextureAndroid
          style={[styles.mapContainer, { height: alturaMapa }]}
        >
          {coordenadas && (
            <React.Fragment>
              <LeafletMap
                ref={this.leafletMap}
                mapId={mapId || 'mapId'}
                onMapLoad={() => this.setState({ exibirBotao: true })}
                scrollEnabled={scrollEnabled}
                accuracy={coordenadas.accuracy || 0}
                markerPosition={{
                  lat: coordenadas.latitude,
                  lng: coordenadas.longitude
                }}
              />
              {this.renderizarBotaoPosicaoAtual()}
            </React.Fragment>
          )}
        </View>
        <View style={styles.textContainer}>
          {nomeBatida && (
            <Text
              size={isTablet() ? 18 : 14}
              color={theme.textColor2}
              style={styles.textBatida}
            >
              {nomeBatida}
            </Text>
          )}
          {mostrarRelogio ? (
            <Clock initialDateTime={new Date(data)}>
              {datetime => (
                <Text size={isTablet() ? 20 : 18} style={styles.textData}>
                  {formatDate(
                    converterDataHoraParaTimezoneBrasil(datetime),
                    'dd/MM/yyyy - HH:mm:ss'
                  )}
                </Text>
              )}
            </Clock>
          ) : (
            <Text bold size={isTablet() ? 20 : 18} style={styles.textData}>
              {formatDate(data, 'dd/MM/yyyy - HH:mm:ss')}
            </Text>
          )}
          {coordenadas && (
            <>
              <Space height={10} />
              <Text
                bold
                color={theme.textColor3}
                size={isTablet() ? 18 : 14}
                style={styles.textPrecisao}
              >
                {coordenadas && coordenadas.accuracy
                  ? coordenadas.accuracy.toFixed(2) + ' ' + translate('Metros')
                  : translate('Precisão desconhecida')}
              </Text>
              <Text
                size={isTablet() ? 16 : 12}
                color={theme.textColor3}
                align={'center'}
                style={styles.textEndereco}
              >
                {endereco}
              </Text>
            </>
          )}
          {exibirMensagemInclusaoPontoSuspeita && (
            <>
              <Space height={12} />
              <View style={styles.viewForaPerimetro}>
                <FontAwesome
                  name="warning"
                  color={theme.warningColor}
                  size={20}
                  nativeID={'mapa-batida-mensagem-fora-perimetro-icone'}
                />
                <Text
                  size={isTablet() ? 16 : 12}
                  color={theme.textColor3}
                  style={styles.textForaPerimetro}
                  nativeID={'mapa-batida-mensagem-fora-perimetro'}
                >
                  {mensagemIncluaoPontoSuspeita()}
                </Text>
              </View>
            </>
          )}
        </View>
      </>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  mapContainer: {
    overflow: 'hidden'
  },
  botaoPosicaoAtual: {
    backgroundColor: 'white',
    position: 'absolute',
    zIndex: 999,
    bottom: 10,
    right: 10,
    padding: 8,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3
  },
  textContainer: {
    padding: isTablet() ? 12 : 8,
    alignItems: 'center'
  },
  textBatida: {
    lineHeight: 19
  },
  textData: {
    lineHeight: isTablet() ? 21 : 19,
    marginTop: 5,
    color: theme.textColor3
  },
  textPrecisao: {
    lineHeight: isTablet() ? 19 : 16
  },
  textEndereco: {
    lineHeight: isTablet() ? 16 : 14,
    marginTop: 10
  },
  textForaPerimetro: {
    alignSelf: 'center',
    marginLeft: 5
  },
  viewForaPerimetro: {
    flexDirection: 'row'
  }
});

export default MapaBatida;
