import * as React from 'react';
import { translate } from 'secullum-i18n';
import { Subscribe } from 'unstated';
import { FuncionarioContainer } from '../../../shared/containers/FuncionarioContainer';
import { verificarExibirTela } from '../../../shared/modules/perfilFuncionario';
import { retornarRotaWeb, Telas } from '../../../shared/modules/telas';
import { buscarPrimeiraTelaInicialDisponivel } from '../../../shared/modules/telasIniciais';
import CardAlterarSenha from '../../../shared/screens/alterar-senha/CardAlterarSenha';
import LoggedInScreen from '../../components/LoggedInScreen';
import { RouteComponentComBancoIdProps, navegar } from '../../modules/routes';

interface Props extends RouteComponentComBancoIdProps {}

class AlterarSenha extends React.Component<Props> {
  handleIrCartaoPonto = (funcionario: FuncionarioContainer) => {
    let telaRedirecionar = Telas.CartaoPonto;

    const possuiPermissaoTelaRedirecionar = verificarExibirTela(
      telaRedirecionar,
      funcionario.state.dados.dadosPerfilFuncionario.telasOcultar
    );

    // Case 136139: Caso o usuário não tenha permissão de acesso à
    // tela de cartão ponto, manda para a primeira tela que tiver disponível
    if (!possuiPermissaoTelaRedirecionar) {
      telaRedirecionar = buscarPrimeiraTelaInicialDisponivel(
        funcionario.state.dados,
        Telas.AlterarSenha
      );
    }

    navegar({
      props: this.props,
      path: retornarRotaWeb(telaRedirecionar)
    });
  };

  render() {
    return (
      <LoggedInScreen screenTitle={translate('Alterar Senha')}>
        <Subscribe to={[FuncionarioContainer]}>
          {(funcionario: FuncionarioContainer) => (
            <CardAlterarSenha
              onCancelPress={() => this.handleIrCartaoPonto(funcionario)}
              onRequestSuccess={() => this.handleIrCartaoPonto(funcionario)}
            />
          )}
        </Subscribe>
      </LoggedInScreen>
    );
  }
}

export default AlterarSenha;
