import * as React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { translate } from 'secullum-i18n';
import { Subscribe } from 'unstated';
import { FuncionarioContainer } from '../../../shared/containers/FuncionarioContainer';
import { TipoTelaIndicadorCustomizado } from '../../../shared/modules/types';
import CardDadosIndicadorCustomizado from '../../../shared/screens/dados-indicador-customizado/CardDadosIndicadorCustomizado';
import LoggedInScreen from '../../components/LoggedInScreen';
import { RouteComponentComBancoIdProps, navegar } from '../../modules/routes';

interface Props
  extends RouteComponentComBancoIdProps<{
    modoTela: TipoTelaIndicadorCustomizado;
    indicadorId?: number;
  }> {}

class DadosIndicadorCustomizado extends React.Component<Props> {
  scrollView: ScrollView | null = null;

  handleCancelClick = (modoTela: TipoTelaIndicadorCustomizado) => {
    if (modoTela == TipoTelaIndicadorCustomizado.Funcionario) {
      navegar({ props: this.props, path: '/indicadores' });
    } else if (modoTela == TipoTelaIndicadorCustomizado.Gerente) {
      navegar({ props: this.props, path: `/indicadores-gerenciais/calculos` });
    }
  };

  render() {
    const { match } = this.props;
    const { modoTela, indicadorId } = match.params;

    return (
      <LoggedInScreen screenTitle={translate('Dados Indicador Customizado')}>
        <ScrollView
          ref={ref => (this.scrollView = ref)}
          style={styles.viewStyle}
        >
          <Subscribe to={[FuncionarioContainer]}>
            {(funcionario: FuncionarioContainer) => (
              <CardDadosIndicadorCustomizado
                indicadorId={indicadorId}
                modoTela={modoTela}
                onCancel={this.handleCancelClick}
                perfilUsuarioLogado={
                  funcionario.state.dados.dadosPerfilFuncionario
                    ? funcionario.state.dados.dadosPerfilFuncionario.tipoPerfil
                    : funcionario.state.dados.perfil
                }
              />
            )}
          </Subscribe>
        </ScrollView>
      </LoggedInScreen>
    );
  }
}

const styles = StyleSheet.create({
  viewStyle: {
    margin: -16,
    padding: 16
  }
});

export default DadosIndicadorCustomizado;
