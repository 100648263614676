import * as React from 'react';
import { StyleSheet, View, Pressable } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { translate } from 'secullum-i18n';
import {
  Button,
  Card,
  Text,
  formatDate,
  isTablet
} from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import { BatidasExibicao } from '../../../shared/containers/BatidasContainer';
import { FuncionarioContainer } from '../../../shared/containers/FuncionarioContainer';
import { getTheme, isHighResolution } from '../../../shared/modules/layout';
import {
  verificarExibirBotaoJustificarPonto,
  verificarExibirBotaoAjustePonto
} from '../../../shared/modules/perfilFuncionario';
import {
  TipoSituacao,
  BatidasItem,
  TipoPerfil,
  TipoPlano
} from '../../../shared/modules/types';
import { TipoDeTela } from '../../../shared/screens/cartao-ponto/DetalheDiaBatida';

interface Props {
  indice: number;
  data: Date;
  batidas: BatidasItem[];
  saldo: string;
  situacao: TipoSituacao;
  tipoDeTela: TipoDeTela;
  funcionarioNome?: string;
  registroPendente: boolean;
  existePeriodoEncerrado?: boolean;
  onDetalhesClick: (indice: number) => void;
  onAjustarPontoClick?: (data: Date) => void;
  onJustificarClick?: (data: Date) => void;
}

interface State {
  expandido: boolean;
}

class CardDiaResumido extends React.PureComponent<Props, State> {
  state: State = {
    expandido: false
  };

  usa10Batidas = () => {
    const { batidas } = this.props;
    return batidas.length > 6;
  };

  listaBatidasParaExibicao = (lista: Array<BatidasItem>): BatidasExibicao[] => {
    let listaBatidasJustificadas: BatidasExibicao[] = [];

    if (lista.every(x => x.valor.length === 0)) return listaBatidasJustificadas;

    lista.forEach(batida => {
      listaBatidasJustificadas.push({
        mensagem: `${batida.valor}`,
        temJustificativa: !/\d*[:]\d*/.test(batida.valor)
      });
    });

    return listaBatidasJustificadas;
  };

  renderizarListaDeBatidas = (
    batidas: BatidasExibicao[],
    corJustificativa: string
  ) => {
    if (batidas.length === 0)
      return (
        <Text bold flex={6} color={corJustificativa}>
          {translate('Nenhum ponto registrado')}
        </Text>
      );

    return batidas.map((batida, index) => (
      <Text
        key={index}
        bold
        flex={1}
        color={!batida.temJustificativa ? corJustificativa : theme.textColor2}
      >
        {batida.mensagem}
      </Text>
    ));
  };

  render() {
    const {
      data,
      batidas,
      saldo,
      situacao,
      registroPendente,
      indice,
      onAjustarPontoClick,
      onDetalhesClick,
      onJustificarClick,
      funcionarioNome,
      tipoDeTela,
      existePeriodoEncerrado
    } = this.props;

    const { expandido } = this.state;

    let color = theme.successColor;
    let iconName = 'check-circle';

    if (registroPendente) {
      color = theme.warningColor;
      iconName = 'hourglass-half';
    } else if (situacao === TipoSituacao.DiaComFaltas) {
      color = theme.errorColor;
      iconName = 'exclamation-circle';
    } else if (situacao === TipoSituacao.DiaIncompleto) {
      color = theme.warningColor;
      iconName = 'exclamation-triangle';
    }
    const colorText =
      situacao === TipoSituacao.DiaCompleto ? theme.textColor3 : color;

    return (
      <Card
        nativeID={'dia-resumido-' + formatDate(data, 'yyyy-MM-dd')}
        style={styles.card}
      >
        <Card.Section style={styles.section}>
          <Pressable onPress={() => this.setState({ expandido: !expandido })}>
            <View style={styles.container}>
              <FontAwesome
                testID={iconName}
                name={iconName}
                color={color}
                style={[
                  styles.icon,
                  { flexGrow: this.usa10Batidas() ? 0.5 : 1 }
                ]}
              />

              <View style={styles.batidasView}>
                {tipoDeTela != TipoDeTela.PontoDiario && (
                  <Text bold flex={2} color={theme.textColor1}>
                    {formatDate(data, 'eeee, dd/MM')}
                  </Text>
                )}
                {tipoDeTela == TipoDeTela.PontoDiario && (
                  <Text
                    bold
                    size={isTablet() ? 18 : 14}
                    flex={2}
                    color={theme.textColor1}
                  >
                    {funcionarioNome}
                  </Text>
                )}
                <View
                  style={[
                    styles.batidasSection,
                    { flex: !this.usa10Batidas() ? 6 : 10 },
                    this.usa10Batidas() &&
                      !isHighResolution() && { flexWrap: 'wrap' }
                  ]}
                >
                  {this.renderizarListaDeBatidas(
                    this.listaBatidasParaExibicao(batidas),
                    colorText
                  )}
                </View>
              </View>
              <Subscribe to={[FuncionarioContainer]}>
                {(funcionario: FuncionarioContainer) =>
                  (funcionario.state.dados.plano <= TipoPlano.Basic ||
                    (funcionario.state.dados.dadosPerfilFuncionario &&
                      funcionario.state.dados.dadosPerfilFuncionario
                        .tipoPerfil != TipoPerfil.FuncionarioRestrito)) && (
                    <Text bold flex={1} color={color}>
                      {['+00:00', '00,00'].includes(saldo) ? null : saldo}
                    </Text>
                  )
                }
              </Subscribe>
            </View>
          </Pressable>
        </Card.Section>
        {expandido && (
          <Card.Section style={styles.buttons}>
            <Button
              nativeID="botao-detalhes-cartao-ponto-resumido"
              text={translate('Detalhes')}
              onPress={() => onDetalhesClick(indice)}
              style={
                tipoDeTela == TipoDeTela.PontoDiario
                  ? styles.buttonPontoDiario
                  : styles.button
              }
            />
            <Subscribe to={[FuncionarioContainer]}>
              {(funcionario: FuncionarioContainer) => (
                <>
                  {verificarExibirBotaoAjustePonto(
                    tipoDeTela,
                    funcionario.state.dados.dadosPerfilFuncionario.telasOcultar,
                    funcionario.state.dados.dadosPerfilFuncionario
                      .restringirAjustePontos,
                    funcionario.state.dados.plano,
                    existePeriodoEncerrado
                  ) &&
                    onAjustarPontoClick && (
                      <Button
                        nativeID="botao-ajustar-ponto-cartao-ponto-resumido"
                        text={translate('Ajustar Ponto')}
                        onPress={() =>
                          onAjustarPontoClick && onAjustarPontoClick(data)
                        }
                        style={styles.button}
                      />
                    )}

                  {verificarExibirBotaoJustificarPonto(
                    tipoDeTela,
                    funcionario.state.dados.dadosPerfilFuncionario.telasOcultar,
                    existePeriodoEncerrado
                  ) &&
                    onJustificarClick && (
                      <Button
                        nativeID="botao-justificar-cartao-ponto-resumido"
                        text={translate('Justificar')}
                        onPress={() => onJustificarClick(data)}
                        style={styles.button}
                      />
                    )}
                </>
              )}
            </Subscribe>
          </Card.Section>
        )}
      </Card>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  card: {
    marginHorizontal: 3
  },
  section: {
    paddingVertical: 8
  },
  icon: {
    fontSize: 24,
    flexBasis: 0,
    flexGrow: 1,
    width: 70
  },
  batidasView: {
    flexDirection: 'row',
    flex: 8,
    flexWrap: 'wrap'
  },
  batidasSection: {
    flexDirection: 'row',
    flex: 6,
    width: 70
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  buttons: {
    flexDirection: 'row',
    margin: 'auto',
    maxWidth: 500,
    width: '100%',
    justifyContent: 'center'
  },
  button: {
    flex: 1,
    height: 30,
    marginLeft: 5,
    marginRight: 5,
    maxWidth: 210
  },
  buttonPontoDiario: {
    minWidth: 210
  }
});

export default CardDiaResumido;
