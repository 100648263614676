import React from 'react';
import { View, StyleSheet, DimensionValue } from 'react-native';
import { getTheme, Space, Text } from 'secullum-react-native-ui';

interface ProgressBarProps {
  nativeID?: string;
  total: number;
  atual: number;
}

class ProgressBar extends React.Component<ProgressBarProps> {
  static defaultProps = {
    atual: 0,
    total: 1
  };

  render() {
    const { total, atual, nativeID } = this.props;

    const percentual =
      (total ? ((atual / total) * 100).toFixed(2) : '0.00') + '%';

    const percentualWidth = ((total
      ? ((atual / total) * 100).toFixed(2)
      : '0.00') + '%') as DimensionValue;

    return (
      <View style={styles.container}>
        <View style={styles.progressBarOuter}>
          <View style={[styles.progressBarInner, { width: percentualWidth }]} />
        </View>
        <Space width={10} />
        <View>
          <Text style={{ color: '#555' }} nativeID={`${nativeID}-percentual`}>
            {percentual}
          </Text>
        </View>
      </View>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  progressBarOuter: {
    overflow: 'hidden',
    width: 180,
    height: 12,
    backgroundColor: theme.backgroundColor2
  },
  progressBarInner: {
    position: 'relative',
    backgroundColor: theme.textColor2,
    height: '100%'
  }
});

export default ProgressBar;
