import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { translate } from 'secullum-i18n';
import { TipoTelaIndicadorCustomizado } from '../../../shared/modules/types';
import CardIndicadoresGerente, {
  Visualizacao
} from '../../../shared/screens/indicadores-gerente/CardIndicadoresGerente';
import LoggedInScreen from '../../components/LoggedInScreen';
import { RouteComponentComBancoIdProps, navegar } from '../../modules/routes';

interface Props
  extends RouteComponentComBancoIdProps<{
    visualizacao?: Visualizacao;
  }> {}

class IndicadoresGerente extends React.Component<Props> {
  render() {
    const { match } = this.props;
    let visualizacao: Visualizacao | undefined;

    if (match.params.visualizacao) {
      visualizacao = match.params.visualizacao;
    }

    return (
      <LoggedInScreen screenTitle={translate('Indicadores Gerenciais')}>
        <CardIndicadoresGerente
          visualizacao={visualizacao}
          onClickSolicitacoes={() =>
            navegar({ props: this.props, path: `/solicitacoes-gerente/` })
          }
          onClickEditarIndicador={indicadorId =>
            navegar({
              props: this.props,
              path: `/dados-indicador-customizado/${
                TipoTelaIndicadorCustomizado.Gerente
              }/${indicadorId ? indicadorId : ''}`
            })
          }
        />
      </LoggedInScreen>
    );
  }
}

export default withRouter(IndicadoresGerente);
