import React from 'react';
import { Platform } from 'react-native';
import { Space } from 'secullum-react-native-ui';
import CardIncluirIndicador from '../../components/CardIncluirIndicador';
import CardIndicadorGerente from '../../components/CardIndicadorGerente';
import { IndicadorCustomizadoGerenteResultado } from '../../modules/types';

interface IndicadorCalculoProps {
  indicadoresCostomizados: Array<IndicadorCustomizadoGerenteResultado>;
  onClickEditarIndicador: (idIndicador?: number) => void;
}

class IndicadorCalculo extends React.Component<IndicadorCalculoProps> {
  render() {
    const { indicadoresCostomizados } = this.props;

    return (
      <>
        {indicadoresCostomizados !== null &&
          indicadoresCostomizados.map((dados, indice) => (
            <React.Fragment key={`container-indicador-gerente-key-${indice}`}>
              <CardIndicadorGerente
                nativeID={`indicador-gerente-${indice}`}
                nomeColunaIndicador={dados.nomeColunaIndicador}
                indicadorId={dados.indicador.id}
                titulo={dados.indicador.titulo}
                funcionarios={dados.funcionarios}
                corTexto={dados.indicador.corTexto}
                onEditarIndicador={() =>
                  dados.indicador.id &&
                  this.props.onClickEditarIndicador(dados.indicador.id)
                }
              />
              <Space width={Platform.OS === 'web' ? 16 : undefined} />
            </React.Fragment>
          ))}
        <CardIncluirIndicador
          onClickIncluirIndicador={this.props.onClickEditarIndicador}
          cardHeight={169}
        />
      </>
    );
  }
}

export default IndicadorCalculo;
