import { translate } from 'secullum-i18n';
import { formatDate } from 'secullum-react-native-ui';
import { Container } from 'unstated';
import { getTheme } from '../../shared/modules/layout';
import Api from '../modules/api';
import {
  AssinaturaDigitalCartaoPontoDados,
  AssinaturaDigitalCartaoPontoStatus,
  AssinaturaDigitalCartaoPontoFiltrosStatus,
  AssinaturaDigitalCartaoPontoFiltros
} from '../modules/types';

export interface State {
  lista: Array<AssinaturaDigitalCartaoPontoDados>;
  precisaProcessarAssinaturasDigitaisCartaoPonto?: boolean;
  erro: string;
  erroConexao: boolean;
  onTentarNovamente: (() => void) | null;
}

const theme = getTheme();

export const getIconeStatus = (status: AssinaturaDigitalCartaoPontoStatus) => {
  switch (status) {
    case AssinaturaDigitalCartaoPontoStatus.Aprovado:
      return 'thumbs-up';
    case AssinaturaDigitalCartaoPontoStatus.Rejeitado:
      return 'thumbs-down';
    default:
      return 'hourglass-half';
  }
};

export const getCorStatus = (status: AssinaturaDigitalCartaoPontoStatus) => {
  switch (status) {
    case AssinaturaDigitalCartaoPontoStatus.Aprovado:
      return theme.successColor;
    case AssinaturaDigitalCartaoPontoStatus.Rejeitado:
      return theme.errorColor;
    default:
      return theme.warningColor;
  }
};

export const getPeriodoFormatado = (
  dados: AssinaturaDigitalCartaoPontoDados,
  format = 'dd/MM/yy'
) => {
  return `${formatDate(dados.dataInicio, format)} - ${formatDate(
    dados.dataFim,
    format
  )}`;
};

export class AssinaturaDigitalCartaoPontoContainer extends Container<State> {
  state: State = {
    lista: [],
    erro: '',
    erroConexao: false,
    onTentarNovamente: null
  };

  itensStatusAssinaturaDigitalCartaoPonto = [
    {
      nativeID: 'assinatura-digital-cartao-ponto-filtros-todas',
      value: AssinaturaDigitalCartaoPontoFiltrosStatus.Todos,
      label: translate('Todas')
    },
    {
      nativeID: 'assinatura-digital-cartao-ponto-filtros-processadas',
      value: AssinaturaDigitalCartaoPontoFiltrosStatus.Processados,
      label: translate('Aceitas') + '/' + translate('Rejeitadas')
    },
    {
      nativeID: 'assinatura-digital-cartao-ponto-filtros-pendentes',
      value: AssinaturaDigitalCartaoPontoFiltrosStatus.Pendentes,
      label: translate('Aguardando Retorno')
    }
  ];

  loadAsync = async (filtros: AssinaturaDigitalCartaoPontoFiltros) => {
    const api = new Api();

    await api.getAssinaturasDigitaisCartaoPonto(filtros, {
      onSuccess: async (lista: Array<AssinaturaDigitalCartaoPontoDados>) => {
        this.setState({
          lista,
          precisaProcessarAssinaturasDigitaisCartaoPonto:
            lista.filter(
              x => x.estado === AssinaturaDigitalCartaoPontoStatus.NaoRespondido
            ).length > 0
        });
      },
      onError: (err: Error) => {
        this.setState({
          erro: err.message,
          erroConexao: err.name === 'TimeoutError',
          onTentarNovamente: () => this.loadAsync(filtros)
        });
      }
    });
  };

  carregarAsync = async (assinaturaId: number) => {
    const api = new Api();

    await api.getAssinaturaDigitalCartaoPonto(assinaturaId, {
      onSuccess: async (assinatura: AssinaturaDigitalCartaoPontoDados) => {
        const lista = [assinatura];
        this.setState({
          lista,
          precisaProcessarAssinaturasDigitaisCartaoPonto:
            lista.filter(
              x => x.estado === AssinaturaDigitalCartaoPontoStatus.NaoRespondido
            ).length > 0
        });
      },
      onError: (err: Error) => {
        this.setState({
          erro: err.message,
          erroConexao: err.name === 'TimeoutError',
          onTentarNovamente: () => this.carregarAsync(assinaturaId)
        });
      }
    });
  };

  limparErro = () => {
    this.setState({
      erro: '',
      erroConexao: false,
      onTentarNovamente: null
    });
  };
}
