import React, { Component } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Platform, StyleSheet, View, Pressable } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { translate } from 'secullum-i18n';
import { getTheme, isTablet, Space, Text } from 'secullum-react-native-ui';

interface Props extends FallbackProps {
  restart: () => void;
}

class Error extends Component<Props> {
  render() {
    const { error, restart } = this.props;

    let style = {};

    if (Platform.OS === 'web') {
      style = { height: '100vh' };
    } else {
      style = { flex: 1 };
    }

    return (
      <Pressable
        style={({ pressed }) => [{ opacity: pressed ? 0.7 : 1 }, style]}
        onPress={() => restart()}
      >
        <View style={styles.container}>
          <FontAwesome
            color="white"
            name="times-circle"
            size={isTablet() ? 120 : 80}
          />
          <Text style={styles.errorTitle}>
            {translate('Uma situação inesperada ocorreu')}
          </Text>
          <Space />
          <Text style={styles.errorMessage}>{error.name}</Text>
          <Text style={styles.errorMessage}>{error.message}</Text>
          <Space height={100} />
          <Text style={styles.textTouch}>
            {Platform.OS === 'web'
              ? translate('Clique na tela para recarregar')
              : translate('Toque na tela para recarregar')}
          </Text>
        </View>
      </Pressable>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#53565a'
  },
  errorTitle: {
    padding: 10,
    color: 'white',
    textAlign: 'center',
    fontSize: isTablet() ? 34 : 24,
    fontFamily: theme.fontFamily1
  },
  errorMessage: {
    color: 'white',
    textAlign: 'center',
    fontSize: isTablet() ? 32 : 22,
    fontFamily: theme.fontFamily2
  },
  textTouch: {
    color: 'white',
    textAlign: 'center',
    fontSize: isTablet() ? 28 : 18,
    fontFamily: theme.fontFamily2
  }
});

export default Error;
