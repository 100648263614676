import * as React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { translate } from 'secullum-i18n';
import { Subscribe } from 'unstated';
import { FuncionarioContainer } from '../../../shared/containers/FuncionarioContainer';
import { verificarExibirTela } from '../../../shared/modules/perfilFuncionario';
import { retornarRotaWeb, Telas } from '../../../shared/modules/telas';
import { buscarPrimeiraTelaInicialDisponivel } from '../../../shared/modules/telasIniciais';
import CardsDadosCadastrais from '../../../shared/screens/dados-cadastrais/CardDadosCadastrais';
import LoggedInScreen from '../../components/LoggedInScreen';
import { RouteComponentComBancoIdProps, navegar } from '../../modules/routes';

interface Props extends RouteComponentComBancoIdProps<{}> {}

class DadosCadastrais extends React.Component<Props> {
  scrollView: ScrollView | null = null;

  renderFoto = (foto: string) => {
    return (
      <img
        src={
          foto.indexOf('data:image') == -1
            ? `data:image/jpeg;base64,${foto}`
            : foto
        }
        style={{ height: 'auto', width: '100%' }}
      />
    );
  };

  handleIrCartaoPonto = (funcionario: FuncionarioContainer) => {
    let telaRedirecionar = Telas.CartaoPonto;

    const possuiPermissaoTelaRedirecionar = verificarExibirTela(
      telaRedirecionar,
      funcionario.state.dados.dadosPerfilFuncionario.telasOcultar
    );

    // Case 136139: Caso o usuário não tenha permissão de acesso à
    // tela de cartão ponto, manda para a primeira tela que tiver disponível
    if (!possuiPermissaoTelaRedirecionar) {
      telaRedirecionar = buscarPrimeiraTelaInicialDisponivel(
        funcionario.state.dados,
        Telas.DadosCadastrais
      );
    }

    navegar({
      props: this.props,
      path: retornarRotaWeb(telaRedirecionar)
    });
  };

  render() {
    return (
      <LoggedInScreen screenTitle={translate('Dados Cadastrais')}>
        <Subscribe to={[FuncionarioContainer]}>
          {(funcionario: FuncionarioContainer) => (
            <ScrollView
              ref={ref => (this.scrollView = ref)}
              style={styles.viewStyle}
            >
              <CardsDadosCadastrais
                onSuccess={() => this.handleIrCartaoPonto(funcionario)}
                onCancel={() => this.handleIrCartaoPonto(funcionario)}
                onValidationError={() => this.scrollView!.scrollTo({ y: 0 })}
                renderFoto={this.renderFoto}
              />
            </ScrollView>
          )}
        </Subscribe>
      </LoggedInScreen>
    );
  }
}

const styles = StyleSheet.create({
  viewStyle: {
    margin: -16,
    padding: 16
  }
});

export default DadosCadastrais;
