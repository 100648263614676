import * as React from 'react';
import { StyleSheet } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Card,
  DropDown,
  RangeDatePicker,
  Space,
  getTheme,
  ButtonProperties,
  Message
} from 'secullum-react-native-ui';
import ArquivoHeader from '../../../shared/components/ArquivoHeader';
import ArquivoLinha from '../../../shared/components/ArquivoLinha';
import { ButtonBar } from '../../../shared/components/ButtonBar';
import ModalAssinaturaDigitalArquivos from '../../../shared/components/ModalAssinaturaDigitalArquivos';
import { ArquivosContainer } from '../../../shared/containers/ArquivosContainer';
import withContainer from '../../../shared/containers/withContainer';
import {
  carregarDadosLoginAsync,
  DadosLogin
} from '../../../shared/modules/auth';
import {
  ArquivosFiltros,
  ArquivosDados,
  ArquivosFiltroData,
  ArquivoDadosStatus
} from '../../../shared/modules/types';
import {
  getDataInicioDiasAtras,
  isFirefox
} from '../../../shared/modules/utils';
import LoggedInScreen from '../../components/LoggedInScreen';
import {
  BROWSER_NO_SUPPORT_GEOLOCATION,
  getGeolocationError
} from '../../modules/geolocation';
import ArquivosDetalhes from './ArquivosDetalhes';

interface Props {
  container: ArquivosContainer;
}

interface State {
  dadosLogin: DadosLogin | null;
  filtros: ArquivosFiltros;
  arquivo: ArquivosDados | null;
  filtrosData?: ArquivosFiltroData;
  todasSelecionadas: boolean;
  listaIdsSelecionados: Array<number>;
  modalOpen: boolean;
  erroFiltro: string;
  erroLocalizacao: string | null;
}

class Arquivos extends React.Component<Props, State> {
  state: State = {
    dadosLogin: null,
    filtros: {
      dataInicio: getDataInicioDiasAtras(14),
      dataFinal: new Date(),
      funcionarioId: 0,
      filtrosData: ArquivosFiltroData.PorPeriodo
    },
    arquivo: null,
    todasSelecionadas: false,
    listaIdsSelecionados: [],
    modalOpen: false,
    erroFiltro: '',
    erroLocalizacao: null
  };

  locationSubscription: number | null = null;
  navigatorIsFirefox = isFirefox();

  async componentDidMount() {
    const dadosLogin = await carregarDadosLoginAsync();

    if (!dadosLogin) {
      return;
    }

    const filtros = {
      ...this.state.filtros,
      funcionarioId: dadosLogin.funcionarioId
    };

    this.setState({
      ...this.state,
      dadosLogin,
      filtros
    });

    this.startGeolocationWatch();
    await this.props.container.loadAsync(filtros);

    // O Firefox não permite realizar watchPosition sem foco na tela, ocasionando timeout.
    // Com base nisso adicionamos um EventListener para parar o watchPosition quando a tela
    // perde foco e retomá-lo quando o foco é recuperado.
    if (this.navigatorIsFirefox) {
      document.addEventListener(
        'visibilitychange',
        this.handleVisibilityChange
      );
    }
  }

  componentWillUnmount() {
    if (this.locationSubscription) {
      navigator.geolocation.clearWatch(this.locationSubscription);
    }

    if (this.navigatorIsFirefox) {
      document.removeEventListener(
        'visibilitychange',
        this.handleVisibilityChange
      );
    }
  }

  startGeolocationWatch = () => {
    if (!navigator.geolocation) {
      this.setState({
        erroLocalizacao: translate(BROWSER_NO_SUPPORT_GEOLOCATION)
      });
      return;
    }

    this.locationSubscription = navigator.geolocation.watchPosition(
      position => {
        this.props.container.setState({
          coordenadas: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            accuracy: position.coords.accuracy
          }
        });
      },
      error => {
        this.setState({ erroLocalizacao: getGeolocationError(error) });
      },
      { enableHighAccuracy: true, timeout: 30000, maximumAge: 1000 }
    );
  };

  handleDateInicioChange = (value: Date, name: keyof State['filtros']) => {
    this.setState({
      ...this.state,
      filtros: { ...this.state.filtros, [name]: value }
    });
  };

  handleChange = (value: string | Date, name: keyof State['filtros']) => {
    const novosFiltros = {
      ...this.state.filtros,
      [name]: value
    };
    this.setState(
      {
        ...this.state,
        filtros: novosFiltros
      },
      async () => {
        const { dataInicio, dataFinal } = this.state.filtros;

        if (dataInicio && dataFinal && dataInicio > dataFinal) {
          this.setState({
            ...this.state,
            erroFiltro: translate('Selecione o período corretamente')
          });

          return;
        }

        await this.props.container.loadAsync(this.state.filtros);
      }
    );
  };

  handleSelecionarTodas = (checked: boolean) => {
    const { container } = this.props;

    this.setState({
      todasSelecionadas: checked,
      listaIdsSelecionados: checked
        ? container.state.lista
            .filter(
              x =>
                x.solicitarAssinaturaFuncionario && x.assinaturas.length === 0
            )
            .map(y => y.id)
        : []
    });
  };

  handleSelecionar = (assinaturaDigitalArquivoId: number) => {
    const { listaIdsSelecionados } = this.state;

    this.setState({
      listaIdsSelecionados: listaIdsSelecionados.includes(
        assinaturaDigitalArquivoId
      )
        ? listaIdsSelecionados.filter(
            item => item !== assinaturaDigitalArquivoId
          )
        : [...this.state.listaIdsSelecionados, assinaturaDigitalArquivoId]
    });
  };

  handleAtualizarInformacoesAsync = async () => {
    await this.props.container.loadAsync(this.state.filtros);

    this.setState({ listaIdsSelecionados: [] });
  };

  handleVisibilityChange = () => {
    if (document.hidden) {
      if (this.locationSubscription) {
        navigator.geolocation.clearWatch(this.locationSubscription);
        this.locationSubscription = null;
      }
    } else {
      this.startGeolocationWatch();
    }
  };

  render() {
    const { container } = this.props;
    const {
      arquivo,
      dadosLogin,
      filtros,
      listaIdsSelecionados,
      todasSelecionadas,
      erroFiltro,
      modalOpen
    } = this.state;

    const hasValidade = container.state.lista.some(x => x.validadeArquivo);

    const leftButton: ButtonProperties = {
      disabled: listaIdsSelecionados.length === 0,
      text: translate('Aprovar'),
      onPress: () => {
        container.abrirModalValidacao(
          ArquivoDadosStatus.Aceita,
          null,
          null,
          dadosLogin
        );
        this.setState({ modalOpen: true });
      },
      textStyle: styles.textButton,
      style: [styles.button, { backgroundColor: theme.successColor }],
      nativeID: 'botao-aprovar-assinatura-digital-arquivos-selecionados'
    };

    const rightButton: ButtonProperties = {
      disabled: listaIdsSelecionados.length === 0,
      text: translate('Reprovar'),
      onPress: () => {
        container.abrirModalValidacao(
          ArquivoDadosStatus.Descartada,
          null,
          null,
          dadosLogin
        );
        this.setState({ modalOpen: true });
      },
      textStyle: styles.textButton,
      style: [styles.button, { backgroundColor: theme.errorColor }],
      nativeID: 'botao-reprovar-assinatura-digital-arquivos-selecionados'
    };

    return (
      <LoggedInScreen screenTitle={translate('Meus Arquivos')}>
        <Card>
          <Card.Header title={translate('Filtrar')} />
          <Card.Section style={styles.section}>
            <DropDown
              nativeID="arquivos-periodo"
              label={translate('Exibição dos Arquivos')}
              items={container.itensTipoFiltros}
              value={filtros.filtrosData}
              onChange={filtroPeriodo =>
                this.handleChange(filtroPeriodo, 'filtrosData')
              }
              style={styles.campoFiltro}
            />
            {filtros.filtrosData == ArquivosFiltroData.PorPeriodo && (
              <RangeDatePicker
                nativeID="arquivos-data"
                style={styles.campoFiltro}
                label={translate('Insira o Período')}
                startDate={filtros.dataInicio!}
                endDate={filtros.dataFinal!}
                onStartDateChange={date =>
                  this.handleDateInicioChange(date, 'dataInicio')
                }
                onEndDateChange={date => this.handleChange(date, 'dataFinal')}
              />
            )}
            <ButtonBar
              buttonBarStyle={styles.buttons}
              leftButton={leftButton}
              rightButton={rightButton}
            />
          </Card.Section>
          {container.state.retorno && (
            <Message
              nativeID="processar-assinatura-digital-arquivos-erro"
              type={container.state.retorno.tipoAviso}
              visible={container.state.retorno !== null}
              message={container.state.retorno?.mensagem ?? erroFiltro}
              onRequestClose={() => container.limparErro()}
            />
          )}
        </Card>
        <Space height={10} />
        <Card>
          <Card.Section style={styles.headerSection}>
            <ArquivoHeader
              checked={todasSelecionadas}
              showChecked={
                container.state.lista.filter(
                  x =>
                    x.assinaturas.length === 0 &&
                    x.solicitarAssinaturaFuncionario
                ).length > 0
              }
              onChecked={checked => this.handleSelecionarTodas(checked)}
              showValidity={hasValidade}
            />
          </Card.Section>
        </Card>
        <Space height={10} />
        {container.state.lista.map((arquivo, index) => (
          <ArquivoLinha
            key={arquivo.id}
            arquivo={arquivo}
            exibirSeparador={index > 0}
            checked={listaIdsSelecionados.includes(arquivo.id)}
            onNavigate={() => this.setState({ arquivo })}
            onChecked={() => this.handleSelecionar(arquivo.id)}
            showValidity={hasValidade}
          />
        ))}
        {arquivo && (
          <ArquivosDetalhes
            arquivo={arquivo}
            modalVisible={arquivo !== null}
            onClose={() => {
              this.setState({ arquivo: null });
              this.handleAtualizarInformacoesAsync();
            }}
            container={container}
          />
        )}
        {container.state.dadosModalAssinatura && modalOpen && (
          <ModalAssinaturaDigitalArquivos
            dadosAssinatura={container.state.dadosModalAssinatura}
            onConcluir={motivo => {
              container.concluirProcessamento(
                motivo,
                listaIdsSelecionados,
                () => {
                  this.handleAtualizarInformacoesAsync();
                  this.setState({ modalOpen: false });
                }
              );
            }}
            onCancelar={() => {
              container.handleCancelarModalValidacao();
              this.setState({ modalOpen: false });
            }}
          />
        )}
        {this.state.erroLocalizacao ? (
          <Message
            type={'warning'}
            visible
            message={this.state.erroLocalizacao}
            onRequestClose={() => this.setState({ erroLocalizacao: null })}
          />
        ) : null}
      </LoggedInScreen>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  headerSection: {
    padding: 0
  },
  campoFiltro: {
    flex: 1,
    minWidth: 250,
    maxWidth: 350,
    marginTop: 10,
    marginLeft: 16
  },
  section: {
    flexDirection: 'row',
    flex: 8,
    flexWrap: 'wrap',
    minHeight: 88,
    paddingTop: 6,
    paddingLeft: 0
  },
  buttons: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 350,
    minWidth: 220,
    marginLeft: 16,
    marginTop: 10
  },
  button: {
    height: 42,
    maxWidth: 138
  },
  textButton: {
    fontSize: 14
  }
});

export default withContainer(ArquivosContainer)(Arquivos);
