import * as React from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { translate } from 'secullum-i18n';
import {
  Button,
  Card,
  ErrorMessage,
  ImageButton,
  Message,
  RangeDatePicker,
  formatDate,
  isTablet,
  Text
} from 'secullum-react-native-ui';
import { ArquivosContainer } from '../../../shared/containers/ArquivosContainer';
import { AssinaturaDigitalCartaoPontoContainer } from '../../../shared/containers/AssinaturaDigitalCartaoPontoContainer';
import { FuncionarioContainer } from '../../../shared/containers/FuncionarioContainer';
import { NotificacoesContainer } from '../../../shared/containers/NotificacoesContainer';
import { SolicitacoesContainer } from '../../../shared/containers/SolicitacoesContainer';
import withContainer from '../../../shared/containers/withContainer';
import { getTheme, isHighResolution } from '../../../shared/modules/layout';
import {
  verificarExibirBotaoAjustePonto,
  exibirTelaInclusaoPonto,
  verificarExibirBotaoJustificarPonto,
  verificarExibirTela
} from '../../../shared/modules/perfilFuncionario';
import { Telas } from '../../../shared/modules/telas';
import {
  NotificacaoDados,
  TipoNotificacao,
  SolicitacoesDados,
  AssinaturaDigitalCartaoPontoDados,
  ArquivosDados
} from '../../../shared/modules/types';
import {
  converterDataParaTimezoneBrasil,
  getDataInicioDiasAtras
} from '../../../shared/modules/utils';
import LoggedInScreen from '../../components/LoggedInScreen';
import { navegar, RouteComponentComBancoIdProps } from '../../modules/routes';
import ArquivosDetalhes from '../arquivos/ArquivosDetalhes';
import AssinaturasDigitaisCartaoPontoDetalhes from '../assinatura-digital-cartao-ponto/AssinaturasDigitaisCartaoPontoDetalhes';
import SolicitacoesDetalhes from '../solicitacoes/SolicitacoesDetalhes';

interface Props extends RouteComponentComBancoIdProps {
  notificacoesContainer: NotificacoesContainer;
  solicitacoesContainer: SolicitacoesContainer;
  funcionarioContainer: FuncionarioContainer;
  assinaturaDigitalCartaoPontoContainer: AssinaturaDigitalCartaoPontoContainer;
  arquivosContainer: ArquivosContainer;
}

interface State {
  solicitacaoSelecionada: SolicitacoesDados | null;
  assinaturaDigitalCartaoPontoSelecionada: AssinaturaDigitalCartaoPontoDados | null;
  arquivoSelecionado: ArquivosDados | null;
  erroGeral: string;
  filtros: {
    dataInicio: Date;
    dataFinal: Date;
  };
  larguraCardFiltro: number;
}

class Notificacao extends React.Component<Props, State> {
  state: State = {
    solicitacaoSelecionada: null,
    assinaturaDigitalCartaoPontoSelecionada: null,
    arquivoSelecionado: null,
    filtros: {
      dataInicio: getDataInicioDiasAtras(30),
      dataFinal: new Date()
    },
    erroGeral: '',
    larguraCardFiltro: 0
  };

  notificacaoSelecionada: NotificacaoDados | null = null;

  componentDidMount() {
    this.props.notificacoesContainer.limparErro();
    this.carregarListaNotificacoes();
  }

  carregarListaNotificacoes = async () => {
    const { filtros } = this.state;
    await this.props.notificacoesContainer.loadNotificacoesAsync(filtros);
  };

  handleVisualizarNotificacaoClick = async (
    notificacaoDados: NotificacaoDados
  ) => {
    await this.props.notificacoesContainer.marcarNotificacaoVisualizadaAsync(
      notificacaoDados.id,
      !notificacaoDados.visualizada,
      async () => await this.carregarListaNotificacoes()
    );
  };

  handleAcessarSolicitacao = async (notificacao: NotificacaoDados) => {
    if (!notificacao.solicitacaoId) return;

    const { solicitacoesContainer, funcionarioContainer } = this.props;

    await solicitacoesContainer.loadAsyncPorSolicitacaoId(
      notificacao.solicitacaoId,
      funcionarioContainer.state.dados.perfil
    );

    const listaSolicitacoes = this.props.solicitacoesContainer.state.lista;

    this.notificacaoSelecionada = notificacao;

    if (listaSolicitacoes && listaSolicitacoes.length > 0) {
      this.setState({
        solicitacaoSelecionada: listaSolicitacoes[0]
      });
    }
  };

  handleAcessarAssinaturaDigitalCartaoPonto = async (
    notificacao: NotificacaoDados
  ) => {
    if (!notificacao.assinaturaDigitalCartaoPontoId) return;

    const { assinaturaDigitalCartaoPontoContainer } = this.props;

    await assinaturaDigitalCartaoPontoContainer.carregarAsync(
      notificacao.assinaturaDigitalCartaoPontoId
    );

    const listaAssinaturasDigitaisCartaoPonto =
      this.props.assinaturaDigitalCartaoPontoContainer.state.lista;

    this.notificacaoSelecionada = notificacao;

    if (
      listaAssinaturasDigitaisCartaoPonto &&
      listaAssinaturasDigitaisCartaoPonto.length > 0
    ) {
      this.setState({
        assinaturaDigitalCartaoPontoSelecionada:
          listaAssinaturasDigitaisCartaoPonto[0]
      });
    }
  };

  handleAcessarArquivoAsync = async (notificacao: NotificacaoDados) => {
    if (!notificacao.repositorioArquivoId) return;

    const { arquivosContainer } = this.props;

    await arquivosContainer.carregarAsync(notificacao.repositorioArquivoId);

    const arquivo = this.props.arquivosContainer.state.arquivo;

    this.notificacaoSelecionada = notificacao;

    if (arquivo) {
      this.setState({
        arquivoSelecionado: arquivo
      });
    }
  };

  handleDateChange = (value: Date, name: keyof State['filtros']) => {
    const novosFiltros = {
      ...this.state.filtros,
      [name]: value
    };

    this.setState(
      {
        filtros: novosFiltros,
        erroGeral: ''
      },
      async () => {
        if (name === 'dataFinal') {
          if (this.state.filtros.dataInicio > this.state.filtros.dataFinal) {
            this.setState({
              erroGeral: translate('Selecione o período corretamente')
            });
            return;
          }
          await this.carregarListaNotificacoes();
        }
      }
    );
  };

  renderIconeNotificacao = (tipoNotificacao: TipoNotificacao) => {
    let nomeIcone = 'exclamation-triangle';
    let corIcone = theme.warningColor;

    switch (tipoNotificacao) {
      case TipoNotificacao.SolicitacaoDescartada:
      case TipoNotificacao.RegistroPontoRejeitado:
        nomeIcone = 'thumbs-down';
        corIcone = theme.errorColor;
        break;
      case TipoNotificacao.NovaAssinaturaDigitalCartaoPonto:
      case TipoNotificacao.NovoArquivoDisponivel:
        nomeIcone = 'check-square-o';
        break;
    }

    return (
      <FontAwesome
        name={nomeIcone}
        color={corIcone}
        style={styles.iconeTipoNotificacao}
      />
    );
  };

  renderBotoesRegistroPontoRejeitado = (
    notificacao: NotificacaoDados,
    index: number
  ) => {
    const { funcionarioContainer } = this.props;
    const { dados } = funcionarioContainer.state;

    const exibirBotaoInclusaoPonto = exibirTelaInclusaoPonto(dados);

    if (!exibirBotaoInclusaoPonto) {
      return;
    }

    return (
      <Card.Section style={styles.secaoBotoes}>
        <Button
          text={translate('Visualizar')}
          nativeID={`btnVisualizarRegistroRejeitado${index}`}
          style={styles.botao}
          textStyle={styles.botaoTexto}
          onPress={async () => {
            if (!notificacao.visualizada) {
              this.handleVisualizarNotificacaoClick(notificacao);
            }

            navegar({
              props: this.props,
              path: '/incluir-ponto?ultimos-registros=1'
            });
          }}
        />
      </Card.Section>
    );
  };

  renderBotoesSolicitacaoDescartada = (
    notificacao: NotificacaoDados,
    index: number
  ) => {
    const { dados } = this.props.funcionarioContainer.state;

    // Sec-issues#10274: Só exibe o botão 'Visualizar' na notificação se tiver acesso a tela,
    // pois estava sendo possível acessar a tela de Solicitações mesmo essa tela estando oculta para o funcionário
    const possuiPermissaoTelaSolicitacoes = verificarExibirTela(
      Telas.Solicitacoes,
      dados.dadosPerfilFuncionario.telasOcultar
    );

    return (
      possuiPermissaoTelaSolicitacoes && (
        <Card.Section style={styles.secaoBotoes}>
          <Button
            text={translate('Visualizar')}
            nativeID={`btnVisualizarSolicitacao${index}`}
            style={styles.botao}
            textStyle={styles.botaoTexto}
            onPress={async () => {
              if (!notificacao.visualizada) {
                this.handleVisualizarNotificacaoClick(notificacao);
              }

              this.handleAcessarSolicitacao(notificacao);
            }}
          />
        </Card.Section>
      )
    );
  };

  renderBotoesEsqueceuBaterPonto = (notificacao: NotificacaoDados) => {
    const { funcionarioContainer } = this.props;
    const { dados } = funcionarioContainer.state;

    const exibirBotaoInclusaoPonto = exibirTelaInclusaoPonto(dados);

    const exibirBotaoAjustePonto = verificarExibirBotaoAjustePonto(
      undefined,
      dados.dadosPerfilFuncionario.telasOcultar
    );

    const exbirBotaoJustificarPonto = verificarExibirBotaoJustificarPonto(
      undefined,
      dados.dadosPerfilFuncionario.telasOcultar
    );

    if (
      !(
        exbirBotaoJustificarPonto &&
        exibirBotaoAjustePonto &&
        exibirBotaoInclusaoPonto
      )
    ) {
      return;
    }

    return (
      <Card.Section style={styles.secaoBotoes}>
        {exibirBotaoInclusaoPonto && (
          <Button
            text={translate('Incluir Ponto')}
            textStyle={styles.botaoTexto}
            style={styles.botao}
            onPress={() => {
              if (!notificacao.visualizada) {
                this.handleVisualizarNotificacaoClick(notificacao);
              }

              navegar({
                props: this.props,
                path: '/incluir-ponto'
              });
            }}
          />
        )}
        {exibirBotaoAjustePonto && (
          <Button
            text={translate('Ajustar Ponto')}
            textStyle={styles.botaoTexto}
            style={styles.botao}
            onPress={() => {
              if (!notificacao.visualizada) {
                this.handleVisualizarNotificacaoClick(notificacao);
              }

              let path = `/ajustar-ponto/${formatDate(
                notificacao.dataHora,
                'yyyy-MM-dd'
              )}`;

              navegar({
                props: this.props,
                path
              });
            }}
          />
        )}
        {exbirBotaoJustificarPonto && (
          <Button
            text={translate('Justificar')}
            textStyle={styles.botaoTexto}
            style={styles.botao}
            onPress={() => {
              if (!notificacao.visualizada) {
                this.handleVisualizarNotificacaoClick(notificacao);
              }

              let path = `/justificar-ausencia/${formatDate(
                notificacao.dataHora,
                'yyyy-MM-dd'
              )}`;

              navegar({
                props: this.props,
                path
              });
            }}
          />
        )}
      </Card.Section>
    );
  };

  renderBotoesNovaAssinaturaDigitalCartaoPonto = (
    notificacao: NotificacaoDados,
    index: number
  ) => {
    const { dados } = this.props.funcionarioContainer.state;

    // Sec-issues#10274: Só exibe o botão 'Visualizar' na notificação se tiver acesso a tela,
    // pois estava sendo possível acessar a tela de Assinatura Eletrônica mesmo essa tela estando oculta para o funcionário
    const possuiPermissaoTelaAssinaturaEletronica = verificarExibirTela(
      Telas.AssinaturaDigitalCartaoPonto,
      dados.dadosPerfilFuncionario.telasOcultar
    );

    return (
      possuiPermissaoTelaAssinaturaEletronica && (
        <Card.Section style={styles.secaoBotoes}>
          <Button
            text={translate('Visualizar')}
            nativeID={`btnVisualizarSolicitacao${index}`}
            style={styles.botao}
            textStyle={styles.botaoTexto}
            onPress={async () => {
              if (!notificacao.visualizada) {
                this.handleVisualizarNotificacaoClick(notificacao);
              }

              this.handleAcessarAssinaturaDigitalCartaoPonto(notificacao);
            }}
          />
        </Card.Section>
      )
    );
  };

  renderBotoesNovoArquivoDisponivel = (
    notificacao: NotificacaoDados,
    index: number
  ) => {
    const { dados } = this.props.funcionarioContainer.state;

    // Sec-issues#10274: Só exibe o botão 'Visualizar' na notificação se tiver acesso a tela,
    // pois estava sendo possível acessar a tela de Arquivos mesmo essa tela estando oculta para o funcionário
    const possuiPermissaoTelaArquivos = verificarExibirTela(
      Telas.Arquivos,
      dados.dadosPerfilFuncionario.telasOcultar
    );

    return (
      possuiPermissaoTelaArquivos && (
        <Card.Section style={styles.secaoBotoes}>
          <Button
            text={translate('Visualizar')}
            nativeID={`btnVisualizarSolicitacao${index}`}
            style={styles.botao}
            textStyle={styles.botaoTexto}
            onPress={async () => {
              if (!notificacao.visualizada) {
                this.handleVisualizarNotificacaoClick(notificacao);
              }

              this.handleAcessarArquivoAsync(notificacao);
            }}
          />
        </Card.Section>
      )
    );
  };

  renderBotoesAvisoRevisarCartaoPonto = (
    notificacao: NotificacaoDados,
    index: number
  ) => {
    const { dados } = this.props.funcionarioContainer.state;

    // Sec-issues#10274: Só exibe o botão 'Visualizar' na notificação se tiver acesso a tela,
    // pois estava sendo possível acessar a tela de cartão ponto mesmo essa tela estando oculta para o funcionário
    const possuiPermissaoTelaCartaoPonto = verificarExibirTela(
      Telas.CartaoPonto,
      dados.dadosPerfilFuncionario.telasOcultar
    );

    return (
      possuiPermissaoTelaCartaoPonto && (
        <Card.Section style={styles.secaoBotoes}>
          <Button
            text={translate('Visualizar')}
            nativeID={`btnVisualizarSolicitacao${index}`}
            style={styles.botao}
            textStyle={styles.botaoTexto}
            onPress={async () => {
              if (!notificacao.visualizada) {
                this.handleVisualizarNotificacaoClick(notificacao);
              }

              navegar({
                props: this.props,
                path: '/cartao-ponto'
              });
            }}
          />
        </Card.Section>
      )
    );
  };

  renderListaNotificacoes = (notificacoes: Array<NotificacaoDados>) => {
    return notificacoes.map((notificacao, index) => (
      <View key={notificacao.id} style={styles.containerNotificacoes}>
        <Card
          style={styles.cardNotiticacao}
          nativeID={`cardNotificacao${index}`}
        >
          <Card.Section style={styles.secaoDadosNotificacao}>
            {this.renderIconeNotificacao(notificacao.tipo)}
            <View style={styles.containerTextos}>
              <Text style={styles.textoMensagem}>{notificacao.mensagem}</Text>
              <Text style={styles.textoDataHora}>
                {notificacao.detalheMensagem
                  ? notificacao.detalheMensagem
                  : formatDate(
                      notificacao.dataHora,
                      'eeee, dd/MM/yyyy - HH:mm'
                    )}
              </Text>
            </View>
            <ImageButton
              icon="check-circle"
              nativeID={`btnMarcarNotificacaoVisualizada${index}`}
              iconColor={
                notificacao.visualizada ? theme.successColor : theme.textColor3
              }
              iconSize={30}
              hitBoxSize={10}
              onPress={() => this.handleVisualizarNotificacaoClick(notificacao)}
              style={styles.iconeNotificacaoVisualizada}
            />
          </Card.Section>
          {notificacao.tipo === TipoNotificacao.RegistroPontoRejeitado &&
            this.renderBotoesRegistroPontoRejeitado(notificacao, index)}
          {notificacao.tipo === TipoNotificacao.SolicitacaoDescartada &&
            this.renderBotoesSolicitacaoDescartada(notificacao, index)}
          {notificacao.tipo === TipoNotificacao.EsqueceuBaterPonto &&
            this.renderBotoesEsqueceuBaterPonto(notificacao)}
          {notificacao.tipo ===
            TipoNotificacao.NovaAssinaturaDigitalCartaoPonto &&
            this.renderBotoesNovaAssinaturaDigitalCartaoPonto(
              notificacao,
              index
            )}
          {notificacao.tipo === TipoNotificacao.NovoArquivoDisponivel &&
            this.renderBotoesNovoArquivoDisponivel(notificacao, index)}
          {notificacao.tipo === TipoNotificacao.AvisoRevisarCartaoPonto &&
            this.renderBotoesAvisoRevisarCartaoPonto(notificacao, index)}
        </Card>
      </View>
    ));
  };

  render() {
    const { notificacoesContainer } = this.props;
    const { notificacoes } = notificacoesContainer.state;
    const {
      solicitacaoSelecionada,
      assinaturaDigitalCartaoPontoSelecionada,
      arquivoSelecionado,
      filtros,
      erroGeral
    } = this.state;

    return (
      <LoggedInScreen
        screenTitle={translate('Notificações')}
        carregarNotificacoes={false}
      >
        <View style={styles.containerPrincipal}>
          <Card style={styles.containerFiltro}>
            <Card.Header title={translate('Filtrar')} />
            <Card.Section>
              <RangeDatePicker
                label={translate('Insira o Período')}
                startDate={converterDataParaTimezoneBrasil(filtros.dataInicio)}
                endDate={converterDataParaTimezoneBrasil(filtros.dataFinal)}
                onStartDateChange={date =>
                  this.handleDateChange(date, 'dataInicio')
                }
                onEndDateChange={date =>
                  this.handleDateChange(date, 'dataFinal')
                }
              />
              <ErrorMessage message={erroGeral} />
            </Card.Section>
          </Card>

          {notificacoes.length == 0 ? (
            <Card
              style={styles.cardNenhumRegistro}
              nativeID="cardNenhumRegistroEncontrado"
            >
              <Card.Header
                title={translate('Nenhum registro encontrado')}
                titleStyle={styles.textoSemRegistros}
              />
            </Card>
          ) : (
            this.renderListaNotificacoes(notificacoes)
          )}

          <Message
            type="warning"
            visible={notificacoesContainer.state.erro !== ''}
            message={notificacoesContainer.state.erro}
            onRequestClose={() => notificacoesContainer.limparErro()}
          />

          {solicitacaoSelecionada && (
            <SolicitacoesDetalhes
              modalVisible={solicitacaoSelecionada !== null}
              solicitacao={solicitacaoSelecionada}
              onClose={() => {
                this.setState({ solicitacaoSelecionada: null });
              }}
            />
          )}

          {assinaturaDigitalCartaoPontoSelecionada && (
            <AssinaturasDigitaisCartaoPontoDetalhes
              modalVisible={assinaturaDigitalCartaoPontoSelecionada !== null}
              assinaturaDigitalCartaoPonto={
                assinaturaDigitalCartaoPontoSelecionada
              }
              onClose={() => {
                this.setState({
                  assinaturaDigitalCartaoPontoSelecionada: null
                });
              }}
            />
          )}

          {arquivoSelecionado && (
            <ArquivosDetalhes
              arquivo={arquivoSelecionado}
              modalVisible={arquivoSelecionado !== null}
              onClose={() => this.setState({ arquivoSelecionado: null })}
              container={this.props.arquivosContainer}
            />
          )}
        </View>
      </LoggedInScreen>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  textoSemRegistros: {
    textAlign: 'center'
  },
  secaoDadosNotificacao: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingVertical: 8
  },
  iconeTipoNotificacao: {
    fontSize: 26,
    marginTop: 8,
    alignSelf: 'flex-start'
  },
  iconeNotificacaoVisualizada: {
    borderWidth: 0,
    height: 'auto',
    width: 'auto'
  },
  containerTextos: {
    flexShrink: 1,
    paddingHorizontal: 16
  },
  textoMensagem: {
    color: theme.textColor3,
    fontSize: isTablet() ? 18 : 15,
    fontFamily: theme.fontFamily1
  },
  textoDataHora: {
    color: theme.textColor3,
    fontSize: isTablet() ? 15 : 12,
    paddingTop: 5
  },
  secaoBotoes: {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 5
  },
  botao: {
    height: isTablet() ? 35 : 30,
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 100,
    marginHorizontal: !isHighResolution() ? 2 : 10
  },
  botaoTexto: {
    paddingHorizontal: 0
  },
  cardNotiticacao: {
    margin: 5,
    width: '100%'
  },
  containerPrincipal: {
    maxWidth: 530,
    display: 'flex',
    alignSelf: 'center'
  },
  containerNotificacoes: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  containerFiltro: {
    display: 'flex',
    flex: 3,
    minWidth: Dimensions.get('window').width < 1000 ? 300 : 530
  },
  cardNenhumRegistro: {
    display: 'flex',
    flex: 1,
    marginTop: 10
  }
});

export default withContainer(
  AssinaturaDigitalCartaoPontoContainer,
  'assinaturaDigitalCartaoPontoContainer'
)(
  withContainer(
    FuncionarioContainer,
    'funcionarioContainer'
  )(
    withContainer(
      ArquivosContainer,
      'arquivosContainer'
    )(
      withContainer(
        SolicitacoesContainer,
        'solicitacoesContainer'
      )(
        withContainer(
          NotificacoesContainer,
          'notificacoesContainer'
        )(Notificacao)
      )
    )
  )
);
