import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { translate } from 'secullum-i18n';
import { Text } from 'secullum-react-native-ui';
import { getTheme } from '../../../shared/modules/layout';

function Footer() {
  const theme = getTheme();

  return (
    <View style={styles.container}>
      <Text size={8} color={theme.textColor4}>
        {'\u00A9'}
        {translate(
          'Copyright Secullum Software. Todos os direitos reservados.'
        )}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20
  }
});

export default Footer;
