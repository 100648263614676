import L from 'leaflet';
import * as React from 'react';
import { LeafletMapProps } from './LeafletMap';

export class LeafletMap extends React.Component<LeafletMapProps> {
  leafletMap: L.Map | null = null;
  leafletMarker: L.Marker | null = null;
  leafletCircle: L.Circle | null = null;

  componentDidMount() {
    const { mapId, markerPosition, accuracy, scrollEnabled } = this.props;

    this.leafletMap = L.map(mapId, {
      center: markerPosition,
      zoom: 15,
      maxZoom: 18,
      zoomControl: false,
      scrollWheelZoom: scrollEnabled,
      attributionControl: false,
      layers: [
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')
      ]
    });

    this.leafletMap.whenReady(() => {
      if (this.props.onMapLoad) {
        this.props.onMapLoad();
      }
    });

    if (!this.props.scrollEnabled) {
      this.leafletMap.dragging.disable();
    }

    this.leafletMarker = L.marker(markerPosition).addTo(this.leafletMap);

    this.leafletCircle = L.circle(markerPosition, {
      radius: accuracy,
      fillColor: '#F59999',
      fillOpacity: 0.4,
      color: '#FF8080',
      weight: 0.8,
      opacity: 0.8
    }).addTo(this.leafletMap);

    this.leafletMap.fitBounds(this.leafletCircle.getBounds());
  }

  componentDidUpdate(prevProps: LeafletMapProps) {
    const { markerPosition, accuracy } = this.props;

    if (
      prevProps.markerPosition.lat !== markerPosition.lat ||
      prevProps.markerPosition.lng !== markerPosition.lng
    ) {
      this.leafletMarker!.setLatLng(markerPosition);
      this.leafletCircle!.setLatLng(markerPosition);
    }

    if (prevProps.accuracy !== accuracy) {
      this.leafletCircle!.setRadius(accuracy);
    }
  }

  center = () => {
    if (this.leafletMap) {
      this.leafletMap.panTo(this.props.markerPosition);
      this.leafletMap.fitBounds(this.leafletCircle!.getBounds());
    }
  };

  render() {
    return (
      <div
        id={this.props.mapId}
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    );
  }
}
