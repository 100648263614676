import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { translate } from 'secullum-i18n';
import { Modal, isTablet, Text } from 'secullum-react-native-ui';
import { getTheme } from '../../shared/modules/layout';

interface Props {
  dados: { nome: string; valor: string } | null;
  onRequestClose: () => void;
}

class ModalBatidaOriginal extends React.Component<Props> {
  render() {
    const { dados, onRequestClose } = this.props;

    return (
      <Modal
        overlayStyle={{ overflow: 'scroll' }}
        visible={dados !== null}
        onRequestClose={onRequestClose}
      >
        <View style={styles.container}>
          <Text bold size={isTablet() ? 20 : 18} style={styles.title}>
            {translate('Registro de Ponto Original')}
          </Text>
          <View style={styles.bodyContainer}>
            <Text bold size={isTablet() ? 16 : 14}>
              {dados && dados.nome}
            </Text>
            <Text bold size={isTablet() ? 16 : 14}>
              {dados && dados.valor}
            </Text>
          </View>
        </View>
      </Modal>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.backgroundColor1,
    marginTop: 'auto',
    marginBottom: 180,
    borderRadius: 5,
    maxWidth: 325,
    width: '100%',
    alignSelf: 'center'
  },
  title: {
    padding: 16
  },
  bodyContainer: {
    borderTopWidth: 1,
    borderTopColor: theme.borderColor1,
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

export default ModalBatidaOriginal;
