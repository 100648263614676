import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  ErrorMessage,
  Card,
  RangeDatePicker,
  DropDown,
  Space,
  ImageButton
} from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import { ButtonBar } from '../../../shared/components/ButtonBar';
import { BatidasContainer } from '../../../shared/containers/BatidasContainer';
import { FuncionarioContainer } from '../../../shared/containers/FuncionarioContainer';
import withContainer from '../../../shared/containers/withContainer';
import Api from '../../../shared/modules/api';
import {
  COR_BOTAO_CINZA,
  getTheme,
  isDesktop
} from '../../../shared/modules/layout';
import { verificarExibirTela } from '../../../shared/modules/perfilFuncionario';
import { Telas } from '../../../shared/modules/telas';
import { DadosListagemFuncionariosGerente } from '../../../shared/modules/types';
import {
  converterDataParaTimezoneBrasil,
  getDataInicioDiasAtras
} from '../../../shared/modules/utils';
import CartaoPontoCompleto from '../../../shared/screens/cartao-ponto/CartaoPontoCompleto';
import { TipoDeTela } from '../../../shared/screens/cartao-ponto/DetalheDiaBatida';
import LoggedInScreen from '../../components/LoggedInScreen';
import { RouteComponentComBancoIdProps } from '../../modules/routes';
import CartaoPontoResumido from '../cartao-ponto/CartaoPontoResumido';
import ModalImprimir from '../cartao-ponto/ModalImprimir';

type Visualizacao = 'resumido' | 'completo';

interface Props
  extends RouteComponentComBancoIdProps<{
    funcionarioSelecionadoId?: number;
  }> {
  container: BatidasContainer;
}

interface State {
  visualizacao: Visualizacao;
  filtros: {
    dataInicio: Date;
    dataFinal: Date;
    funcionarioId: number;
  };
  erroGeral: string;
  chaveTabela: number;
  funcionariosGerente: DadosListagemFuncionariosGerente[];
  exibirModalImprimir: boolean;
}

class CartaoPontoGerente extends React.Component<Props, State> {
  state: State = {
    visualizacao: 'resumido',
    filtros: {
      dataInicio: converterDataParaTimezoneBrasil(getDataInicioDiasAtras(14)),
      dataFinal: converterDataParaTimezoneBrasil(new Date()),
      funcionarioId: 0
    },
    erroGeral: '',
    chaveTabela: 0,
    funcionariosGerente: [],
    exibirModalImprimir: false
  };

  async componentDidMount() {
    const api = new Api();

    this.props.container.limparLista();
    const { match } = this.props;

    let funcionarioId = Number(match.params.funcionarioSelecionadoId);

    await api.getListaFuncionariosGerente({
      onSuccess: funcionariosGerente => {
        if (!funcionarioId) {
          funcionarioId =
            funcionariosGerente.length > 0 ? funcionariosGerente[0].id : 0;
        }

        this.setState(
          prevState => ({
            funcionariosGerente,
            filtros: {
              ...prevState.filtros,
              funcionarioId
            }
          }),
          async () => await this.handleModeChange('resumido')
        );
      },
      onError: resp => {
        this.setState({
          erroGeral: resp.message
        });
      }
    });
  }

  handleFuncionarioChange = async (funcionarioId: number) => {
    const filtros = {
      ...this.state.filtros,
      funcionarioId
    };

    this.setState({ filtros }, async () => {
      await this.handleModeChange(this.state.visualizacao);
    });
  };

  handleDateChange = (value: Date, name: keyof State['filtros']) => {
    const filtros = {
      ...this.state.filtros,
      [name]: value
    };

    if (name === 'dataInicio') {
      filtros.dataFinal = value;
    }

    this.setState(
      {
        filtros,
        chaveTabela: this.state.chaveTabela + 1,
        erroGeral: ''
      },
      async () => {
        if (name === 'dataFinal') {
          if (this.state.filtros.dataInicio > this.state.filtros.dataFinal) {
            this.setState({
              erroGeral: translate('Selecione o período corretamente')
            });

            return;
          }

          await this.handleModeChange(this.state.visualizacao);
        }
      }
    );
  };

  handleModeChange = async (mode: Visualizacao) => {
    if (
      this.state.erroGeral.length > 0 ||
      this.state.filtros.funcionarioId === 0
    ) {
      return;
    }

    this.setState({
      visualizacao: mode,
      chaveTabela: this.state.chaveTabela + 1
    });

    await this.props.container.loadCartaoPontoAsync(this.state.filtros);
  };

  handleExibirModalImprimir = () => {
    this.setState({ exibirModalImprimir: true });
  };

  render() {
    const {
      visualizacao,
      filtros,
      erroGeral,
      funcionariosGerente,
      exibirModalImprimir
    } = this.state;

    const { container } = this.props;
    const listaDiaResumido = container.listaOrdenada();

    return (
      <LoggedInScreen screenTitle={translate('Cartões Ponto')}>
        <Card>
          {!isDesktop() && visualizacao === 'completo' ? (
            <View style={styles.headerCartaoPonto}>
              <Card.Header title={translate('Filtrar')} />
              <ImageButton
                nativeID="cartao-ponto-completo-imprimir-mobile-view"
                icon="print"
                style={styles.printButtonMobile}
                onPress={this.handleExibirModalImprimir}
              />
            </View>
          ) : (
            <Card.Header title={translate('Filtrar')} />
          )}
          <Card.Section style={styles.section}>
            <Subscribe to={[FuncionarioContainer]}>
              {(funcionario: FuncionarioContainer) => {
                // Só deve listar o próprio gerente na lista de funcionários caso ele tenha permissões na tela de cartão ponto
                const gerentePossuiPermissaoCartaoPonto = verificarExibirTela(
                  Telas.CartaoPonto,
                  funcionario.state.dados.dadosPerfilFuncionario.telasOcultar
                );
                const funcionariosGerenteFiltrado =
                  gerentePossuiPermissaoCartaoPonto
                    ? funcionariosGerente
                    : funcionariosGerente.filter(
                        x => x.id != funcionario.state.dados.id
                      );
                return (
                  <DropDown
                    nativeID="cartao-ponto-funcionarios"
                    style={styles.campoFiltro}
                    label={translate('Selecione o funcionário')}
                    value={filtros.funcionarioId}
                    items={funcionariosGerenteFiltrado.map((x, indice) => ({
                      value: x.id,
                      label: x.nome,
                      nativeID: `funcionario-${indice + 1}`
                    }))}
                    onChange={funcionarioId =>
                      this.handleFuncionarioChange(funcionarioId)
                    }
                  />
                );
              }}
            </Subscribe>

            <RangeDatePicker
              nativeID="cartao-ponto-data"
              style={styles.campoFiltro}
              label={translate('Insira o Período')}
              startDate={filtros.dataInicio}
              endDate={filtros.dataFinal}
              onStartDateChange={date =>
                this.handleDateChange(date, 'dataInicio')
              }
              onEndDateChange={date => this.handleDateChange(date, 'dataFinal')}
            />
            <ErrorMessage
              nativeID="cartao-ponto-erro-geral"
              message={erroGeral}
            />
            <ButtonBar
              buttonBarStyle={styles.buttons}
              leftButton={{
                nativeID: 'cartao-ponto-resumido',
                text: translate('Resumido'),
                onPress: () => this.handleModeChange('resumido'),
                textStyle: styles.textButton,
                style: [
                  styles.button,
                  {
                    backgroundColor:
                      visualizacao === 'resumido'
                        ? COR_BOTAO_CINZA
                        : theme.backgroundColor3
                  }
                ]
              }}
              rightButton={{
                nativeID: 'cartao-ponto-completo',
                text: translate('Completo'),
                onPress: () => this.handleModeChange('completo'),
                textStyle: styles.textButton,
                style: [
                  styles.button,
                  {
                    backgroundColor:
                      visualizacao === 'completo'
                        ? COR_BOTAO_CINZA
                        : theme.backgroundColor3
                  }
                ]
              }}
            />
            {visualizacao === 'completo' && isDesktop() && (
              <>
                <Space width={16} />
                <ImageButton
                  nativeID="cartao-ponto-completo-imprimir"
                  icon="print"
                  style={styles.printButton}
                  onPress={this.handleExibirModalImprimir}
                />
              </>
            )}
          </Card.Section>
        </Card>
        {visualizacao === 'resumido' ? (
          <CartaoPontoResumido
            funcionarioId={filtros.funcionarioId}
            listaOrdenada={listaDiaResumido}
            key={this.state.chaveTabela}
            tipoTela={TipoDeTela.CartaoPontoGerente}
          />
        ) : (
          <CartaoPontoCompleto
            batidas={listaDiaResumido}
            totais={container.state.dados.totais}
          />
        )}
        {exibirModalImprimir && (
          <Subscribe to={[FuncionarioContainer]}>
            {(funcionario: FuncionarioContainer) => (
              <ModalImprimir
                configuracoesEspeciaisAcesso={
                  funcionario.state.dados.configuracoesEspeciaisAcesso
                }
                funcionarioId={filtros.funcionarioId}
                filtros={filtros}
                dados={container.state.dados}
                onRequestClose={opcoes => {
                  container.atualizarOpcoesCartaoPonto(opcoes);
                  this.setState({ exibirModalImprimir: false });
                }}
                tipoPlano={funcionario.state.dados.plano}
              />
            )}
          </Subscribe>
        )}
      </LoggedInScreen>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  buttons: {
    flex: 1,
    height: 56,
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 350,
    minWidth: 220,
    marginLeft: 16
  },
  button: {
    height: 42,
    maxWidth: 138
  },
  campoFiltro: {
    flex: 1,
    minWidth: 250,
    maxWidth: 350,
    marginLeft: 16
  },
  section: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: 0
  },
  textButton: {
    fontSize: 14
  },
  printButton: {
    marginVertical: 'auto',
    marginLeft: 'auto',
    padding: 5
  },
  printButtonMobile: {
    marginVertical: 'auto',
    marginLeft: 'auto',
    marginRight: 5,
    padding: 5
  },
  headerCartaoPonto: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

export default withContainer(BatidasContainer)(CartaoPontoGerente);
