import * as React from 'react';
import { translate } from 'secullum-i18n';
import CardAvisoSemAcessoTelas from '../../../shared/screens/aviso-sem-acesso-telas/CardAvisoSemAcessoTelas';
import LoggedInScreen from '../../components/LoggedInScreen';

class AvisoSemAcessoTelas extends React.Component {
  render() {
    // Caso a pessoa não possua acesso a tela nenhuma, não iremos renderizar o botão de notificações
    // pois através das notificações é possível ter acesso as telas, que originalmente foram bloqueadas.
    return (
      <LoggedInScreen
        screenTitle={translate('Acesso Bloqueado')}
        exibirBotaoNotificacao={false}
      >
        <CardAvisoSemAcessoTelas />
      </LoggedInScreen>
    );
  }
}

export default AvisoSemAcessoTelas;
