import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import { translate, translateFirstUpper } from 'secullum-i18n';
import {
  Card,
  CheckBox,
  DropDown,
  Message,
  Space,
  Text,
  TextBox,
  isTablet,
  Button,
  RED
} from 'secullum-react-native-ui';
import ModalConfirmarEmail from '../../../shared/components/ModalConfirmarEmail';
import { FuncionarioContainer } from '../../../shared/containers/FuncionarioContainer';
import withContainer from '../../../shared/containers/withContainer';
import Api from '../../../shared/modules/api';
import { getTheme } from '../../../shared/modules/layout';
import {
  verificarExibirTela,
  exibirTelaInclusaoPonto
} from '../../../shared/modules/perfilFuncionario';
import { Telas } from '../../../shared/modules/telas';
import {
  carregarConfiguracaoSelecionadaTelaInicialAsync,
  salvarConfiguracaoTelaInicialAsync,
  retornarOpcoesTelasIniciais,
  carregarConfiguracaoForcarTelaInicialAsync,
  salvarForcarTelaInicialAsync,
  MINUTOS_LIMITE_FORCAR_TELA_INICIAL
} from '../../../shared/modules/telasIniciais';
import { TipoPlataformaLogin } from '../../../shared/modules/types';
import LoggedInScreen from '../../components/LoggedInScreen';
import { navegar, RouteComponentComBancoIdProps } from '../../modules/routes';

interface Props extends RouteComponentComBancoIdProps<{}> {}

interface Props {
  funcionarioContainer: FuncionarioContainer;
}

interface State {
  configuracaoTelaInicial: Telas;
  erroGeral: string;
  mostrarModalConfirmarEmail: boolean;
  forcarTelaInicial: boolean;
}

class Configuracao extends Component<Props, State> {
  state: State = {
    configuracaoTelaInicial: Telas.UltimaTelaAcessada,
    erroGeral: '',
    mostrarModalConfirmarEmail: false,
    forcarTelaInicial: false
  };

  api = new Api();

  async componentDidMount() {
    const configuracaoTelaInicial =
      await carregarConfiguracaoSelecionadaTelaInicialAsync(
        this.props.funcionarioContainer.state.dados
      );
    const forcarTelaInicial =
      await carregarConfiguracaoForcarTelaInicialAsync();

    this.setState({
      configuracaoTelaInicial,
      forcarTelaInicial
    });

    await this.api.getDadosFuncionario(
      {
        id: this.props.funcionarioContainer.state.dados.id,
        plataformaLogin: TipoPlataformaLogin.Web,
        apelidoDispositivo: 'navegador', // Dados preenchidos para evitar a controller de compatibilidade
        identificacaoDispositivo: 'IP'
      },
      {
        onSuccess: async dados => {
          this.props.funcionarioContainer.setarDadosFuncionario(dados);
        },
        onError: err => {
          this.setState({ erroGeral: err.message });
        }
      }
    );
  }

  handleForcarTelaInicialChange = async (forcar: boolean) => {
    await salvarForcarTelaInicialAsync(forcar);

    this.setState({ forcarTelaInicial: forcar });
  };

  handleDropDownTelasIniciaisChange = async (
    configuracaoTelaInicial: Telas
  ) => {
    await salvarConfiguracaoTelaInicialAsync(configuracaoTelaInicial);

    this.setState({ configuracaoTelaInicial });
  };

  handleReceberEmailComprovantePontoChange = async (
    receberEmailComprovantePonto: boolean
  ) => {
    if (receberEmailComprovantePonto) {
      const dadosFuncionario = this.api.funcionario.state.dados;

      const usuarioTemAcessoDadosCadastrais = verificarExibirTela(
        Telas.DadosCadastrais,
        dadosFuncionario.dadosPerfilFuncionario.telasOcultar
      );

      if (usuarioTemAcessoDadosCadastrais) {
        this.setState({
          mostrarModalConfirmarEmail: true
        });

        return;
      } else if ((dadosFuncionario.email || '') === '') {
        this.setState({
          erroGeral: translate(
            'Você não possui um email cadastrado. Contate o administrador do sistema.'
          )
        });

        return;
      }
    }

    await this.salvarConfiguracaoEmailComprovantePontoAsync(
      receberEmailComprovantePonto
    );
  };

  salvarConfiguracaoEmailComprovantePontoAsync = async (
    receberEmailComprovantePonto: boolean
  ) => {
    await this.api.postConfiguracoesReceberComprovantePonto(
      receberEmailComprovantePonto,
      {
        onSuccess: () => {
          this.props.funcionarioContainer.setState({
            dados: {
              ...this.props.funcionarioContainer.state.dados,
              receberEmailComprovantePonto
            }
          });
        },
        onError: resp => {
          this.setState({
            erroGeral: resp.message
          });
        }
      }
    );
  };

  render() {
    const { dados } = this.props.funcionarioContainer.state;
    const versaoApp = process.env.REACT_APP_VERSION;

    return (
      <LoggedInScreen screenTitle={translate('Configurações')}>
        <Message
          type="warning"
          visible={this.state.erroGeral !== ''}
          message={this.state.erroGeral}
          onRequestClose={() => {
            this.setState({ erroGeral: '' });
          }}
        />
        <Card>
          <Card.Header
            nativeID="configuracoes-texto-explicativo"
            title={translate(
              'A tela inicial é a página que será exibida após você efetuar o login.'
            )}
            titleStyle={styles.titleHeader}
          />
          <Card.Section style={styles.section}>
            <DropDown
              nativeID="configuracoes-tela-inicial"
              label={translate('Tela Inicial do Aplicativo')}
              items={retornarOpcoesTelasIniciais(dados)}
              value={this.state.configuracaoTelaInicial}
              onChange={this.handleDropDownTelasIniciaisChange}
            />
          </Card.Section>
          {exibirTelaInclusaoPonto(dados) && (
            <Card.Section>
              <CheckBox
                nativeID="configuracoes-tela-inicial-forcar-incluir-ponto"
                label={translate(
                  'Abrir em Incluir Ponto quando próximo do horário da marcação'
                )}
                value={this.state.forcarTelaInicial}
                onChange={this.handleForcarTelaInicialChange}
              />
              <Space height={8} />
              <Text color={theme.textColor2} size={12}>
                {translate(
                  'A tela inicial será Incluir Ponto quando até {0} min antes/depois do horário esperado para a marcação',
                  MINUTOS_LIMITE_FORCAR_TELA_INICIAL.toString()
                )}
              </Text>
            </Card.Section>
          )}
        </Card>
        {exibirTelaInclusaoPonto(dados) && (
          <>
            <Space />
            <Card>
              <Card.Header
                nativeID="configuracoes-comprovante-registro-ponto"
                title={translate('Comprovante de Registro de Ponto')}
                titleStyle={styles.titleHeader}
              />
              <Card.Section>
                <CheckBox
                  nativeID="campo-comprovante-registro-ponto"
                  label={translate(
                    'Receber email com comprovante da inclusão de ponto'
                  )}
                  value={dados.receberEmailComprovantePonto}
                  onChange={this.handleReceberEmailComprovantePontoChange}
                />
                <Space height={8} />
                <Text color={theme.textColor2} size={12}>
                  {translate(
                    'Você receberá o comprovante no email informado junto ao seu cadastro na empresa'
                  )}
                </Text>
              </Card.Section>
            </Card>
          </>
        )}
        <Space />
        <Card>
          <Card.Header
            nativeID="configuracoes-identificacao-dispositivo"
            title={translate('Identificação do Dispositivo')}
            titleStyle={styles.titleHeader}
          />
          <Card.Section>
            <TextBox
              label={translateFirstUpper('Identificação')}
              editable={false}
              value={dados.loginEnderecoIp}
            />
            <Space />
            <TextBox
              label={translate('Apelido')}
              editable={false}
              value={dados.loginDadosNavegador}
            />
          </Card.Section>
        </Card>
        <Space />
        <Card>
          <Card.Section style={styles.sectionButton}>
            <Button
              nativeID="deletar-conta"
              text="Deletar Conta"
              style={styles.button}
              onPress={() => {
                navegar({ props: this.props, path: '/deletar-conta' });
              }}
            />
          </Card.Section>
        </Card>
        <Space />
        {versaoApp && (
          <>
            <Space />
            <Card>
              <Card.Section>
                <Text bold align={'center'}>
                  {translate('Secullum Ponto Web - Central do Funcionário')}
                </Text>
                <Text
                  size={12}
                  color={theme.textColor2}
                  align={'center'}
                >{`${translate('Versão')} ${versaoApp}`}</Text>
              </Card.Section>
            </Card>
          </>
        )}
        {this.state.mostrarModalConfirmarEmail && (
          <ModalConfirmarEmail
            dadosFuncionario={dados}
            onConcluir={async () => {
              await this.salvarConfiguracaoEmailComprovantePontoAsync(true);
              this.setState({ mostrarModalConfirmarEmail: false });
            }}
            onCancelar={() => {
              this.setState({ mostrarModalConfirmarEmail: false });
            }}
          />
        )}
      </LoggedInScreen>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  section: {
    margin: 'auto',
    maxWidth: 350,
    width: '100%'
  },
  titleHeader: {
    color: theme.textColor2,
    fontSize: isTablet() ? 16 : 12,
    fontFamily: theme.fontFamily2
  },
  sectionButton: {
    alignItems: 'center'
  },
  button: {
    width: '100%',
    maxWidth: 318,
    backgroundColor: RED
  }
});

export default withContainer(
  FuncionarioContainer,
  'funcionarioContainer'
)(Configuracao);
