import * as React from 'react';
import { View, Linking, Pressable, Platform, StyleSheet } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { translate } from 'secullum-i18n';
import { Card, Text, Message, isTablet } from 'secullum-react-native-ui';
import { getTheme, isHighResolution } from '../../shared/modules/layout';
import {
  CorTextoIndicadorCustomizado,
  DadosFuncionarioIndicadorCustomizadoGerente
} from '../modules/types';
import ModalIndicadorListaFuncionarios from './ModalIndicadorListaFuncionarios';

interface Props {
  nativeID?: string;
  titulo: string;
  nomeColunaIndicador: string;
  funcionarios: Array<DadosFuncionarioIndicadorCustomizadoGerente>;
  corTexto: CorTextoIndicadorCustomizado;
  indicadorId: number;
  onEditarIndicador: () => void;
}

interface State {
  alertaFuncionario: string;
  exibirModalFuncionarios: boolean;
  erro: string;
}

class CardIndicadorGerente extends React.Component<Props, State> {
  state: State = {
    alertaFuncionario: '',
    exibirModalFuncionarios: false,
    erro: ''
  };

  handleCloseModalFuncionarios = () => {
    this.setState({ exibirModalFuncionarios: false });
  };

  handlePhoneClick = (celular: string) => {
    if (!celular) {
      this.setState({
        alertaFuncionario: translate(
          'Não há número celular cadastrado para este funcionário'
        )
      });

      return;
    }

    Linking.openURL(`tel:${celular}`);
  };

  buscarCorTexto = () => {
    const { corTexto } = this.props;
    if (corTexto == CorTextoIndicadorCustomizado.Amarelo) {
      return theme.warningColor;
    } else if (corTexto == CorTextoIndicadorCustomizado.Vermelho) {
      return theme.errorColor;
    } else {
      return theme.successColor;
    }
  };

  renderCardHeader() {
    const { nativeID, titulo } = this.props;
    return (
      <Card.Header
        nativeID={nativeID}
        title={titulo}
        numberOfLines={1}
        containerStyle={{ flex: 1 }}
      />
    );
  }

  render() {
    const {
      nativeID,
      titulo,
      funcionarios,
      onEditarIndicador,
      nomeColunaIndicador
    } = this.props;
    const { exibirModalFuncionarios, erro } = this.state;
    const corTextoHora = this.buscarCorTexto();
    return (
      <>
        <Card
          style={[
            {
              width: Platform.OS === 'web' && isHighResolution() ? 342 : '100%',
              marginTop: 10
            }
          ]}
        >
          <View style={styles.headerIndicador}>
            {Platform.OS == 'web' ? (
              <div title={titulo} style={{ maxWidth: 300 }}>
                {this.renderCardHeader()}
              </div>
            ) : (
              this.renderCardHeader()
            )}
            <Pressable
              onPress={onEditarIndicador}
              style={({ pressed }) => [{ opacity: pressed ? 0.7 : 1 }]}
            >
              <FontAwesome
                testID="editar-indicador"
                name="edit"
                style={styles.editarIndicador}
              />
            </Pressable>
          </View>
          <Pressable
            onPress={() => this.setState({ exibirModalFuncionarios: true })}
            style={({ pressed }) => [{ opacity: pressed ? 0.7 : 1 }]}
          >
            <Card.Section>
              <View style={styles.ausenteView}>
                {funcionarios && funcionarios.length > 0 ? (
                  <>
                    <Text
                      nativeID={`${nativeID}-total`}
                      align={'center'}
                      bold
                      color={corTextoHora}
                      size={40}
                    >
                      {funcionarios.length}
                    </Text>
                    <Text color={theme.textColor3}>{nomeColunaIndicador}</Text>
                  </>
                ) : (
                  <>
                    <FontAwesome
                      name="check"
                      color={theme.successColor}
                      size={50}
                    />
                    <Text
                      nativeID={`${nativeID}-total`}
                      align={'center'}
                      color={theme.textColor3}
                      style={styles.avisoSemDadosIndicador}
                    >
                      {translate('Não há funcionários')}
                    </Text>
                  </>
                )}
              </View>
            </Card.Section>
          </Pressable>
          {funcionarios.length > 0 && (
            <ModalIndicadorListaFuncionarios
              onClose={this.handleCloseModalFuncionarios}
              titulo={titulo}
              corTextoHora={corTextoHora}
              exibirModal={exibirModalFuncionarios}
              funcionarios={funcionarios}
            />
          )}
        </Card>
        <Message
          type="warning"
          message={erro}
          visible={erro !== ''}
          onRequestClose={() => this.setState({ erro: '' })}
        />
      </>
    );
  }
}

const theme = getTheme();
const styles = StyleSheet.create({
  avisoSemDadosIndicador: {
    textAlign: 'center'
  },
  ausenteView: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 80
  },
  headerIndicador: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  editarIndicador: {
    fontSize: 20,
    marginRight: 8
  },
  cardHeaderTitleStyle: {
    fontSize: isTablet() ? 16 : 12
  }
});

export default CardIndicadorGerente;
