import 'core-js/features/array/fill';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/includes';
import 'core-js/features/object/values';
import 'core-js/features/string/includes';
import 'core-js/features/string/pad-start';
import * as React from 'react';
import 'react-app-polyfill/ie11';
import * as ReactDOM from 'react-dom/client';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import { setTheme } from 'secullum-react-native-ui';
import Error from './shared/components/Error';
import Root from './web/components/Root';
import { loadFonts } from './web/modules/fonts';

loadFonts([
  {
    family: 'FontAwesome',
    url: require('react-native-vector-icons/Fonts/FontAwesome.ttf')
  },
  {
    family: 'Roboto-Medium',
    url: require('./shared/fonts/Roboto-Medium.ttf')
  },
  {
    family: 'Roboto-Light',
    url: require('./shared/fonts/Roboto-Light.ttf')
  },
  {
    family: 'Roboto',
    url: require('./shared/fonts/Roboto-Regular.ttf')
  }
]);

setTheme({
  textColor1: '#555',
  textColor2: '#59CBE8',
  textColor3: '#555',
  backgroundColor3: '#59CBE8',
  statusBarColor: '#59CBE8'
});

const fallbackComponent = (props: FallbackProps) => {
  const restart = () => {
    // Quando ocorrer um erro na página redirecionamos o usuário para a rota raiz.
    // A rota raiz segue o padrão definido na variável de ambiente REACT_APP_BASENAME
    // e também se o usuário está logado e informou o banco.
    //
    // As possibilidades de rota raiz são:
    // http://localhost:3000/ --> rota sem o banco
    // http://localhost:3000/1/ --> rota com o banco
    // http://localhost:3000/PontoWebCentral --> rota utilizada no secullum-prg sem o banco
    // http://localhost:3000/PontoWebCentral/1/ -->  --> rota utilizada no secullum-prg com o banco
    const urlBase = process.env.REACT_APP_BASENAME || '';
    const rotaComBancoId = window.location.href.match(/\/(\d+)\//);
    const bancoId = rotaComBancoId ? `/${rotaComBancoId[1]}` : '';

    window.location.href = `${urlBase}${bancoId}`;
  };

  return <Error {...{ ...props, restart }} />;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ErrorBoundary FallbackComponent={fallbackComponent}>
    <Root />
  </ErrorBoundary>
);
