import * as React from 'react';
import {
  View,
  Linking,
  Pressable,
  StyleSheet,
  Platform,
  Dimensions
} from 'react-native';
import { translate } from 'secullum-i18n';
import { Card, Message, Text } from 'secullum-react-native-ui';
import ModalIndicadorListaFuncionarios from '../../components/ModalIndicadorListaFuncionarios';
import ProgressBar from '../../components/ProgressBar';
import { getTheme, isHighResolution } from '../../modules/layout';
import { ResumoDiarioResultado } from '../../modules/types';

interface Props {
  nativeID?: string;
  resumoDiario: ResumoDiarioResultado;
}

interface State {
  alertaFuncionario: string;
  exibirModalFuncionarios: boolean;
}

class CardResumoDiario extends React.Component<Props, State> {
  state: State = { alertaFuncionario: '', exibirModalFuncionarios: false };

  handlePhoneClick = (celular: string) => {
    if (!celular) {
      this.setState({
        alertaFuncionario: translate(
          'Não há número celular cadastrado para este funcionário'
        )
      });

      return;
    }

    Linking.openURL(`tel:${celular}`);
  };

  render() {
    const { nativeID } = this.props;
    const { funcionarios, atual, titulo, total } = this.props.resumoDiario;
    const { exibirModalFuncionarios } = this.state;

    return (
      <Pressable
        onPress={() => this.setState({ exibirModalFuncionarios: true })}
        disabled={!(funcionarios.length > 0)}
        style={({ pressed }) => [
          { opacity: pressed ? 0.7 : 1 },
          {
            width: Platform.OS === 'web' && isHighResolution() ? 360 : '100%',
            marginTop:
              Platform.OS === 'web' && Dimensions.get('window').width < 732
                ? 10
                : 12
          }
        ]}
      >
        <Card>
          <Card.Header
            nativeID={`${nativeID}-titulo`}
            title={translate(titulo)}
            titleStyle={{ textAlign: 'center' }}
          />
          <Card.Section>
            <View style={styles.resumoDiarioView}>
              <>
                <Text
                  nativeID={`${nativeID}-total`}
                  bold
                  color={theme.textColor2}
                  size={40}
                >
                  {funcionarios.length}
                </Text>
                <ProgressBar nativeID={nativeID} atual={atual} total={total} />
              </>
            </View>
          </Card.Section>
          <ModalIndicadorListaFuncionarios
            corTextoHora={theme.textColor3}
            exibirModal={exibirModalFuncionarios}
            titulo={translate(titulo)}
            funcionarios={funcionarios.map(x => ({
              nome: x.nome,
              celular: x.celular,
              totalHorasFuncionario: ''
            }))}
            onClose={() => this.setState({ exibirModalFuncionarios: false })}
          />
        </Card>
        <Message
          type="warning"
          message={this.state.alertaFuncionario}
          visible={this.state.alertaFuncionario !== ''}
          onRequestClose={() => this.setState({ alertaFuncionario: '' })}
        />
      </Pressable>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  resumoDiarioView: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 80
  }
});

export default CardResumoDiario;
