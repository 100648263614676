import { Container } from 'unstated';
import { converterDataServidorParaDataLocal } from '../../shared/modules/utils';
import Api from '../modules/api';
import {
  CartaoPontoDados,
  PontoDiarioDados,
  BatidasResposta,
  BatidasItem,
  Justificativa,
  OpcoesCartaoPonto
} from '../modules/types';

interface State {
  dados: BatidasResposta;
  erro: string;
  erroConexao: boolean;
  onTentarNovamente: (() => void) | null;
}

export interface BatidasExibicao {
  mensagem: string;
  temJustificativa: boolean;
}

const formataItensDaListaDeBatidas = (batidas: BatidasExibicao[]) => {
  const pontoSeparador = '•';
  let tamanhoLista = batidas.length;
  let ultimaBatida = batidas[tamanhoLista - 1];

  const simbolosOrdenados = [pontoSeparador, 'X - X', pontoSeparador];

  simbolosOrdenados.forEach(simbolo => {
    if (ultimaBatida.mensagem.includes(simbolo)) {
      batidas.pop();
      tamanhoLista = batidas.length;
      ultimaBatida = batidas[tamanhoLista - 1];
    }
  });
};

export class BatidasContainer extends Container<State> {
  state: State = {
    dados: {
      lista: [],
      justificativas: [],
      totais: [],
      opcoesCartaoPonto: {
        funcionarioId: 0,
        colunasSelecionadasImpressao: [],
        exibirEventos: false,
        exibirMiniaturaDoHorario: false,
        exibirTermosMTB: false,
        exibirTotaisNoRodape: false,
        modoPaisagem: false,
        exibirJustificativas: false,
        justificativasPreenchidasUsuario: false,
        exibirLegendasJustificativas: false,
        exibirAtividades: false
      }
    },
    erro: '',
    erroConexao: false,
    onTentarNovamente: null
  };

  loadCartaoPontoAsync = async (filtros: CartaoPontoDados) => {
    this.limparLista();

    const api = new Api();

    await api.getCartaoPonto(filtros, {
      onSuccess: (resp: BatidasResposta) => {
        this.setState({
          dados: {
            lista: resp.lista.map(x => {
              const dataAjustada = converterDataServidorParaDataLocal(x.data);

              // Fiz aqui no container, para não precisar fazer em todas as telas que usam
              x.data = dataAjustada;
              return x;
            }),
            justificativas: resp.justificativas,
            totais: resp.totais,
            opcoesCartaoPonto: resp.opcoesCartaoPonto
          }
        });
      },
      onError: (err: Error) => {
        this.setState({
          erro: err.message,
          erroConexao: err.name === 'TimeoutError',
          onTentarNovamente: () => this.loadCartaoPontoAsync(filtros)
        });
      },
      onValidationError: (errorList, errorObj) => {
        this.setState({
          erro: errorObj.dataInicio,
          erroConexao: false,
          onTentarNovamente: null
        });
      }
    });
  };

  loadPontoDiarioAsync = async (filtros: PontoDiarioDados) => {
    this.limparLista();

    const api = new Api();

    await api.getPontoDiario(filtros, {
      onSuccess: (resp: BatidasResposta) => {
        this.setState({
          dados: {
            lista: resp.lista.map(x => {
              const dataAjustada = converterDataServidorParaDataLocal(
                new Date(x.data)
              );
              // Fiz aqui no container, para não precisar fazer em todas as telas que usam
              x.data = dataAjustada;
              return x;
            }),
            justificativas: resp.justificativas,
            totais: resp.totais,
            opcoesCartaoPonto: resp.opcoesCartaoPonto
          }
        });
      },
      onError: (err: Error) => {
        this.setState({
          erro: err.message,
          erroConexao: err.name === 'TimeoutError',
          onTentarNovamente: () => this.loadPontoDiarioAsync(filtros)
        });
      },
      onValidationError: (errorList, errorObj) => {
        this.setState({
          erro: errorObj.dataInicio,
          erroConexao: false,
          onTentarNovamente: null
        });
      }
    });
  };

  listaOrdenada = () => {
    if (this.state.dados.lista.length === 0) {
      return null;
    }

    return this.state.dados.lista
      .slice()
      .sort((a, b) => b.data.getTime() - a.data.getTime());
  };

  atualizarOpcoesCartaoPonto = (opcoes: OpcoesCartaoPonto) => {
    this.setState({
      dados: {
        ...this.state.dados,
        opcoesCartaoPonto: opcoes
      }
    });
  };

  limparLista = () => {
    this.setState({
      dados: {
        ...this.state.dados,
        lista: []
      }
    });
  };

  limparErro = () => {
    this.setState({
      erro: '',
      erroConexao: false,
      onTentarNovamente: null
    });
  };

  informarErro = (
    erro: string,
    erroConexao: boolean = false,
    onTentarNovamente: (() => void) | null = null
  ) => {
    this.setState({
      erro,
      erroConexao,
      onTentarNovamente
    });
  };
}

export const listaBatidasParaExibicao = (
  lista: Array<BatidasItem>,
  listaJustificativas: Justificativa[]
): BatidasExibicao[] => {
  let listaBatidasJustificadas: BatidasExibicao[] = [];
  const objPontoSeparadorDeBatida = { mensagem: '•', temJustificativa: false };

  if (lista.every(x => x.valor.length === 0)) return listaBatidasJustificadas;

  // caso todas as batidas sejam iguais (manhã, tarde, noite),
  // é um atestado para o dia todo
  if (lista.slice(0, 6).every(x => x.valor === lista[0].valor)) {
    const justificativa = listaJustificativas.find(
      x => x.nomeAbreviado == lista[0].valor
    );

    if (justificativa) {
      listaBatidasJustificadas.push({
        mensagem: justificativa.nomeCompleto || justificativa.nomeAbreviado,
        temJustificativa: true
      });

      return listaBatidasJustificadas;
    }
  }

  lista
    .map(x => x.valor)
    .reduce(
      // cria um array com um array (entrada, saida) para formar os grupos de batidas
      (acc, item, index, arr) =>
        index % 2 === 0 ? [...acc, [item, arr[index + 1]]] : acc,
      [] as Array<Array<string>>
    )
    .forEach(grupo => {
      const [entrada, saida] = grupo;

      // para cada grupo, verifica se:
      if (
        entrada !== '' && // tem valor
        !/\d*[:]\d*/.test(entrada) && // não é batida
        entrada === saida // as batidas são iguais (justificativa)
      ) {
        const justificativa = listaJustificativas.find(
          x => x.nomeAbreviado === entrada
        );

        if (justificativa) {
          listaBatidasJustificadas.push(
            {
              mensagem:
                justificativa.nomeCompleto || justificativa.nomeAbreviado,
              temJustificativa: true
            },
            objPontoSeparadorDeBatida
          );

          return;
        }
      }

      // caso seja batida ou vazio, retorna a com os dados do grupo
      listaBatidasJustificadas.push(
        {
          mensagem: `${entrada || 'X'} - ${saida || 'X'}`,
          temJustificativa: false
        },
        objPontoSeparadorDeBatida
      );
    });

  formataItensDaListaDeBatidas(listaBatidasJustificadas);
  return listaBatidasJustificadas;
};
