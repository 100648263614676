import { Platform } from 'react-native';
import { translate } from 'secullum-i18n';
import { primeiraLetraMaisculaCadaPalavra } from '../../shared/modules/string';
import { criarPathComBancoId } from '../../web/modules/routes';

export enum Telas {
  Indicadores = 'Indicadores',
  IndicadoresGerente = 'IndicadoresGerente',
  CartaoPonto = 'CartaoPonto',
  CartaoPontoGerente = 'CartaoPontoGerente',
  DetalhesDiaBatidas = 'DetalhesDiaBatidas',
  IncluirPonto = 'IncluirPonto',
  IncluirAtividade = 'IncluirAtividade',
  AjustarPonto = 'AjustarPonto',
  JustificarAusencia = 'JustificarAusencia',
  Solicitacoes = 'Solicitacoes',
  DadosCadastrais = 'DadosCadastrais',
  AlterarSenha = 'AlterarSenha',
  AcompanhamentoPonto = 'PontoDiario',
  Configuracoes = 'Configuracoes',
  Login = 'Login',
  SolicitacoesGerente = 'SolicitacoesGerente',
  SolicitacoesDetalhes = 'SolicitacoesDetalhes',
  Ajuda = 'Ajuda',
  Notificacoes = 'Notificacoes',
  DadosIndicadorCustomizado = 'DadosIndicadorCustomizado',
  ImpressaoCartaoPonto = 'ImpressaoCartaoPonto',
  UltimaTelaAcessada = 'UltimaTelaAcessada',
  AssinaturaDigitalCartaoPonto = 'AssinaturaDigitalCartaoPonto',
  AssinaturaDigitalCartaoPontoDetalhes = 'AssinaturaDigitalCartaoPontoDetalhes',
  DeletarConta = 'DeletarConta',
  Arquivos = 'Arquivos',
  ArquivosDetalhes = 'ArquivosDetalhes',
  RecuperarSenha = 'RecuperarSenha',
  ErroSemTelasDisponiveis = 'ErroSemTelasDisponiveis'
}

export interface DadosTela {
  nome: Telas;
  titulo: string;
  podeSerTelaInicial: boolean;
  nativeID: string;
}

const rotasTelas = [
  {
    nome: Telas.IndicadoresGerente,
    path: '/indicadores-gerenciais'
  },
  {
    nome: Telas.CartaoPontoGerente,
    path: '/cartao-ponto-gerente'
  },
  {
    nome: Telas.AcompanhamentoPonto,
    path: '/acompanhamento-ponto'
  },
  {
    nome: Telas.SolicitacoesGerente,
    path: '/solicitacoes-gerente'
  },
  {
    nome: Telas.Indicadores,
    path: '/indicadores'
  },
  {
    nome: Telas.CartaoPonto,
    path: '/cartao-ponto'
  },
  {
    nome: Telas.CartaoPontoGerente,
    path: '/cartao-ponto-gerente'
  },
  {
    nome: Telas.IncluirPonto,
    path: '/incluir-ponto'
  },
  {
    nome: Telas.IncluirAtividade,
    path: '/incluir-atividade'
  },
  {
    nome: Telas.AjustarPonto,
    path: '/ajustar-ponto'
  },
  {
    nome: Telas.JustificarAusencia,
    path: '/justificar-ausencia'
  },
  {
    nome: Telas.Solicitacoes,
    path: '/solicitacoes'
  },
  {
    nome: Telas.DadosCadastrais,
    path: '/dados-cadastrais'
  },
  {
    nome: Telas.AlterarSenha,
    path: '/alterar-senha'
  },
  {
    nome: Telas.AcompanhamentoPonto,
    path: '/acompanhamento-ponto'
  },
  {
    nome: Telas.Configuracoes,
    path: '/configuracoes'
  },
  {
    nome: Telas.Login,
    path: '/logout'
  },
  {
    nome: Telas.Notificacoes,
    path: '/notificacoes'
  },
  {
    nome: Telas.DadosIndicadorCustomizado,
    path: '/dados-indicador-customizado'
  },
  {
    nome: Telas.AssinaturaDigitalCartaoPonto,
    path: '/assinatura-digital-cartao-ponto'
  },
  {
    nome: Telas.DeletarConta,
    path: '/deletar-conta'
  },
  {
    nome: Telas.Arquivos,
    path: '/arquivos'
  },
  {
    nome: Telas.RecuperarSenha,
    path: '/RecuperarSenha'
  },
  {
    nome: Telas.ErroSemTelasDisponiveis,
    path: '/sem-acesso'
  }
];

export function retornarTelas() {
  const telasDadosGerenciais: Array<DadosTela> = [
    {
      nome: Telas.IndicadoresGerente,
      titulo: retornarTituloTela(Telas.IndicadoresGerente),
      podeSerTelaInicial: true,
      nativeID: 'menu-indicadores-gerenciais'
    },
    {
      nome: Telas.CartaoPontoGerente,
      titulo: retornarTituloTela(Telas.CartaoPontoGerente),
      podeSerTelaInicial: true,
      nativeID: 'menu-cartao-ponto-gerente'
    },
    {
      nome: Telas.AcompanhamentoPonto,
      titulo: retornarTituloTela(Telas.AcompanhamentoPonto),
      podeSerTelaInicial: true,
      nativeID: 'menu-acompanhamento-ponto'
    },
    {
      nome: Telas.SolicitacoesGerente,
      titulo: retornarTituloTela(Telas.SolicitacoesGerente),
      podeSerTelaInicial: true,
      nativeID: 'menu-solicitacoes-gerente'
    }
  ];

  const telasDadosPessoais: Array<DadosTela> = [
    {
      nome: Telas.Indicadores,
      titulo: retornarTituloTela(Telas.Indicadores),
      podeSerTelaInicial: true,
      nativeID: 'menu-indicadores'
    },
    {
      nome: Telas.CartaoPonto,
      titulo: retornarTituloTela(Telas.CartaoPonto),
      podeSerTelaInicial: true,
      nativeID: 'menu-cartao-ponto'
    },
    {
      nome: Telas.IncluirPonto,
      titulo: retornarTituloTela(Telas.IncluirPonto),
      podeSerTelaInicial: true,
      nativeID: 'menu-incluir-ponto'
    },
    {
      nome: Telas.IncluirAtividade,
      titulo: retornarTituloTela(Telas.IncluirAtividade),
      podeSerTelaInicial: true,
      nativeID: 'menu-incluir-atividade'
    },
    {
      nome: Telas.AjustarPonto,
      titulo: retornarTituloTela(Telas.AjustarPonto),
      podeSerTelaInicial: true,
      nativeID: 'menu-ajustar-ponto'
    },
    {
      nome: Telas.JustificarAusencia,
      titulo: retornarTituloTela(Telas.JustificarAusencia),
      podeSerTelaInicial: true,
      nativeID: 'menu-justificar-ausencia'
    },
    {
      nome: Telas.Solicitacoes,
      titulo: retornarTituloTela(Telas.Solicitacoes),
      podeSerTelaInicial: true,
      nativeID: 'menu-solicitacoes'
    },
    {
      nome: Telas.AssinaturaDigitalCartaoPonto,
      titulo: retornarTituloTela(Telas.AssinaturaDigitalCartaoPonto),
      podeSerTelaInicial: true,
      nativeID: 'menu-assinatura-digital-cartao-ponto'
    },
    {
      nome: Telas.Arquivos,
      titulo: retornarTituloTela(Telas.Arquivos),
      podeSerTelaInicial: false,
      nativeID: 'menu-arquivos'
    },
    {
      nome: Telas.DadosCadastrais,
      titulo: retornarTituloTela(Telas.DadosCadastrais),
      podeSerTelaInicial: false,
      nativeID: 'menu-dados-cadastrais'
    },
    {
      nome: Telas.AlterarSenha,
      titulo: retornarTituloTela(Telas.AlterarSenha),
      podeSerTelaInicial: false,
      nativeID: 'menu-alterar-senha'
    }
  ];

  let telasGerais: Array<DadosTela> = [
    {
      nome: Telas.Configuracoes,
      titulo: retornarTituloTela(Telas.Configuracoes),
      podeSerTelaInicial: false,
      nativeID: 'menu-configuracoes'
    },
    {
      nome: Telas.Ajuda,
      titulo: retornarTituloTela(Telas.Ajuda),
      podeSerTelaInicial: false,
      nativeID: 'menu-ajuda'
    },
    {
      nome: Telas.Login,
      titulo: retornarTituloTela(Telas.Login),
      podeSerTelaInicial: false,
      nativeID: 'menu-logout'
    }
  ];

  if (Platform.OS === 'web') {
    telasGerais = telasGerais.filter(x => x.nome !== Telas.Ajuda);
  }

  return {
    telasDadosGerenciais,
    telasDadosPessoais,
    telasGerais
  };
}

export function retornarTelaPorPath(path: string) {
  let telaSelecionada: Telas | undefined = Object.values(Telas).find(
    value => value == path
  );

  //Se não encontrou é porque o path veio do módulo Web cujo o critério de busca deve ser baseado no path
  if (!telaSelecionada) {
    const telaEncontrada = rotasTelas.find(x => x.path == path);
    telaSelecionada = telaEncontrada ? telaEncontrada.nome : undefined;
  }

  return telaSelecionada;
}

export function retornarTituloTela(tela: Telas): string {
  switch (tela) {
    case Telas.Indicadores:
      return translate('Indicadores');
    case Telas.CartaoPonto:
      return translate('Cartão Ponto');
    case Telas.CartaoPontoGerente:
      return translate('Cartões Ponto');
    case Telas.DetalhesDiaBatidas:
      return translate('Detalhes');
    case Telas.IncluirPonto:
      return translate('Incluir Ponto');
    case Telas.IncluirAtividade:
      return translate('Incluir Atividade');
    case Telas.AjustarPonto:
      return translate('Ajustar Ponto');
    case Telas.JustificarAusencia:
      return translate('Justificar Ausência');
    case Telas.Solicitacoes:
      return translate('Minhas Solicitações');
    case Telas.DadosCadastrais:
      return primeiraLetraMaisculaCadaPalavra(translate('Dados Cadastrais'));
    case Telas.AlterarSenha:
      return translate('Alterar Senha');
    case Telas.AcompanhamentoPonto:
      return translate('Acompanhamento de Ponto');
    case Telas.Configuracoes:
      return translate('Configurações');
    case Telas.Login:
      return translate('Sair');
    case Telas.SolicitacoesGerente:
      return translate('Solicitações');
    case Telas.SolicitacoesDetalhes:
      return translate('Detalhes');
    case Telas.IndicadoresGerente:
      return translate('Indicadores Gerenciais');
    case Telas.Ajuda:
      return translate('Ajuda');
    case Telas.Notificacoes:
      return translate('Notificações');
    case Telas.DadosIndicadorCustomizado:
      return translate('Indicador');
    case Telas.ImpressaoCartaoPonto:
      return translate('Imprimir Cartão Ponto');
    case Telas.AssinaturaDigitalCartaoPonto:
      return translate('Assinatura Eletrônica de Cartão Ponto');
    case Telas.DeletarConta:
      return translate('Deletar Conta');
    case Telas.Arquivos:
      return translate('Arquivos');
    case Telas.ArquivosDetalhes:
      return translate('Detalhes');
    default:
      return '';
  }
}

export function retornarRotaWeb(tela: Telas): string {
  const telaSelecionada = rotasTelas.find(x => x.nome == tela);

  if (!telaSelecionada) {
    return '';
  }

  // Deve redirecionar para a tela de login: caso a tela acessada seja a de login ou se o usuário não tem acesso a tela especificada
  return telaSelecionada.nome == Telas.Login
    ? telaSelecionada.path
    : criarPathComBancoId(telaSelecionada.path);
}
