import * as React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { translate } from 'secullum-i18n';
import { Message, ErrorMessage } from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import CardHorasPeriodo from '../../components/CardHorasPeriodo';
import CardIncluirIndicador from '../../components/CardIncluirIndicador';
import CardIndicadorFuncionario from '../../components/CardIndicadorFuncionario';
import CardSaldoBancoDeHoras from '../../components/CardSaldoBancoDeHoras';
import { FuncionarioContainer } from '../../containers/FuncionarioContainer';
import Api from '../../modules/api';
import { verificarExibirColuna } from '../../modules/perfilFuncionario';
import {
  IndicadoresDados,
  IndicadorCustomizadoFuncionarioResultado
} from '../../modules/types';

interface State {
  campos: {
    horasExtrasDataFinal: Date;
    horasFaltantesDataInicial: Date;
    horasFaltantesDataFinal: Date;
    horasExtrasDataInicial: Date;
    saldoBancoDeHorasDataFinal: Date;
    saldoBancoDeHorasDataInicial: Date;
  };
  bancoHorasHabilitado: boolean;
  erro: string;
  dadosHorasFaltas: IndicadoresDados;
  dadosHorasExtras: IndicadoresDados;
  dadosSaldoBancoDeHoras: IndicadoresDados;
  listaDadosIndicadorCustomizado: Array<IndicadorCustomizadoFuncionarioResultado>;
  erroIndicadorHoraExtra: string;
  erroIndicadorHoraFaltante: string;
  erroSaldoBancoDeHoras: string;
}

interface CardIndicadoresProps {
  onClickEditarIndicador: (idIndicador?: number) => void;
  onErroConexao?: (mensagem: string, onTentarNovamente: () => void) => void;
}

const DadosVisualizacaoInicial = {
  dia: { valores: [] },
  mes: { valores: [] },
  semana: { valores: [] }
};

const dataFim = new Date();
const dataInicio = new Date(new Date().setDate(dataFim.getDate() - 9));

class CardIndicadores extends React.Component<CardIndicadoresProps, State> {
  state: State = {
    bancoHorasHabilitado: false,
    erro: '',
    campos: {
      horasExtrasDataInicial: dataInicio,
      horasExtrasDataFinal: dataFim,
      horasFaltantesDataInicial: dataInicio,
      horasFaltantesDataFinal: dataFim,
      saldoBancoDeHorasDataInicial: dataInicio,
      saldoBancoDeHorasDataFinal: dataFim
    },
    dadosHorasFaltas: DadosVisualizacaoInicial,
    dadosHorasExtras: DadosVisualizacaoInicial,
    dadosSaldoBancoDeHoras: DadosVisualizacaoInicial,
    listaDadosIndicadorCustomizado: [],
    erroIndicadorHoraExtra: '',
    erroIndicadorHoraFaltante: '',
    erroSaldoBancoDeHoras: ''
  };

  _cargaGeral: boolean = false;

  async componentDidMount() {
    await this.carregarIndicadoresAsync();
  }

  buscarIndicadoresSaldoBancoDeHoras = async () => {
    const { saldoBancoDeHorasDataFinal, saldoBancoDeHorasDataInicial } =
      this.state.campos;

    const api = new Api();

    if (api.funcionario.state.dados.bancoHorasHabilitado) {
      await api.getIndicadoresSaldoBancoDeHoras(
        {
          dataInicio: saldoBancoDeHorasDataInicial,
          dataFinal: saldoBancoDeHorasDataFinal
        },
        {
          onSuccess: (resp: IndicadoresDados) => {
            this.setState({ dadosSaldoBancoDeHoras: resp });
          },
          onError: (err: Error) => {
            if (err.name === 'TimeoutError' && this.props.onErroConexao) {
              this.props.onErroConexao(
                err.message,
                this._cargaGeral
                  ? () => this.carregarIndicadoresAsync()
                  : () => this.buscarIndicadoresSaldoBancoDeHoras()
              );
            } else {
              this.setState({
                erro: err.message
              });
            }
          }
        }
      );
    }

    this.setState({
      bancoHorasHabilitado: api.funcionario.state.dados.bancoHorasHabilitado
    });
  };

  buscarIndicadoresHorasExtras = async () => {
    const { horasExtrasDataInicial, horasExtrasDataFinal } = this.state.campos;
    const api = new Api();

    await api.getIndicadoresHorasExtras(
      { dataInicio: horasExtrasDataInicial, dataFinal: horasExtrasDataFinal },
      {
        onSuccess: (resp: IndicadoresDados) => {
          this.setState({ dadosHorasExtras: resp });
        },
        onError: (err: Error) => {
          if (err.name === 'TimeoutError' && this.props.onErroConexao) {
            this.props.onErroConexao(
              err.message,
              this._cargaGeral
                ? () => this.carregarIndicadoresAsync()
                : () => this.buscarIndicadoresHorasExtras()
            );
          } else {
            this.setState({
              erro: err.message
            });
          }
        }
      }
    );
  };

  buscarIndicadoresHorasFaltas = async () => {
    const { horasFaltantesDataInicial, horasFaltantesDataFinal } =
      this.state.campos;

    const api = new Api();

    await api.getIndicadoresHorasFaltantes(
      {
        dataInicio: horasFaltantesDataInicial,
        dataFinal: horasFaltantesDataFinal
      },
      {
        onSuccess: (resp: IndicadoresDados) => {
          this.setState({ dadosHorasFaltas: resp });
        },
        onError: (err: Error) => {
          if (err.name === 'TimeoutError' && this.props.onErroConexao) {
            this.props.onErroConexao(
              err.message,
              this._cargaGeral
                ? () => this.carregarIndicadoresAsync()
                : () => this.buscarIndicadoresHorasFaltas()
            );
          } else {
            this.setState({
              erro: err.message
            });
          }
        }
      }
    );
  };

  buscarIndicadoresFuncionario = async (
    dataInicio: Date,
    dataFinal: Date,
    indicadorId?: number
  ) => {
    const api = new Api();

    await api.getDadosIndicadoresFuncionario(
      {
        dataInicio,
        dataFinal,
        indicadorId
      },
      {
        onSuccess: (resp: Array<IndicadorCustomizadoFuncionarioResultado>) => {
          this.setState({
            listaDadosIndicadorCustomizado: resp
          });
        },
        onError: (err: Error) => {
          if (err.name === 'TimeoutError' && this.props.onErroConexao) {
            this.props.onErroConexao(
              err.message,
              this._cargaGeral
                ? () => this.carregarIndicadoresAsync()
                : () =>
                    this.buscarIndicadoresFuncionario(
                      dataInicio,
                      dataFim,
                      indicadorId
                    )
            );
          } else {
            this.setState({
              erro: err.message
            });
          }
        }
      }
    );
  };

  handleDateChange = (value: Date, name: keyof State['campos']) => {
    this.setState(
      {
        campos: {
          ...this.state.campos,
          [name]: value,
          [name.replace('DataInicial', 'DataFinal')]: value // Alteração necessária para que o calendário não marque as datas a partir da última data final escolhida
        },
        erroIndicadorHoraExtra: '',
        erroIndicadorHoraFaltante: '',
        erroSaldoBancoDeHoras: ''
      },
      () => {
        if (name === 'horasFaltantesDataFinal') {
          if (
            this.state.campos.horasFaltantesDataInicial >
            this.state.campos.horasFaltantesDataFinal
          ) {
            this.setState({
              erroIndicadorHoraFaltante: translate(
                'Selecione o período corretamente'
              )
            });
          } else {
            this.buscarIndicadoresHorasFaltas();
          }
        }

        if (name === 'horasExtrasDataFinal') {
          if (
            this.state.campos.horasExtrasDataInicial >
            this.state.campos.horasExtrasDataFinal
          ) {
            this.setState({
              erroIndicadorHoraExtra: translate(
                'Selecione o período corretamente'
              )
            });
          } else {
            this.buscarIndicadoresHorasExtras();
          }
        }

        if (name === 'saldoBancoDeHorasDataFinal') {
          if (
            this.state.campos.saldoBancoDeHorasDataInicial >
            this.state.campos.saldoBancoDeHorasDataFinal
          ) {
            this.setState({
              erroSaldoBancoDeHoras: translate(
                'Selecione o período corretamente'
              )
            });
          } else {
            this.buscarIndicadoresSaldoBancoDeHoras();
          }
        }
      }
    );
  };

  carregarIndicadoresAsync = async () => {
    this._cargaGeral = true;
    await this.buscarIndicadoresHorasFaltas();
    await this.buscarIndicadoresHorasExtras();
    await this.buscarIndicadoresSaldoBancoDeHoras();
    await this.buscarIndicadoresFuncionario(dataInicio, dataFim);
    this._cargaGeral = false;
  };

  render() {
    const { listaDadosIndicadorCustomizado } = this.state;

    return (
      <View style={styles.cardIndicadores}>
        <Subscribe to={[FuncionarioContainer]}>
          {(funcionario: FuncionarioContainer) => {
            return (
              <>
                {this.state.bancoHorasHabilitado &&
                  verificarExibirColuna(
                    'BSaldo',
                    funcionario.state.dados.dadosPerfilFuncionario
                      .colunasOcultar
                  ) && (
                    <>
                      <CardSaldoBancoDeHoras
                        nativeID="indicadores-banco-horas"
                        positivo
                        visualizacoes={this.state.dadosSaldoBancoDeHoras}
                        dataInicial={
                          this.state.campos.saldoBancoDeHorasDataInicial
                        }
                        dataFinal={this.state.campos.saldoBancoDeHorasDataFinal}
                        onDataInicialChange={date =>
                          this.handleDateChange(
                            date,
                            'saldoBancoDeHorasDataInicial'
                          )
                        }
                        onDataFinalChange={date =>
                          this.handleDateChange(
                            date,
                            'saldoBancoDeHorasDataFinal'
                          )
                        }
                        onEndDateCancel={() =>
                          this.buscarIndicadoresSaldoBancoDeHoras()
                        }
                      />
                      <ErrorMessage
                        message={this.state.erroSaldoBancoDeHoras}
                      />
                    </>
                  )}

                {verificarExibirColuna(
                  'Extras',
                  funcionario.state.dados.dadosPerfilFuncionario.colunasOcultar
                ) && (
                  <>
                    <CardHorasPeriodo
                      nativeID="indicadores-horas-extras"
                      positivo
                      titulo={translate('Horas Extras')}
                      visualizacoes={this.state.dadosHorasExtras}
                      dataInicial={this.state.campos.horasExtrasDataInicial}
                      dataFinal={this.state.campos.horasExtrasDataFinal}
                      onDataInicialChange={date =>
                        this.handleDateChange(date, 'horasExtrasDataInicial')
                      }
                      onDataFinalChange={date =>
                        this.handleDateChange(date, 'horasExtrasDataFinal')
                      }
                      onEndDateCancel={() => {
                        this.buscarIndicadoresHorasExtras();
                      }}
                    />
                    <ErrorMessage message={this.state.erroIndicadorHoraExtra} />
                  </>
                )}

                {verificarExibirColuna(
                  'Faltas',
                  funcionario.state.dados.dadosPerfilFuncionario.colunasOcultar
                ) && (
                  <>
                    <CardHorasPeriodo
                      nativeID="indicadores-horas-faltantes"
                      positivo={false}
                      titulo={translate('Horas Faltantes')}
                      visualizacoes={this.state.dadosHorasFaltas}
                      dataInicial={this.state.campos.horasFaltantesDataInicial}
                      dataFinal={this.state.campos.horasFaltantesDataFinal}
                      onDataInicialChange={date =>
                        this.handleDateChange(date, 'horasFaltantesDataInicial')
                      }
                      onDataFinalChange={date =>
                        this.handleDateChange(date, 'horasFaltantesDataFinal')
                      }
                      onEndDateCancel={() => {
                        this.buscarIndicadoresHorasFaltas();
                      }}
                    />

                    <ErrorMessage
                      message={this.state.erroIndicadorHoraFaltante}
                    />
                  </>
                )}
              </>
            );
          }}
        </Subscribe>

        {listaDadosIndicadorCustomizado &&
          listaDadosIndicadorCustomizado.map((dados, indice) => (
            <CardIndicadorFuncionario
              key={`indicadores-funcionario-${indice}`}
              nativeID={`indicadores-funcionario-${indice}`}
              nomeColunaIndicador={dados.nomeColunaIndicador}
              titulo={dados.indicador.titulo}
              visualizacoes={dados.dadosIndicador}
              dataInicial={dataInicio}
              dataFinal={dataFim}
              corTexto={dados.indicador.corTexto}
              indicadorId={dados.indicador.id}
              onEditarIndicador={() =>
                dados.indicador.id &&
                this.props.onClickEditarIndicador(dados.indicador.id)
              }
              onErroConexao={this.props.onErroConexao}
            />
          ))}
        <Message
          nativeID="indicadores-erro"
          type="warning"
          message={this.state.erro}
          visible={this.state.erro !== ''}
          onRequestClose={() => this.setState({ erro: '' })}
        />
        <CardIncluirIndicador
          onClickIncluirIndicador={this.props.onClickEditarIndicador}
          customContainerStyles={styles.cardIncluirIndicador}
          cardHeight={342}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  cardIndicadores: {
    flexDirection: Platform.OS === 'web' ? 'row' : 'column',
    flexWrap: 'wrap',
    overflow: 'hidden',
    padding: Platform.OS === 'web' ? 16 : undefined,
    marginTop: Platform.OS === 'web' ? -32 : undefined,
    marginLeft: Platform.OS === 'web' ? -16 : undefined
  },
  cardIncluirIndicador: {
    marginTop: 16
  }
});

export default CardIndicadores;
