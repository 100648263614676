import * as React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  isTablet,
  Modal,
  TextBox,
  formatDate,
  Text
} from 'secullum-react-native-ui';
import { ImageButton } from 'secullum-react-native-ui';
import { getTheme } from '../../shared/modules/layout';
import { ButtonBar } from '../components/ButtonBar';

export interface DadosModalMotivo {
  id: number;
  titulo: string;
  data: Date;
  funcionarioNome: string;
  observacoes: string;
  hora?: string;
}

interface Props {
  dados: DadosModalMotivo;
  onCancelar: () => void;
  onConcluir: (motivo: string) => void;
}

interface State {
  motivo: string;
}

class ModalSolicitacao extends React.Component<Props, State> {
  state: State = {
    motivo: ''
  };

  async componentDidMount() {
    this.setState({ motivo: this.props.dados.observacoes });
  }

  handleChangeMotivo = (motivo: string) => {
    this.setState({ motivo: motivo });
  };

  render() {
    const { dados } = this.props;
    const { motivo } = this.state;

    return (
      <Modal
        visible={dados !== undefined}
        onRequestClose={this.props.onCancelar}
        overlayStyle={Platform.OS === 'web' ? styles.modalWeb : styles.modal}
      >
        <View
          style={Platform.OS === 'web' ? styles.containerWeb : styles.container}
        >
          {Platform.OS === 'web' ? (
            <View style={styles.modalIcons}>
              <Text bold style={styles.title}>
                {translate(dados.titulo)}
              </Text>
              <ImageButton
                style={styles.imageButtonLeft}
                icon="close"
                onPress={this.props.onCancelar}
              />
            </View>
          ) : (
            <Text bold style={styles.title}>
              {translate(dados.titulo)}
            </Text>
          )}
          <TextBox
            label={translate('Funcionário')}
            style={styles.bodyText}
            editable={false}
            value={dados.funcionarioNome}
            nativeID="input-funcionario"
          />
          <TextBox
            label={translate('Registro')}
            style={styles.bodyText}
            editable={false}
            value={`${formatDate(dados.data, 'dd/MM/yyyy')}${
              dados.hora ? ' - ' + dados.hora : ''
            }`}
            nativeID="input-data-registro"
          />
          <TextBox
            label={translate('Motivo')}
            style={[styles.bodyText, Platform.OS === 'web' && styles.TextWeb]}
            multiline={true}
            value={motivo}
            onChange={this.handleChangeMotivo}
            nativeID="input-motivo"
          />
          <ButtonBar
            buttonBarStyle={{ padding: 16 }}
            leftButton={{
              text: translate('Cancelar'),
              onPress: this.props.onCancelar,
              primary: false,
              nativeID: 'botao-modal-cancelar-solicitacao'
            }}
            rightButton={{
              text: translate('Enviar'),
              onPress: () => this.props.onConcluir(motivo),
              nativeID: 'botao-modal-enviar-solicitacao'
            }}
          />
        </View>
      </Modal>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: 'center'
  },
  modalWeb: {
    overflow: 'scroll'
  },
  modalIcons: {
    flexDirection: 'row'
  },
  container: {
    backgroundColor: theme.backgroundColor1,
    marginHorizontal: 16,
    borderRadius: 5
  },
  containerWeb: {
    margin: 'auto',
    width: '90%',
    maxWidth: 366,
    backgroundColor: theme.backgroundColor2,
    borderRadius: 10,
    justifyContent: 'center'
  },
  title: {
    padding: 16,
    color: theme.textColor3,
    fontSize: isTablet() ? 20 : 17
  },
  bodyText: {
    padding: 16,
    margin: 16,
    marginBottom: 5,
    marginTop: 5,
    height: 60
  },
  TextWeb: {
    backgroundColor: theme.backgroundColor1
  },
  imageButtonLeft: {
    borderWidth: 0,
    marginLeft: 'auto'
  }
});

export default ModalSolicitacao;
