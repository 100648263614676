import * as React from 'react';
import { ContainerType, Subscribe } from 'unstated';

const withContainer =
  (container: ContainerType<any>, propName: string = 'container') =>
  (WrappedComponent: React.ComponentType<any>) => {
    return class extends React.Component {
      render() {
        return (
          <Subscribe to={[container]}>
            {container =>
              React.createElement(WrappedComponent, {
                [propName]: container,
                ...this.props
              })
            }
          </Subscribe>
        );
      }
    };
  };

export default withContainer;
