import * as React from 'react';
import { translate } from 'secullum-i18n';
import ModalSolicitacao, {
  DadosModalMotivo
} from '../../shared/components/ModalSolicitacao';
import Api from '../modules/api';
import {
  TipoAlteracaoFonteDados,
  SolicitacoesDados,
  SolicitacaoAlteracaoFonteDados
} from '../modules/types';

interface State {
  dadosModalMotivo: DadosModalMotivo | null;
}

interface Props {
  solicitacaoAtual: SolicitacoesDados | null;
  tipoAcao: TipoAlteracaoFonteDados | null;
  concluirEnvioSucesso: (solicitacao: SolicitacoesDados) => void;
  concluirEnvioErro: (erro: string) => void;
  finalizarProcessamento: () => void;
}

const ehAlteracaoSemMotivoPreenchido = (
  alteracao: SolicitacaoAlteracaoFonteDados
) => {
  const tiposQuePrecisamPreenchimento = [
    TipoAlteracaoFonteDados.IncluirFonteDadosDescarte,
    TipoAlteracaoFonteDados.IncluirFonteDadosInclusaoManual
  ];

  return (
    tiposQuePrecisamPreenchimento.includes(alteracao.tipo) &&
    alteracao.motivo == null
  );
};

class SolicitacaoProcessamento extends React.Component<Props, State> {
  state: State = {
    dadosModalMotivo: null
  };

  componentDidMount() {
    this.processarSolicitacaoAtual();
  }

  handleModalMotivoConcluir = (motivo: string) => {
    const { solicitacaoAtual, tipoAcao } = this.props;

    if (!solicitacaoAtual) {
      return;
    }

    if (tipoAcao === TipoAlteracaoFonteDados.Troca) {
      const alteracaoFonteDadosParaPreencher =
        solicitacaoAtual.alteracoesFonteDados.find(
          ehAlteracaoSemMotivoPreenchido
        );

      if (alteracaoFonteDadosParaPreencher === undefined) {
        return;
      }

      alteracaoFonteDadosParaPreencher!.motivo = motivo;
    } else {
      solicitacaoAtual.motivoRejeicao = motivo;
    }

    this.processarSolicitacaoAtual(true);
  };

  processarSolicitacaoAtual = (
    forcarValidacaoMotivoRejeicao: boolean = false
  ) => {
    const { solicitacaoAtual, tipoAcao } = this.props;

    if (!solicitacaoAtual) {
      return;
    }

    if (tipoAcao === TipoAlteracaoFonteDados.Troca) {
      const alteracaoFonteDadosParaPreencher =
        solicitacaoAtual.alteracoesFonteDados.find(
          ehAlteracaoSemMotivoPreenchido
        );

      // Preencheu tudo que precisava -> manda pro servidor
      // Falta preencher -> mostra modal
      if (alteracaoFonteDadosParaPreencher === undefined) {
        this.enviarSolicitacaoAprovada(solicitacaoAtual);
      } else {
        const batida = solicitacaoAtual.batidas.find(
          value => alteracaoFonteDadosParaPreencher.coluna === value.coluna
        );

        const hora =
          alteracaoFonteDadosParaPreencher.tipo ===
          TipoAlteracaoFonteDados.IncluirFonteDadosDescarte
            ? batida!.valorOriginal
            : batida!.valor;

        const titulo =
          alteracaoFonteDadosParaPreencher.tipo ===
          TipoAlteracaoFonteDados.IncluirFonteDadosDescarte
            ? 'Informe o motivo do descarte do seguinte registro original'
            : 'Informe o motivo da inclusão manual';

        this.setState({
          dadosModalMotivo: {
            id: solicitacaoAtual.id,
            titulo: translate(titulo),
            data: solicitacaoAtual.data,
            hora: hora,
            funcionarioNome: solicitacaoAtual.funcionarioNome,
            observacoes:
              alteracaoFonteDadosParaPreencher.tipo ===
              TipoAlteracaoFonteDados.IncluirFonteDadosDescarte
                ? ''
                : solicitacaoAtual.observacao
          }
        });
      }
    } else if (tipoAcao === TipoAlteracaoFonteDados.MarcarVisto) {
      this.enviarSolicitacaoMarcarVisto(solicitacaoAtual);
    } else if (tipoAcao === TipoAlteracaoFonteDados.DesmarcarVisto) {
      this.enviarSolicitacaoDesmarcarVisto(solicitacaoAtual);
    } else {
      if (forcarValidacaoMotivoRejeicao) {
        this.enviarSolicitacaoRejeitada(solicitacaoAtual);
      } else {
        this.setState({
          dadosModalMotivo: {
            id: solicitacaoAtual.id,
            titulo: translate('Informe o motivo do descarte'),
            data: solicitacaoAtual.data,
            funcionarioNome: solicitacaoAtual.funcionarioNome,
            observacoes: ''
          }
        });
      }
    }
  };

  enviarSolicitacaoAprovada = (solicitacao: SolicitacoesDados) => {
    const api = new Api();

    api.postAprovarSolicitacao(solicitacao, {
      onSuccess: retorno => this.props.concluirEnvioSucesso(retorno),
      onError: err => {
        this.props.concluirEnvioErro(err.message);
      },
      onValidationError: errorList => {
        let mensagemErro = '';
        errorList.forEach(error => (mensagemErro += error.message + '\n'));
        this.props.concluirEnvioErro(mensagemErro);
      }
    });
  };

  enviarSolicitacaoMarcarVisto = (solicitacao: SolicitacoesDados) => {
    const api = new Api();

    api.postMarcarVistoSolicitacao(solicitacao, {
      onSuccess: retorno => this.props.concluirEnvioSucesso(retorno),
      onError: err => {
        this.props.concluirEnvioErro(err.message);
      },
      onValidationError: errorList => {
        let mensagemErro = '';
        errorList.forEach(error => (mensagemErro += error.message + '\n'));
        this.props.concluirEnvioErro(mensagemErro);
      }
    });
  };

  enviarSolicitacaoDesmarcarVisto = (solicitacao: SolicitacoesDados) => {
    const api = new Api();

    api.postDesmarcarVistoSolicitacao(solicitacao, {
      onSuccess: retorno => this.props.concluirEnvioSucesso(retorno),
      onError: err => {
        this.props.concluirEnvioErro(err.message);
      },
      onValidationError: errorList => {
        let mensagemErro = '';
        errorList.forEach(error => (mensagemErro += error.message + '\n'));
        this.props.concluirEnvioErro(mensagemErro);
      }
    });
  };

  enviarSolicitacaoRejeitada = (solicitacao: SolicitacoesDados) => {
    const api = new Api();

    api.postReprovarSolicitacao(solicitacao, {
      onSuccess: retorno => this.props.concluirEnvioSucesso(retorno),
      onError: err => {
        this.props.concluirEnvioErro(err.message);
      },
      onValidationError: (errorList, _) => {
        let mensagemErro = '';
        errorList.forEach(error => (mensagemErro += error.message + '\n'));
        this.props.concluirEnvioErro(mensagemErro);
      }
    });
  };

  render() {
    const { dadosModalMotivo } = this.state;

    if (!dadosModalMotivo) {
      return null;
    }

    return (
      <ModalSolicitacao
        key={dadosModalMotivo.id}
        dados={dadosModalMotivo}
        onCancelar={this.props.finalizarProcessamento}
        onConcluir={this.handleModalMotivoConcluir}
      />
    );
  }
}

export default SolicitacaoProcessamento;
