import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Telas, retornarRotaWeb } from '../../shared/modules/telas';
import Configuracoes from '../../web/screens/configuracoes/Configuracoes';
import IndicadoresGerente from '../../web/screens/indicadores-gerente/IndicadoresGerente';
import PontoDiario from '../../web/screens/ponto-diario/PontoDiario';
import { criarPathComBancoId } from '../modules/routes';
import AjustarPonto from '../screens/ajustar-ponto/AjustarPonto';
import AlterarSenha from '../screens/alterar-senha/AlterarSenha';
import Arquivos from '../screens/arquivos/Arquivos';
import AssinaturaDigitalCartaoPonto from '../screens/assinatura-digital-cartao-ponto/AssinaturaDigitalCartaoPonto';
import AvisoSemAcessoTelas from '../screens/aviso-sem-telas-disponiveis/AvisoSemAcessoTelas';
import CartaoPontoGerente from '../screens/cartao-ponto-gerente/CartaoPontoGerente';
import CartaoPonto from '../screens/cartao-ponto/CartaoPonto';
import DadosCadastrais from '../screens/dados-cadastrais/DadosCadastrais';
import DadosIndicadorCustomizado from '../screens/dados-indicador-customizado/DadosIndicadorCustomizado';
import DeletarConta from '../screens/deletar-conta/DeletarConta';
import IncluirAtividade from '../screens/incluir-atividade/IncluirAtividade';
import IncluirPonto from '../screens/incluir-ponto/IncluirPonto';
import Indicadores from '../screens/indicadores/Indicadores';
import JustificarAusencia from '../screens/justificar-ausencia/JustificarAusencia';
import NaoEncontrada from '../screens/nao-encontrada/NaoEncontrada';
import Notificacoes from '../screens/notificacoes/Notificacoes';
import SolicitacoesGerente from '../screens/solicitacoes-gerente/SolicitacoesGerente';
import Solicitacoes from '../screens/solicitacoes/Solicitacoes';

interface RoutesProps {
  initialRoute: Telas;
  bancoIdToRedirect: string;
  redirectToHome: boolean;
}

function Routes(props: RoutesProps) {
  // issue 8152 - Se tiver os dados de login + opção Continuar Conectado na tela de login da Central
  // adicionamos o bancoId na URL, acontecia do usuario marcar para Continuar Conectado e o login não ser realizado
  // ao acessar a Central pelo link que não tem o banco na URL.
  const urlToRedirect = props.redirectToHome
    ? '/' +
      props.bancoIdToRedirect +
      retornarRotaWeb(props.initialRoute).replace('/:bancoId(\\d+)?', '')
    : retornarRotaWeb(props.initialRoute);

  return (
    <Switch>
      <Route
        path={retornarRotaWeb(Telas.Indicadores)}
        exact
        component={Indicadores}
      />
      <Route
        path={`${retornarRotaWeb(Telas.IndicadoresGerente)}/:visualizacao?`}
        exact
        component={IndicadoresGerente}
      />
      <Route
        path={retornarRotaWeb(Telas.CartaoPonto)}
        exact
        component={CartaoPonto}
      />
      <Route
        path={`${retornarRotaWeb(
          Telas.CartaoPontoGerente
        )}/:funcionarioSelecionadoId?`}
        exact
        component={CartaoPontoGerente}
      />
      <Route
        path={`${retornarRotaWeb(Telas.AjustarPonto)}/:data?/:funcionarioId?`}
        exact
        component={AjustarPonto}
      />
      <Route
        path={retornarRotaWeb(Telas.IncluirPonto)}
        exact
        component={IncluirPonto}
      />
      <Route
        path={retornarRotaWeb(Telas.IncluirAtividade)}
        exact
        component={IncluirAtividade}
      />
      <Route
        path={`${retornarRotaWeb(
          Telas.JustificarAusencia
        )}/:data?/:funcionarioId?`}
        exact
        component={JustificarAusencia}
      />
      <Route
        path={retornarRotaWeb(Telas.Solicitacoes)}
        exact
        component={Solicitacoes}
      />
      <Route
        path={retornarRotaWeb(Telas.SolicitacoesGerente)}
        exact
        component={SolicitacoesGerente}
      />
      <Route
        path={retornarRotaWeb(Telas.Arquivos)}
        exact
        component={Arquivos}
      />
      <Route
        path={retornarRotaWeb(Telas.AssinaturaDigitalCartaoPonto)}
        exact
        component={AssinaturaDigitalCartaoPonto}
      />
      <Route
        path={retornarRotaWeb(Telas.AcompanhamentoPonto)}
        exact
        component={PontoDiario}
      />
      <Route
        path={retornarRotaWeb(Telas.DadosCadastrais)}
        exact
        component={DadosCadastrais}
      />
      <Route
        path={`${retornarRotaWeb(
          Telas.DadosIndicadorCustomizado
        )}/:modoTela/:indicadorId?`}
        exact
        component={DadosIndicadorCustomizado}
      />
      <Route
        path={retornarRotaWeb(Telas.AlterarSenha)}
        exact
        component={AlterarSenha}
      />
      <Route
        path={retornarRotaWeb(Telas.Configuracoes)}
        exact
        component={Configuracoes}
      />
      <Route
        path={retornarRotaWeb(Telas.Notificacoes)}
        exact
        component={Notificacoes}
      />
      <Route
        path={retornarRotaWeb(Telas.DeletarConta)}
        exact
        component={DeletarConta}
      />
      <Route
        path={retornarRotaWeb(Telas.ErroSemTelasDisponiveis)}
        exact
        component={AvisoSemAcessoTelas}
      />
      <Redirect from={criarPathComBancoId('/')} exact to={urlToRedirect} />
      <Route component={NaoEncontrada} />
    </Switch>
  );
}

export default Routes;
