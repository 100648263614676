import * as base64 from 'base-64';
import * as React from 'react';
import { View } from 'react-native';
import { translate } from 'secullum-i18n';
import { Details } from 'secullum-react-native-ui';
import { getUrlApi } from '../modules/api';
import { carregarDadosLoginAsync } from '../modules/auth';
import {
  AssinaturaDigitalCartaoPontoDados,
  TipoAlteracaoAssinaturaDigitalCartaoPonto
} from '../modules/types';

interface Props {
  assinaturaDigitalCartaoPonto: AssinaturaDigitalCartaoPontoDados;
  estado: TipoAlteracaoAssinaturaDigitalCartaoPonto | null;
  onHabilitarScroll?: (habilitar: boolean) => void;
}

interface State {
  url: string;
}

class VisualizadorPdf extends React.Component<Props, State> {
  state: State = {
    url: ''
  };

  async componentDidMount() {
    await this.carregarPdfAsync();
  }

  carregarPdfAsync = async () => {
    const dadosLogin = await carregarDadosLoginAsync();
    const { assinaturaDigitalCartaoPonto } = this.props;

    if (!dadosLogin || !dadosLogin.bancoId || !assinaturaDigitalCartaoPonto) {
      return;
    }

    const user = base64.encode(
      dadosLogin.funcionarioNumero +
        ':' +
        dadosLogin.funcionarioSenha +
        ':' +
        dadosLogin.tipoUsuarioAutenticacao
    );

    const urlApiBase = getUrlApi();

    const url = `${urlApiBase}${dadosLogin.bancoId}/AssinaturaDigitalCartaoPonto/${assinaturaDigitalCartaoPonto.assinaturaDigitalCartaoPontoId}?axpw=${user}`;

    this.setState({ url: url });
  };

  renderPdfViewer = () => {
    return (
      <View style={{ padding: 16 }}>
        <embed
          key={this.props.estado}
          src={this.state.url}
          style={{ maxWidth: 500, height: 761 }}
        />
      </View>
    );
  };

  render() {
    return (
      this.state.url !== '' && (
        <Details
          title={translate('Cartão Ponto')}
          lines={[{ render: this.renderPdfViewer }]}
        />
      )
    );
  }
}

export default VisualizadorPdf;
