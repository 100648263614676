import AsyncStorage from '@react-native-async-storage/async-storage';

const CHAVE_CONFIGURACAO_TEMPO_MAX_RESPOSTA = 'ConfiguracaoTempoMaxResposta';

export const salvarTempoMaximoRespostaAsync = async (timeout: number) => {
  await AsyncStorage.setItem(
    CHAVE_CONFIGURACAO_TEMPO_MAX_RESPOSTA,
    JSON.stringify(timeout)
  );
};

export const carregarTempoMaximoRespostaAsync = async () => {
  const tempoMaximoRespostaJson = await AsyncStorage.getItem(
    CHAVE_CONFIGURACAO_TEMPO_MAX_RESPOSTA
  );

  const tempoMaximoResposta = tempoMaximoRespostaJson
    ? (JSON.parse(tempoMaximoRespostaJson) as number)
    : 60;

  return tempoMaximoResposta;
};

export const fetchTimeout = (
  url: string,
  options: RequestInit | undefined = undefined,
  timeout: number = 60, // 1min
  timeoutMessage: string | undefined = undefined
): Promise<Response> => {
  return Promise.race([
    fetch(url, options),
    new Promise<Response>((_, reject) =>
      setTimeout(() => reject(new TimeoutError(timeoutMessage)), timeout * 1000)
    )
  ]);
};

class TimeoutError extends Error {
  constructor(message: string | undefined) {
    super(message);
    this.name = 'TimeoutError';
  }
}
