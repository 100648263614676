import { translate } from 'secullum-i18n';
import { Container } from 'unstated';
import { getTheme } from '../../shared/modules/layout';
import Api from '../modules/api';
import {
  SolicitacoesDados,
  SolicitacoesFiltros,
  SolicitacoesStatus,
  SolicitacoesFiltrosStatus,
  TipoPerfil
} from '../modules/types';

export interface State {
  lista: Array<SolicitacoesDados>;
  precisaProcessarSolicitacoes?: boolean;
  erro: string;
  erroConexao: boolean;
  onTentarNovamente: (() => void) | null;
}

const theme = getTheme();

export const getIconeStatus = (status: SolicitacoesStatus) => {
  switch (status) {
    case SolicitacoesStatus.Aceita:
      return 'thumbs-up';
    case SolicitacoesStatus.Descartada:
      return 'thumbs-down';
    case SolicitacoesStatus.Visto:
      return 'check';
    default:
      return 'hourglass-half';
  }
};

export const getCorStatus = (status: SolicitacoesStatus) => {
  switch (status) {
    case SolicitacoesStatus.Aceita:
      return theme.successColor;
    case SolicitacoesStatus.Descartada:
      return theme.errorColor;
    case SolicitacoesStatus.Visto:
      return theme.backgroundColor3;
    default:
      return theme.warningColor;
  }
};

export class SolicitacoesContainer extends Container<State> {
  state: State = {
    lista: [],
    erro: '',
    erroConexao: false,
    onTentarNovamente: null
  };

  itensStatusSolicitacoes = [
    {
      nativeID: 'solicitacoes-filtros-todas',
      value: SolicitacoesFiltrosStatus.Todos,
      label: translate('Todas')
    },
    {
      nativeID: 'solicitacoes-filtros-processadas',
      value: SolicitacoesFiltrosStatus.Processados,
      label:
        translate('Aceitas') +
        '/' +
        translate('Rejeitadas') +
        '/' +
        translate('Vistas')
    },
    {
      nativeID: 'solicitacoes-filtros-pendentes',
      value: SolicitacoesFiltrosStatus.Pendentes,
      label: translate('Aguardando Retorno')
    }
  ];

  loadAsyncPorSolicitacaoId = async (
    solicitacaoId: number,
    tipoPerfilFuncionario: TipoPerfil
  ) => {
    const api = new Api();

    //Nesta consulta não considera a data da solicitação na busca, somente o Id e o perfil do funcionário
    const filtros = {
      dataInicio: null,
      dataFinal: null,
      status: SolicitacoesFiltrosStatus.Todos,
      perfil: tipoPerfilFuncionario,
      solicitacaoId: solicitacaoId
    };

    await api.getSolicitacoes(filtros, {
      onSuccess: async (lista: Array<SolicitacoesDados>) => {
        this.setState({
          lista,
          precisaProcessarSolicitacoes:
            lista.filter(x => x.status == SolicitacoesStatus.Pendente).length >
            0
        });
      },
      onError: (err: Error) => {
        this.setState({
          erro: err.message,
          erroConexao: err.name === 'TimeoutError',
          onTentarNovamente: () =>
            this.loadAsyncPorSolicitacaoId(solicitacaoId, tipoPerfilFuncionario)
        });
      }
    });
  };

  loadAsync = async (filtros: SolicitacoesFiltros) => {
    const api = new Api();

    await api.getSolicitacoes(filtros, {
      onSuccess: async (lista: Array<SolicitacoesDados>) => {
        this.setState({
          lista,
          precisaProcessarSolicitacoes:
            lista.filter(x => x.status == SolicitacoesStatus.Pendente).length >
            0
        });
      },
      onError: (err: Error) => {
        this.setState({
          erro: err.message,
          erroConexao: err.name === 'TimeoutError',
          onTentarNovamente: () => this.loadAsync(filtros)
        });
      }
    });
  };

  limparErro = () => {
    this.setState({
      erro: '',
      erroConexao: false,
      onTentarNovamente: null
    });
  };
}
