import * as React from 'react';
import { translate } from 'secullum-i18n';
import { parseDate } from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import { FuncionarioContainer } from '../../../shared/containers/FuncionarioContainer';
import { verificarExibirTela } from '../../../shared/modules/perfilFuncionario';
import { retornarRotaWeb, Telas } from '../../../shared/modules/telas';
import { buscarPrimeiraTelaInicialDisponivel } from '../../../shared/modules/telasIniciais';
import { converterDataParaTimezoneBrasil } from '../../../shared/modules/utils';
import CardJustificarAusencia from '../../../shared/screens/justificar-ausencia/CardJustificarAusencia';
import LoggedInScreen from '../../components/LoggedInScreen';
import { comprimirImagem } from '../../modules/image';
import { RouteComponentComBancoIdProps, navegar } from '../../modules/routes';

interface Props
  extends RouteComponentComBancoIdProps<{
    data?: string;
    funcionarioId?: string;
  }> {}

class JustificarAusencia extends React.Component<Props> {
  fileInput = React.createRef<HTMLInputElement>();
  cardJustificarAusencia = React.createRef<CardJustificarAusencia>();

  handleChange = async (imagens: FileList | null) => {
    if (!imagens || imagens.length == 0) {
      return;
    }

    const cardJustificarAusencia = this.cardJustificarAusencia.current;

    if (!cardJustificarAusencia) {
      return;
    }

    try {
      const imagemOtimizada = await comprimirImagem(
        URL.createObjectURL(imagens[0])
      );

      cardJustificarAusencia.incluirFoto(imagemOtimizada);
    } catch (exception) {
      cardJustificarAusencia.onErrorFoto(
        translate('Não foi possível carregar a imagem.')
      );
      return;
    }
  };

  getPathCartaoPontoGerente = (funcionarioId: number) => {
    return `/cartao-ponto-gerente/${funcionarioId}`;
  };

  render() {
    const { match } = this.props;

    const data = match.params.data
      ? parseDate(match.params.data, 'yyyy-MM-dd')
      : converterDataParaTimezoneBrasil(new Date());

    let funcionarioId: number | undefined;

    if (match.params.funcionarioId) {
      funcionarioId = Number(match.params.funcionarioId);
    }

    return (
      <LoggedInScreen screenTitle={translate('Justificar Ausência')}>
        <Subscribe to={[FuncionarioContainer]}>
          {(funcionario: FuncionarioContainer) => (
            <CardJustificarAusencia
              funcionarioId={funcionarioId}
              ref={this.cardJustificarAusencia}
              data={data}
              dados={funcionario.state.dados}
              onSuccess={funcionarioSelecionadoId => {
                const possuiPermissaoTelaCartaoPonto = verificarExibirTela(
                  funcionarioSelecionadoId
                    ? Telas.CartaoPontoGerente
                    : Telas.CartaoPonto,
                  funcionario.state.dados.dadosPerfilFuncionario.telasOcultar
                );

                // Case 136139: Caso o usuário não tenha permissão de acesso à
                // tela de cartão ponto, manda para a primeira tela que tiver disponível
                if (possuiPermissaoTelaCartaoPonto) {
                  navegar({
                    props: this.props,
                    path: funcionarioSelecionadoId
                      ? this.getPathCartaoPontoGerente(funcionarioSelecionadoId)
                      : '/cartao-ponto'
                  });
                } else {
                  const telaDisponivel = buscarPrimeiraTelaInicialDisponivel(
                    funcionario.state.dados,
                    Telas.JustificarAusencia
                  );

                  navegar({
                    props: this.props,
                    path: retornarRotaWeb(telaDisponivel)
                  });
                }
              }}
              onEnviarFoto={() => {
                if (this.fileInput.current) {
                  this.fileInput.current.click();
                }
              }}
            />
          )}
        </Subscribe>
        <input
          type="file"
          accept="image/*"
          ref={this.fileInput}
          onChange={e => this.handleChange(e.target.files)}
          style={{ visibility: 'hidden' }}
        />
      </LoggedInScreen>
    );
  }
}

export default JustificarAusencia;
