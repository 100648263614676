import { init, SecullumI18nData } from 'secullum-i18n';
import { setLocale, Locale } from 'secullum-react-native-ui';

export async function loadExpressionsAsync(localeText: string) {
  let locale: Locale;
  let promiseExpressions: Promise<{ default: SecullumI18nData }>;

  switch (localeText.toLowerCase().substr(0, 2)) {
    case 'en':
      locale = 'en';
      promiseExpressions = import('../expressions/en.json');
      break;
    case 'es':
      locale = 'es';
      promiseExpressions = import('../expressions/es.json');
      break;
    default:
      locale = 'pt';
      promiseExpressions = import('../expressions/pt.json');
      break;
  }

  const i18nData = await promiseExpressions.then(x => x.default);

  init(i18nData);
  setLocale(locale);
}
