import * as React from 'react';
import {
  View,
  StyleSheet,
  Dimensions,
  EmitterSubscription
} from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Button,
  Card,
  CheckBox,
  getTheme,
  isTablet,
  Text,
  Space
} from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import ViewPrincipal from '../../native/components/ViewPrincipal';
import { AvisoCamposImportantesContainer } from '../containers/AvisoCamposImportantesContainer';
import { FuncionarioContainer } from '../containers/FuncionarioContainer';
import { salvarNaoPerguntarNovamenteCamposImportantesAsync } from '../modules/configuracoes';
import { isDesktop } from '../modules/layout';
import { verificarExibirTela } from '../modules/perfilFuncionario';
import { Telas } from '../modules/telas';

interface State {
  naoPerguntarNovamente: boolean;
  idFuncionario: number;
}

interface Props {
  onCadastrar: (erro: string) => void;
}

class AvisoCamposImportantes extends React.Component<Props, State> {
  state: State = {
    naoPerguntarNovamente: false,
    idFuncionario: 0
  };

  screenChangeDimensionsSubscription: EmitterSubscription | undefined;

  componentDidMount() {
    this.screenChangeDimensionsSubscription = Dimensions.addEventListener(
      'change',
      this.handleDimensionsChange
    );
  }

  componentWillUnmount = async () => {
    if (this.state.naoPerguntarNovamente) {
      await salvarNaoPerguntarNovamenteCamposImportantesAsync(
        this.state.idFuncionario
      );
    }

    if (this.screenChangeDimensionsSubscription) {
      this.screenChangeDimensionsSubscription.remove();
    }
  };

  handleDimensionsChange = () => {
    this.forceUpdate();
  };

  render() {
    return (
      <ViewPrincipal>
        <Subscribe to={[FuncionarioContainer, AvisoCamposImportantesContainer]}>
          {(
            funcionario: FuncionarioContainer,
            avisoCamposImportantes: AvisoCamposImportantesContainer
          ) => {
            return (
              <>
                <Card nativeID="aviso-cadastrar-email" style={styles.container}>
                  <Card.Section>
                    <Text style={styles.text}>
                      {translate(
                        'Alguns campos importantes para o acesso a recursos do sistema não estão informados no seu cadastro:'
                      )}
                    </Text>
                    <Space />
                    {avisoCamposImportantes
                      .listarCamposImportantes(funcionario.state.dados)
                      .map((campo, index) => (
                        <React.Fragment key={index}>
                          <Text style={styles.text}>
                            <Text style={styles.text} bold>
                              {campo.tipo}
                              {' - '}
                            </Text>
                            <Text style={styles.text}>{campo.descricao}</Text>
                          </Text>
                          <Space />
                        </React.Fragment>
                      ))}
                    <Text style={styles.text}>
                      {translate(
                        'Você não é obrigado a preencher estes dados neste aplicativo. Se preferir, você pode solicitar pessoalmente o preenchimento destas informações na empresa onde você trabalha.'
                      )}
                    </Text>
                    <Space height={32} />
                    <CheckBox
                      label={translate('Não perguntar novamente')}
                      nativeID="aviso-email-cfp-nao-perguntar-novamente"
                      value={this.state.naoPerguntarNovamente}
                      onChange={value =>
                        this.setState({
                          naoPerguntarNovamente: value,
                          idFuncionario: funcionario.state.dados.id
                        })
                      }
                    />
                    <Space height={32} />
                    <View style={styles.botoes}>
                      <Button
                        text={translate('Agora não')}
                        onPress={() => {
                          avisoCamposImportantes.manterTelaFechada({
                            manter: true
                          });
                        }}
                        nativeID="aviso-cadastrar-email-botao-agora-nao"
                        style={styles.botao}
                        primary={false}
                      />
                      <Space width={16} />
                      <Button
                        text={translate('Cadastrar')}
                        onPress={() => {
                          let erro = '';

                          if (
                            !verificarExibirTela(
                              Telas.DadosCadastrais,
                              funcionario.state.dados.dadosPerfilFuncionario
                                .telasOcultar
                            )
                          ) {
                            erro = translate(
                              'Contate o administrador do sistema para cadastrar o seu email.'
                            );
                          }

                          this.props.onCadastrar(erro);
                        }}
                        nativeID="aviso-cadastrar-email-botao-cadastrar"
                        style={styles.botao}
                      />
                    </View>
                  </Card.Section>
                </Card>
              </>
            );
          }}
        </Subscribe>
      </ViewPrincipal>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    borderRadius: 6,
    marginHorizontal: isDesktop() || isTablet() ? undefined : 16,
    width: isDesktop() || isTablet() ? 400 : undefined
  },
  text: {
    color: theme.textColor3,
    fontSize: isTablet() ? 25 : 16,
    textAlign: 'left',
    paddingTop: 5
  },
  botoes: {
    flexDirection: 'row'
  },
  botao: {
    flex: 1
  }
});

export default AvisoCamposImportantes;
