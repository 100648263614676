import * as React from 'react';
import { translate } from 'secullum-i18n';
import Api from '../../../shared/modules/api';
import { excluirDadosLoginAsync } from '../../../shared/modules/auth';
import CardDeletarConta from '../../../shared/screens/deletar-conta/CardDeletarConta';
import LoggedInScreen from '../../components/LoggedInScreen';
import { navegar, RouteComponentComBancoIdProps } from '../../modules/routes';

interface Props extends RouteComponentComBancoIdProps<{}> {}

class DeletarConta extends React.Component<Props> {
  handleLogout = async () => {
    await excluirDadosLoginAsync(true);

    // Removi o callback porque ao executar o método limparDadosFuncionario,
    // o contexto da API não estava mais disponível e o callback não seria
    // executado corretamente.
    new Api().funcionario.limparDadosFuncionario();

    navegar({ props: this.props, path: '/' });
  };

  render() {
    return (
      <LoggedInScreen screenTitle={translate('Deletar Conta')}>
        <CardDeletarConta
          onCancelPress={() => {
            navegar({ props: this.props, path: '/configuracoes' });
          }}
          onRequestSuccess={async () => this.handleLogout()}
        />
      </LoggedInScreen>
    );
  }
}

export default DeletarConta;
