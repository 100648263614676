import * as React from "react";
import Svg, { Defs, ClipPath, Path, G } from "react-native-svg";
const LogoVertical  = (props) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 85.195831 56.885418"
    id="svg8"
    {...props}
  >
    <Defs id="defs2">
      <ClipPath clipPathUnits="userSpaceOnUse" id="clipPath26">
        <Path d="M 0,160.503 H 241.016 V 0 H 0 Z" id="path24" />
      </ClipPath>
    </Defs>
    <G id="layer1">
      <G
        id="g22"
        clipPath="url(#clipPath26)"
        transform="matrix(0.35277777,0,0,-0.35277777,0,56.621573)"
      >
        <G id="g28" transform="translate(241.0156,29.0982)">
          <Path
            d="m 0,0 v 22.672 c 0,5.702 -4.613,10.315 -10.314,10.315 -5.516,0 -10.259,-3.264 -12.413,-7.967 -1.066,4.56 -5.163,7.967 -10.042,7.967 -5.128,0 -9.796,-2.862 -12.139,-7.364 v 6.228 h -6.034 V 0 h 6.034 v 15.936 c 0,6.384 5.194,11.578 11.578,11.578 2.67,0 4.842,-2.172 4.842,-4.842 V 0 h 6.034 v 15.936 c 0,6.399 5.179,11.578 11.578,11.578 2.676,0 4.842,-2.166 4.842,-4.842 L -6.034,0 Z"
            style={{
              fill: "#75787b",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path30"
          />
        </G>
        <G id="g32" transform="translate(178.3506,60.9488)">
          <Path
            d="m 0,0 v -15.367 c 0,-6.384 -5.193,-11.578 -11.577,-11.578 -2.67,0 -4.842,2.172 -4.842,4.842 V 0 h -6.035 v -22.103 c 0,-5.688 4.628,-10.315 10.315,-10.315 5.128,0 9.796,2.861 12.139,7.364 v -6.802 H 6.035 V 0 Z"
            style={{
              fill: "#75787b",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path34"
          />
        </G>
        <Path
          d="m 143.583,29.098 h 6.058 v 46.068 h -6.058 z"
          style={{
            fill: "#75787b",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          id="path36"
        />
        <Path
          d="m 129.364,29.098 h 6.058 v 46.068 h -6.058 z"
          style={{
            fill: "#75787b",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          id="path38"
        />
        <G id="g40" transform="translate(116.5049,60.9488)">
          <Path
            d="m 0,0 v -15.367 c 0,-6.384 -5.194,-11.578 -11.578,-11.578 -2.67,0 -4.842,2.172 -4.842,4.842 V 0 h -6.034 v -22.103 c 0,-5.688 4.627,-10.315 10.314,-10.315 5.129,0 9.797,2.861 12.14,7.364 v -6.802 H 6.034 V 0 Z"
            style={{
              fill: "#75787b",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path42"
          />
        </G>
        <G id="g44" transform="translate(79.3916,56.7696)">
          <Path
            d="m 0,0 c 4.16,0 7.3,-1.407 9.54,-2.818 v 5.636 c -1.922,1.024 -4.931,2.497 -9.861,2.497 -9.863,0 -16.716,-7.107 -16.716,-16.778 0,-9.668 6.853,-16.778 16.716,-16.778 4.831,0 7.927,1.442 9.861,2.463 v 5.554 c -2.005,-1.2 -5.264,-2.702 -9.54,-2.702 -6.47,0 -10.951,4.741 -10.951,11.463 C -10.951,-4.737 -6.404,0 0,0"
            style={{
              fill: "#75787b",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path46"
          />
        </G>
        <G id="g48" transform="translate(44.4229,57.0904)">
          <Path
            d="M 0,0 C -5.508,0 -9.122,-4.227 -9.634,-8.837 H 8.645 C 8.709,-4.227 5.699,0 0,0 m 14.536,-9.926 v -3.137 H -9.698 v -0.064 c 0.192,-6.276 4.703,-10.247 11.427,-10.247 4.354,0 8.901,1.281 11.59,3.01 v -5.443 c -2.497,-1.409 -6.788,-2.754 -11.974,-2.754 -10.118,0 -16.97,6.853 -16.97,16.778 0,9.67 6.852,16.778 15.688,16.778 9.415,0 14.473,-7.044 14.473,-14.921"
            style={{
              fill: "#75787b",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path50"
          />
        </G>
        <G id="g52" transform="translate(11.3916,43.0015)">
          <Path
            d="m 0,0 c -6.714,1.857 -11.392,4.291 -11.392,10.054 0,5.315 5.659,9.029 13.505,9.029 4.074,0 8.073,-0.896 10.638,-1.921 V 11.783 C 10.035,13 6.263,14.024 2.867,14.024 c -4.3,0 -7.979,-1.281 -7.979,-4.162 0,-2.562 2.924,-3.779 8.055,-5.187 7.997,-2.241 11.92,-4.739 11.92,-10.118 0,-5.828 -5.583,-9.03 -13.73,-9.03 -4.904,0 -9.28,0.897 -12.298,2.114 v 5.443 c 3.696,-1.537 8.525,-2.626 12.071,-2.626 3.923,0 7.753,1.153 7.753,4.163 C 8.659,-2.625 5.584,-1.537 0,0"
            style={{
              fill: "#75787b",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path54"
          />
        </G>
        <G id="g56" transform="translate(101.9385,91.733)">
          <Path
            d="M 0,0 9.438,9.755 9.226,-3.773 c 2.215,-0.571 4.512,-0.939 6.868,-1.089 V 25.809 L -5.678,4.037 C -3.921,2.521 -2.021,1.168 0,0 m 45.91,7.238 c 2.073,2.291 3.862,4.844 5.315,7.602 l -6.201,5.889 8.605,-0.215 c 0.947,2.889 1.549,5.936 1.754,9.09 H 23.473 Z M 20.95,-4.856 c 3.155,0.208 6.201,0.812 9.091,1.762 l -0.216,8.623 5.899,-6.208 c 2.755,1.455 5.306,3.246 7.594,5.322 L 20.879,27.082 Z M 55.385,34.46 c -0.152,2.356 -0.522,4.653 -1.094,6.868 l -13.492,-0.213 9.725,9.411 c -1.167,2.021 -2.52,3.921 -4.036,5.677 L 24.746,34.46 Z"
            style={{
              fill: "#75787b",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path58"
          />
        </G>
        <G id="g60" transform="translate(84.3525,115.5381)">
          <Path
            d="M 0,0 17.07,0.198 4.901,-11.691 c 0.677,-1.054 1.406,-2.071 2.182,-3.049 L 25.513,3.69 H -0.645 C -0.491,2.44 -0.275,1.209 0,0 M 6.839,30.912 C 6.366,30.304 5.913,29.682 5.479,29.045 L 20.014,14.762 -0.318,14.933 C -0.455,14.181 -0.569,13.42 -0.66,12.653 H 25.098 Z M 55.996,39.404 44.09,27.218 44.288,44.31 c -1.209,0.274 -2.44,0.49 -3.69,0.642 V 18.775 l 18.448,18.448 c -0.979,0.776 -1.995,1.506 -3.05,2.181 M 29.355,44.621 29.525,24.274 15.239,38.812 C 14.604,38.377 13.98,37.924 13.374,37.452 L 31.635,19.19 v 25.774 c -0.768,-0.091 -1.528,-0.206 -2.28,-0.343"
            style={{
              fill: "#53cbea",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path62"
          />
        </G>
        <G id="g64" transform="translate(104.7715,8.316)">
          <Path
            d="m 0,0 c 0,0.465 -0.049,0.903 -0.146,1.313 -0.098,0.41 -0.25,0.77 -0.458,1.081 C -0.81,2.704 -1.071,2.949 -1.387,3.129 -1.702,3.31 -2.078,3.401 -2.515,3.401 -2.784,3.401 -3.029,3.369 -3.25,3.306 -3.471,3.243 -3.671,3.154 -3.849,3.039 -4.026,2.924 -4.185,2.788 -4.322,2.63 -4.46,2.472 -4.58,2.302 -4.684,2.118 v -4.451 c 0.104,-0.184 0.224,-0.355 0.362,-0.512 0.137,-0.158 0.296,-0.293 0.473,-0.405 0.178,-0.112 0.379,-0.199 0.603,-0.262 0.224,-0.064 0.474,-0.095 0.749,-0.095 0.431,0 0.802,0.092 1.115,0.275 0.313,0.184 0.572,0.431 0.778,0.741 0.208,0.31 0.36,0.673 0.458,1.089 C -0.049,-1.086 0,-0.646 0,-0.181 Z m 1.592,-0.181 c 0,-0.683 -0.08,-1.313 -0.24,-1.889 C 1.19,-2.647 0.957,-3.146 0.649,-3.568 0.343,-3.99 -0.035,-4.319 -0.482,-4.554 -0.93,-4.789 -1.441,-4.907 -2.015,-4.907 c -0.586,0 -1.097,0.092 -1.537,0.276 -0.439,0.183 -0.816,0.45 -1.132,0.8 V -8.316 H -6.276 V 4.58 h 1.455 l 0.077,-1.033 c 0.315,0.391 0.697,0.689 1.145,0.895 0.448,0.207 0.967,0.31 1.558,0.31 0.586,0 1.104,-0.115 1.555,-0.344 0.45,-0.23 0.83,-0.553 1.14,-0.969 C 0.964,3.023 1.197,2.524 1.355,1.941 1.514,1.359 1.592,0.712 1.592,0 Z"
            style={{
              fill: "#53cbea",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path66"
          />
        </G>
        <G id="g68" transform="translate(109.7422,8.1353)">
          <Path
            d="m 0,0 c 0,-0.465 0.055,-0.904 0.164,-1.317 0.108,-0.413 0.274,-0.776 0.495,-1.089 0.221,-0.313 0.496,-0.561 0.826,-0.745 0.33,-0.183 0.716,-0.275 1.159,-0.275 0.435,0 0.82,0.092 1.153,0.275 0.333,0.184 0.608,0.432 0.826,0.745 0.219,0.313 0.384,0.676 0.495,1.089 C 5.23,-0.904 5.286,-0.465 5.286,0 V 0.189 C 5.286,0.648 5.23,1.086 5.118,1.502 5.007,1.918 4.84,2.283 4.619,2.596 4.398,2.908 4.121,3.158 3.788,3.345 3.455,3.531 3.068,3.625 2.626,3.625 2.189,3.625 1.809,3.531 1.481,3.345 1.154,3.158 0.88,2.908 0.659,2.596 0.438,2.283 0.272,1.918 0.164,1.502 0.055,1.086 0,0.648 0,0.189 Z m -1.601,0.189 c 0,0.678 0.099,1.305 0.297,1.881 0.199,0.577 0.48,1.078 0.844,1.503 0.364,0.425 0.808,0.758 1.33,0.998 0.522,0.241 1.108,0.362 1.756,0.362 0.66,0 1.253,-0.121 1.778,-0.362 C 4.929,4.331 5.375,3.998 5.742,3.573 6.109,3.148 6.391,2.647 6.586,2.07 6.781,1.494 6.879,0.867 6.879,0.189 V 0 C 6.879,-0.677 6.781,-1.304 6.586,-1.881 6.391,-2.458 6.109,-2.957 5.742,-3.379 5.375,-3.801 4.931,-4.131 4.408,-4.369 3.886,-4.607 3.298,-4.726 2.644,-4.726 c -0.655,0 -1.244,0.119 -1.765,0.357 -0.523,0.238 -0.968,0.568 -1.335,0.99 -0.367,0.422 -0.649,0.921 -0.848,1.498 -0.198,0.577 -0.297,1.204 -0.297,1.881 z"
            style={{
              fill: "#53cbea",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path70"
          />
        </G>
        <G id="g72" transform="translate(120.3008,12.8961)">
          <Path
            d="m 0,0 0.052,-1.171 c 0.333,0.419 0.735,0.748 1.206,0.986 0.47,0.238 0.998,0.357 1.584,0.357 0.453,0 0.863,-0.062 1.23,-0.185 C 4.439,-0.136 4.753,-0.334 5.011,-0.607 5.27,-0.879 5.469,-1.23 5.609,-1.657 5.75,-2.085 5.82,-2.603 5.82,-3.211 V -9.315 H 4.228 v 6.121 c 0,0.362 -0.041,0.67 -0.122,0.925 -0.08,0.256 -0.199,0.464 -0.356,0.625 -0.158,0.16 -0.354,0.278 -0.586,0.353 -0.232,0.074 -0.501,0.112 -0.805,0.112 -0.516,0 -0.969,-0.138 -1.36,-0.414 C 0.608,-1.868 0.305,-2.229 0.087,-2.677 V -9.315 H -1.506 V 0 Z"
            style={{
              fill: "#53cbea",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path74"
          />
        </G>
        <G id="g76" transform="translate(130.8604,15.1519)">
          <Path
            d="M 0,0 V -2.256 H 1.738 V -3.487 H 0 v -5.776 c 0,-0.241 0.028,-0.435 0.086,-0.582 0.057,-0.146 0.134,-0.259 0.227,-0.34 0.095,-0.08 0.205,-0.133 0.328,-0.159 0.124,-0.026 0.251,-0.039 0.383,-0.039 0.132,0 0.271,0.013 0.417,0.039 0.147,0.026 0.26,0.048 0.34,0.065 v -1.292 c -0.126,-0.034 -0.289,-0.072 -0.49,-0.112 -0.201,-0.04 -0.445,-0.06 -0.731,-0.06 -0.293,0 -0.571,0.042 -0.831,0.125 -0.262,0.083 -0.49,0.222 -0.685,0.418 -0.195,0.195 -0.351,0.451 -0.465,0.77 -0.115,0.319 -0.172,0.71 -0.172,1.175 v 5.768 h -1.696 v 1.231 h 1.696 V 0 Z"
            style={{
              fill: "#53cbea",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path78"
          />
        </G>
        <G id="g80" transform="translate(135.6416,8.1353)">
          <Path
            d="m 0,0 c 0,-0.465 0.055,-0.904 0.164,-1.317 0.108,-0.413 0.274,-0.776 0.495,-1.089 0.221,-0.313 0.496,-0.561 0.826,-0.745 0.33,-0.183 0.716,-0.275 1.159,-0.275 0.435,0 0.82,0.092 1.153,0.275 0.333,0.184 0.608,0.432 0.826,0.745 0.219,0.313 0.384,0.676 0.495,1.089 C 5.23,-0.904 5.286,-0.465 5.286,0 V 0.189 C 5.286,0.648 5.23,1.086 5.118,1.502 5.007,1.918 4.84,2.283 4.619,2.596 4.398,2.908 4.121,3.158 3.788,3.345 3.455,3.531 3.068,3.625 2.626,3.625 2.189,3.625 1.809,3.531 1.481,3.345 1.154,3.158 0.88,2.908 0.659,2.596 0.438,2.283 0.272,1.918 0.164,1.502 0.055,1.086 0,0.648 0,0.189 Z m -1.601,0.189 c 0,0.678 0.099,1.305 0.297,1.881 0.199,0.577 0.48,1.078 0.844,1.503 0.364,0.425 0.808,0.758 1.33,0.998 0.522,0.241 1.108,0.362 1.756,0.362 0.66,0 1.253,-0.121 1.778,-0.362 C 4.929,4.331 5.375,3.998 5.742,3.573 6.109,3.148 6.391,2.647 6.586,2.07 6.781,1.494 6.879,0.867 6.879,0.189 V 0 C 6.879,-0.677 6.781,-1.304 6.586,-1.881 6.391,-2.458 6.109,-2.957 5.742,-3.379 5.375,-3.801 4.931,-4.131 4.408,-4.369 3.886,-4.607 3.298,-4.726 2.644,-4.726 c -0.655,0 -1.244,0.119 -1.765,0.357 -0.523,0.238 -0.968,0.568 -1.335,0.99 -0.367,0.422 -0.649,0.921 -0.848,1.498 -0.198,0.577 -0.297,1.204 -0.297,1.881 z"
            style={{
              fill: "#53cbea",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path82"
          />
        </G>
      </G>
    </G>
  </Svg>
);
export default LogoVertical;