import { translate } from 'secullum-i18n';
import { Container } from 'unstated';
import { DadosFuncionario } from '../modules/types';

export interface AvisoCamposImportantesState {
  telaFechada: boolean;
}

export interface ManterTelaFechadaParams {
  manter: boolean;
}

export class AvisoCamposImportantesContainer extends Container<AvisoCamposImportantesState> {
  state: AvisoCamposImportantesState = {
    telaFechada: false
  };

  listarCamposImportantes = (dadosFuncionario: DadosFuncionario) => {
    const camposImportantes: {
      [key: string]: { tipo: string; descricao: string };
    } = {
      email: {
        tipo: translate('Email'),
        descricao: translate(
          'O Email poderá ser utilizado para redefinição de sua senha de acesso, comprovantes de registro de ponto, entre outros.'
        )
      }
    };

    if (!dadosFuncionario.usaOutraLegislacao) {
      camposImportantes.cpf = {
        tipo: translate('CPF'),
        descricao: translate(
          'O CPF é uma exigência do Ministério do Trabalho de acordo com a Portaria 671 para geração de arquivos fiscais.'
        )
      };
    }

    return Object.keys(camposImportantes)
      .filter(key => !dadosFuncionario[key as keyof DadosFuncionario])
      .map(key => camposImportantes[key]);
  };

  deveAbrirTela = (dadosFuncionario: DadosFuncionario) => {
    if (this.state.telaFechada) {
      return false;
    }

    if (dadosFuncionario.possuiSolicitacaoAtualizacaoDadosCadastraisPendente) {
      return false;
    }

    if (this.listarCamposImportantes(dadosFuncionario).length === 0) {
      return false;
    }

    return true;
  };

  manterTelaFechada = ({ manter }: ManterTelaFechadaParams) => {
    this.setState({ telaFechada: manter });
  };
}

export default new AvisoCamposImportantesContainer();
