import AsyncStorage from '@react-native-async-storage/async-storage';

const CHAVE_CONFIGURACAO_CAMPOS_IMPORTANTES =
  'NaoPerguntarNovamenteCamposImportantes';

export const salvarNaoPerguntarNovamenteCamposImportantesAsync = async (
  idFuncionario: number
) => {
  await AsyncStorage.setItem(
    montarChaveConfiguracaoCamposImportantes(idFuncionario),
    'True'
  );
};

export const getNaoPerguntarNovamenteCamposImportantesAsync = async (
  idFuncionario: number
): Promise<boolean> => {
  const naoPerguntarNovamenteCamposImportantes = await AsyncStorage.getItem(
    CHAVE_CONFIGURACAO_CAMPOS_IMPORTANTES
  );

  // Caso o usuário já tenha marcado a opção de 'Não perguntar novamente' nas versões anteriores do sistema
  // então excluímos essa chave e criamos a chave contendo o id do funcionário
  if (naoPerguntarNovamenteCamposImportantes === 'True') {
    await AsyncStorage.removeItem(CHAVE_CONFIGURACAO_CAMPOS_IMPORTANTES);
    await salvarNaoPerguntarNovamenteCamposImportantesAsync(idFuncionario);

    return true;
  }

  const naoPerguntarNovamenteCamposImportantesPorIdFuncionario =
    await AsyncStorage.getItem(
      montarChaveConfiguracaoCamposImportantes(idFuncionario)
    );

  return naoPerguntarNovamenteCamposImportantesPorIdFuncionario === 'True';
};

export const montarChaveConfiguracaoCamposImportantes = (
  idFuncionario: number
) => {
  return CHAVE_CONFIGURACAO_CAMPOS_IMPORTANTES + ':' + idFuncionario;
};
