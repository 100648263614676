import React from 'react';
import { useEffect, useState } from 'react';
import { StyleSheet, View, Text, Pressable, Linking } from 'react-native';
import { translate } from 'secullum-i18n';
import { getTheme, Loading } from 'secullum-react-native-ui';
import { FileViewerProps } from './FileViewer';

const FileViewer = (props: FileViewerProps) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(undefined);
  const [contentType, setContentType] = useState<string>('');
  const [fileExcluded, setFileExcluded] = useState<boolean>(false);

  useEffect(() => {
    function fetchData() {
      loadContent();
    }

    fetchData();
  }, []);

  const loadContent = () => {
    fetch(props.src)
      .then(response =>
        response.blob().then(raw => ({
          raw,
          type: response.headers.get('Content-Type')
        }))
      )
      .then(data => {
        setFileExcluded(data.type === null);
        setContentType(data.type ? data.type : '');
        setData(data.type ? URL.createObjectURL(data.raw) : null);
      })
      .finally(() => setLoading(false));
  };

  const renderNoPreview = (message: string) => {
    return (
      <View style={styles.noPreview}>
        <Text style={styles.noPreviewText}>{translate(message)}</Text>
      </View>
    );
  };

  return (
    <View>
      <View style={{ height: props.height, width: '100%' }}>
        {loading ? (
          <View style={styles.loading}>
            <Loading height={props.height} width={'100%'} />
          </View>
        ) : null}
        {data ? (
          <object
            data={data}
            type={contentType}
            style={{
              width: '100%',
              height: props.height,
              objectFit: 'contain'
            }}
          >
            {renderNoPreview('Visualização Indisponível')}
          </object>
        ) : null}
        {fileExcluded ? renderNoPreview('Arquivo Excluído') : null}
      </View>
      <View style={styles.linkContainer}>
        <Pressable
          disabled={fileExcluded || loading}
          onPress={() => Linking.openURL(props.src)}
          style={({ pressed }) => [{ opacity: pressed ? 0.7 : 1 }]}
        >
          <Text
            style={[
              styles.link,
              fileExcluded || loading ? styles.linkDisabled : styles.linkEnabled
            ]}
          >
            {translate('Download do Arquivo')}
          </Text>
        </Pressable>
      </View>
    </View>
  );
};

const theme = getTheme();

const styles = StyleSheet.create({
  noPreview: {
    flex: 1,
    width: '100%',
    height: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ccc',
    alignItems: 'center',
    justifyContent: 'center'
  },
  noPreviewText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'rgb(85, 85, 85)'
  },
  linkContainer: {
    borderTopWidth: 3,
    borderTopColor: theme.disabledColor
  },
  linkEnabled: {
    color: theme.textColor2
  },
  linkDisabled: {
    color: '#d0d0d0'
  },
  loading: {
    backgroundColor: '#d0d0d0'
  },
  link: {
    padding: 8,
    textAlign: 'left',
    color: theme.textColor2
  }
});

export default FileViewer;
