import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  ErrorMessage,
  Card,
  RangeDatePicker,
  Space,
  ImageButton
} from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import { ButtonBar } from '../../../shared/components/ButtonBar';
import { BatidasContainer } from '../../../shared/containers/BatidasContainer';
import { FuncionarioContainer } from '../../../shared/containers/FuncionarioContainer';
import withContainer from '../../../shared/containers/withContainer';
import {
  COR_BOTAO_CINZA,
  getTheme,
  isDesktop
} from '../../../shared/modules/layout';
import {
  converterDataParaTimezoneBrasil,
  getDataInicioDiasAtras
} from '../../../shared/modules/utils';
import CartaoPontoCompleto from '../../../shared/screens/cartao-ponto/CartaoPontoCompleto';
import { TipoDeTela } from '../../../shared/screens/cartao-ponto/DetalheDiaBatida';
import LoggedInScreen from '../../components/LoggedInScreen';
import CartaoPontoResumido from './CartaoPontoResumido';
import ModalImprimir from './ModalImprimir';

type Visualizacao = 'resumido' | 'completo';

interface Props {
  container: BatidasContainer;
}

interface State {
  visualizacao: Visualizacao;
  filtros: {
    dataInicio: Date;
    dataFinal: Date;
  };
  erroGeral: string;
  chaveTabela: number;
  exibirModalImprimir: boolean;
}

class CartaoPonto extends React.Component<Props, State> {
  state: State = {
    visualizacao: 'resumido',
    filtros: {
      dataInicio: converterDataParaTimezoneBrasil(getDataInicioDiasAtras(14)),
      dataFinal: converterDataParaTimezoneBrasil(new Date())
    },
    erroGeral: '',
    chaveTabela: 0,
    exibirModalImprimir: false
  };

  componentDidMount() {
    this.handleModeChange('resumido');
  }

  handleDateChange = (value: Date, name: keyof State['filtros']) => {
    const novosFiltros = {
      ...this.state.filtros,
      [name]: value
    };

    if (name === 'dataInicio') {
      novosFiltros.dataFinal = value;
    }

    this.setState(
      {
        filtros: novosFiltros,
        chaveTabela: this.state.chaveTabela + 1,
        erroGeral: ''
      },
      async () => {
        if (name === 'dataFinal') {
          if (this.state.filtros.dataInicio > this.state.filtros.dataFinal) {
            this.setState({
              erroGeral: translate('Selecione o período corretamente')
            });

            return;
          }

          await this.handleModeChange(this.state.visualizacao);
        }
      }
    );
  };

  handleExibirModalImprimir = () => {
    this.setState({ exibirModalImprimir: true });
  };

  handleModeChange = async (mode: Visualizacao) => {
    if (this.state.erroGeral.length > 0) {
      return;
    }

    await this.props.container.loadCartaoPontoAsync(this.state.filtros);

    this.setState({
      visualizacao: mode,
      chaveTabela: this.state.chaveTabela + 1
    });
  };

  render() {
    const { visualizacao, filtros, erroGeral, exibirModalImprimir } =
      this.state;
    const { container } = this.props;
    const listaDiaResumido = container.listaOrdenada();

    return (
      <LoggedInScreen screenTitle={translate('Cartão Ponto')}>
        <Card>
          {!isDesktop() && visualizacao === 'completo' ? (
            <View style={styles.headerCartaoPonto}>
              <Card.Header title={translate('Filtrar')} />
              <ImageButton
                nativeID="cartao-ponto-completo-imprimir-mobile-view"
                icon="print"
                style={styles.printButtonMobile}
                onPress={this.handleExibirModalImprimir}
              />
            </View>
          ) : (
            <Card.Header title={translate('Filtrar')} />
          )}
          <Card.Section style={styles.section}>
            <RangeDatePicker
              nativeID="cartao-ponto-data"
              style={styles.datePicker}
              label={translate('Insira o Período')}
              startDate={filtros.dataInicio}
              endDate={filtros.dataFinal}
              onStartDateChange={date =>
                this.handleDateChange(date, 'dataInicio')
              }
              onEndDateChange={date => this.handleDateChange(date, 'dataFinal')}
            />
            <ErrorMessage
              nativeID="cartao-ponto-erro-geral"
              message={erroGeral}
            />
            <ButtonBar
              buttonBarStyle={styles.buttons}
              leftButton={{
                nativeID: 'cartao-ponto-resumido',
                text: translate('Resumido'),
                onPress: () => this.handleModeChange('resumido'),
                textStyle: styles.textButton,
                style: [
                  styles.button,
                  {
                    backgroundColor:
                      visualizacao === 'resumido'
                        ? COR_BOTAO_CINZA
                        : theme.backgroundColor3
                  }
                ]
              }}
              rightButton={{
                nativeID: 'cartao-ponto-completo',
                text: translate('Completo'),
                onPress: () => this.handleModeChange('completo'),
                textStyle: styles.textButton,
                style: [
                  styles.button,
                  {
                    backgroundColor:
                      visualizacao === 'completo'
                        ? COR_BOTAO_CINZA
                        : theme.backgroundColor3
                  }
                ]
              }}
            />
            {visualizacao === 'completo' && isDesktop() && (
              <>
                <Space width={16} />
                <ImageButton
                  nativeID="cartao-ponto-completo-imprimir"
                  icon="print"
                  style={styles.printButton}
                  onPress={this.handleExibirModalImprimir}
                />
              </>
            )}
          </Card.Section>
        </Card>
        {visualizacao === 'resumido' ? (
          <CartaoPontoResumido
            tipoTela={TipoDeTela.CartaoPonto}
            listaOrdenada={listaDiaResumido}
            key={this.state.chaveTabela}
          />
        ) : (
          <CartaoPontoCompleto
            batidas={listaDiaResumido}
            totais={container.state.dados.totais}
          />
        )}
        {exibirModalImprimir && (
          <Subscribe to={[FuncionarioContainer]}>
            {(funcionario: FuncionarioContainer) => (
              <ModalImprimir
                funcionarioId={
                  container.state.dados.opcoesCartaoPonto.funcionarioId
                }
                filtros={filtros}
                dados={container.state.dados}
                configuracoesEspeciaisAcesso={
                  funcionario.state.dados.configuracoesEspeciaisAcesso
                }
                onRequestClose={opcoes => {
                  container.atualizarOpcoesCartaoPonto(opcoes);
                  this.setState({ exibirModalImprimir: false });
                }}
                tipoPlano={funcionario.state.dados.plano}
              />
            )}
          </Subscribe>
        )}
      </LoggedInScreen>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  buttons: {
    flex: 1,
    height: 56,
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 350,
    minWidth: 200,
    marginLeft: 16
  },
  button: {
    height: 42,
    maxWidth: 138
  },
  datePicker: {
    flex: 1,
    minWidth: 250,
    maxWidth: 350,
    marginLeft: 16
  },
  section: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: 0
  },
  textButton: {
    fontSize: 14
  },
  printButton: {
    marginVertical: 'auto',
    marginLeft: 'auto',
    padding: 5
  },
  printButtonMobile: {
    marginVertical: 'auto',
    marginLeft: 'auto',
    marginRight: 5,
    padding: 5
  },
  headerCartaoPonto: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

export default withContainer(BatidasContainer)(CartaoPonto);
