import * as React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'unstated';
import AvisoCamposImportantes from '../../shared/containers/AvisoCamposImportantesContainer';
import AvisoTermosLgpd from '../../shared/containers/AvisoDadosSensiveisLgpdContainer';
import Funcionario from '../../shared/containers/FuncionarioContainer';
import Notificacoes from '../../shared/containers/NotificacoesContainer';
import Processando from '../../shared/containers/ProcessandoContainer';
import { criarPathComBancoId } from '../modules/routes';
import App from './App';

function Root() {
  return (
    <Provider
      inject={[
        Funcionario,
        Processando,
        Notificacoes,
        AvisoCamposImportantes,
        AvisoTermosLgpd
      ]}
    >
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        <Route path={criarPathComBancoId('/')} component={App} />
      </BrowserRouter>
    </Provider>
  );
}

export default Root;
