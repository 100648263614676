import { Container } from 'unstated';
import Api from '../modules/api';

export interface AvisoDadosSensiveisLgpdState {
  telaFechada: boolean;
  textoTermo: string;
}

export class AvisoDadosSensiveisLgpdContainer extends Container<AvisoDadosSensiveisLgpdState> {
  state: AvisoDadosSensiveisLgpdState = {
    telaFechada: false,
    textoTermo: ''
  };

  carregarTextoTermosDadosSensiveis = () => {
    const api = new Api();

    if (this.state.textoTermo != '') {
      return;
    }

    api.getTermoDadosSensiveis({
      onSuccess: textoTermo => {
        this.setState({
          textoTermo
        });
      }
    });
  };

  abrirTelaDadosSensiveis = (aceitouTermosLgpd: boolean) => {
    if (aceitouTermosLgpd) {
      return false;
    }

    if (this.state.telaFechada) {
      return false;
    }

    return true;
  };

  manterTelaFechada = (manter: boolean) => {
    this.setState({ telaFechada: manter });
  };
}

export default new AvisoDadosSensiveisLgpdContainer();
