import * as base64 from 'base-64';
import { Linking } from 'react-native';
import { getUrlApi } from './api';
import { carregarDadosLoginAsync } from './auth';

export async function gerarComprovante(batidaId: number, batidaNome: string) {
  await solicitarComprovante(`batidaId=${batidaId}&nome=${batidaNome}`);
}

export async function gerarComprovantePorRegistroPendencia(id: number) {
  await solicitarComprovante(`registroPendenciaId=${id}`);
}

async function solicitarComprovante(query: string) {
  const dadosLogin = await carregarDadosLoginAsync();

  if (dadosLogin == null) {
    return;
  }

  const user = base64.encode(
    dadosLogin.funcionarioNumero +
      ':' +
      dadosLogin.funcionarioSenha +
      ':' +
      dadosLogin.tipoUsuarioAutenticacao
  );

  const urlApiBase = getUrlApi();

  const url = `${urlApiBase}${dadosLogin.bancoId}/Batidas/Comprovante?axpw=${user}&${query}`;

  Linking.openURL(url);
}
