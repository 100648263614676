import * as React from 'react';
import { StyleSheet, Platform, View } from 'react-native';
import { translate } from 'secullum-i18n';
import { isTablet, Card, CheckBox, Text } from 'secullum-react-native-ui';
import { getTheme, isHighResolution } from '../modules/layout';

interface Props {
  checked?: boolean;
  showChecked?: boolean;
  onChecked?: (value: boolean) => void;
}

class SolicitacaoHeader extends React.Component<Props> {
  render() {
    const { onChecked, checked, showChecked } = this.props;

    const fontSize = Platform.OS == 'web' ? 14 : isTablet() ? 15 : 12;

    const webDesktop = Platform.OS === 'web' && isHighResolution();
    let larguraCampoData: number;

    if (Platform.OS === 'web') {
      larguraCampoData = isHighResolution() ? 200 : 70;
    } else if (isTablet()) {
      larguraCampoData = 75;
    } else {
      larguraCampoData = onChecked ? 45 : 65;
    }

    return (
      <Card.Section style={styles.headerContainer}>
        {onChecked ? (
          showChecked ? (
            <CheckBox
              nativeID="solicitacao-checkbox-todos"
              value={checked!}
              onChange={onChecked}
            />
          ) : (
            <View style={styles.placeholderCheckbox} />
          )
        ) : null}

        <Text
          bold
          flex={webDesktop ? 1 : undefined}
          size={fontSize}
          color={theme.textColor3}
          style={[styles.headerText, { width: larguraCampoData }]}
        >
          {translate('Data')}
        </Text>
        {onChecked && (
          <Text
            bold
            flex={2}
            size={fontSize}
            color={theme.textColor3}
            style={styles.headerText}
          >
            {translate('Funcionário')}
          </Text>
        )}
        <Text
          bold
          flex={1}
          size={fontSize}
          color={theme.textColor3}
          style={styles.headerText}
        >
          {translate('Tipo')}
        </Text>
        {!onChecked && (
          <Text
            bold
            flex={webDesktop ? 1 : undefined}
            size={fontSize}
            align="center"
            color={theme.textColor3}
            style={[
              styles.headerText,
              { width: Platform.OS == 'web' ? 65 : isTablet() ? 70 : 60 }
            ]}
          >
            {translate('Motivo')}
          </Text>
        )}
        <Text
          bold
          flex={webDesktop ? 1 : undefined}
          size={fontSize}
          color={theme.textColor3}
          align="center"
          style={[
            styles.headerText,
            { width: Platform.OS == 'web' || isTablet() ? 50 : 40 }
          ]}
        >
          {translate('Status')}
        </Text>
      </Card.Section>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    paddingVertical: 6
  },
  headerText: {
    paddingRight: 5
  },
  placeholderCheckbox: {
    width: 24
  }
});

export default SolicitacaoHeader;
