import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Details,
  DetailsHeader,
  DetailsLine,
  Message,
  Space,
  Text,
  formatDate,
  isTablet
} from 'secullum-react-native-ui';
import {
  getCorStatus,
  getIconeStatus,
  getPeriodoFormatado
} from '../../shared/containers/AssinaturaDigitalCartaoPontoContainer';
import { getTheme } from '../../shared/modules/layout';
import {
  TipoAlteracaoAssinaturaDigitalCartaoPonto,
  AssinaturaDigitalCartaoPontoDados,
  AssinaturaDigitalCartaoPontoStatus
} from '../../shared/modules/types';
import AssinaturaDigitalCartaoPontoProcessamento from './AssinaturaDigitalCartaoPontoProcessamento';
import { ButtonBar } from './ButtonBar';
import VisualizadorPdf from './VisualizadorPdf';

interface Props {
  assinaturaDigitalCartaoPonto: AssinaturaDigitalCartaoPontoDados;
  onHabilitarScroll?: (habilitar: boolean) => void;
  onErroConexao?: (mensagem: string, onTentarNovamente: () => void) => void;
}

interface State {
  assinaturaDigitalCartaoPontoAtual: AssinaturaDigitalCartaoPontoDados | null;
  tipoAcao: TipoAlteracaoAssinaturaDigitalCartaoPonto | null;
  erros: string | null;
}

const DATE_FORMAT = 'dd/MM/yyyy HH:mm:ss';

class AssinaturaDigitalCartaoPontoDetalhe extends React.Component<
  Props,
  State
> {
  state: State = {
    assinaturaDigitalCartaoPontoAtual: null,
    tipoAcao: null,
    erros: null
  };

  visualizadorPdf: VisualizadorPdf | null = null;

  async componentDidMount() {
    const { assinaturaDigitalCartaoPonto } = this.props;

    this.setState({
      assinaturaDigitalCartaoPontoAtual: assinaturaDigitalCartaoPonto
    });
  }

  finalizarProcessamento = () => {
    this.setState({ tipoAcao: null });
  };

  concluirProcessamentoComErro = (erros: string) => {
    this.setState({
      tipoAcao: null,
      erros
    });
  };

  renderBotoes = () => {
    return (
      <View style={styles.container}>
        <ButtonBar
          leftButton={{
            text: translate('Aprovar'),
            onPress: () =>
              this.setState({
                tipoAcao: TipoAlteracaoAssinaturaDigitalCartaoPonto.Aprovar
              }),
            style: { backgroundColor: theme.successColor },
            nativeID: 'botao-aprovar-assinatura-digital-cartao-ponto-detalhes'
          }}
          rightButton={{
            text: translate('Reprovar'),
            onPress: () =>
              this.setState({
                tipoAcao: TipoAlteracaoAssinaturaDigitalCartaoPonto.Rejeitar
              }),
            style: { backgroundColor: theme.errorColor },
            nativeID: 'botao-reprovar-assinatura-digital-cartao-ponto-detalhes'
          }}
        />
      </View>
    );
  };

  renderMotivoRejeicao = (titulo: string, observacao: string) => {
    return (
      <View style={styles.container}>
        <Text style={styles.textObservacao}>{titulo}</Text>
        <Text style={styles.textObservacao}>{observacao}</Text>
      </View>
    );
  };

  getTextoStatus = () => {
    const { assinaturaDigitalCartaoPontoAtual } = this.state;

    if (!assinaturaDigitalCartaoPontoAtual) {
      return;
    }

    switch (assinaturaDigitalCartaoPontoAtual.estado) {
      case AssinaturaDigitalCartaoPontoStatus.Aprovado:
        return translate('Aceito');
      case AssinaturaDigitalCartaoPontoStatus.Rejeitado:
        return translate('Rejeitado');
      default:
        return translate('Aguardando Retorno');
    }
  };

  montarLinhasInformacoes = () => {
    const { assinaturaDigitalCartaoPontoAtual } = this.state;

    if (!assinaturaDigitalCartaoPontoAtual) {
      return [];
    }

    const linhasInformacoes: Array<DetailsLine> = [
      {
        title: translate('Número'),
        value:
          assinaturaDigitalCartaoPontoAtual.assinaturaDigitalCartaoPontoId.toString()
      },
      {
        title: translate('Criado em'),
        value: formatDate(
          assinaturaDigitalCartaoPontoAtual.dataInclusao,
          DATE_FORMAT
        )
      },
      {
        title: translate('Status'),
        value: this.getTextoStatus(),
        icons: [
          {
            name: getIconeStatus(assinaturaDigitalCartaoPontoAtual.estado),
            color: getCorStatus(assinaturaDigitalCartaoPontoAtual.estado),
            size: 18
          }
        ]
      }
    ];

    if (assinaturaDigitalCartaoPontoAtual.dataResposta) {
      linhasInformacoes.push({
        title: translate('Respondido em'),
        value: formatDate(
          assinaturaDigitalCartaoPontoAtual.dataResposta,
          DATE_FORMAT
        )
      });
    }

    if (
      assinaturaDigitalCartaoPontoAtual.motivo &&
      assinaturaDigitalCartaoPontoAtual.motivo.length !== 0
    ) {
      linhasInformacoes.push({
        render: () =>
          this.renderMotivoRejeicao(
            translate('Motivo'),
            assinaturaDigitalCartaoPontoAtual.motivo
          )
      });
    }

    if (
      assinaturaDigitalCartaoPontoAtual.estado ===
      AssinaturaDigitalCartaoPontoStatus.NaoRespondido
    ) {
      linhasInformacoes.push({
        render: () => this.renderBotoes()
      });
    }

    return linhasInformacoes;
  };

  render() {
    const { assinaturaDigitalCartaoPonto } = this.props;
    const { erros, assinaturaDigitalCartaoPontoAtual, tipoAcao } = this.state;

    return (
      <>
        <DetailsHeader
          text={`${
            assinaturaDigitalCartaoPonto.descricao
          }\n${getPeriodoFormatado(
            assinaturaDigitalCartaoPonto,
            'dd/MM/yyyy'
          )}`}
        />
        <Space />
        <Details
          title={translate('Informações')}
          lines={this.montarLinhasInformacoes()}
        />
        <Space />
        {assinaturaDigitalCartaoPontoAtual && (
          <VisualizadorPdf
            ref={ref => (this.visualizadorPdf = ref)}
            assinaturaDigitalCartaoPonto={assinaturaDigitalCartaoPontoAtual!}
            estado={tipoAcao}
            onHabilitarScroll={this.props.onHabilitarScroll}
            onError={error => this.setState({ erros: error })}
          />
        )}
        {tipoAcao != null && assinaturaDigitalCartaoPontoAtual && (
          <AssinaturaDigitalCartaoPontoProcessamento
            assinaturaDigitalCartaoPontoAtual={
              assinaturaDigitalCartaoPontoAtual
            }
            tipoAcao={tipoAcao}
            concluirEnvioSucesso={assinaturaDigitalCartaoPonto => {
              this.setState({
                tipoAcao: null,
                assinaturaDigitalCartaoPontoAtual: assinaturaDigitalCartaoPonto
              });

              if (this.visualizadorPdf) {
                this.visualizadorPdf.carregarPdfAsync(true);
              }
            }}
            concluirEnvioErro={erros =>
              this.concluirProcessamentoComErro(erros)
            }
            finalizarProcessamento={this.finalizarProcessamento}
          />
        )}
        <Message
          nativeID="processar-assinatura-digital-cartao-ponto-erro"
          type="warning"
          visible={erros !== null}
          message={erros || ''}
          onRequestClose={() => this.setState({ erros: null })}
        />
      </>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 10
  },
  textObservacao: {
    fontSize: isTablet() ? 18 : 14,
    color: theme.textColor3
  }
});

export default AssinaturaDigitalCartaoPontoDetalhe;
