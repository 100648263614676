import * as React from 'react';
import { StyleSheet, Platform } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Card,
  Text,
  Space,
  CheckBox,
  TextBox,
  ErrorMessage,
  Message
} from 'secullum-react-native-ui';
import { getTheme } from '../../../shared/modules/layout';
import { ButtonBar } from '../../components/ButtonBar';
import Api from '../../modules/api';

interface Props {
  onCancelPress(): void;
  onErroConexao?: (mensagem: string, onTentarNovamente: () => void) => void;
  onRequestSuccess(): void;
}

interface State {
  termoAceite: boolean;
  senhaUsuario: string;
  erroGeral: string;
  erros: { [key: string]: string };
  resultado: 'sucesso' | 'erro' | null;
}

class CardDeletarConta extends React.Component<Props, State> {
  state: State = {
    termoAceite: false,
    senhaUsuario: '',
    erroGeral: '',
    erros: {},
    resultado: null
  };

  handleSucesso() {
    this.setState({ resultado: null }, () => {
      this.props.onRequestSuccess();
    });
  }

  handleEnviar() {
    const api = new Api();
    const senha = this.state.senhaUsuario;

    if (senha == '') {
      this.setState({
        erros: { senhaUsuario: 'Informe a senha corretamente.' }
      });

      return;
    }

    api.postDeletarConta(senha, {
      onSuccess: async () => {
        this.setState({
          resultado: 'sucesso'
        });
      },
      onError: err => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () => this.handleEnviar());
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      },
      onValidationError: (errorList, errorObj) => {
        this.setState({
          erros: errorObj
        });
      }
    });
  }

  render() {
    const { termoAceite, senhaUsuario, resultado, erros, erroGeral } =
      this.state;

    return (
      <Card>
        <Card.Section>
          <Text
            nativeID="texto-deletar"
            color={theme.textColor1}
            size={14}
            bold
          >
            {translate('A sua conta será deletada imediatamente.')}
          </Text>
          <Space />
          <Text
            nativeID="texto-deletar"
            color={theme.textColor1}
            size={14}
            bold
          >
            {translate(
              'No entanto, algumas informações estão sob controle da empresa, devido a questões legais e contratuais.'
            )}
          </Text>
          <Space />
          <CheckBox
            nativeID="termo-deletar"
            label={translate('Li e estou ciente que minha conta será deletada')}
            value={termoAceite}
            onChange={value => this.setState({ termoAceite: value })}
            labelStyle={styles.termo}
          />
          <Space />
          <TextBox
            nativeID="senha-usuario"
            label={translate('Senha')}
            value={senhaUsuario}
            secureTextEntry={true}
            maxLength={20}
            editable={termoAceite}
            onChange={value =>
              this.setState({
                senhaUsuario: value,
                erros: {
                  ...this.state.erros,
                  ['senhaUsuario']: ''
                }
              })
            }
          />
          <ErrorMessage
            nativeID="senhaUsuario-erro"
            message={erros.senhaUsuario}
          />
          <Space />
          <ButtonBar
            buttonBarStyle={Platform.OS == 'web' && styles.buttons}
            leftButton={{
              nativeID: 'deletar-conta-cancelar',
              text: translate('Cancelar'),
              primary: false,
              onPress: () => this.props.onCancelPress()
            }}
            rightButton={{
              nativeID: 'deletar-conta-excluir',
              text: translate('Excluir'),
              disabled: !termoAceite,
              onPress: () => this.handleEnviar()
            }}
          />
          <Message
            nativeID="deletar-conta-sucesso"
            message={translate('Conta deletada com sucesso')}
            visible={resultado === 'sucesso'}
            onRequestClose={() => this.handleSucesso()}
          />
          <Message
            nativeID="deletar-conta-erro"
            type="warning"
            message={erroGeral}
            visible={resultado === 'erro'}
            onRequestClose={() =>
              this.setState({ resultado: null, erroGeral: '' })
            }
          />
        </Card.Section>
      </Card>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  termo: {
    fontSize: 12
  },
  buttons: {
    flexDirection: 'row',
    margin: 'auto',
    maxWidth: 350,
    width: '100%'
  }
});

export default CardDeletarConta;
