import * as React from 'react';
import {
  LayoutChangeEvent,
  Platform,
  Pressable,
  StyleSheet,
  View
} from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { translate } from 'secullum-i18n';
import {
  Card,
  DatePicker,
  DropDown,
  ErrorMessage,
  Message,
  Space,
  TextBox,
  TimePicker
} from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import {
  converterDataServidorParaDataLocal,
  criarArrayPropriedadesBatidas,
  retornarDescricaoPropriedadeBatidas
} from '../../../shared/modules/utils';
import { ButtonBar } from '../../components/ButtonBar';
import CardAviso from '../../components/CardAviso';
import { FuncionarioContainer } from '../../containers/FuncionarioContainer';
import Api from '../../modules/api';
import { getTheme } from '../../modules/layout';
import { verificarExibirTela } from '../../modules/perfilFuncionario';
import { Telas } from '../../modules/telas';
import {
  DadosListagemFuncionariosGerente,
  NivelPermissao,
  PropriedadeBatidas,
  SolicitacaoDados,
  TipoSolicitacao,
  ConfiguracoesEspeciaisAcesso,
  DefaultList
} from '../../modules/types';
import Draggable from './Draggable';

interface State {
  resultado: 'sucesso' | 'erro' | null;
  erroGeral: string;
  dados: SolicitacaoDados;
  erros: { [key: string]: string };
  funcionariosGerente: DadosListagemFuncionariosGerente[];
  campos: Array<Campos>;
  nomeCampoAreaDrop: string;
  ativarDraggableCampo: string | null;
  listaFiltro1: Array<DefaultList>;
  listaFiltro2: Array<DefaultList>;
}

interface Props {
  date: Date;
  onSuccess(funcionarioSelecionadoId?: number): void;
  usaPonto10batidas: boolean;
  funcionarioId?: number;
  onHabilitarScroll?: (habilitar: boolean) => void;
  scrollTop: number;
  onErroConexao?: (mensagem: string, onTentarNovamente: () => void) => void;
  configuracoesEspeciaisAcesso: ConfiguracoesEspeciaisAcesso;
}

interface Campos {
  propriedadeBatida: PropriedadeBatidas;
  nomeCampo: string;
  nomeCampoErro: string;
  valor: string;
  chaveCampo: string;
  layout: {
    x: number;
    y: number;
    height: number;
  };
}

class CardAjustarPonto extends React.PureComponent<Props, State> {
  estaArrastandoCampo = false;

  state: State = {
    resultado: null,
    ativarDraggableCampo: null,
    dados: {
      data: new Date(),
      justificativaId: null,
      entrada1: '',
      saida1: '',
      entrada2: '',
      saida2: '',
      entrada3: '',
      saida3: '',
      entrada4: '',
      saida4: '',
      entrada5: '',
      saida5: '',
      tipo: TipoSolicitacao.AlteracaoDePonto,
      observacoes: '',
      foto: '',
      temFoto: false,
      filtro1Id: null,
      filtro2Id: null,
      periculosidade: '',
      registroPendente: false,
      existePeriodoEncerrado: false
    },
    erroGeral: '',
    erros: {},
    funcionariosGerente: [],
    listaFiltro1: [],
    listaFiltro2: [],
    campos: [],
    nomeCampoAreaDrop: ''
  };

  async componentDidMount() {
    this.carregarDadosAsync();
  }

  carregarDadosAsync = async () => {
    const { usaPonto10batidas } = this.props;

    this.criarCampos(usaPonto10batidas);

    await this.carregarDadosGerais();

    const { onHabilitarScroll } = this.props;

    if (onHabilitarScroll) {
      onHabilitarScroll(true);
    }
  };

  componentDidUpdate(prevProps: Props) {
    const { date } = prevProps;
    const newDate = this.props.date;

    if (
      date &&
      typeof date === Date() &&
      date.toLocaleDateString() !== newDate.toLocaleDateString()
    ) {
      this.setState(
        {
          dados: { ...this.state.dados, data: newDate }
        },
        () => this.carregarDadosAsync()
      );
    }
  }

  carregarDadosGerais = async () => {
    const { date, funcionarioId } = this.props;

    // Se informar o id de outro funcionário, é porque está acessando como gerente
    // Nesse caso deve carregar a lista dos funcionários que o gerente tem acesso
    if (funcionarioId) {
      const api = new Api();

      await api.getListaFuncionariosGerente({
        onSuccess: funcionariosGerente => {
          this.setState({
            funcionariosGerente
          });
        },
        onError: err => {
          if (err.name === 'TimeoutError' && this.props.onErroConexao) {
            this.props.onErroConexao(err.message, () =>
              this.carregarDadosGerais()
            );
          } else {
            this.setState({
              resultado: 'erro',
              erroGeral: err.message
            });
          }
        }
      });
    }

    await this.carregarDadosSolicitacao(new Date(date), funcionarioId, true);
    await this.carregarListaFiltros();
  };

  carregarListaFiltros = async () => {
    const { configuracoesEspeciaisAcesso } = this.props;
    let listaFiltro1: Array<DefaultList> = [];
    let listaFiltro2: Array<DefaultList> = [];
    let erroListaFiltro: string = '';

    if (
      configuracoesEspeciaisAcesso &&
      configuracoesEspeciaisAcesso.tecpontoIntero
    ) {
      const api = new Api();

      await api.getListaFiltro1({
        onSuccess: resp => {
          listaFiltro1 = resp;
        },
        onError: err => {
          if (err.name === 'TimeoutError' && this.props.onErroConexao) {
            this.props.onErroConexao(err.message, () =>
              this.carregarDadosGerais()
            );
          } else {
            erroListaFiltro +=
              translate('Erro ao carregar a lista de centro de custos.') +
              ' ' +
              err.message;
          }
        }
      });

      await api.getListaFiltro2({
        onSuccess: resp => {
          listaFiltro2 = resp;
        },
        onError: err => {
          if (err.name === 'TimeoutError' && this.props.onErroConexao) {
            this.props.onErroConexao(err.message, () =>
              this.carregarDadosGerais()
            );
          } else {
            erroListaFiltro +=
              translate('Erro ao carregar a lista de Serviços.') +
              ' ' +
              err.message;
          }
        }
      });

      this.setState({
        listaFiltro1,
        listaFiltro2,
        erroGeral: erroListaFiltro,
        resultado: erroListaFiltro ? 'erro' : null
      });
    }
  };

  // Este método é usado externamente e foi criado para que seja possível
  // atualizar a tela de justificativa de ausência (ao puxar a mesma para baixo no app)
  // mesmo quando a data seja alterada
  atualizarSolicitacao = () => {
    const { data, funcionarioId } = this.state.dados;

    this.carregarDadosSolicitacao(
      data,
      this.props.funcionarioId ? funcionarioId : undefined
    );
  };

  handleSucesso = () => {
    this.setState({ resultado: null });

    const funcionarioSelecionadoId = this.props.funcionarioId
      ? this.state.dados.funcionarioId
      : undefined;

    this.props.onSuccess(funcionarioSelecionadoId);
  };

  handleChange = (name: string, value: any) => {
    let novosDados = {
      ...this.state.dados,
      [name]: value
    };

    const regexCampoBatida = /(entrada|saida)(\d)/;
    const matchesCampoBatida = regexCampoBatida.exec(name);
    const { campos } = this.state;

    // Se for campos de batidas, verifica se o "parzinho" era uma justificativa
    // Caso seja, limpa ele para não ficar justificativas sozinhas
    if (matchesCampoBatida) {
      const sentidoOutraColuna =
        matchesCampoBatida[1] === 'entrada' ? 'saida' : 'entrada';

      const nomeOutraColuna = sentidoOutraColuna + matchesCampoBatida[2];
      const valorOutraColuna = (this.state.dados as any)[nomeOutraColuna];

      if (valorOutraColuna && !/^\d/.test(valorOutraColuna)) {
        novosDados = {
          ...novosDados,
          [nomeOutraColuna]: ''
        };
      }

      const indiceCampoAtualizar = campos.findIndex(
        x => x.propriedadeBatida == name
      );

      if (indiceCampoAtualizar > -1) {
        campos[indiceCampoAtualizar].valor = value;
      }
    }

    this.setState(
      {
        dados: novosDados,
        erros: {
          ...this.state.erros,
          [name]: ''
        },
        campos
      },
      () => {
        if (name === 'data' || name === 'funcionarioId') {
          const { data, funcionarioId } = this.state.dados;

          this.carregarDadosSolicitacao(
            data,
            // Só informa o id do funcionário se a tela for acessada pelo gerente (quando tem funcionarioId nas props)
            this.props.funcionarioId ? funcionarioId : undefined
          );
        }
      }
    );
  };

  handleEnviar = () => {
    const api = new Api();

    const dados = {
      ...this.state.dados,
      tipo: TipoSolicitacao.AlteracaoDePonto,
      justificativaId: null
    };

    api.postSolicitacao(dados, {
      onSuccess: () => {
        this.setState({
          resultado: 'sucesso'
        });
      },
      onError: err => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () => this.handleEnviar());
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      },
      onValidationError: (errorList, errorObj) => {
        const erroGeral = errorList.filter(erro => erro.property === null);
        const temErroGeral = erroGeral.length > 0;

        this.setState({
          resultado: temErroGeral ? 'erro' : null,
          erroGeral: temErroGeral ? erroGeral[0].message : '',
          erros: errorObj
        });
      }
    });
  };

  carregarDadosSolicitacao = async (
    data: Date,
    funcionarioId?: number,
    cargaGeral: boolean = false
  ) => {
    const api = new Api();
    const dados = { data, funcionarioId };

    await api.getSolicitacao(dados, {
      onSuccess: resp => {
        this.setState({
          dados: resp,
          campos: this.atualizarValorCampos(resp)
        });
      },
      onError: err => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(
            err.message,
            cargaGeral
              ? () => this.carregarDadosGerais()
              : () =>
                  this.carregarDadosSolicitacao(data, funcionarioId, cargaGeral)
          );
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      }
    });
  };

  atualizarValorCampos = (dados: SolicitacaoDados) => {
    const { campos } = this.state;

    return campos.map(campo => ({
      ...campo,
      valor: dados[campo.propriedadeBatida]
    }));
  };

  criarCampos = (usaPonto10Batidas: boolean) => {
    const batidas = criarArrayPropriedadesBatidas({
      ponto10Batidas: usaPonto10Batidas
    });

    const { dados } = this.state;

    const campos = batidas.map((propriedadeBatida, index) => ({
      propriedadeBatida: propriedadeBatida,
      nomeCampo: `ajustar-ponto-${propriedadeBatida}`,
      nomeCampoErro: `ajustar-ponto-erro-${propriedadeBatida}`,
      valor: dados[propriedadeBatida] || '',
      chaveCampo: `${propriedadeBatida}_view_${index}`,
      layout: {
        x: 0,
        y: 0,
        height: 0
      }
    }));

    this.setState({ campos });
  };

  buscarCampoPorPosicao = (posicao: number) => {
    const { campos } = this.state;
    const { scrollTop } = this.props;

    const campoEncontrado = campos.find(
      campo =>
        posicao + scrollTop > campo.layout.y &&
        posicao + scrollTop < campo.layout.y + campo.layout.height
    );

    return campoEncontrado;
  };

  verificarAreaDrop = (posicao: number) => {
    return this.buscarCampoPorPosicao(posicao) != null;
  };

  armazenarCoordenadasCampos = (
    event: LayoutChangeEvent,
    nomeCampo: string
  ) => {
    // Foi necessário atribuir o valor da variável Layout fora do setState
    // pois o event.nativeEvent estava ficando null e crashando a central APP
    // Assim, o valor está sendo atribuído e podemos vincular ele dentro do setState sem erros.
    const layout = {
      x: event.nativeEvent.layout.x,
      y: event.nativeEvent.layout.y,
      height: event.nativeEvent.layout.height
    };

    this.setState(prevState => {
      const campos = prevState.campos.map(campo => {
        if (campo.nomeCampo === nomeCampo) {
          return {
            ...campo,
            layout
          };
        }

        return campo;
      });

      return { campos };
    });
  };

  marcarDropppableArea = (posicao: number, nomeCampoArrastado: string) => {
    const campoSelecionado = this.buscarCampoPorPosicao(posicao);

    const nomeCampoAreaDrop =
      campoSelecionado && campoSelecionado.nomeCampo !== nomeCampoArrastado
        ? campoSelecionado.nomeCampo
        : '';

    this.setState({ nomeCampoAreaDrop });
  };

  trocarValoresCampo = (posicao: number, nomeCampoArrastado: string) => {
    const campoTrocar = this.buscarCampoPorPosicao(posicao);

    const campoArrastado = this.state.campos.find(
      campo => campo.nomeCampo == nomeCampoArrastado
    );

    if (campoTrocar && campoArrastado) {
      const valorTrocar = campoTrocar.valor;
      const valorArrastado = campoArrastado.valor;

      const campos = this.state.campos.map(campo => {
        if (campo.nomeCampo === campoTrocar.nomeCampo) {
          return {
            ...campo,
            valor: valorArrastado || ''
          };
        } else if (campo.nomeCampo === campoArrastado.nomeCampo) {
          return {
            ...campo,
            valor: valorTrocar || ''
          };
        }

        return campo;
      });

      this.setState({
        campos,
        dados: {
          ...this.state.dados,
          [campoTrocar.propriedadeBatida]: valorArrastado,
          [campoArrastado.propriedadeBatida]: valorTrocar
        }
      });
    }
  };

  handleDragging = () => {
    if (!this.estaArrastandoCampo) {
      const { onHabilitarScroll } = this.props;

      if (onHabilitarScroll) {
        onHabilitarScroll(false);
      }

      this.estaArrastandoCampo = true;
    }
  };

  handleStopDragging = () => {
    const { onHabilitarScroll } = this.props;

    if (onHabilitarScroll) {
      onHabilitarScroll(true);
    }

    this.setState({
      ativarDraggableCampo: null,
      nomeCampoAreaDrop: ''
    });

    this.estaArrastandoCampo = false;
  };

  handleAtivarDraggable = (campo: string) => {
    this.setState({ ativarDraggableCampo: campo });
  };

  normalizarBatida = (batida: string) => {
    const regex = /[^0-9:]/;

    return batida.replace(regex, '');
  };

  renderListarCampos() {
    const { dados, campos, erros, nomeCampoAreaDrop, ativarDraggableCampo } =
      this.state;

    const campoDesabilitado = (campo: Campos) => {
      return (
        <>
          <TextBox
            nativeID={campo.nomeCampo}
            label={retornarDescricaoPropriedadeBatidas(campo.propriedadeBatida)}
            value={campo.valor || ''}
            editable={false}
          />
          <Space />
        </>
      );
    };

    const campoArrastavel = (campo: Campos) => {
      let corBordaCampo = styles.timerBordaNaoSelecionada;

      if (nomeCampoAreaDrop == campo.nomeCampo) {
        corBordaCampo = styles.timerBordaAreaDrop;
      } else if (ativarDraggableCampo == campo.nomeCampo) {
        corBordaCampo = styles.timerBordaArrastada;
      }

      return (
        <>
          <Draggable
            marcarDroppableArea={this.marcarDropppableArea}
            trocarValores={this.trocarValoresCampo}
            checkDroppableArea={this.verificarAreaDrop}
            nomeCampoArrastado={campo.nomeCampo}
            onDragging={this.handleDragging}
            onStopDragging={this.handleStopDragging}
            style={corBordaCampo}
            ativarDraggable={ativarDraggableCampo === campo.nomeCampo}
          >
            <TimePicker
              style={styles.timer}
              nativeID={campo.nomeCampo}
              label={retornarDescricaoPropriedadeBatidas(
                campo.propriedadeBatida
              )}
              value={campo.valor || ''}
              onChange={value => {
                const val = this.normalizarBatida(value);

                this.handleChange(campo.propriedadeBatida, val);
                this.handleStopDragging();
              }}
              onCancel={this.handleStopDragging}
            />

            <Pressable
              onPressIn={() => this.handleAtivarDraggable(campo.nomeCampo)}
              hitSlop={{ top: 5, right: 5, bottom: 5, left: 5 }}
              accessible={Platform.OS === 'web' ? false : undefined}
            >
              <FontAwesome
                name="bars"
                style={{ marginRight: 10 }}
                color={theme.textColor2}
                size={20}
              />
            </Pressable>
          </Draggable>

          <ErrorMessage
            nativeID={campo.nomeCampoErro}
            message={erros[campo.propriedadeBatida]}
          />
          <Space />
        </>
      );
    };

    return campos.map(campo => {
      return (
        <View
          key={campo.chaveCampo}
          collapsable={false}
          onLayout={(e: LayoutChangeEvent) =>
            this.armazenarCoordenadasCampos(e, campo.nomeCampo)
          }
        >
          {Platform.OS === 'web' ? (
            <div style={{ userSelect: 'none' }}>
              {dados.existePeriodoEncerrado
                ? campoDesabilitado(campo)
                : campoArrastavel(campo)}
            </div>
          ) : dados.existePeriodoEncerrado ? (
            campoDesabilitado(campo)
          ) : (
            campoArrastavel(campo)
          )}
        </View>
      );
    });
  }

  render() {
    const {
      resultado,
      dados,
      erros,
      funcionariosGerente,
      listaFiltro1,
      listaFiltro2
    } = this.state;

    const { funcionarioId, configuracoesEspeciaisAcesso } = this.props;
    const dataAjustada = converterDataServidorParaDataLocal(dados.data);

    return (
      <>
        {dados.registroPendente && (
          <>
            <CardAviso
              nativeID="aviso-inclusoes-processamento"
              icone="hourglass-half"
              texto={`${translate(
                'Existem inclusões de ponto em processamento neste dia'
              )}.`}
            />
            <Space />
          </>
        )}
        {dados.existePeriodoEncerrado && (
          <>
            <CardAviso
              nativeID="aviso-periodo-encerrado"
              icone="exclamation-triangle"
              texto={`${translate(
                'Não é possível alterar o ponto neste dia, pois o período está encerrado'
              )}.`}
            />
            <Space />
          </>
        )}
        <Card>
          <Card.Header
            nativeID="ajustar-ponto-texto-explicativo"
            title={translate(
              'O ajuste de ponto deve ser utilizado caso você tenha tido algum problema para registrar o ponto.'
            )}
            titleStyle={styles.cardHeader}
          />
          <Card.Section>
            <View style={Platform.OS === 'web' && styles.cardSection}>
              {funcionarioId && (
                <Subscribe to={[FuncionarioContainer]}>
                  {(funcionario: FuncionarioContainer) => {
                    // Só deve listar o próprio gerente na lista de funcionários caso ele tenha permissões na tela de ajuste de ponto
                    const gerentePossuiPermissaoAjustarPonto =
                      verificarExibirTela(
                        Telas.AjustarPonto,
                        funcionario.state.dados.dadosPerfilFuncionario
                          .telasOcultar
                      );
                    const funcionariosGerenteFiltrado =
                      gerentePossuiPermissaoAjustarPonto
                        ? funcionariosGerente
                        : funcionariosGerente.filter(
                            x => x.id != funcionario.state.dados.id
                          );
                    return (
                      <>
                        <DropDown
                          nativeID="ajustar-ponto-funcionarios"
                          label={translate('Funcionário')}
                          value={dados.funcionarioId}
                          items={funcionariosGerenteFiltrado.map(
                            (x, indice) => ({
                              value: x.id,
                              label: x.nome,
                              nativeID: `funcionario-${indice + 1}`
                            })
                          )}
                          onChange={funcionarioId =>
                            this.handleChange('funcionarioId', funcionarioId)
                          }
                        />
                        <Space />
                      </>
                    );
                  }}
                </Subscribe>
              )}
              <DatePicker
                nativeID="ajustar-ponto-data"
                label={translate('Data')}
                value={dataAjustada}
                onChange={data => this.handleChange('data', data)}
                clearable={false}
              />
              <ErrorMessage
                nativeID="ajustar-ponto-erro-data"
                message={erros.data}
              />
              <Space />
              {this.renderListarCampos()}

              {configuracoesEspeciaisAcesso.tecpontoIntero && (
                <>
                  <DropDown
                    nativeID="filtro2Id"
                    label={translate('Serviços')}
                    value={dados.filtro2Id}
                    items={listaFiltro2.map(x => ({
                      value: x.id,
                      label: x.descricao
                    }))}
                    onChange={filtro2Id =>
                      this.handleChange('filtro2Id', filtro2Id)
                    }
                  />
                  <Space />
                  <TimePicker
                    nativeID="periculosidade"
                    label={translate('Periculosidade')}
                    value={dados.periculosidade || ''}
                    onChange={value =>
                      this.handleChange('periculosidade', value)
                    }
                  />
                  <Space />
                  <DropDown
                    nativeID="filtro1Id"
                    label={translate('Centro de Custos')}
                    value={dados.filtro1Id}
                    items={listaFiltro1.map(x => ({
                      value: x.id,
                      label: x.descricao
                    }))}
                    onChange={filtro1Id =>
                      this.handleChange('filtro1Id', filtro1Id)
                    }
                  />
                  <Space />
                </>
              )}
            </View>

            <TextBox
              nativeID="ajustar-ponto-observacoes"
              label={translate('Observação')}
              value={dados.observacoes || ''}
              returnKeyType={'done'}
              onChange={value => this.handleChange('observacoes', value)}
              multiline
              maxLength={200}
              editable={!dados.existePeriodoEncerrado}
              inputStyle={Platform.OS === 'web' && styles.observacaoText}
            />
            <ErrorMessage
              nativeID="ajustar-ponto-erro-observacoes"
              message={erros.observacoes}
            />
            <Space />
            <Subscribe to={[FuncionarioContainer]}>
              {(funcionarioContainer: FuncionarioContainer) => (
                <ButtonBar
                  buttonBarStyle={Platform.OS === 'web' && styles.buttons}
                  leftButton={{
                    nativeID: 'ajustar-ponto-cancelar',
                    text: translate('Cancelar'),
                    primary: false,
                    onPress: this.handleSucesso
                  }}
                  rightButton={
                    funcionarioContainer.state.dados.nivelPermissao ===
                      NivelPermissao.NaoPermiteAlteracoes ||
                    dados.existePeriodoEncerrado
                      ? null
                      : {
                          nativeID: 'ajustar-ponto-enviar',
                          text: translate('Enviar'),
                          onPress: () => this.handleEnviar()
                        }
                  }
                />
              )}
            </Subscribe>
          </Card.Section>
        </Card>
        <Message
          nativeID="ajustar-ponto-enviar-sucesso"
          message={translate('Solicitação de ajuste efetuada com sucesso')}
          visible={resultado === 'sucesso'}
          onRequestClose={() => this.handleSucesso()}
        />
        <Message
          nativeID="ajustar-ponto-enviar-erro"
          type="warning"
          message={this.state.erroGeral}
          visible={resultado === 'erro'}
          onRequestClose={() =>
            this.setState({ resultado: null, erroGeral: '' })
          }
        />
      </>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  centralizarView: {
    margin: 'auto',
    maxWidth: 350,
    width: '100%'
  },
  cardSection: {
    margin: 'auto',
    maxWidth: 350,
    width: '100%'
  },
  cardHeader: {
    color: theme.textColor2,
    fontSize: 12,
    fontFamily: theme.fontFamily2
  },
  observacaoText: {
    minHeight: 60
  },
  buttons: {
    flexDirection: 'row',
    margin: 'auto',
    maxWidth: 350,
    width: '100%'
  },
  timer: {
    borderWidth: 0,
    flex: 1
  },
  timerBordaArrastada: {
    borderColor: theme.successColor
  },
  timerBordaNaoSelecionada: {
    borderColor: theme.borderColor1
  },
  timerBordaAreaDrop: {
    borderColor: theme.textColor2
  }
});

export default CardAjustarPonto;
