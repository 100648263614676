import * as React from 'react';
import { Platform, View, StyleSheet, Dimensions } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  ErrorMessage,
  Space,
  Message,
  DropDown,
  Button,
  Question,
  TextBox,
  Card,
  isTablet
} from 'secullum-react-native-ui';
import { Form } from '@secullum/react-native-autofocus';
import { getTheme } from '../../../shared/modules/layout';
import { ButtonBar } from '../../components/ButtonBar';
import Api from '../../modules/api';
import {
  IndicadorCustomizado,
  CorTextoIndicadorCustomizado,
  ColunaCalculoIndicadorCustomizado,
  ComparacaoIndicadorCustomizado,
  TipoPerfil,
  TipoIndicadorCustomizado,
  TipoTelaIndicadorCustomizado,
  ItemListaColunaCalculoIndicaor
} from '../../modules/types';

interface Props {
  indicadorId?: number;
  modoTela: TipoTelaIndicadorCustomizado;
  onCancel: (modoTela: TipoTelaIndicadorCustomizado) => void;
  perfilUsuarioLogado: TipoPerfil;
  onErroConexao?: (mensagem: string, onTentarNovamente: () => void) => void;
}

interface State {
  resultado: 'sucesso' | 'erro' | null;
  campos: IndicadorCustomizado;
  erroGeral: string;
  erros: { [key: string]: string };
  modoTela: TipoTelaIndicadorCustomizado;
  exibirModalConfirmacaoExclusao: boolean;
  mensagemSucesso: string;
  colunasCalculoIndicador: Array<{
    label: string;
    value: ColunaCalculoIndicadorCustomizado;
  }>;
}

class CardDadosIndicadorCustomizado extends React.Component<Props, State> {
  private backspace = false;

  state: State = {
    resultado: null,
    erroGeral: '',
    erros: {},
    campos: {
      id: 0,
      colunaCalculo: 0,
      titulo: '',
      corTexto: 0,
      tipo: TipoIndicadorCustomizado.Funcionario
    },
    modoTela: TipoTelaIndicadorCustomizado.Funcionario,
    exibirModalConfirmacaoExclusao: false,
    mensagemSucesso: '',
    colunasCalculoIndicador: []
  };

  componentDidMount() {
    const { modoTela, perfilUsuarioLogado, indicadorId } = this.props;

    this.setState(
      {
        modoTela:
          perfilUsuarioLogado == TipoPerfil.Funcionario &&
          modoTela == TipoTelaIndicadorCustomizado.Gerente
            ? TipoTelaIndicadorCustomizado.Funcionario
            : modoTela
      },
      () => {
        if (indicadorId) {
          this.buscarDadosIndicador(indicadorId);
        } else {
          this.listarColunaCalculoIndicador();
        }
      }
    );
  }

  buscarDadosIndicador = (indicadorId: number) => {
    const api = new Api();

    api.getIndicadorCustomizado(indicadorId, {
      onSuccess: (resp: IndicadorCustomizado) => {
        this.setState({
          campos: resp ? { ...resp } : this.state.campos
        });

        this.listarColunaCalculoIndicador();
      },
      onError: (err: Error) => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () =>
            this.buscarDadosIndicador(indicadorId)
          );
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      }
    });
  };

  listarCoresTexto = () => {
    return [
      {
        label: translate('Verde'),
        value: CorTextoIndicadorCustomizado.Verde,
        nativeID: 'cor-texto-verde'
      },
      {
        label: translate('Amarelo'),
        value: CorTextoIndicadorCustomizado.Amarelo,
        nativeID: 'cor-texto-amarelo'
      },
      {
        label: translate('Vermelho'),
        value: CorTextoIndicadorCustomizado.Vermelho,
        nativeID: 'cor-texto-vermelho'
      }
    ];
  };

  listarColunaCalculoIndicador = () => {
    const { modoTela } = this.state;
    const api = new Api();

    const tipoIndicador =
      modoTela == TipoTelaIndicadorCustomizado.Funcionario
        ? TipoIndicadorCustomizado.Funcionario
        : TipoIndicadorCustomizado.Gerente;

    api.getListaColunasCalculoIndicador(tipoIndicador, {
      onSuccess: (resp: Array<ItemListaColunaCalculoIndicaor>) => {
        this.setState({
          colunasCalculoIndicador: resp.map(coluna => ({
            label: coluna.nomeColuna,
            value: coluna.colunaCalculo,
            nativeID: `coluna-calculo-${coluna.colunaCalculo}`
          }))
        });
      },
      onError: (err: Error) => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () =>
            this.listarColunaCalculoIndicador()
          );
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      }
    });
  };

  listarComparacaoColunaIndicador = () => {
    return [
      {
        label: translate('Igual'),
        value: ComparacaoIndicadorCustomizado.Igual,
        nativeID: 'comparacao-coluna-igual'
      },
      {
        label: translate('Maior que'),
        value: ComparacaoIndicadorCustomizado.MaiorQue,
        nativeID: 'comparacao-coluna-maior-que'
      },
      {
        label: translate('Menor que'),
        value: ComparacaoIndicadorCustomizado.MenorQue,
        nativeID: 'comparacao-coluna-menor-que'
      }
    ];
  };

  consultarExpressaoValidacaoHora = (valorCampoHora: string) => {
    const expressaoHoraNegativa =
      /^(-|$)(?:[0-9]{1,3}|$)(?::|$)(?:[0-5]{1}|$)([0-9]{1}|$)$/g;
    const expressaoHoraPositiva =
      /^(?:[0-9]{1,3}|$)(?::|$)(?:[0-5]{1}|$)([0-9]{1}|$)$/g;

    return valorCampoHora.length > 0 && valorCampoHora[0] == '-'
      ? expressaoHoraNegativa
      : expressaoHoraPositiva;
  };

  formatarCampoHora = (valorCampoHora: string) => {
    const tamanhoValor = valorCampoHora.length;
    const ehHoraNegativa = tamanhoValor > 0 && valorCampoHora[0] == '-';
    const expressaoHora = this.consultarExpressaoValidacaoHora(valorCampoHora);

    if (valorCampoHora.length <= 7) {
      if (valorCampoHora.match(expressaoHora) || valorCampoHora == '') {
        if (
          (ehHoraNegativa && tamanhoValor == 3) ||
          (!ehHoraNegativa && tamanhoValor == 2)
        ) {
          valorCampoHora = valorCampoHora + ':';
        }
        if (
          ((ehHoraNegativa && tamanhoValor == 4) ||
            (!ehHoraNegativa && tamanhoValor == 3)) &&
          valorCampoHora.indexOf(':') < 0
        ) {
          valorCampoHora = valorCampoHora + ':';
        }
      } else {
        //Remove o último caracter digitado caso a expressão não dê match
        valorCampoHora = valorCampoHora.substring(0, valorCampoHora.length - 1);
      }
    }
    return valorCampoHora;
  };

  handleChange = (name: string, value: any) => {
    if (name == 'valor' && !this.backspace) {
      value = this.formatarCampoHora(value);
    }

    this.setState({
      campos: {
        ...this.state.campos,
        [name]: value
      }
    });
  };

  handleSalvar = () => {
    const api = new Api();
    const { campos, modoTela } = this.state;

    campos.tipo =
      modoTela == TipoTelaIndicadorCustomizado.Funcionario
        ? TipoIndicadorCustomizado.Funcionario
        : TipoIndicadorCustomizado.Gerente;

    api.postSalvarIndicador(campos, {
      onSuccess: () => {
        this.setState({
          resultado: 'sucesso',
          mensagemSucesso: translate('Indicador salvo com sucesso.')
        });
      },
      onError: (err: Error) => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () => this.handleSalvar());
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      },
      onValidationError: (errorList, errorObj) => {
        this.setState({
          erros: errorObj
        });
      }
    });
  };

  handleExcluirIndicador = () => {
    const { indicadorId } = this.props;

    if (!indicadorId) {
      return;
    }

    const api = new Api();

    this.setState({ exibirModalConfirmacaoExclusao: false });

    api.excluirIndicador(indicadorId, {
      onSuccess: resp => {
        this.setState({
          resultado: 'sucesso',
          mensagemSucesso: translate('Indicador excluído com sucesso.')
        });
      },
      onError: (err: Error) => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () =>
            this.handleExcluirIndicador()
          );
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      }
    });
  };

  validarCampoValor = (valor: string) => {
    if (valor.length > 0 && !valor.match(/^(-)*\d{1,3}:[0-5]{1}[0-9]{1}/)) {
      const valorTratado = valor.indexOf(':') < 0 ? valor + ':' : valor;
      const tamanhoCampo = valorTratado.length + 2;
      this.handleChange('valor', valorTratado.padEnd(tamanhoCampo, '0'));
    }
  };

  render() {
    const {
      erros,
      campos,
      resultado,
      erroGeral,
      modoTela,
      exibirModalConfirmacaoExclusao,
      mensagemSucesso,
      colunasCalculoIndicador
    } = this.state;
    const { onCancel } = this.props;

    const mensagemAcessoNaoAutorizado =
      campos.id &&
      !(
        (modoTela == TipoTelaIndicadorCustomizado.Gerente &&
          campos.tipo == TipoIndicadorCustomizado.Gerente) ||
        (modoTela == TipoTelaIndicadorCustomizado.Funcionario &&
          campos.tipo == TipoIndicadorCustomizado.Funcionario)
      )
        ? translate('Acesso Bloqueado')
        : undefined;

    return !mensagemAcessoNaoAutorizado ? (
      <Card>
        <Card.Header
          nativeID="dados-indicador-customizado-texto-explicativo"
          title={translate(
            'Informe os dados que serão usados para montar o indicador.'
          )}
          titleStyle={styles.titleHeader}
        />
        <Card.Section>
          <View style={Platform.OS === 'web' && styles.centralizarView}>
            <Form focusOn={[TextBox, DropDown]}>
              <TextBox
                nativeID="dados-indicador-customizado-titulo"
                label={translate('Título')}
                value={campos.titulo}
                maxLength={50}
                returnKeyType={'next'}
                onChange={titulo => this.handleChange('titulo', titulo)}
              />
              <ErrorMessage
                nativeID="dados-indicador-customizado-erro-titulo"
                message={erros.titulo}
              />
              <Space height={6} />
              <DropDown
                nativeID="dados-indicador-customizado-corTexto"
                label={translate('Cor do Texto')}
                value={campos.corTexto}
                items={this.listarCoresTexto()}
                onChange={corTextoId =>
                  this.handleChange('corTexto', corTextoId)
                }
              />
              <ErrorMessage
                nativeID="dados-indicador-customizado-erro-titulo"
                message={erros.corTextoId}
              />
              <Space height={6} />
              <DropDown
                nativeID="dados-indicador-customizado-colunaCalculos"
                label={translate('Coluna de Cálculos')}
                value={campos.colunaCalculo}
                items={colunasCalculoIndicador}
                onChange={colunaCalculoId =>
                  this.handleChange('colunaCalculo', colunaCalculoId)
                }
              />
              <ErrorMessage
                nativeID="dados-indicador-customizado-erro-titulo"
                message={erros.colunaCalculoId}
              />
              <Space height={6} />
              {modoTela == TipoTelaIndicadorCustomizado.Gerente && (
                <>
                  <DropDown
                    nativeID="dados-indicador-customizado-comparacao"
                    label={translate('Comparação')}
                    value={campos.comparacao}
                    items={this.listarComparacaoColunaIndicador()}
                    onChange={comparacaoId =>
                      this.handleChange('comparacao', comparacaoId)
                    }
                  />
                  <ErrorMessage
                    nativeID="dados-indicador-customizado-erro-comparacao"
                    message={erros.comparacao}
                  />
                  <Space height={6} />

                  <TextBox
                    nativeID="dados-indicador-customizado-valor"
                    label={translate('Valor')}
                    value={campos.valor || ''}
                    maxLength={7}
                    keyboardType={'default'}
                    onChange={valor => this.handleChange('valor', valor)}
                    returnKeyType={'next'}
                    onBlur={() => this.validarCampoValor(campos.valor || '')}
                    onKeyPress={(eventData: any) => {
                      if (eventData.key === 'Backspace') {
                        this.backspace = true;
                      } else {
                        this.backspace = false;
                      }
                    }}
                  />
                  <ErrorMessage
                    nativeID="dados-indicador-customizado-erro-valor"
                    message={erros.valor}
                  />
                </>
              )}
              <Space height={6} />
              {this.props.indicadorId && this.props.indicadorId > 0 && (
                <Button
                  nativeID="dados-indicador-customizado--excluir"
                  text={translate('Excluir')}
                  style={{ backgroundColor: theme.errorColor }}
                  onPress={() =>
                    this.setState({
                      exibirModalConfirmacaoExclusao: true
                    })
                  }
                />
              )}
              <Space height={6} />
              <ButtonBar
                buttonBarStyle={Platform.OS === 'web' && styles.centralizarView}
                leftButton={{
                  nativeID: 'dados-indicador-customizado--cancelar',
                  text: translate('Cancelar'),
                  primary: false,
                  onPress: () => onCancel(modoTela)
                }}
                rightButton={{
                  nativeID: 'dados-indicador-customizado--salvar',
                  text: translate('Salvar'),
                  onPress: this.handleSalvar
                }}
              />
              <Message
                nativeID="dados-indicador-customizado--sucesso"
                message={mensagemSucesso}
                visible={resultado === 'sucesso'}
                onRequestClose={() => {
                  this.setState({ resultado: null, erroGeral: '' }, () => {
                    onCancel(modoTela);
                  });
                }}
              />
              <Message
                nativeID="dados-indicador-customizado--erro"
                type="warning"
                message={erroGeral}
                visible={resultado === 'erro'}
                onRequestClose={() =>
                  this.setState({ resultado: null, erroGeral: '' })
                }
              />
            </Form>
          </View>
        </Card.Section>
        <Question
          message={translate('Deseja realmente excluir o indicador?')}
          visible={exibirModalConfirmacaoExclusao}
          nativeID="dados-indicador-customizado--mensagem-confirmacao-exclusao"
          okButton={{
            text: 'OK',
            onPress: this.handleExcluirIndicador,
            nativeID: 'botao-ok-modal-excluir-indicador'
          }}
          cancelButton={{
            text: translate('Cancelar'),
            nativeID: 'botao-cancelar-modal-excluir-indicador',
            onPress: () => {
              this.setState({ exibirModalConfirmacaoExclusao: false });
            }
          }}
        />
      </Card>
    ) : (
      <ErrorMessage
        nativeID="dados-indicador-customizado-erro-valor"
        message={mensagemAcessoNaoAutorizado}
      />
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  botoesAcao: {
    minWidth: 75
  },
  centralizarView: {
    margin: 'auto',
    maxWidth: 350,
    width: '100%'
  },
  modal: {
    marginHorizontal: 20,
    top: Dimensions.get('window').height / 2 - 63
  },
  titleHeader: {
    color: theme.textColor2,
    fontSize: isTablet() ? 16 : 12,
    fontFamily: theme.fontFamily2
  }
});

export default CardDadosIndicadorCustomizado;
