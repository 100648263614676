import * as React from 'react';
import { Dimensions, Image, StyleSheet, View, Platform } from 'react-native';
import { translate } from 'secullum-i18n';
import { CheckBox, Message, Modal, Text } from 'secullum-react-native-ui';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ButtonBar } from '../../components/ButtonBar';
import CapturarImagem from '../../components/CapturarImagem';
import { getTheme } from '../../modules/layout';
import { TipoCameraFotoPonto } from '../../modules/types';

const CHAVE_NAO_EXIBIR_MODAL_AVISO_FOTO = 'ChaveNaoExibirModalAvisoFoto';
const TIMEOUT_PROCESSO_CAPTURA_IMAGEM = 60 * 1000; // 1 minuto de timeout

interface Props {
  tipoCamera: TipoCameraFotoPonto;
  mensagem: string;
  mensagemTimeout: string;
  onConcluir: (foto: string) => void;
  onCancelar: () => void;
  onCameraStarting?: () => void;
  onCameraReady?: () => void;
}

interface State {
  passo: null | 'aviso' | 'captura' | 'resultado' | 'timeout';
  foto: string | null;
  desabilitarAvisoFoto: boolean;
}

class ModalCapturarFoto extends React.Component<Props, State> {
  timeoutProcessoCapturaFoto: NodeJS.Timeout | null = null;

  state: State = {
    passo: null,
    foto: null,
    desabilitarAvisoFoto: false
  };

  async componentDidMount() {
    const naoExibirAvisoFoto = await AsyncStorage.getItem(
      CHAVE_NAO_EXIBIR_MODAL_AVISO_FOTO
    );

    if (naoExibirAvisoFoto) {
      this.handleIniciarCaptura();
    } else {
      this.setState({ passo: 'aviso' }, this.restartTimeout);
    }
  }

  componentWillUnmount() {
    if (this.timeoutProcessoCapturaFoto) {
      clearTimeout(this.timeoutProcessoCapturaFoto);
    }
  }

  restartTimeout = () => {
    if (this.timeoutProcessoCapturaFoto) {
      clearTimeout(this.timeoutProcessoCapturaFoto);
    }

    this.timeoutProcessoCapturaFoto = setTimeout(
      () => this.setState({ passo: 'timeout' }),
      TIMEOUT_PROCESSO_CAPTURA_IMAGEM
    );
  };

  handleClickContinuarAvisoAsync = async () => {
    if (this.state.desabilitarAvisoFoto) {
      await AsyncStorage.setItem(CHAVE_NAO_EXIBIR_MODAL_AVISO_FOTO, 'true');
    }

    this.handleIniciarCaptura();
  };

  handleIniciarCaptura = () => {
    // Quando a câmera é iniciada o nosso app fica em segundo plano
    // disparando o evento de mudança de estado do app.
    // Essa função é responsável por informar quando vamos iniciar a câmera.
    if (this.props.onCameraStarting) {
      this.props.onCameraStarting();
    }

    this.setState({ passo: 'captura' }, this.restartTimeout);
  };

  handleCameraReady = () => {
    // Quando a câmera é iniciada o nosso app fica em segundo plano
    // disparando o evento de mudança de estado do app.
    // Essa função é responsável por informar quando a câmera já foi iniciada.
    if (this.props.onCameraReady) {
      this.props.onCameraReady();
    }

    this.restartTimeout();
  };

  render() {
    switch (this.state.passo) {
      case 'aviso':
        return this.renderAviso();
      case 'captura':
        return this.renderCaptura();
      case 'resultado':
        return this.renderResultado();
      case 'timeout':
        return this.renderTimeout();
      default:
        return null;
    }
  }

  renderAviso() {
    const { desabilitarAvisoFoto } = this.state;
    const { mensagem, onCancelar } = this.props;

    return (
      <Modal
        visible
        onRequestClose={Platform.OS === 'web' ? undefined : onCancelar}
        overlayStyle={styles.modal}
      >
        <View style={[styles.modalContainer, styles.modalExigirFoto]}>
          <Text style={styles.espaco}>{mensagem}</Text>
          <CheckBox
            style={styles.espacoCheckbox}
            labelStyle={styles.textoCheckbox}
            label={translate('Não me avise novamente')}
            value={desabilitarAvisoFoto}
            onChange={() =>
              this.setState({ desabilitarAvisoFoto: !desabilitarAvisoFoto })
            }
          />
          <ButtonBar
            buttonBarStyle={styles.espaco}
            leftButton={{
              nativeID: 'captura-foto-cancelar',
              text: translate('Cancelar'),
              onPress: onCancelar,
              primary: false
            }}
            rightButton={{
              nativeID: 'captura-foto-continuar',
              text: translate('Continuar'),
              onPress: this.handleClickContinuarAvisoAsync
            }}
          />
        </View>
      </Modal>
    );
  }

  renderCaptura() {
    const { tipoCamera, onCancelar } = this.props;

    return (
      <Modal
        visible
        onRequestClose={Platform.OS === 'web' ? undefined : onCancelar}
        overlayStyle={styles.modal}
      >
        <View style={styles.modalCapturarFoto}>
          <CapturarImagem
            onConcluir={foto =>
              this.setState({ foto, passo: 'resultado' }, this.restartTimeout)
            }
            onFechar={onCancelar}
            onCameraReady={this.handleCameraReady}
            cameraPadrao={
              tipoCamera === TipoCameraFotoPonto.SomenteCameraTraseira
                ? 'back'
                : 'front'
            }
            permiteAlterarCamera={
              tipoCamera === TipoCameraFotoPonto.LivreEscolhaFuncionario
            }
            mensagemErroCameraNaoEncontrada={translate(
              'É necessário capturar uma foto ao registrar o ponto. Por favor efetue a marcação via APP ou utilize uma webcam conectada ao computador.'
            )}
          />
        </View>
      </Modal>
    );
  }

  renderResultado() {
    const { foto } = this.state;
    const { onConcluir, onCancelar } = this.props;

    const mediumScreen = Dimensions.get('window').width <= 800;
    const smallScreen = Dimensions.get('window').width <= 500;

    return (
      <Modal visible overlayStyle={styles.modal}>
        <View
          style={[
            styles.modalContainer,
            Platform.OS === 'web'
              ? [
                  styles.modalCapturarFotoWeb,
                  {
                    height: smallScreen ? 300 : mediumScreen ? 427 : 590
                  }
                ]
              : styles.modalCapturarFoto
          ]}
        >
          <Image
            source={{ uri: `data:image/jpeg;base64,${foto}` }}
            style={
              Platform.OS === 'web'
                ? [
                    styles.fotoWeb,
                    {
                      width: smallScreen ? 262 : mediumScreen ? 422 : 642,
                      height: smallScreen ? 196 : mediumScreen ? 320 : 482
                    }
                  ]
                : [
                    styles.foto,
                    { height: Dimensions.get('window').height - 120 }
                  ]
            }
          />
          <ButtonBar
            buttonBarStyle={styles.espaco}
            leftButton={{
              nativeID: 'capturar-imagem-cancelar',
              text: translate('Cancelar'),
              onPress: onCancelar,
              primary: false
            }}
            rightButton={{
              nativeID: 'capturar-imagem-continuar',
              text: translate('Continuar'),
              disabled: !foto,
              onPress: () => onConcluir(foto!)
            }}
          />
        </View>
      </Modal>
    );
  }

  renderTimeout() {
    const { mensagemTimeout, onCancelar } = this.props;

    return (
      <Message
        type="warning"
        visible
        message={mensagemTimeout}
        onRequestClose={onCancelar}
      />
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: 'center'
  },
  modalContainer: {
    backgroundColor: theme.backgroundColor1,
    margin: 0,
    borderRadius: 5
  },
  modalExigirFoto: {
    margin: 16,
    paddingTop: 10,
    maxWidth: Platform.OS === 'web' ? 400 : undefined,
    alignSelf: Platform.OS === 'web' ? 'center' : undefined
  },
  modalCapturarFoto: {
    height: '100%',
    width: '100%'
  },
  modalCapturarFotoWeb: {
    alignSelf: 'center'
  },
  foto: {
    margin: 16,
    marginBottom: 0
  },
  fotoWeb: {
    alignSelf: 'center',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 30,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: '#0000003b'
  },
  espaco: {
    padding: 16
  },
  espacoCheckbox: {
    paddingLeft: 16
  },
  textoCheckbox: {
    fontSize: 14
  },
  buttonCancelar: {
    margin: 16
  }
});

export default ModalCapturarFoto;
