import * as base64 from 'base-64';
import cookie, { CookieAttributes } from 'js-cookie';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { TipoUsuarioAutenticacao } from './types';

export interface DadosLogin {
  funcionarioId: number;
  funcionarioNumero: string;
  funcionarioSenha: string;
  funcionarioSenhaBiometria?: string | undefined;
  bancoId: string;
  continuarConectado?: boolean;
  acessaComBiometria?: boolean;
  tipoUsuarioAutenticacao: TipoUsuarioAutenticacao;
}

export const salvarDadosLoginAsync = async (dados: DadosLogin) => {
  if (Platform.OS === 'web') {
    const cookieAttributes: CookieAttributes = {};

    if (dados.continuarConectado) {
      cookieAttributes.expires = 365 * 10; // ~10 anos
    }

    // Iniciais de: Central do Funcionário Dados Login
    cookie.set('cfdl', base64.encode(JSON.stringify(dados)), cookieAttributes);
  } else {
    await AsyncStorage.setItem('dadosLogin', JSON.stringify(dados));
  }
};

export const carregarDadosLoginAsync = async (): Promise<DadosLogin | null> => {
  let dadosLoginSerializados: string | null | undefined = null;

  if (Platform.OS === 'web') {
    // No app web passamos a armazenar os cookies em um base64 ao invés de texto plano,
    // e com isso alteramos também o nome da propriedade armazenada. Então mantém a compatibilidade
    // na hora de carregar os dados, e remove o padrão antigo caso ele ainda esteja armazenado.
    const base64String = cookie.get('cfdl');

    if (base64String) {
      dadosLoginSerializados = base64.decode(base64String);
    } else {
      dadosLoginSerializados = cookie.get('dadosLogin');

      if (dadosLoginSerializados) {
        await salvarDadosLoginAsync(JSON.parse(dadosLoginSerializados));
        cookie.remove('dadosLogin');
      }
    }
  } else {
    dadosLoginSerializados = await AsyncStorage.getItem('dadosLogin');
  }

  if (!dadosLoginSerializados) {
    return null;
  }

  return JSON.parse(dadosLoginSerializados);
};

export const excluirDadosLoginAsync = async (limparTudo: boolean = false) => {
  const dadosLogin = await carregarDadosLoginAsync();

  if (dadosLogin == null) {
    return;
  }

  await salvarDadosLoginAsync({
    funcionarioId: 0,
    funcionarioNumero: limparTudo ? '' : dadosLogin.funcionarioNumero,
    funcionarioSenha: '',
    bancoId: limparTudo ? '' : dadosLogin.bancoId,
    continuarConectado: dadosLogin.continuarConectado,
    acessaComBiometria: dadosLogin.acessaComBiometria,
    funcionarioSenhaBiometria:
      dadosLogin.acessaComBiometria &&
      dadosLogin.funcionarioSenha == dadosLogin.funcionarioSenhaBiometria
        ? dadosLogin.funcionarioSenhaBiometria
        : undefined,
    tipoUsuarioAutenticacao: dadosLogin.tipoUsuarioAutenticacao
  });
};
