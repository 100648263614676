import { RouteComponentProps } from 'react-router-dom';

export type RouteComponentComBancoIdProps<T = {}> = RouteComponentProps<
  {
    bancoId: string;
  } & T
>;

export function criarPathComBancoId(path: string) {
  return `/:bancoId(\\d+)?${path}`;
}

export function navegar(opcoes: {
  props: RouteComponentComBancoIdProps;
  path: string;
  bancoId?: string;
  replace?: boolean;
}) {
  const {
    props,
    path,
    bancoId = opcoes.props.match.params.bancoId,
    replace
  } = opcoes;

  const url = '/' + bancoId + path.replace('/:bancoId(\\d+)?', '');

  if (replace) {
    props.history.replace(url);
  } else {
    props.history.push(url);
  }
}
