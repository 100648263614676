import * as React from 'react';
import { StyleSheet, Pressable, View, Platform } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import {
  isTablet,
  Space,
  Card,
  CheckBox,
  Text
} from 'secullum-react-native-ui';
import { getTheme } from '../../shared/modules/layout';
import { getPeriodoFormatado } from '../containers/AssinaturaDigitalCartaoPontoContainer';
import { isHighResolution } from '../modules/layout';
import {
  AssinaturaDigitalCartaoPontoDados,
  AssinaturaDigitalCartaoPontoStatus
} from '../modules/types';

interface Props {
  assinaturaDigitalCartaoPonto: AssinaturaDigitalCartaoPontoDados;
  iconeAssinaturaDigitalCartaoPonto: string;
  corAssinaturaDigitalCartaoPonto: string;
  assinaturaDigitalCartaoPontoNativeId?: string;
  checked: boolean;
  exibirSeparador: boolean;
  onNavigate: () => void;
  onChecked: () => void;
}

class AssinaturaDigitalCartaoPontoLinha extends React.Component<Props> {
  static defaultProps = {
    checked: false
  };

  render() {
    const {
      assinaturaDigitalCartaoPonto,
      corAssinaturaDigitalCartaoPonto,
      iconeAssinaturaDigitalCartaoPonto,
      exibirSeparador,
      checked,
      onChecked,
      assinaturaDigitalCartaoPontoNativeId
    } = this.props;

    const webDesktop = Platform.OS === 'web' && isHighResolution();

    return (
      <>
        {exibirSeparador && <Space height={10} />}
        <Card nativeID={assinaturaDigitalCartaoPontoNativeId}>
          <Card.Section>
            <Pressable
              style={({ pressed }) => [
                { opacity: pressed ? 0.7 : 1 },
                styles.linhaTouchable
              ]}
              onPress={() => this.props.onNavigate()}
            >
              {assinaturaDigitalCartaoPonto.estado ===
              AssinaturaDigitalCartaoPontoStatus.NaoRespondido ? (
                <CheckBox
                  nativeID={`assinatura-digital-cartao-ponto-checkbox-${assinaturaDigitalCartaoPonto.id}`}
                  value={checked}
                  onChange={onChecked}
                />
              ) : (
                <View style={styles.placeholderCheckbox} />
              )}
              <Text bold flex={0.4} size={14} style={styles.linhaText}>
                {assinaturaDigitalCartaoPonto.assinaturaDigitalCartaoPontoId}
              </Text>

              {Platform.OS === 'web' ? (
                <>
                  <Text bold flex={1} size={14} style={styles.linhaText}>
                    {assinaturaDigitalCartaoPonto.descricao}
                  </Text>
                  <Text bold flex={1} size={14} style={styles.linhaText}>
                    {getPeriodoFormatado(assinaturaDigitalCartaoPonto)}
                  </Text>
                </>
              ) : (
                <Text bold flex={1} size={14} style={styles.linhaText}>
                  {assinaturaDigitalCartaoPonto.descricao}
                  {'\n'}
                  {getPeriodoFormatado(assinaturaDigitalCartaoPonto)}
                </Text>
              )}
              <FontAwesome
                testID={iconeAssinaturaDigitalCartaoPonto}
                name={iconeAssinaturaDigitalCartaoPonto}
                color={corAssinaturaDigitalCartaoPonto}
                size={20}
                style={[
                  styles.icon,
                  {
                    width: Platform.OS === 'web' || isTablet() ? 50 : 40,
                    flex: webDesktop ? 1 : undefined
                  }
                ]}
              />
            </Pressable>
          </Card.Section>
        </Card>
      </>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  linhaTouchable: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1
  },
  placeholderCheckbox: {
    width: 24
  },
  linhaText: {
    paddingRight: 5,
    ...(Platform.OS === 'web' ? { wordBreak: 'break-all' } : {}),
    color: theme.textColor1
  },
  icon: {
    textAlign: 'center'
  }
});

export default AssinaturaDigitalCartaoPontoLinha;
