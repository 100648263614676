import * as React from 'react';
import { translate } from 'secullum-i18n';
import { parseDate } from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import { FuncionarioContainer } from '../../../shared/containers/FuncionarioContainer';
import { verificarExibirTela } from '../../../shared/modules/perfilFuncionario';
import { retornarRotaWeb, Telas } from '../../../shared/modules/telas';
import { buscarPrimeiraTelaInicialDisponivel } from '../../../shared/modules/telasIniciais';
import { converterDataParaTimezoneBrasil } from '../../../shared/modules/utils';
import CardAjustarPonto from '../../../shared/screens/ajustar-ponto/CardAjustarPonto';
import LoggedInScreen from '../../components/LoggedInScreen';
import { RouteComponentComBancoIdProps, navegar } from '../../modules/routes';

interface Props
  extends RouteComponentComBancoIdProps<{
    data?: string;
    funcionarioId?: string;
  }> {}

interface State {
  scrollTop: number;
}

class AjustarPonto extends React.Component<Props, State> {
  state = {
    scrollTop: 0
  };

  componentDidMount() {
    const content = document.getElementById('app-shell-desktop-content');

    if (content) {
      content.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    const content = document.getElementById('app-shell-desktop-content');

    if (content) {
      content.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    const content = document.getElementById('app-shell-desktop-content');

    this.setState({ scrollTop: content!.scrollTop });
  };

  getPathCartaoPontoGerente = (funcionarioId: number) => {
    return `/cartao-ponto-gerente/${funcionarioId}`;
  };

  render() {
    const { match } = this.props;

    let data = converterDataParaTimezoneBrasil(new Date());
    let funcionarioId: number | undefined;

    if (match.params.data) {
      data = parseDate(match.params.data, 'yyyy-MM-dd');
    }

    if (match.params.funcionarioId) {
      funcionarioId = Number(match.params.funcionarioId);
    }

    return (
      <LoggedInScreen screenTitle={translate('Ajustar Ponto')}>
        <Subscribe to={[FuncionarioContainer]}>
          {(funcionario: FuncionarioContainer) => (
            <CardAjustarPonto
              funcionarioId={funcionarioId}
              configuracoesEspeciaisAcesso={
                funcionario.state.dados.configuracoesEspeciaisAcesso
              }
              date={data}
              usaPonto10batidas={funcionario.state.dados.usaPonto10Batidas}
              scrollTop={this.state.scrollTop}
              onSuccess={funcionarioSelecionadoId => {
                const possuiPermissaoTelaCartaoPonto = verificarExibirTela(
                  funcionarioSelecionadoId
                    ? Telas.CartaoPontoGerente
                    : Telas.CartaoPonto,
                  funcionario.state.dados.dadosPerfilFuncionario.telasOcultar
                );

                // Case 136139: Caso o usuário não tenha permissão de acesso à
                // tela de cartão ponto, manda para a primeira tela que tiver disponível
                if (possuiPermissaoTelaCartaoPonto) {
                  navegar({
                    props: this.props,
                    path: funcionarioSelecionadoId
                      ? this.getPathCartaoPontoGerente(funcionarioSelecionadoId)
                      : '/cartao-ponto'
                  });
                } else {
                  const telaDisponivel = buscarPrimeiraTelaInicialDisponivel(
                    funcionario.state.dados,
                    Telas.AjustarPonto
                  );

                  navegar({
                    props: this.props,
                    path: retornarRotaWeb(telaDisponivel)
                  });
                }
              }}
            />
          )}
        </Subscribe>
      </LoggedInScreen>
    );
  }
}

export default AjustarPonto;
