import * as ImagePickerModule from 'expo-image-picker';
import * as React from 'react';
import {
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  View
} from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Card,
  FilePicker,
  Message,
  Modal,
  Space,
  Text,
  getTheme
} from 'secullum-react-native-ui';
import { ButtonBar } from '../../components/ButtonBar';
import CapturarImagem from '../../components/CapturarImagem';
import { manipulateImageAsync } from '../../modules/image';

interface Props {
  renderFoto: (foto: string) => JSX.Element;
  onConcluir: (foto: string) => void;
  onCancelar: () => void;
}

interface State {
  fotoComprovante: string | null;
  temPermissaoCamera: boolean;
  temPermissaoGaleria: boolean;
  capturandoImagem: boolean;
  erroGeral: string;
}

type PickMediaFunction = (
  options: ImagePickerModule.ImagePickerOptions
) => Promise<ImagePickerModule.ImagePickerResult>;

const LARGURA_IMAGEM_COMPROVANTE = 650;

class ModalCapturarComprovanteEndereco extends React.Component<Props, State> {
  state: State = {
    fotoComprovante: null,
    temPermissaoCamera: false,
    temPermissaoGaleria: false,
    capturandoImagem: false,
    erroGeral: ''
  };

  async componentDidMount() {
    const permissaoCamera = await ImagePickerModule.getCameraPermissionsAsync();
    const permissaoGaleria =
      await ImagePickerModule.getMediaLibraryPermissionsAsync();

    if (permissaoCamera.granted) {
      this.setState({ temPermissaoCamera: true });
    }

    if (permissaoGaleria.granted) {
      this.setState({ temPermissaoGaleria: true });
    }
  }

  onPressCamera = async () => {
    // Na web, o launchCameraAsync simplesmente abre o explorador de arquivos
    // Temos que mostrar a camera manualmente para capturar aqui
    if (Platform.OS === 'web') {
      this.setState({ capturandoImagem: true });
      return;
    }

    if (!this.state.temPermissaoCamera) {
      const permissaoCamera =
        await ImagePickerModule.requestCameraPermissionsAsync();

      if (!permissaoCamera.granted) {
        this.setState({ erroGeral: translate('Sem acesso à câmera') });
        return;
      }
    }

    await this.pickMediaAsync(ImagePickerModule.launchCameraAsync);
  };

  onPressGaleria = async () => {
    if (!this.state.temPermissaoGaleria) {
      const permissaoGaleria =
        await ImagePickerModule.requestMediaLibraryPermissionsAsync();

      if (!permissaoGaleria.granted) {
        this.setState({ erroGeral: translate('Sem acesso à galeria') });
        return;
      }
    }

    await this.pickMediaAsync(ImagePickerModule.launchImageLibraryAsync);
  };

  pickMediaAsync = async (pickMediaFunction: PickMediaFunction) => {
    const options: ImagePickerModule.ImagePickerOptions = {
      quality: 1,
      allowsEditing: true,
      base64: false,
      exif: false,
      mediaTypes: ImagePickerModule.MediaTypeOptions.Images
    };

    const result = await pickMediaFunction(options);

    if (result.canceled) {
      return;
    }

    const image = await manipulateImageAsync(
      result.assets[0].uri,
      LARGURA_IMAGEM_COMPROVANTE
    );

    this.setState({ fotoComprovante: image });
  };

  render() {
    const { renderFoto, onConcluir, onCancelar } = this.props;
    const { fotoComprovante, capturandoImagem, erroGeral } = this.state;

    return (
      <Modal visible overlayStyle={styles.modalOverlay}>
        <Card
          style={styles.modalContainer}
          nativeID="modal-comprovante-endereco"
        >
          <Card.Header title={translate('Comprovante de endereço')} />
          <Card.Section>
            {fotoComprovante && (
              <>
                <ScrollView
                  style={{
                    maxHeight: Dimensions.get('window').height - 324
                  }}
                  nativeID="modal-comprovante-endereco-foto"
                >
                  {renderFoto(fotoComprovante)}
                </ScrollView>
                <Space />
              </>
            )}
            <View style={styles.pickersContainer}>
              <View style={[styles.pickerContainer, { paddingRight: 8 }]}>
                <FilePicker
                  label={translate('Câmera')}
                  icon={'camera'}
                  onPress={this.onPressCamera}
                  nativeID="modal-comprovante-endereco-camera"
                />
              </View>
              <View style={[styles.pickerContainer, { paddingLeft: 8 }]}>
                <FilePicker
                  label={translate('Galeria')}
                  icon={'photo'}
                  onPress={this.onPressGaleria}
                  nativeID="modal-comprovante-endereco-galeria"
                />
              </View>
            </View>
            <Space />
            <Text size={12} color={theme.textColor2}>
              {translate(
                'A empresa onde você trabalha exige um comprovante para alterar os seus dados cadastrais.'
              )}
            </Text>
            <Space />
            <ButtonBar
              leftButton={{
                text: translate('Cancelar'),
                onPress: onCancelar,
                primary: false,
                nativeID: 'modal-comprovante-endereco-cancelar'
              }}
              rightButton={{
                text: translate('Confirmar'),
                onPress: () => onConcluir(fotoComprovante!),
                primary: true,
                disabled: fotoComprovante === null,
                nativeID: 'modal-comprovante-endereco-confirmar'
              }}
            />
            {capturandoImagem && (
              <Modal visible>
                <CapturarImagem
                  onConcluir={foto =>
                    this.setState({
                      fotoComprovante: foto,
                      capturandoImagem: false
                    })
                  }
                  onFechar={() =>
                    this.setState({
                      capturandoImagem: false
                    })
                  }
                  larguraImagem={LARGURA_IMAGEM_COMPROVANTE}
                  mensagemErroCameraNaoEncontrada={translate(
                    'Não foi possível encontrar uma WebCam para realizar a captura.'
                  )}
                />
              </Modal>
            )}
            <Message
              type="warning"
              message={erroGeral}
              visible={erroGeral !== ''}
              onRequestClose={() => this.setState({ erroGeral: '' })}
              nativeID="modal-comprovante-endereco-mensagem-erro"
            />
          </Card.Section>
        </Card>
      </Modal>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalContainer: {
    maxWidth: 400,
    width: '95%'
  },
  pickersContainer: {
    flexDirection: 'row'
  },
  pickerContainer: {
    flex: 1
  }
});

export default ModalCapturarComprovanteEndereco;
