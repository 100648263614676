import * as React from 'react';
import { Platform, StyleSheet, Text, Pressable, View } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { translate } from 'secullum-i18n';
import {
  Button,
  Card,
  Details,
  Space,
  getLocale,
  formatDate,
  isTablet
} from 'secullum-react-native-ui';
import MapaBatida from '../../components/MapaBatida';
import Api from '../../modules/api';
import { gerarComprovantePorRegistroPendencia } from '../../modules/comprovatanteRegistro';
import { getTheme, isDesktop } from '../../modules/layout';
import {
  RegistroPontoPendencia,
  RegistroPontoPendenciaStatus
} from '../../modules/types';
import { converterDataeHoraServidorParaDataLocal } from '../../modules/utils';

interface Props {
  onNovaInclusao: () => void;
  onAtualizar: () => void;
  listaUltimasPendencias: Array<RegistroPontoPendencia>;
  exbirBotaoNovaInclusao: boolean;
}

interface State {
  showGeo: { [key: number]: boolean };
}

class ListaUltimasPendencias extends React.Component<Props, State> {
  state: State = {
    showGeo: {}
  };

  montarLinhas = (lista: Array<RegistroPontoPendencia>) => {
    const getIconeStatusRegistro = (registro: RegistroPontoPendencia) => {
      switch (registro.status) {
        case RegistroPontoPendenciaStatus.Aceito:
          return 'thumbs-up';
        case RegistroPontoPendenciaStatus.Rejeitado:
          return 'thumbs-down';
        default:
          return 'hourglass-half';
      }
    };

    const getCorStatusRegistro = (registro: RegistroPontoPendencia) => {
      switch (registro.status) {
        case RegistroPontoPendenciaStatus.Aceito:
          return theme.successColor;
        case RegistroPontoPendenciaStatus.Rejeitado:
          return theme.errorColor;
        default:
          return theme.warningColor;
      }
    };

    const getTextoStatusRegistro = (registro: RegistroPontoPendencia) => {
      switch (registro.status) {
        case RegistroPontoPendenciaStatus.Aceito:
          return translate('Aceita');
        case RegistroPontoPendenciaStatus.Rejeitado:
          return registro.motivoRejeicao;
        default:
          return translate('Em processamento');
      }
    };

    const dateFormat =
      getLocale() === 'en' ? 'MM/dd - h:mm a' : 'dd/MM - HH:mm';

    const { dataAtualizacaoPortaria671 } = new Api().funcionario.state.dados;

    return lista
      .map((registro, index) => {
        const onMapPress = () => {
          this.setState(state => ({
            showGeo: {
              ...state.showGeo,
              [registro.id]: !state.showGeo[registro.id]
            }
          }));
        };

        const exibirMapa = (registro.latitude && registro.longitude) != null;

        const linhas = [];

        linhas.push({
          render: () => (
            <View style={styles.lineContainer}>
              {registro.fonteDadosId && dataAtualizacaoPortaria671 != null ? (
                <Pressable
                  onPress={() =>
                    gerarComprovantePorRegistroPendencia(registro.id)
                  }
                  style={({ pressed }) => [{ opacity: pressed ? 0.7 : 1 }]}
                >
                  <FontAwesome
                    name="file-text-o"
                    color={theme.textColor3}
                    size={22}
                    style={styles.comprovanteIcon}
                  />
                </Pressable>
              ) : (
                <Space width={25} />
              )}

              {exibirMapa && (
                <Pressable
                  onPress={onMapPress}
                  style={({ pressed }) => [{ opacity: pressed ? 0.7 : 1 }]}
                >
                  <FontAwesome
                    name="map-marker"
                    color={theme.textColor3}
                    size={22}
                    style={styles.lineIcon}
                  />
                </Pressable>
              )}

              <Text style={[styles.lineText, styles.lineTextLeft]}>
                {formatDate(
                  converterDataeHoraServidorParaDataLocal(registro.dataHora),
                  dateFormat
                )}
                {registro.atividadeDescricaoAbreviada &&
                  ` (${registro.atividadeDescricaoAbreviada})`}
              </Text>
              <Text
                nativeID={`status-processamento-${index}`}
                style={[styles.lineText, styles.lineTextRight]}
              >
                {getTextoStatusRegistro(registro)}
              </Text>
              <FontAwesome
                name={getIconeStatusRegistro(registro)}
                color={getCorStatusRegistro(registro)}
                size={22}
                style={styles.lineIcon}
              />
            </View>
          )
        });

        if (this.state.showGeo[registro.id]) {
          linhas.push({
            render: () => (
              <MapaBatida
                data={registro.dataHora}
                coordenadas={
                  exibirMapa
                    ? {
                        latitude: registro.latitude,
                        longitude: registro.longitude,
                        accuracy: registro.precisao
                      }
                    : null
                }
                endereco={
                  registro.endereco || translate('Endereço não disponível')
                }
                mostrarRelogio={false}
                alturaMapa={
                  exibirMapa ? (Platform.OS == 'web' ? 250 : 295) : undefined
                }
                scrollEnabled={false}
                mapId={'mapaDetalheBatida' + index.toString()}
              />
            )
          });
        }

        return linhas;
      })
      .reduce((acc, val) => acc.concat(val), []);
  };

  render() {
    const {
      onNovaInclusao,
      onAtualizar,
      listaUltimasPendencias,
      exbirBotaoNovaInclusao
    } = this.props;

    if (listaUltimasPendencias.length === 0) {
      return null;
    }

    const desktop = isDesktop();

    return (
      <>
        {exbirBotaoNovaInclusao && !desktop && (
          <>
            <Card.Section style={styles.sectionButtonMobile}>
              <Button
                nativeID="botao-nova-inclusao"
                text={translate('Nova Inclusão')}
                style={styles.buttonMobile}
                onPress={onNovaInclusao}
              />
            </Card.Section>
          </>
        )}
        {(!exbirBotaoNovaInclusao || !desktop) && <Space />}
        <Details
          title={translate('Últimos Registros')}
          lines={this.montarLinhas(listaUltimasPendencias)}
        />
        {exbirBotaoNovaInclusao && desktop && (
          <>
            <Space />
            <Card.Section style={styles.sectionButtonDesktop}>
              <Button
                nativeID="botao-nova-inclusao"
                text={translate('Nova Inclusão')}
                style={styles.buttonDesktop}
                onPress={onNovaInclusao}
              />
              <Button
                nativeID="botao-atualizar"
                text={translate('Atualizar')}
                style={styles.buttonDesktop}
                onPress={onAtualizar}
              />
            </Card.Section>
          </>
        )}
      </>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  sectionButtonDesktop: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  buttonDesktop: {
    width: '100%',
    marginHorizontal: 10,
    maxWidth: 350
  },
  sectionButtonMobile: {
    padding: 0
  },
  buttonMobile: {
    width: '100%'
  },
  lineContainer: {
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 45
  },
  lineText: {
    fontFamily: theme.fontFamily2,
    fontSize: isTablet() ? 18 : 14,
    color: theme.textColor1,
    minWidth: 40,
    flex: 1
  },
  lineTextLeft: {
    textAlign: 'left'
  },
  lineTextRight: {
    textAlign: 'right',
    paddingRight: 5
  },
  lineIcon: {
    width: 25,
    textAlign: 'center'
  },
  comprovanteIcon: {
    width: 25,
    textAlign: 'center',
    marginRight: 5
  }
});

export default ListaUltimasPendencias;
