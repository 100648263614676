import * as React from 'react';
import { DimensionValue, Platform, StyleSheet } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Button,
  Card,
  ErrorMessage,
  Message,
  Text,
  TextBox,
  isTablet,
  formatDate,
  DropDown
} from 'secullum-react-native-ui';
import MapaBatida from '../../components/MapaBatida';
import ModalAvisoInclusaoPontoSuspeita from '../../components/ModalAvisoInclusaoPontoSuspeita';
import Api from '../../modules/api';
import { validarBatidaDentroPerimetro } from '../../modules/geolocation';
import { getTheme } from '../../modules/layout';
import {
  Atividade,
  PerimetroAutorizado,
  QualidadeVidaTrabalho,
  RegistroPontoPendencia,
  RegistroPontoPendenciaStatus,
  TipoCameraFotoPonto,
  TipoExibirPesquisa,
  TipoInclusao,
  TipoPlano,
  TipoRespostaQualidadeVidaTrabalho,
  TipoSolicitacao
} from '../../modules/types';
import ListaUltimasPendencias from './ListaUltimasPendencias';
import ModalConfirmarNovaInclusao from './ModalConfirmarNovaInclusao';
import ModalQualidadeVidaTrabalho from './ModalQualidadeVidaTrabalho';

interface Props {
  coordenadas: {
    latitude: number;
    longitude: number;
    accuracy: number | null;
  } | null;
  endereco: string | null;
  localizacaoStatus: string | null;
  dispositivoAutorizadoInclusaoPonto: boolean;
  identificacaoDispositivo: string | null;
  centralWeb: boolean;
  exibirUltimosRegistros: boolean;
  permiteInclusaoPontoSemLocalizacao: boolean;
  tipoInclusao: TipoInclusao;
  checkLocalizacaoFicticiaAsync?: () => Promise<boolean>;
  onExigirCapturaFoto(tipoCamera: TipoCameraFotoPonto, mensagem: string): void;
  onExibirMapaNovaInclusaoPonto: () => void;
  onErroConexao?: (mensagem: string, onTentarNovamente: () => void) => void;
}

interface State {
  horaServidor: Date;
  justificativaAutomatica: boolean;
  justificativa: string | null;
  exigirCapturaFotoPonto: boolean;
  tipoCameraCapturaFotoPonto: TipoCameraFotoPonto;
  reconhecerFace: boolean;
  fotoTemporaria: string | null;
  carregouOpcoes: boolean;
  exibirControlesInclusaoPonto: boolean;
  funcionarioAfastado: boolean;
  listaUltimasPendencias: Array<RegistroPontoPendencia>;
  exibirModalConfirmacaoNovaInclusao: boolean;
  exibirModalQualidadeVidaTrabalho: boolean;
  erros: { [keyof: string]: string };
  erroGeral: string | null;
  resultado: 'sucesso' | 'erro' | null;
  perimetrosAutorizados: PerimetroAutorizado[];
  foraDoPerimetro: boolean;
  abrirModalAvisoInclusaoPontoSuspeita: boolean;
  somentePerimetrosAutorizados: boolean;
  qualidadeVidaTrabalho: QualidadeVidaTrabalho;
  atividades: Atividade[];
  atividadeId: number;
  localizacaoFicticiaDetectada: boolean;
}

class CardInclusaoPontoAssincrona extends React.Component<Props, State> {
  timeoutAtualizacaoAutomatica: NodeJS.Timeout | null = null;

  state: State = {
    horaServidor: new Date(),
    justificativaAutomatica: true,
    justificativa: null,
    exigirCapturaFotoPonto: false,
    tipoCameraCapturaFotoPonto: TipoCameraFotoPonto.LivreEscolhaFuncionario,
    reconhecerFace: false,
    fotoTemporaria: null,
    carregouOpcoes: false,
    exibirControlesInclusaoPonto: true,
    funcionarioAfastado: false,
    listaUltimasPendencias: [],
    exibirModalConfirmacaoNovaInclusao: false,
    exibirModalQualidadeVidaTrabalho: false,
    erros: { justificativa: '' },
    erroGeral: null,
    resultado: null,
    perimetrosAutorizados: [],
    foraDoPerimetro: false,
    abrirModalAvisoInclusaoPontoSuspeita: false,
    somentePerimetrosAutorizados: false,
    qualidadeVidaTrabalho: {
      habilitado: false,
      pergunta: '',
      respostaBem: '',
      respostaBemDescricao: '',
      respostaExcelente: '',
      respostaExcelenteDescricao: '',
      respostaMal: '',
      respostaMalDescricao: '',
      respostaMuitoBem: '',
      respostaMuitoBemDescricao: '',
      respostaNaoEstouBem: '',
      respostaNaoEstouBemDescricao: '',
      exibirPesquisa: 0
    },
    atividades: [],
    atividadeId: 0,
    localizacaoFicticiaDetectada: false
  };

  async componentDidMount() {
    if (this.props.exibirUltimosRegistros) {
      this.setState({ exibirControlesInclusaoPonto: false });
    }

    await this.carregarInformacoesNovaInclusaoManual({
      exibirControlesInclusaoPonto: false
    });

    await this.carregarUltimasBatidasAsync({
      exibirControlesInclusaoPonto: false
    });

    await this.utilizaLocalizacaoFicticiaAsync();
  }

  componentWillUnmount() {
    if (this.timeoutAtualizacaoAutomatica) {
      clearTimeout(this.timeoutAtualizacaoAutomatica);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { coordenadas, tipoInclusao } = this.props;
    const { perimetrosAutorizados, somentePerimetrosAutorizados } = this.state;

    // Não valida perímetro quando é uma atividade.
    if (tipoInclusao === TipoInclusao.IncluirAtividade) {
      return;
    }

    if (
      coordenadas &&
      prevProps.coordenadas !== coordenadas &&
      somentePerimetrosAutorizados
    ) {
      this.setState({
        foraDoPerimetro: !validarBatidaDentroPerimetro(
          coordenadas.latitude,
          coordenadas.longitude,
          perimetrosAutorizados
        )
      });
    }
  }

  handleChange = (name: string, value: any) => {
    this.setState(state => ({
      ...state,
      [name]: value,
      erros: {
        ...state.erros,
        [name]: ''
      }
    }));
  };

  handleNovaInclusao = async () => {
    const existeRegistroPendente = this.verificarExistenciaRegistroPendente(
      this.state.listaUltimasPendencias
    );

    if (existeRegistroPendente) {
      this.setState({ exibirModalConfirmacaoNovaInclusao: true });
    } else {
      await this.carregarInformacoesNovaInclusaoManual({
        exibirControlesInclusaoPonto: true
      });
    }
  };

  handleModalConfirmarNovaInclusaoCancelar = () => {
    this.setState({ exibirModalConfirmacaoNovaInclusao: false });
  };

  handleModalConfirmarNovaInclusaoContinuar = async () => {
    this.setState({ exibirModalConfirmacaoNovaInclusao: false });

    await this.carregarInformacoesNovaInclusaoManual({
      exibirControlesInclusaoPonto: true
    });
  };

  handleIncluirPontoSucesso = () => {
    this.setState(
      {
        resultado: 'sucesso',
        fotoTemporaria: null,
        exibirControlesInclusaoPonto: false,
        exibirModalQualidadeVidaTrabalho: false
      },
      async () => {
        await this.carregarUltimasBatidasAsync({
          exibirControlesInclusaoPonto: false
        });
      }
    );
  };

  handleModalQualidadeVidaTrabalhoConcluir = async (
    respostaPesquisa: TipoRespostaQualidadeVidaTrabalho
  ) => {
    const api = new Api();

    api.postIncluirRespostaQualidadeVidaTrabalho(
      {
        respostaPesquisa: respostaPesquisa
      },
      {
        onSuccess: () => this.handleIncluirPontoSucesso(),
        onError: err => {
          if (err.name === 'TimeoutError' && this.props.onErroConexao) {
            this.props.onErroConexao(
              err.message,
              async () =>
                await this.handleModalQualidadeVidaTrabalhoConcluir(
                  respostaPesquisa
                )
            );
          } else {
            this.setState({ resultado: 'erro', erroGeral: err.message });
          }
        },
        onValidationError: (errorList, errorObj) =>
          this.setState({ erros: errorObj })
      }
    );
  };

  handleAtualizacaoAutomaticaUltimosRegistros = async () => {
    const listaUltimasPendencias = await this.carregarListaUltimasPendencias();

    if (listaUltimasPendencias === undefined) {
      return;
    }

    const existeRegistroPendente = this.verificarExistenciaRegistroPendente(
      listaUltimasPendencias
    );

    if (existeRegistroPendente) {
      this.timeoutAtualizacaoAutomatica = setTimeout(
        this.handleAtualizacaoAutomaticaUltimosRegistros,
        10 * 1000 // Depois da primeira tentativa, fica tentando a cada 10s
      );
    }
  };

  handleIncluirPonto = async () => {
    await this.utilizaLocalizacaoFicticiaAsync();

    const { tipoInclusao, onExigirCapturaFoto } = this.props;

    const {
      justificativaAutomatica,
      justificativa,
      exigirCapturaFotoPonto,
      tipoCameraCapturaFotoPonto
    } = this.state;

    if (!justificativaAutomatica && !justificativa) {
      this.setState({
        erros: {
          justificativa: translate('Preencha o campo corretamente') + '.'
        }
      });

      return;
    }

    const mensagemCapturarFoto =
      tipoInclusao === TipoInclusao.IncluirPonto
        ? translate(
            'A empresa onde você trabalha solicita uma foto sua a cada registro de ponto.'
          )
        : translate(
            'A empresa onde você trabalha solicita uma foto sua a cada registro de atividade.'
          );

    if (exigirCapturaFotoPonto) {
      onExigirCapturaFoto(tipoCameraCapturaFotoPonto, mensagemCapturarFoto);
    } else {
      this.incluirPonto();
    }
  };

  utilizaLocalizacaoFicticiaAsync = async () => {
    const { checkLocalizacaoFicticiaAsync } = this.props;

    if (
      checkLocalizacaoFicticiaAsync &&
      (await checkLocalizacaoFicticiaAsync())
    ) {
      this.setState({ localizacaoFicticiaDetectada: true });
      return true;
    }

    return false;
  };

  verificarExibirPesquisaQualidadeVidaTrabalho = () => {
    const { qualidadeVidaTrabalho, listaUltimasPendencias } = this.state;

    if (!qualidadeVidaTrabalho.habilitado) {
      return false;
    }

    // Sempre deve exibir a pesquisa QVT por batida
    if (
      qualidadeVidaTrabalho.exibirPesquisa === TipoExibirPesquisa.TodasBatidas
    ) {
      return true;
    }

    if (listaUltimasPendencias.length > 0) {
      const dataPrimeiraBatida = formatDate(
        listaUltimasPendencias[0].dataHora,
        'dd/MM/yyyy'
      );
      const dataHoje = formatDate(new Date(), 'dd/MM/yyyy');

      // Se já respondeu hoje, não precisa mais responder
      if (dataPrimeiraBatida === dataHoje) {
        return false;
      }
    }

    return true;
  };

  incluirPonto = async (fotoCapturada: string | null = null) => {
    const {
      coordenadas,
      endereco,
      centralWeb,
      identificacaoDispositivo,
      permiteInclusaoPontoSemLocalizacao,
      tipoInclusao
    } = this.props;
    const { justificativa, fotoTemporaria, foraDoPerimetro, atividadeId } =
      this.state;

    const foto = fotoCapturada || fotoTemporaria;

    const localizacaoFicticiaDetectada =
      await this.utilizaLocalizacaoFicticiaAsync();

    if (!coordenadas && !permiteInclusaoPontoSemLocalizacao) {
      return;
    }

    await new Api().postIncluirPonto(
      {
        justificativa,
        latitude: coordenadas ? coordenadas.latitude : null,
        longitude: coordenadas ? coordenadas.longitude : null,
        precisao: coordenadas ? coordenadas.accuracy || 0 : null,
        endereco,
        foto,
        marcacaoOffline: false,
        viaCentralWeb: centralWeb,
        identificacaoDispositivo: identificacaoDispositivo,
        foraDoPerimetro: foraDoPerimetro,
        utilizaLocalizacaoFicticia: localizacaoFicticiaDetectada,
        horaFoiModificada: false,
        atividadeId:
          tipoInclusao === TipoInclusao.IncluirAtividade ? atividadeId : null
      },
      {
        esconderSpinner: false
      },
      {
        onSuccess: () => {
          if (this.verificarExibirPesquisaQualidadeVidaTrabalho()) {
            this.setState({ exibirModalQualidadeVidaTrabalho: true });
          } else {
            this.handleIncluirPontoSucesso();
          }
        },
        onError: err => {
          if (err.name === 'TimeoutError' && this.props.onErroConexao) {
            this.props.onErroConexao(err.message, () =>
              this.handleIncluirPonto()
            );
          } else {
            this.setState({
              resultado: 'erro',
              erroGeral: err.message,
              fotoTemporaria: foto,
              exigirCapturaFotoPonto:
                this.verificarExigenciaCapturaFotoPonto(foto)
            });
          }
        },
        onValidationError: (errorList, errorObj) => {
          const erroGeral = errorList.filter(erro => erro.property === null);
          const temErroGeral = erroGeral.length > 0;

          this.setState({
            resultado: temErroGeral ? 'erro' : null,
            erroGeral: temErroGeral ? erroGeral[0].message : null,
            erros: errorObj,
            fotoTemporaria: foto,
            exigirCapturaFotoPonto:
              this.verificarExigenciaCapturaFotoPonto(foto)
          });
        }
      }
    );
  };

  carregarUltimasBatidasAsync = async (options: {
    exibirControlesInclusaoPonto: boolean;
  }) => {
    const listaUltimasPendencias = await this.carregarListaUltimasPendencias(
      () => this.carregarInformacoesNovaInclusaoManual(options)
    );

    // Erro ao carregar lista de pendências
    if (listaUltimasPendencias === undefined) {
      return;
    }

    const existeRegistroPendente = this.verificarExistenciaRegistroPendente(
      listaUltimasPendencias
    );

    if (existeRegistroPendente) {
      this.timeoutAtualizacaoAutomatica = setTimeout(
        this.handleAtualizacaoAutomaticaUltimosRegistros,
        70 * 1000 // 1m10s para dar tempo do servidor processar inicialmente
      );
    }
  };

  carregarInformacoesNovaInclusaoManual = async (options: {
    exibirControlesInclusaoPonto: boolean;
  }) => {
    const api = new Api();

    await api.getInformacoesNovaInclusaoManual({
      onSuccess: async resp => {
        this.setState({
          horaServidor: resp.horaServidor,
          justificativaAutomatica: resp.justificativaAutomatica,
          justificativa: null,
          exigirCapturaFotoPonto:
            api.funcionario.state.dados.plano >= TipoPlano.Pro &&
            resp.exigirCapturaFotoPonto,
          tipoCameraCapturaFotoPonto: resp.tipoCameraCapturaFotoPonto,
          reconhecerFace: resp.reconhecerFace,
          carregouOpcoes: true,
          funcionarioAfastado: resp.funcionarioAfastado,
          somentePerimetrosAutorizados: resp.somentePerimetrosAutorizados,
          perimetrosAutorizados: resp.perimetrosAutorizados,
          qualidadeVidaTrabalho: resp.qualidadeVidaTrabalho,
          atividades: resp.atividades,
          atividadeId: resp.atividades.length > 0 ? resp.atividades[0].id : 0
        });

        if (resp.somentePerimetrosAutorizados && this.props.coordenadas) {
          this.setState({
            foraDoPerimetro: !validarBatidaDentroPerimetro(
              this.props.coordenadas.latitude,
              this.props.coordenadas.longitude,
              resp.perimetrosAutorizados
            )
          });
        }

        if (options.exibirControlesInclusaoPonto) {
          this.setState({ exibirControlesInclusaoPonto: true }, () => {
            this.props.onExibirMapaNovaInclusaoPonto();
          });
        }

        if (
          this.props.tipoInclusao === TipoInclusao.IncluirAtividade &&
          resp.atividades.length === 0
        ) {
          this.setState({
            resultado: 'erro',
            erroGeral: translate(
              'Você não possui permissão para inclusão de atividade'
            )
          });
        }
      },
      onError: (err: Error) => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () =>
            this.carregarInformacoesNovaInclusaoManual({
              exibirControlesInclusaoPonto: options.exibirControlesInclusaoPonto
            })
          );
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      }
    });
  };

  carregarListaUltimasPendencias = async (
    funcaoTentarNovamente?: () => void
  ) => {
    const { tipoInclusao } = this.props;
    let retorno: Array<RegistroPontoPendencia> | undefined;

    const api = new Api();

    await api.getListaUltimasPendencias(tipoInclusao, {
      onSuccess: (lista: Array<RegistroPontoPendencia>) => {
        this.setState({
          listaUltimasPendencias: lista
        });

        retorno = lista;
      },
      onError: (err: Error) => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(
            err.message,
            funcaoTentarNovamente
              ? funcaoTentarNovamente
              : () => this.carregarListaUltimasPendencias()
          );
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      }
    });

    return retorno;
  };

  verificarExigenciaCapturaFotoPonto = (fotoTemporaria: string | null) => {
    return (
      (this.state.exigirCapturaFotoPonto && this.state.reconhecerFace) ||
      (this.state.exigirCapturaFotoPonto && !fotoTemporaria)
    );
  };

  verificarExistenciaRegistroPendente = (
    lista: Array<RegistroPontoPendencia>
  ) => {
    const registroPendente = lista.find(
      x => x.status === RegistroPontoPendenciaStatus.Pendente
    );

    return registroPendente !== undefined;
  };

  alturaMapa = () => {
    const { justificativaAutomatica, foraDoPerimetro } = this.state;
    const { permiteInclusaoPontoSemLocalizacao } = this.props;

    if (permiteInclusaoPontoSemLocalizacao) {
      return undefined;
    }

    // A tipagem do StyleSheet mudou na versão do ReactNative 0.73.4 de "string | number | null" para o tipo DimensionValue
    // que foi pensado para ser usado no ReactNative mobile, onde não aceita string literais como "10px" por exemplo.
    // Porém no web, esses valores são aceitos pelo navegador e funcionam normalmente.
    // Sendo assim, daremos um cast da string para DimensionValue para que o typescript reconheça como um valor válido.
    if (Platform.OS === 'web') {
      return `calc(100vh - ${
        justificativaAutomatica
          ? foraDoPerimetro
            ? 294
            : 262
          : foraDoPerimetro
          ? 372
          : 323
      }px)` as DimensionValue;
    }

    if (isTablet()) {
      return justificativaAutomatica
        ? foraDoPerimetro
          ? 382
          : 414
        : foraDoPerimetro
        ? 307
        : 339;
    } else {
      return justificativaAutomatica
        ? foraDoPerimetro
          ? 294
          : 314
        : foraDoPerimetro
        ? 209
        : 239;
    }
  };

  render() {
    const {
      coordenadas,
      endereco,
      localizacaoStatus,
      dispositivoAutorizadoInclusaoPonto,
      permiteInclusaoPontoSemLocalizacao,
      tipoInclusao
    } = this.props;

    const {
      horaServidor,
      justificativaAutomatica,
      justificativa,
      carregouOpcoes,
      exibirControlesInclusaoPonto,
      funcionarioAfastado,
      listaUltimasPendencias,
      exibirModalConfirmacaoNovaInclusao,
      erros,
      erroGeral,
      resultado,
      foraDoPerimetro,
      localizacaoFicticiaDetectada,
      abrirModalAvisoInclusaoPontoSuspeita,
      exibirModalQualidadeVidaTrabalho,
      atividades,
      atividadeId
    } = this.state;

    const inclusaoAtividade = tipoInclusao === TipoInclusao.IncluirAtividade;
    let mensagemErro: string | null = null;

    if (localizacaoStatus) {
      mensagemErro = localizacaoStatus;
    } else if (funcionarioAfastado) {
      mensagemErro = translate(
        'Você está afastado no dia de hoje. Inclusão de ponto desativada.'
      );
    }

    const mensagemSucesso = inclusaoAtividade
      ? `${translate('Inclusão de atividade efetuada com êxito.')}`
      : `${translate('Inclusão de ponto efetuada com êxito.')}\n\n${translate(
          'Dentro de alguns instantes estará disponível em seu Cartão Ponto.'
        )}`;

    const mensagemDisposivitoAutorizado = inclusaoAtividade
      ? translate(
          'A empresa onde você trabalha não autoriza a inclusão de atividade a partir deste dispositivo.'
        )
      : translate(
          'A empresa onde você trabalha não autoriza a inclusão de ponto a partir deste dispositivo.'
        );

    const mensagemNovaInclusao = inclusaoAtividade
      ? translate(
          'Já existe uma atividade realizada que está aguardando processamento.'
        )
      : translate(
          'Já existe uma inclusão de ponto realizada que está aguardando processamento.'
        );

    const mensagemConfirmacaoNovaInclusao = inclusaoAtividade
      ? translate('Deseja ainda assim incluir um novo registro?')
      : translate(
          'Caso você registre uma nova inclusão, esta poderá ser considerada como batida duplicada.'
        );

    const listaAtividades = inclusaoAtividade
      ? atividades.map((atividade, index) => ({
          nativeID: 'atividade-' + index,
          label: atividade.descricao,
          value: atividade.id
        }))
      : [];

    const ultimasPendencias =
      inclusaoAtividade && atividades.length > 0
        ? listaUltimasPendencias.map(pendencia => {
            if (!pendencia.atividadeId) {
              return pendencia;
            }

            const atividade = atividades.find(
              y => y.id === pendencia.atividadeId
            );

            if (atividade) {
              pendencia.atividadeDescricaoAbreviada =
                atividade.descricaoAbreviada;
            }

            return pendencia;
          })
        : listaUltimasPendencias;

    return (
      <>
        {!dispositivoAutorizadoInclusaoPonto ? (
          <Card>
            <Card.Section>
              <Text
                color={theme.textColor1}
                nativeID="localizacao-mensagem-dispositivo-restrito"
              >
                {mensagemDisposivitoAutorizado}
                {'\n\n'}
                {translate('Por favor, entre em contato com sua empresa.')}
              </Text>
            </Card.Section>
          </Card>
        ) : mensagemErro ? (
          <Card>
            <Card.Section style={styles.sectionSemAcessoLocalizacao}>
              <Text
                nativeID="localizacao-mensagem-erro"
                bold
                color={theme.errorColor}
                size={isTablet() ? 30 : 26}
              >
                {mensagemErro}
              </Text>
            </Card.Section>
          </Card>
        ) : carregouOpcoes ? (
          <>
            {exibirControlesInclusaoPonto && (
              <Card>
                <Card.Section style={styles.sectionMapa}>
                  <MapaBatida
                    coordenadas={coordenadas}
                    endereco={endereco}
                    mostrarRelogio={true}
                    data={horaServidor}
                    alturaMapa={this.alturaMapa()}
                    utilizaLocalizacaoFicticia={localizacaoFicticiaDetectada}
                    foraDoPerimetro={foraDoPerimetro}
                  />
                </Card.Section>
                {!justificativaAutomatica && (
                  <Card.Section style={styles.sectionJustificativa}>
                    <TextBox
                      nativeID="localizacao-justificativa"
                      label={translate('Justificativa')}
                      value={justificativa || ''}
                      onChange={(justificativa: string) => {
                        this.handleChange('justificativa', justificativa);
                      }}
                      returnKeyType={'done'}
                      maxLength={100}
                    />
                    <ErrorMessage
                      nativeID="localizacao-erro-justificativa"
                      message={erros.justificativa}
                    />
                  </Card.Section>
                )}
                {inclusaoAtividade && (
                  <Card.Section style={styles.sectionJustificativa}>
                    <DropDown
                      nativeID="localizacao-tipo-atividade"
                      label={translate('Tipo de Atividade')}
                      items={listaAtividades}
                      value={atividadeId}
                      onChange={atividadeId =>
                        this.handleChange('atividadeId', atividadeId)
                      }
                    />
                  </Card.Section>
                )}
                <Card.Section
                  style={Platform.OS === 'web' && styles.sectionButton}
                >
                  <Button
                    nativeID="localizacao-incluir-ponto"
                    text={
                      inclusaoAtividade
                        ? translate('Incluir Atividade')
                        : translate('Incluir Ponto')
                    }
                    style={Platform.OS === 'web' && styles.button}
                    disabled={
                      (!coordenadas && !permiteInclusaoPontoSemLocalizacao) ||
                      (inclusaoAtividade && atividades.length === 0)
                    }
                    onPress={() =>
                      foraDoPerimetro || localizacaoFicticiaDetectada
                        ? this.setState({
                            abrirModalAvisoInclusaoPontoSuspeita: true
                          })
                        : this.handleIncluirPonto()
                    }
                  />
                </Card.Section>
              </Card>
            )}

            <ListaUltimasPendencias
              onNovaInclusao={this.handleNovaInclusao}
              onAtualizar={this.carregarListaUltimasPendencias}
              listaUltimasPendencias={ultimasPendencias}
              exbirBotaoNovaInclusao={!exibirControlesInclusaoPonto}
            />
          </>
        ) : null}

        <ModalAvisoInclusaoPontoSuspeita
          visible={abrirModalAvisoInclusaoPontoSuspeita}
          onCancelar={() => {
            this.setState({ abrirModalAvisoInclusaoPontoSuspeita: false });
          }}
          onConcluir={() => {
            this.setState({ abrirModalAvisoInclusaoPontoSuspeita: false });
            this.handleIncluirPonto();
          }}
          tipoAviso={
            localizacaoFicticiaDetectada
              ? TipoSolicitacao.UtilizaLocalizacaoFicticia
              : TipoSolicitacao.ForaDoPerimetro
          }
        />

        <Message
          nativeID="localizacao-sucesso"
          message={mensagemSucesso}
          textStyle={styles.textoSucesso}
          visible={resultado === 'sucesso'}
          onRequestClose={() => {
            this.setState({ resultado: null });
          }}
        />
        <Message
          nativeID="localizacao-erro"
          type="warning"
          message={erroGeral ? erroGeral : ''}
          visible={resultado === 'erro'}
          onRequestClose={() => {
            this.setState({ resultado: null, erroGeral: '' });
          }}
        />

        <ModalConfirmarNovaInclusao
          mensagem={mensagemNovaInclusao}
          mensagemConfirmacao={mensagemConfirmacaoNovaInclusao}
          visivel={exibirModalConfirmacaoNovaInclusao}
          onCancelar={this.handleModalConfirmarNovaInclusaoCancelar}
          onContinuar={this.handleModalConfirmarNovaInclusaoContinuar}
        />
        {this.state.qualidadeVidaTrabalho && (
          <ModalQualidadeVidaTrabalho
            dados={this.state.qualidadeVidaTrabalho}
            visivel={exibirModalQualidadeVidaTrabalho}
            onConcluir={this.handleModalQualidadeVidaTrabalhoConcluir}
          />
        )}
      </>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  sectionSemAcessoLocalizacao: {
    paddingVertical: 100,
    alignItems: 'center',
    justifyContent: 'center'
  },
  sectionMapa: {
    padding: 0
  },
  sectionJustificativa: {
    paddingVertical: 10
  },
  sectionButton: {
    alignItems: 'center'
  },
  textoSucesso: {
    textAlign: 'center',
    fontSize: isTablet() ? 25 : 16
  },
  button: {
    width: '100%',
    maxWidth: 350
  }
});

export default CardInclusaoPontoAssincrona;
