export function loadFonts(fonts: Array<{ family: string; url: string }>) {
  const css = fonts
    .map(
      ({ family, url }) =>
        `@font-face { src: url(${url}); font-family: ${family}; }\n`
    )
    .reduce((acc, x) => acc + x);

  const style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = css;

  document.head.appendChild(style);
}
