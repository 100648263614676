import * as React from 'react';
import { ScrollView } from 'react-native';
import { translate } from 'secullum-i18n';
import Api from '../../../shared/modules/api';
import { reverseGeocodeAsync } from '../../../shared/modules/geolocation';
import {
  TipoCameraFotoPonto,
  TipoInclusao
} from '../../../shared/modules/types';
import { isFirefox } from '../../../shared/modules/utils';
import CardInclusaoPontoAssincrona from '../../../shared/screens/incluir-ponto/CardInclusaoPontoAssincrona';
import ModalCapturarFoto from '../../../shared/screens/incluir-ponto/ModalCapturarFoto';
import LoggedInScreen from '../../components/LoggedInScreen';
import {
  BROWSER_NO_SUPPORT_GEOLOCATION,
  getGeolocationError
} from '../../modules/geolocation';
import { RouteComponentComBancoIdProps } from '../../modules/routes';

interface State {
  coordenadas: {
    latitude: number;
    longitude: number;
    accuracy: number;
  } | null;
  endereco: string | null;
  localizacaoStatus: string | null;
  dispositivoAutorizadoInclusaoPonto: boolean;
  identificacaoDispositivo: string | null;
  capturandoFoto: boolean;
  mensagemCapturarFoto: string;
}

interface Props extends RouteComponentComBancoIdProps {}

class IncluirAtividade extends React.Component<Props, State> {
  cardInclusaoAssincrona = React.createRef<CardInclusaoPontoAssincrona>();
  scrollView: ScrollView | null = null;

  state: State = {
    coordenadas: null,
    endereco: null,
    localizacaoStatus: null,
    capturandoFoto: false,
    dispositivoAutorizadoInclusaoPonto: false,
    identificacaoDispositivo: null,
    mensagemCapturarFoto: ''
  };

  locationSubscription: number | null = null;
  navigatorIsFirefox = isFirefox();

  async componentDidMount() {
    const inclusaoDados = await this.verificarDispositivoAutorizadoAsync();

    this.setState({
      dispositivoAutorizadoInclusaoPonto: inclusaoDados.dispositivoAutorizado,
      identificacaoDispositivo: inclusaoDados.identificacaoDispositivo
    });

    if (!inclusaoDados.dispositivoAutorizado) {
      return;
    }

    if (this.verificarInclusaoPontoSemLocalizacao()) {
      return;
    }

    this.startGeolocationWatch();

    // O Firefox não permite realizar watchPosition sem foco na tela, ocasionando timeout.
    // Com base nisso adicionamos um EventListener para parar o watchPosition quando a tela
    // perde foco e retomá-lo quando o foco é recuperado.
    if (this.navigatorIsFirefox) {
      document.addEventListener(
        'visibilitychange',
        this.handleVisibilityChange
      );
    }
  }

  verificarDispositivoAutorizadoAsync = async (): Promise<{
    identificacaoDispositivo: string;
    dispositivoAutorizado: boolean;
  }> => {
    const api = new Api();
    const { dados } = api.funcionario.state;

    if (!dados.permiteInclusaoPontoDispositivosAutorizados) {
      return {
        identificacaoDispositivo: dados.loginEnderecoIp,
        dispositivoAutorizado: true
      };
    }

    const dispositivoAutorizado =
      dados.listaDispositivosAutorizadosIdentificacao.includes(
        dados.loginEnderecoIp
      );

    return {
      identificacaoDispositivo: dados.loginEnderecoIp,
      dispositivoAutorizado
    };
  };

  componentWillUnmount() {
    if (this.locationSubscription) {
      navigator.geolocation.clearWatch(this.locationSubscription);
    }

    if (this.navigatorIsFirefox) {
      document.removeEventListener(
        'visibilitychange',
        this.handleVisibilityChange
      );
    }
  }

  verificarInclusaoPontoSemLocalizacao = () => {
    const api = new Api();

    return api.funcionario.state.dados.permiteInclusaoPontoSemLocalizacao;
  };

  handleLocationChange = async (coords: GeolocationCoordinates) => {
    const { coordenadas } = this.state;

    if (
      coordenadas !== null &&
      coordenadas.latitude === coords.latitude &&
      coordenadas.longitude === coords.longitude &&
      coordenadas.accuracy === coords.accuracy
    ) {
      return;
    }

    const endereco = await reverseGeocodeAsync(
      coords.latitude,
      coords.longitude
    );

    this.setState({
      coordenadas: {
        latitude: coords.latitude,
        longitude: coords.longitude,
        accuracy: coords.accuracy
      },
      endereco
    });
  };

  handleConcluirCapturaFotoAsync = async (foto: string) => {
    this.setState({ capturandoFoto: false });

    if (this.cardInclusaoAssincrona.current) {
      this.cardInclusaoAssincrona.current.incluirPonto(foto);
    }
  };

  handleVisibilityChange = () => {
    if (document.hidden) {
      if (this.locationSubscription) {
        navigator.geolocation.clearWatch(this.locationSubscription);
        this.locationSubscription = null;
      }
    } else {
      this.startGeolocationWatch();
    }
  };

  startGeolocationWatch = () => {
    if (!navigator.geolocation) {
      this.setState({
        localizacaoStatus: translate(BROWSER_NO_SUPPORT_GEOLOCATION)
      });
      return;
    }

    this.locationSubscription = navigator.geolocation.watchPosition(
      success => {
        this.handleLocationChange(success.coords);
      },
      error => {
        this.setState({ localizacaoStatus: getGeolocationError(error) });
      },
      { enableHighAccuracy: true, timeout: 30000, maximumAge: 5000 }
    );
  };

  render() {
    const {
      coordenadas,
      endereco,
      localizacaoStatus,
      capturandoFoto,
      dispositivoAutorizadoInclusaoPonto,
      identificacaoDispositivo,
      mensagemCapturarFoto
    } = this.state;

    const { location } = this.props;

    return (
      <LoggedInScreen screenTitle={translate('Incluir Ponto')}>
        <ScrollView ref={ref => (this.scrollView = ref)}>
          <CardInclusaoPontoAssincrona
            ref={this.cardInclusaoAssincrona}
            tipoInclusao={TipoInclusao.IncluirAtividade}
            coordenadas={coordenadas}
            endereco={endereco}
            localizacaoStatus={localizacaoStatus}
            dispositivoAutorizadoInclusaoPonto={
              dispositivoAutorizadoInclusaoPonto
            }
            identificacaoDispositivo={identificacaoDispositivo}
            centralWeb={true}
            exibirUltimosRegistros={
              location.search.indexOf('ultimos-registros=1') >= 0
            }
            permiteInclusaoPontoSemLocalizacao={this.verificarInclusaoPontoSemLocalizacao()}
            onExigirCapturaFoto={(tipoCamera, mensagem) => {
              this.setState({
                capturandoFoto: true,
                mensagemCapturarFoto: mensagem
              });
            }}
            onExibirMapaNovaInclusaoPonto={() => {
              this.scrollView!.scrollTo({ y: 0 });
            }}
          />
          {capturandoFoto && (
            <ModalCapturarFoto
              mensagem={mensagemCapturarFoto}
              mensagemTimeout={translate(
                'Tempo esgotado para a Inclusão de {0}',
                translate('Atividade')
              )}
              onConcluir={this.handleConcluirCapturaFotoAsync}
              onCancelar={() => this.setState({ capturandoFoto: false })}
              tipoCamera={TipoCameraFotoPonto.LivreEscolhaFuncionario}
            />
          )}
        </ScrollView>
      </LoggedInScreen>
    );
  }
}

export default IncluirAtividade;
