import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CardRecuperacaoSenha, {
  CardRecuperacaoSenhaProps
} from '../../../shared/components/CardRecuperacaoSenha';

interface Props extends CardRecuperacaoSenhaProps, RouteComponentProps {}

class RecuperarSenhaBase extends React.Component<Props> {
  render() {
    const { history } = this.props;

    return (
      <View style={styles.container}>
        <View style={styles.cardForm}>
          <CardRecuperacaoSenha
            {...this.props}
            onNavegar={() => history.replace('/')}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: '#59CBE8',
    paddingBottom: 0,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  cardForm: {
    flexDirection: 'column',
    flexBasis: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 345
  }
});

export default withRouter(RecuperarSenhaBase);
