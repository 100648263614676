import * as React from 'react';
import { StyleSheet } from 'react-native';
import { translate } from 'secullum-i18n';
import { Card, Text, getTheme, isTablet } from 'secullum-react-native-ui';

class CardAvisoSemAcessoTelas extends React.Component {
  render() {
    return (
      <Card>
        <Card.Section style={styles.sectionAvisoSemAcessoTelas}>
          <Text
            nativeID="aviso-sem-acesso-telas"
            color={theme.errorColor}
            bold
            size={isTablet() ? 30 : 26}
          >
            {translate(
              'Seu acesso às telas está restrito. Contate o administrador do sistema.'
            )}
          </Text>
        </Card.Section>
      </Card>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  sectionAvisoSemAcessoTelas: {
    paddingVertical: 100,
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default CardAvisoSemAcessoTelas;
