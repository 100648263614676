import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { translate } from 'secullum-i18n';
import { Button, Modal, Space, Text, isTablet } from 'secullum-react-native-ui';
import { getTheme, isDesktop } from '../../modules/layout';

interface Props {
  visivel: boolean;
  mensagem: string;
  mensagemConfirmacao: string;
  onCancelar: () => void;
  onContinuar: () => void;
}

class ModalConfirmarNovaInclusao extends React.Component<Props> {
  render() {
    const { mensagem, mensagemConfirmacao, visivel, onCancelar, onContinuar } =
      this.props;

    return (
      <Modal
        visible={visivel}
        overlayStyle={styles.overlay}
        onRequestClose={onCancelar}
      >
        <View style={styles.container}>
          <FontAwesome
            name="warning"
            color={theme.warningColor}
            size={isTablet() ? 52 : 42}
            style={{ textAlign: 'center' }}
          />
          <Space height={7} />
          <Text style={styles.text}>{mensagem}</Text>
          <Space />
          <Text style={styles.text}>{mensagemConfirmacao}</Text>
          <Space />
          <View style={styles.buttonContainer}>
            <Button
              text={translate('Cancelar')}
              nativeID="confirmar-nova-inclusao-cancelar"
              style={[styles.button, { marginRight: isTablet() ? 13 : 8 }]}
              onPress={onCancelar}
              primary={false}
            />
            <Button
              text={translate('Continuar')}
              nativeID="confirmar-nova-inclusao-continuar"
              style={[styles.button, { marginLeft: isTablet() ? 13 : 8 }]}
              onPress={onContinuar}
            />
          </View>
        </View>
      </Modal>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    alignItems: 'center'
  },
  container: {
    backgroundColor: theme.backgroundColor1,
    borderRadius: 6,
    padding: isTablet() ? 25 : 16,
    marginTop: isTablet() ? 250 : 140,
    width: isDesktop() || isTablet() ? 400 : 300
  },
  text: {
    color: theme.textColor3,
    fontFamily: theme.fontFamily1,
    textAlign: 'center',
    fontSize: isTablet() ? 25 : 16
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%'
  },
  button: {
    flex: 1
  }
});

export default ModalConfirmarNovaInclusao;
