import * as React from 'react';
import { Dimensions, StyleSheet, View, Platform } from 'react-native';
import { translate, translateFirstUpper } from 'secullum-i18n';
import {
  Card,
  RangeDatePicker,
  RadioGroup,
  isTablet,
  Text
} from 'secullum-react-native-ui';
import { VictoryAxis, VictoryChart, VictoryLine } from 'victory-native';
import { IndicadoresDados } from '../../shared/modules/types';
import {
  convertDecimalHourToHour,
  monthNames,
  getWeekNumber,
  converterDataParaTimezoneBrasil
} from '../../shared/modules/utils';
import { ehIE } from '../../web/modules/browser';
import { getTheme, isHighResolution } from '../modules/layout';

interface CardSaldoBancoDeHorasProps {
  positivo: boolean;
  visualizacoes: IndicadoresDados;
  dataInicial: Date;
  dataFinal: Date;
  nativeID?: string;
  onDataInicialChange: (date: Date) => void;
  onDataFinalChange: (date: Date) => void;
  onEndDateCancel: () => void;
}

interface State {
  tipoVisualizacao: string;
  width: number;
}

class CardSaldoBancoDeHoras extends React.Component<CardSaldoBancoDeHorasProps> {
  state: State = {
    tipoVisualizacao: 'dia',
    width: Dimensions.get('window').width - 16 * 2
  };

  handleTipoVisualizacaoChange = (tipoVisualizacao: string) => {
    this.setState({ tipoVisualizacao });
  };

  handleOnLayout = () => {
    this.setState({ width: Dimensions.get('window').width - 16 * 2 });
  };

  formatarLabels = (value: Date): string => {
    switch (this.state.tipoVisualizacao) {
      case 'dia':
        return `${value.getDate()}/${value.getMonth() + 1}`;
      case 'semana':
        return `Sem${getWeekNumber(value)}`;
      default:
        return `${monthNames[value.getMonth()]}/${value
          .getFullYear()
          .toString()
          .substr(-2)}`;
    }
  };

  render() {
    const tiposVisualizacoes: Array<{ label: string; value: string }> = [
      { label: translate('Dia'), value: 'dia' },
      { label: translate('Semana'), value: 'semana' },
      { label: translateFirstUpper('Mês'), value: 'mes' }
    ];

    const visualizacao = this.props.visualizacoes[this.state.tipoVisualizacao];

    const total =
      visualizacao.valores.length > 0
        ? visualizacao.valores[visualizacao.valores.length - 1].y
        : 0;

    const cor = total >= 0 ? theme.successColor : theme.errorColor;

    return (
      <Card
        style={[
          styles.cardBancoDeHorasMargin,
          Platform.OS === 'web' && styles.cardBancoDeHoras,
          { width: Platform.OS === 'web' && isHighResolution() ? 342 : '100%' }
        ]}
      >
        <Card.Header
          nativeID={this.props.nativeID}
          title={translate('Saldo do Banco de Horas')}
        />
        <Card.Section style={styles.section}>
          <View style={styles.wrapperCampos}>
            <RadioGroup
              items={tiposVisualizacoes}
              value={this.state.tipoVisualizacao}
              onChange={this.handleTipoVisualizacaoChange}
            />
          </View>
          {visualizacao.valores.length > 1 ? (
            <>
              <Text
                bold
                size={isTablet() ? 64 : ehIE() ? 40 : 55}
                color={cor}
                align={'center'}
                style={styles.graficoSaldo}
              >
                {(total > 0 ? '+' : '') +
                  (this.props.visualizacoes.exibirHorasEmDecimal
                    ? total.toFixed(2).replace('.', ',')
                    : convertDecimalHourToHour(total))}
              </Text>
              <View
                style={{
                  height: ehIE() ? 117 : undefined,
                  pointerEvents: 'none'
                }}
                onLayout={this.handleOnLayout}
              >
                <VictoryChart
                  padding={
                    Platform.OS === 'web'
                      ? { right: 5, bottom: 12, left: 5 }
                      : { top: 10, right: 25, bottom: 30, left: 25 }
                  }
                  height={Platform.OS === 'web' ? 94 : 200}
                  width={
                    Platform.OS === 'web' && isHighResolution()
                      ? 342
                      : this.state.width
                  }
                  domainPadding={Platform.OS === 'web' ? 25 : 10}
                >
                  <VictoryAxis
                    orientation="top"
                    offsetY={Platform.OS === 'web' ? 106 : 200}
                    tickValues={
                      visualizacao.valores.length <= 6
                        ? visualizacao.valores.map(dia => dia.x)
                        : undefined
                    }
                    tickFormat={tick => this.formatarLabels(new Date(tick))}
                    style={{
                      axis: {
                        stroke: 'transparent'
                      },
                      tickLabels: {
                        fill: theme.textColor3,
                        fontSize: Platform.OS === 'web' ? 12 : 10
                      }
                    }}
                  />
                  <VictoryLine
                    data={visualizacao.valores}
                    style={{
                      data: {
                        stroke: cor,
                        strokeWidth: Platform.OS === 'web' ? 3 : 4
                      }
                    }}
                  />
                </VictoryChart>
              </View>
            </>
          ) : (
            <Text
              bold
              align={'center'}
              size={isTablet() ? 24 : 20}
              style={styles.mensagemGrafico}
            >
              {translate('Não há dados suficientes para montar o gráfico')}
            </Text>
          )}

          <View style={styles.wrapperCampos}>
            <RangeDatePicker
              nativeID={`${this.props.nativeID}-data`}
              label={translate('Insira o Período')}
              startDate={converterDataParaTimezoneBrasil(
                this.props.dataInicial
              )}
              endDate={converterDataParaTimezoneBrasil(this.props.dataFinal)}
              onStartDateChange={this.props.onDataInicialChange}
              onEndDateChange={this.props.onDataFinalChange}
              onEndDateCancel={this.props.onEndDateCancel}
            />
          </View>
        </Card.Section>
      </Card>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  cardBancoDeHorasMargin: {
    marginTop: 16
  },
  cardBancoDeHoras: {
    height: 342,
    marginRight: 16
  },
  section: {
    padding: 0
  },
  wrapperCampos: {
    padding: Platform.OS === 'web' ? 8 : isTablet() ? 20 : 16
  },
  graficoSaldo: {
    paddingVertical: 5
  },
  mensagemGrafico: {
    paddingVertical: 5,
    paddingHorizontal: 16,
    height: Platform.OS === 'web' ? 170 : undefined,
    display: Platform.OS === 'web' ? 'flex' : undefined,
    alignItems: Platform.OS === 'web' ? 'center' : undefined
  }
});

export default CardSaldoBancoDeHoras;
