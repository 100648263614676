export const primeiraLetraMaisculaCadaPalavra = (palavra: string) => {
  return palavra.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const somenteNumeros = (str: string) => {
  return str.replace(/[^\d]/g, '');
};

export const getCurrentDateTimeString = (daysToAdd: number = 0) => {
  const dataAtual = new Date();

  dataAtual.setDate(dataAtual.getDate() + daysToAdd);

  return dataAtual.toString();
};
