import * as React from 'react';
import { View, FlatList, StyleSheet } from 'react-native';
import { withRouter } from 'react-router-dom';
import {
  Space,
  Modal,
  ImageButton,
  Card,
  formatDate
} from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import { FuncionarioContainer } from '../../../shared/containers/FuncionarioContainer';
import { getTheme, isHighResolution } from '../../../shared/modules/layout';
import { BatidasDados } from '../../../shared/modules/types';
import DetalheDiaBatida, {
  TipoDeTela
} from '../../../shared/screens/cartao-ponto/DetalheDiaBatida';
import { RouteComponentComBancoIdProps, navegar } from '../../modules/routes';
import CardDiaResumido from './CardDiaResumido';
import HeaderDiaResumido from './HeaderDiaResumido';

interface Props extends RouteComponentComBancoIdProps {
  key: number;
  listaOrdenada: BatidasDados[] | null;
  tipoTela: TipoDeTela;
  funcionarioId?: number;
}

interface State {
  indice: number;
  modal: boolean;
}

class CartaoPontoResumido extends React.Component<Props, State> {
  state: State = {
    indice: 0,
    modal: false
  };

  handleDetalhesClick = (indice: number) => {
    this.setState({ modal: true, indice });
  };

  handleAjustarPontoClick = (data: Date) => {
    let path = `/ajustar-ponto/${formatDate(data, 'yyyy-MM-dd')}`;

    if (this.props.funcionarioId) {
      path += `/${this.props.funcionarioId}`;
    }

    navegar({
      props: this.props,
      path
    });
  };

  handleJustificarClick = (data: Date) => {
    let path = `/justificar-ausencia/${formatDate(data, 'yyyy-MM-dd')}`;

    if (this.props.funcionarioId) {
      path += `/${this.props.funcionarioId}`;
    }

    navegar({
      props: this.props,
      path
    });
  };

  render() {
    const { listaOrdenada, key, tipoTela } = this.props;
    const { indice } = this.state;

    if (!listaOrdenada) {
      return null;
    }

    const onNextPress =
      indice > 0
        ? () => this.setState({ indice: this.state.indice - 1 })
        : undefined;

    const onPrevPress =
      indice < listaOrdenada.length - 1
        ? () => this.setState({ indice: this.state.indice + 1 })
        : undefined;

    return (
      <View style={styles.cartaoPontoCard}>
        {this.state.modal && (
          <Modal visible={this.state.modal} overlayStyle={styles.modal}>
            <View style={styles.modalContainer}>
              <ImageButton
                nativeID="cartao-ponto-resumido-fechar"
                style={styles.imageButton}
                icon="close"
                onPress={() => this.setState({ modal: false })}
              />
              <View
                nativeID="cartao-ponto-resumido-dados"
                style={styles.modalSection}
              >
                <DetalheDiaBatida
                  dados={listaOrdenada[indice]}
                  tipoDeTela={tipoTela}
                  onLeftPress={
                    // Quando está na tela de "Acompanhamento de ponto", a ordem de navegação é por funcionário, com isso a navegação deve começar da direta para esquerda
                    // Quando está na tela de "Cartão Ponto", a ordem de navegação é por data e a ordem de navegação dos botões deve começar da esquerda para a direita
                    tipoTela === TipoDeTela.PontoDiario
                      ? onNextPress
                      : onPrevPress
                  }
                  onRightPress={
                    tipoTela === TipoDeTela.PontoDiario
                      ? onPrevPress
                      : onNextPress
                  }
                />
              </View>
            </View>
          </Modal>
        )}
        <Space height={10} />
        <FlatList
          ListHeaderComponent={
            isHighResolution() ? (
              <>
                <Card style={styles.headerCard}>
                  <Card.Section style={styles.headerSection}>
                    <Subscribe to={[FuncionarioContainer]}>
                      {(funcionario: FuncionarioContainer) => (
                        <HeaderDiaResumido
                          tipoDeTela={tipoTela}
                          usaPonto10Batidas={
                            funcionario.state.dados.usaPonto10Batidas
                          }
                          tipoPerfilFuncionario={
                            funcionario.state.dados.dadosPerfilFuncionario
                              ? funcionario.state.dados.dadosPerfilFuncionario
                                  .tipoPerfil
                              : funcionario.state.dados.perfil
                          }
                          tipoPlanoFuncionario={funcionario.state.dados.plano}
                        />
                      )}
                    </Subscribe>
                  </Card.Section>
                </Card>
                <Space />
              </>
            ) : null
          }
          key={key}
          data={listaOrdenada}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => (
            <>
              <CardDiaResumido
                indice={index}
                data={item.data}
                batidas={item.batidas}
                saldo={item.saldo}
                situacao={item.situacao}
                registroPendente={item.registroPendente}
                existePeriodoEncerrado={item.existePeriodoEncerrado}
                tipoDeTela={tipoTela}
                onDetalhesClick={this.handleDetalhesClick}
                onAjustarPontoClick={this.handleAjustarPontoClick}
                onJustificarClick={this.handleJustificarClick}
              />
              <Space />
            </>
          )}
        />
      </View>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  cartaoPontoCard: {
    marginHorizontal: -3
  },
  headerCard: {
    marginHorizontal: 3
  },
  headerSection: {
    paddingVertical: 8
  },
  imageButton: {
    borderWidth: 0,
    marginLeft: 'auto'
  },
  modal: {
    overflow: 'scroll'
  },
  modalContainer: {
    margin: 'auto',
    width: '90%',
    maxWidth: 366,
    backgroundColor: theme.backgroundColor2,
    borderRadius: 10,
    justifyContent: 'center',
    marginBottom: 10,
    marginTop: 10
  },
  modalSection: {
    width: '90%',
    alignSelf: 'center',
    marginBottom: 25
  }
});

export default withRouter(CartaoPontoResumido);
