import * as React from 'react';
import {
  StyleSheet,
  Pressable,
  ImageSourcePropType,
  Image,
  View
} from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Card,
  DetailsHeader,
  getTheme,
  isTablet,
  Link,
  Space,
  Text
} from 'secullum-react-native-ui';
import {
  QualidadeVidaTrabalho,
  TipoRespostaQualidadeVidaTrabalho
} from '../../modules/types';

const imagemExelente = require('../../images/humor-excelente.png');
const imagemMuitoBem = require('../../images/humor-muito-bem.png');
const imagemBem = require('../../images/humor-bem.png');
const imagemNaoEstouBem = require('../../images/humor-nao-estou-bem.png');
const imagemMal = require('../../images/humor-mal.png');

interface QualidadeVidaTrabalhoOpcoesProps {
  dados: QualidadeVidaTrabalho;
  onConcluir: (resposta: TipoRespostaQualidadeVidaTrabalho) => void;
}

interface QualidadeVidaTrabalhoOpcaoProps {
  text: string;
  image: ImageSourcePropType;
  onPress: () => void;
}

function QualidadeVidaTrabalhoOpcao({
  text,
  image,
  onPress
}: QualidadeVidaTrabalhoOpcaoProps) {
  return (
    <Card>
      <Pressable
        onPress={onPress}
        style={({ pressed }) => [{ opacity: pressed ? 0.7 : 1 }]}
      >
        <Card.Section style={styles.opcaoContainer}>
          <Image style={styles.opcaoImagem} source={image} />
          <Text style={styles.opcaoText}>{text}</Text>
        </Card.Section>
      </Pressable>
    </Card>
  );
}

class QualidadeVidaTrabalhoOpcoes extends React.Component<QualidadeVidaTrabalhoOpcoesProps> {
  render() {
    const { dados, onConcluir } = this.props;

    return (
      <View style={styles.container}>
        <DetailsHeader text={dados.pergunta} />
        <Space />
        <QualidadeVidaTrabalhoOpcao
          text={dados.respostaExcelente}
          image={imagemExelente}
          onPress={() =>
            onConcluir(TipoRespostaQualidadeVidaTrabalho.Excelente)
          }
        />
        <Space />
        <QualidadeVidaTrabalhoOpcao
          text={dados.respostaMuitoBem}
          image={imagemMuitoBem}
          onPress={() => onConcluir(TipoRespostaQualidadeVidaTrabalho.MuitoBem)}
        />
        <Space />
        <QualidadeVidaTrabalhoOpcao
          text={dados.respostaBem}
          image={imagemBem}
          onPress={() => onConcluir(TipoRespostaQualidadeVidaTrabalho.Bem)}
        />
        <Space />
        <QualidadeVidaTrabalhoOpcao
          text={dados.respostaNaoEstouBem}
          image={imagemNaoEstouBem}
          onPress={() =>
            onConcluir(TipoRespostaQualidadeVidaTrabalho.NaoEstouBem)
          }
        />
        <Space />
        <QualidadeVidaTrabalhoOpcao
          text={dados.respostaMal}
          image={imagemMal}
          onPress={() => onConcluir(TipoRespostaQualidadeVidaTrabalho.Mal)}
        />
        <Space />
        <Link
          style={styles.linkText}
          text={translate('Não responder')}
          onPress={() =>
            onConcluir(TipoRespostaQualidadeVidaTrabalho.NaoRespondeu)
          }
        />
      </View>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.backgroundColor1,
    borderRadius: 6,
    padding: isTablet() ? 25 : 16,
    width: '100%',
    height: '100%'
  },
  linkText: {
    fontSize: 20,
    textDecorationLine: 'underline',
    textAlign: 'center'
  },
  opcaoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 5
  },
  opcaoText: {
    fontFamily: theme.fontFamily1,
    fontSize: isTablet() ? 22 : 18,
    color: theme.textColor1,
    width: '80%',
    paddingLeft: 20,
    textAlign: 'left'
  },
  opcaoImagem: {
    width: 60,
    height: 60,
    marginLeft: 20
  }
});

export default QualidadeVidaTrabalhoOpcoes;
