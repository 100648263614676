import * as React from 'react';
import { StyleSheet, Platform } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Card,
  Message,
  Space,
  TextBox,
  ErrorMessage
} from 'secullum-react-native-ui';
import { Form } from '@secullum/react-native-autofocus';
import { ButtonBar } from '../../components/ButtonBar';
import Api from '../../modules/api';
import {
  salvarDadosLoginAsync,
  carregarDadosLoginAsync
} from '../../modules/auth';
import { AtualizarSenhaDados } from '../../modules/types';

interface State {
  resultado: 'sucesso' | 'erro' | null;
  erroGeral: string;
  dados: AtualizarSenhaDados;
  erros: { [key: string]: string };
}

interface Props {
  onCancelPress(): void;
  onRequestSuccess(): void;
  onErroConexao?: (mensagem: string, onTentarNovamente: () => void) => void;
}

class CardAlterarSenha extends React.Component<Props, State> {
  state: State = {
    resultado: null,
    dados: {
      senhaAtual: '',
      novaSenha: '',
      confirmarSenha: ''
    },
    erroGeral: '',
    erros: {}
  };

  handleChange = (name: string, value: any) => {
    let novosDados = {
      ...this.state.dados,
      [name]: value
    };

    this.setState({
      dados: novosDados,
      erros: {
        ...this.state.erros,
        [name]: ''
      }
    });
  };

  handleSucesso() {
    this.setState({ resultado: null }, () => {
      this.props.onRequestSuccess();
    });
  }

  handleEnviar = () => {
    const api = new Api();
    const { dados } = this.state;

    api.postAtualizarSenha(dados, {
      onSuccess: async () => {
        const dadosLogin = await carregarDadosLoginAsync();

        if (dadosLogin != null) {
          await salvarDadosLoginAsync({
            funcionarioNumero: dadosLogin.funcionarioNumero,
            funcionarioSenha: dados.novaSenha,
            bancoId: dadosLogin.bancoId,
            funcionarioId: dadosLogin.funcionarioId,
            funcionarioSenhaBiometria: dadosLogin.funcionarioSenhaBiometria,
            acessaComBiometria: dadosLogin.acessaComBiometria,
            tipoUsuarioAutenticacao: dadosLogin.tipoUsuarioAutenticacao
          });
        }

        this.setState({
          resultado: 'sucesso'
        });
      },
      onError: err => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () => this.handleEnviar());
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      },
      onValidationError: (errorList, errorObj) => {
        this.setState({
          erros: errorObj
        });
      }
    });
  };

  render() {
    const { resultado, dados, erros, erroGeral } = this.state;

    return (
      <Card>
        <Card.Section style={Platform.OS == 'web' && styles.section}>
          <Form focusOn={[TextBox]}>
            <TextBox
              nativeID="alterar-senha-senhaAtual"
              label={translate('Senha Atual')}
              value={dados.senhaAtual}
              maxLength={20}
              secureTextEntry={true}
              returnKeyType={'next'}
              onChange={senhaAtual =>
                this.handleChange('senhaAtual', senhaAtual)
              }
            />
            <ErrorMessage
              nativeID="alterar-senha-erro-senhaAtual"
              message={erros.senhaAtual}
            />
            <Space />

            <TextBox
              nativeID="alterar-senha-novaSenha"
              label={translate('Nova Senha')}
              value={dados.novaSenha}
              maxLength={20}
              secureTextEntry={true}
              returnKeyType={'next'}
              onChange={novaSenha => this.handleChange('novaSenha', novaSenha)}
            />
            <ErrorMessage
              nativeID="alterar-senha-erro-novaSenha"
              message={erros.novaSenha}
            />
            <Space />
            <TextBox
              nativeID="alterar-senha-confirmarSenha"
              label={translate('Confirmar Senha')}
              value={dados.confirmarSenha}
              maxLength={20}
              secureTextEntry={true}
              returnKeyType={'done'}
              onChange={confirmarSenha =>
                this.handleChange('confirmarSenha', confirmarSenha)
              }
            />
            <Space />
            <ButtonBar
              buttonBarStyle={Platform.OS == 'web' && styles.buttons}
              leftButton={{
                nativeID: 'alterar-senha-cancelar',
                text: translate('Cancelar'),
                primary: false,
                onPress: () => this.props.onCancelPress()
              }}
              rightButton={{
                nativeID: 'alterar-senha-salvar',
                text: translate('Salvar'),
                onPress: () => this.handleEnviar()
              }}
            />
            <Message
              nativeID="alterar-senha-sucesso"
              message={translate('Senha salva com sucesso')}
              visible={resultado === 'sucesso'}
              onRequestClose={() => this.handleSucesso()}
            />
            <Message
              nativeID="alterar-senha-erro"
              type="warning"
              message={erroGeral}
              visible={resultado === 'erro'}
              onRequestClose={() =>
                this.setState({ resultado: null, erroGeral: '' })
              }
            />
          </Form>
        </Card.Section>
      </Card>
    );
  }
}

const styles = StyleSheet.create({
  buttons: {
    flexDirection: 'row',
    margin: 'auto',
    maxWidth: 350,
    width: '100%'
  },
  section: {
    margin: 'auto',
    maxWidth: 350,
    width: '100%'
  }
});

export default CardAlterarSenha;
