import { TipoDeTela } from '../screens/cartao-ponto/DetalheDiaBatida';
import { retornarTelaPorPath, Telas } from './telas';
import { DadosFuncionario, TipoPerfil, TipoPlano } from './types';

export const verificarExibirBotaoJustificarPonto = (
  tipoDeTela: TipoDeTela | undefined | null,
  telasOcultarFuncionario: Array<Telas>,
  periodoEncerrado?: boolean
) => {
  if (periodoEncerrado != null && periodoEncerrado) {
    return false;
  }

  if (telasOcultarFuncionario.length == 0) return true;

  if (tipoDeTela == TipoDeTela.CartaoPontoGerente) {
    return !telasOcultarFuncionario.some(x => x == Telas.CartaoPontoGerente);
  }

  return !telasOcultarFuncionario.some(x => x == Telas.JustificarAusencia);
};

export const verificarExibirBotaoAjustePonto = (
  tipoDeTela: TipoDeTela | undefined | null,
  telasOcultarFuncionario: Array<Telas>,
  restringirAjustePonto?: boolean,
  tipoPlano?: TipoPlano,
  periodoEncerrado?: boolean
) => {
  if (periodoEncerrado != null && periodoEncerrado) {
    return false;
  }

  if (telasOcultarFuncionario.length == 0 && !restringirAjustePonto)
    return true;

  if (tipoDeTela == TipoDeTela.CartaoPontoGerente) {
    if (tipoPlano == null || tipoPlano < TipoPlano.Pro) {
      return true;
    }

    return !telasOcultarFuncionario.some(x => x == Telas.CartaoPontoGerente)
      ? !restringirAjustePonto
      : true;
  }

  return !telasOcultarFuncionario.some(x => x == Telas.AjustarPonto);
};

export const exibirTelaInclusaoPonto = (
  funcionario: DadosFuncionario
): boolean => {
  const { podeIncluirPontoManual } = funcionario;
  const { telasOcultar } = funcionario.dadosPerfilFuncionario;

  if (telasOcultar.length == 0 && podeIncluirPontoManual) return true;

  return (
    podeIncluirPontoManual && !telasOcultar.some(x => x == Telas.IncluirPonto)
  );
};

export const verificarExibirTela = (
  telaVerificar: Telas,
  telasOcultar: Array<Telas>
) => {
  return (
    !telasOcultar ||
    telasOcultar.length == 0 ||
    !telasOcultar.some(x => x == telaVerificar)
  );
};

export const verificarExibirTelaPorPath = (
  pathTelaAcessada: string,
  telaInicial: Telas,
  telasOcultar: Array<Telas>,
  perfilFuncionarioLogado: TipoPerfil,
  restringirAjustePontos: boolean,
  tipoPlano: TipoPlano
) => {
  const regexPath = /\/[^\d+]([\w+-]+)/g;
  const match = regexPath.exec(pathTelaAcessada);

  if (!match || match.length == 0) {
    return verificarExibirTela(telaInicial, telasOcultar);
  }

  const path = match[0];
  const telaPath = retornarTelaPorPath(path);
  const telaAcessada =
    telaPath == telaInicial || !telaPath ? telaInicial : telaPath;
  let possuiAcesso = verificarExibirTela(telaAcessada, telasOcultar);
  let patternPathTelaModoGerente = /\/\d+\/[\w+-]+\/\d{4}-\d{1,2}-\d{1,2}\/\d/g;

  if (
    perfilFuncionarioLogado == TipoPerfil.Gerente &&
    pathTelaAcessada.match(patternPathTelaModoGerente) &&
    (telaAcessada == Telas.JustificarAusencia ||
      telaAcessada == Telas.AjustarPonto)
  ) {
    // Verifica se a tela que está sendo acessada é a tela de ajuste de ponto ou justificativa porém no modo gerente
    // nesse caso será verificado se existe acesso a tela de cartões ponto e então e se a tela de Ajuste de ponto
    // possui restrição ao ajuste de pontos de funcionários

    if (
      tipoPlano >= TipoPlano.Pro &&
      telaAcessada == Telas.AjustarPonto &&
      restringirAjustePontos
    ) {
      possuiAcesso = false;
    } else {
      possuiAcesso = verificarExibirTela(
        Telas.CartaoPontoGerente,
        telasOcultar
      );
    }
  }

  return possuiAcesso;
};

export const verificarExibirColuna = (
  nomeColuna: string,
  colunasOcultarFuncionario: Array<string>
) => {
  return !colunasOcultarFuncionario.some(x => x == nomeColuna);
};
