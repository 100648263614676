import * as React from 'react';
import { StyleSheet } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Card,
  RangeDatePicker,
  DropDown,
  Space,
  Message
} from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import { ButtonBar } from '../../../shared/components/ButtonBar';
import SolicitacaoHeader from '../../../shared/components/SolicitacaoHeader';
import SolicitacaoLinha from '../../../shared/components/SolicitacaoLinha';
import SolicitacaoProcessamento from '../../../shared/components/SolicitacaoProcessamento';
import { FuncionarioContainer } from '../../../shared/containers/FuncionarioContainer';
import {
  SolicitacoesContainer,
  getCorStatus,
  getIconeStatus
} from '../../../shared/containers/SolicitacoesContainer';
import withContainer from '../../../shared/containers/withContainer';
import { getTheme } from '../../../shared/modules/layout';
import { primeiraLetraMaisculaCadaPalavra } from '../../../shared/modules/string';
import {
  SolicitacoesFiltros,
  SolicitacoesFiltrosStatus,
  TipoPerfil,
  SolicitacoesDados,
  SolicitacoesStatus,
  TipoAlteracaoFonteDados,
  TipoControleSolicitacoes,
  TipoPlano
} from '../../../shared/modules/types';
import {
  converterDataParaTimezoneBrasil,
  getDataInicioDiasAtras
} from '../../../shared/modules/utils';
import LoggedInScreen from '../../components/LoggedInScreen';
import SolicitacoesDetalhes from '../solicitacoes/SolicitacoesDetalhes';

interface Props {
  container: SolicitacoesContainer;
}

interface State {
  filtros: SolicitacoesFiltros;
  erroGeral: string;
  solicitacao: SolicitacoesDados | null;
  todasSelecionadas: boolean;
  listaIdsSelecionados: Array<number>;
  tipoAcao: TipoAlteracaoFonteDados | null;
  solicitacaoAtual: SolicitacoesDados | null;
}

class SolicitacoesGerente extends React.Component<Props, State> {
  state: State = {
    filtros: {
      dataInicio: converterDataParaTimezoneBrasil(getDataInicioDiasAtras(14)),
      dataFinal: converterDataParaTimezoneBrasil(new Date()),
      status: SolicitacoesFiltrosStatus.Pendentes,
      perfil: TipoPerfil.Gerente
    },
    erroGeral: '',
    solicitacao: null,
    todasSelecionadas: false,
    listaIdsSelecionados: [],
    tipoAcao: null,
    solicitacaoAtual: null
  };

  listaSolicitacoesAcao: SolicitacoesDados[] = [];

  async componentDidMount() {
    await this.props.container.loadAsync(this.state.filtros);
  }

  handleDateInicioChange = (value: Date, name: keyof State['filtros']) => {
    this.setState({
      ...this.state,
      filtros: { ...this.state.filtros, dataInicio: value, dataFinal: value },
      erroGeral: ''
    });
  };

  handleChange = (value: string | Date, name: keyof State['filtros']) => {
    const novosFiltros = {
      ...this.state.filtros,
      [name]: value
    };
    this.setState(
      {
        ...this.state,
        filtros: novosFiltros,
        listaIdsSelecionados: [],
        erroGeral: ''
      },
      async () => {
        if (
          this.state.filtros.dataInicio &&
          this.state.filtros.dataFinal &&
          this.state.filtros.dataInicio > this.state.filtros.dataFinal
        ) {
          this.setState({
            ...this.state,
            erroGeral: translate('Selecione o período corretamente')
          });

          return;
        }
        await this.props.container.loadAsync(this.state.filtros);
      }
    );
  };

  iniciarProcessamento = (tipoAcao: TipoAlteracaoFonteDados) => {
    const { listaIdsSelecionados } = this.state;
    const { lista } = this.props.container.state;

    this.setState({ tipoAcao });

    this.listaSolicitacoesAcao = lista.filter(x =>
      listaIdsSelecionados.find(y => x.id == y)
    );

    this.processarSolicitacaoAtual();
  };

  processarSolicitacaoAtual = () => {
    if (this.listaSolicitacoesAcao.length === 0) {
      this.finalizarProcessamento();
      return;
    }

    this.setState({ solicitacaoAtual: this.listaSolicitacoesAcao[0] });
  };

  finalizarProcessamento = () => {
    this.listaSolicitacoesAcao = [];

    this.setState(
      { tipoAcao: null, solicitacaoAtual: null, todasSelecionadas: false },
      async () => await this.props.container.loadAsync(this.state.filtros)
    );
  };

  concluirEnvioSucesso = () => {
    const { solicitacaoAtual } = this.state;

    if (solicitacaoAtual) {
      this.setState({
        listaIdsSelecionados: this.state.listaIdsSelecionados.filter(
          item => item !== solicitacaoAtual.id
        )
      });
    }

    this.listaSolicitacoesAcao.shift();
    this.processarSolicitacaoAtual();
  };

  handleSelecionarTodas = (checked: boolean) => {
    const { container } = this.props;
    this.setState({
      todasSelecionadas: checked,
      listaIdsSelecionados: checked
        ? container.state.lista
            .filter(x => x.status == SolicitacoesStatus.Pendente)
            .map(y => y.id)
        : []
    });
  };

  handleSelecionar = (solicitacaoId: number) => {
    const { listaIdsSelecionados } = this.state;

    this.setState({
      listaIdsSelecionados: listaIdsSelecionados.includes(solicitacaoId)
        ? listaIdsSelecionados.filter(item => item !== solicitacaoId)
        : [...this.state.listaIdsSelecionados, solicitacaoId]
    });
  };

  render() {
    const { container } = this.props;
    const {
      filtros,
      erroGeral,
      solicitacao,
      todasSelecionadas,
      listaIdsSelecionados,
      solicitacaoAtual,
      tipoAcao
    } = this.state;

    const leftButtonAprovar = {
      disabled: listaIdsSelecionados.length === 0,
      text: translate('Aprovar'),
      onPress: () => this.iniciarProcessamento(TipoAlteracaoFonteDados.Troca),
      textStyle: styles.textButton,
      style: [styles.button, styles.buttonAprovar],
      nativeID: 'botao-aprovar-solicitacao-gerente'
    };

    const leftButtonMarcarVisto = {
      disabled: listaIdsSelecionados.length === 0,
      text: primeiraLetraMaisculaCadaPalavra(translate('Marcar Visto')),
      onPress: () =>
        this.iniciarProcessamento(TipoAlteracaoFonteDados.MarcarVisto),
      textStyle: styles.textButton,
      style: styles.button,
      nativeID: 'botao-visto-solicitacao-gerente'
    };

    const rightButton = {
      disabled: listaIdsSelecionados.length === 0,
      text: translate('Reprovar'),
      onPress: () =>
        this.iniciarProcessamento(TipoAlteracaoFonteDados.ApagarFonteDados),
      textStyle: styles.textButton,
      style: [styles.button, { backgroundColor: theme.errorColor }],
      nativeID: 'botao-reprovar-solicitacao-gerente'
    };

    return (
      <LoggedInScreen screenTitle={translate('Solicitações Gerente')}>
        <Card>
          <Card.Header title={translate('Filtrar')} />
          <Card.Section style={styles.section}>
            <DropDown
              nativeID="solicitacoes-status"
              label={translate('Status da Solicitação')}
              items={container.itensStatusSolicitacoes}
              value={filtros.status}
              onChange={status => this.handleChange(status, 'status')}
              style={styles.campoFiltro}
            />
            <RangeDatePicker
              nativeID="solicitacoes-data"
              style={styles.campoFiltro}
              label={translate('Insira o Período')}
              startDate={filtros.dataInicio!}
              endDate={filtros.dataFinal!}
              onStartDateChange={date =>
                this.handleDateInicioChange(date, 'dataInicio')
              }
              onEndDateChange={date => this.handleChange(date, 'dataFinal')}
            />
            <Subscribe to={[FuncionarioContainer]}>
              {(funcionario: FuncionarioContainer) => (
                <ButtonBar
                  buttonBarStyle={styles.buttons}
                  leftButton={
                    funcionario.state.dados.plano >= TipoPlano.Pro &&
                    funcionario.state.dados.dadosPerfilFuncionario
                      .controleSolicitacoes ===
                      TipoControleSolicitacoes.MarcarVisto
                      ? leftButtonMarcarVisto
                      : leftButtonAprovar
                  }
                  rightButton={rightButton}
                />
              )}
            </Subscribe>
          </Card.Section>
        </Card>
        <Space height={10} />
        <Card>
          <Card.Section style={styles.headerSection}>
            {container.state.lista.length > 0 && (
              <SolicitacaoHeader
                checked={todasSelecionadas}
                showChecked={container.state.precisaProcessarSolicitacoes}
                onChecked={checked => this.handleSelecionarTodas(checked)}
              />
            )}
          </Card.Section>
        </Card>
        <Space height={10} />
        {container.state.lista.map((solicitacao, index) => (
          <SolicitacaoLinha
            key={solicitacao.id}
            exibirSeparador={index > 0}
            solicitacao={solicitacao}
            checked={listaIdsSelecionados.includes(solicitacao.id)}
            corSolicitacao={getCorStatus(solicitacao.status)}
            iconeSolicitacao={getIconeStatus(solicitacao.status)}
            onChecked={() => this.handleSelecionar(solicitacao.id)}
            onNavigate={() => {
              this.setState({ solicitacao });
            }}
          />
        ))}

        {solicitacao && (
          <SolicitacoesDetalhes
            modalVisible={solicitacao !== null}
            solicitacao={solicitacao}
            gerente={true}
            onClose={() => {
              this.finalizarProcessamento();
              this.setState({ solicitacao: null });
            }}
          />
        )}

        {solicitacaoAtual ? (
          // Precisamos gerar uma nova versão do componente
          // toda vez que uma solicitação for processada
          // pois o processamento  é iniciado no didmount do componente.
          <SolicitacaoProcessamento
            key={solicitacaoAtual.id}
            solicitacaoAtual={solicitacaoAtual}
            tipoAcao={tipoAcao}
            concluirEnvioSucesso={this.concluirEnvioSucesso}
            finalizarProcessamento={this.finalizarProcessamento}
            concluirEnvioErro={(erros: string) =>
              this.setState({ erroGeral: erros }, this.finalizarProcessamento)
            }
          />
        ) : null}

        <Message
          type="warning"
          visible={erroGeral != ''}
          message={erroGeral}
          onRequestClose={() => this.setState({ erroGeral: '' })}
        />
      </LoggedInScreen>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  headerSection: {
    padding: 0
  },
  campoFiltro: {
    flex: 1,
    minWidth: 250,
    maxWidth: 350,
    marginTop: 10,
    marginLeft: 16
  },
  section: {
    flexDirection: 'row',
    flex: 8,
    flexWrap: 'wrap',
    minHeight: 88,
    paddingTop: 6,
    paddingLeft: 0
  },
  buttons: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 350,
    minWidth: 220,
    marginLeft: 16,
    marginTop: 10
  },
  button: {
    height: 42,
    maxWidth: 138
  },
  buttonAprovar: {
    backgroundColor: theme.successColor
  },
  textButton: {
    fontSize: 14
  }
});

export default withContainer(SolicitacoesContainer)(SolicitacoesGerente);
