import { Container } from 'unstated';
import Api from '../modules/api';
import { NotificacaoDados, FiltroNotificacoes } from '../modules/types';
import {
  getDataInicioDiasAtras,
  converterDataParaTimezoneBrasil
} from '../modules/utils';

const TEMPO_NAO_REPETIR_REQUISICOES = 5 * 60 * 1000; // 5 minutos em milissegundos

export interface NotificacoesState {
  notificacoes: Array<NotificacaoDados>;
  erro: string;
  erroConexao: boolean;
  onTentarNovamente: (() => void) | null;
  ultimaExecucao: number;
}

export class NotificacoesContainer extends Container<NotificacoesState> {
  state: NotificacoesState = {
    notificacoes: [],
    erro: '',
    erroConexao: false,
    onTentarNovamente: null,
    ultimaExecucao: 0
  };

  loadUltimosTrintaDiasNotificacoesAsync = async () => {
    const ultimaExecucao = this.state.ultimaExecucao;
    const agora = new Date().getTime();

    // Quando o usuário navega nos menus do app, é feita uma requisição para atualizar as notificações não visualizadas em cada uma das telas acessadas, gerando uma carga desnecessária no servidor.
    // Por isso criamos uma validação que só faz a requisição depois de um determinado tempo, buscando as novas notificações para renderizar em tela.
    if (agora - ultimaExecucao < TEMPO_NAO_REPETIR_REQUISICOES) {
      return;
    }

    const api = new Api();

    const filtros: FiltroNotificacoes = {
      dataInicio: converterDataParaTimezoneBrasil(getDataInicioDiasAtras(30)),
      dataFinal: converterDataParaTimezoneBrasil(new Date())
    };

    await api.getNotificacoes(
      {
        onSuccess: (notificacoes: Array<NotificacaoDados>) => {
          this.setState({
            notificacoes,
            ultimaExecucao: agora
          });
        }
      },
      filtros
    );
  };

  loadNotificacoesAsync = async (filtros: FiltroNotificacoes) => {
    const api = new Api();

    await api.getNotificacoes(
      {
        onSuccess: (notificacoes: Array<NotificacaoDados>) => {
          this.setState({
            notificacoes
          });
        },
        onError: (err: Error) => {
          this.setState({
            erro: err.message,
            erroConexao: err.name === 'TimeoutError',
            onTentarNovamente: () => this.loadNotificacoesAsync(filtros)
          });
        }
      },
      filtros
    );
  };

  marcarNotificacaoVisualizadaAsync = async (
    notificacaoId: number,
    visualizada: boolean,
    onSuccess: () => void
  ) => {
    const api = new Api();

    await api.postMarcarNotificacaoVisualizada(notificacaoId, visualizada, {
      onSuccess: async () => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (err: Error) => {
        this.setState({
          erro: err.message,
          erroConexao: err.name === 'TimeoutError',
          onTentarNovamente: () =>
            this.marcarNotificacaoVisualizadaAsync(
              notificacaoId,
              visualizada,
              onSuccess
            )
        });
      }
    });
  };

  retornarQuantidadeNotificacoesNaoVisualizadas = () => {
    return this.state.notificacoes.filter(x => !x.visualizada).length;
  };

  limparNotificacoes = () => {
    this.setState({
      notificacoes: [],
      ultimaExecucao: 0
    });
  };

  limparErro = () => {
    this.setState({
      erro: '',
      erroConexao: false,
      onTentarNovamente: null
    });
  };
}

export default new NotificacoesContainer();
