import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { CardRecuperacaoSenhaProps } from '../../shared/components/CardRecuperacaoSenha';
import { DadosEmpresa, DadosFuncionario } from '../../shared/modules/types';
import { criarPathComBancoId } from '../modules/routes';
import Login from '../screens/login/Login';
import RecuperarSenhaBase from '../screens/recuperar-senha/RecuperarSenhaBase';

interface RotasProps {
  dadosEmpresaPrincipal: DadosEmpresa;
  onSuccessoLogin: (dados: DadosFuncionario) => Promise<void>;
  onNaoEncontrado: () => void;
}

interface LocationState {
  cardRecuperacaoSenhaProps: CardRecuperacaoSenhaProps;
}

function PublicRoutes(props: Readonly<RotasProps>) {
  const { dadosEmpresaPrincipal, onSuccessoLogin, onNaoEncontrado } = props;

  return (
    <Switch>
      <Route
        path={criarPathComBancoId('/')}
        exact
        render={() => (
          <Login
            dadosEmpresaPrincipal={dadosEmpresaPrincipal}
            onSuccessoLogin={onSuccessoLogin}
          />
        )}
      />
      <Route
        path="/RecuperarSenha"
        exact
        render={props => {
          return (
            <RecuperarSenhaBase
              {...(props.location.state as LocationState)
                .cardRecuperacaoSenhaProps}
            />
          );
        }}
      />
      <Route
        render={() => {
          onNaoEncontrado();
          return undefined;
        }}
      />
    </Switch>
  );
}

export default PublicRoutes;
