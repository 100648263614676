import React from 'react';
import { Subscribe } from 'unstated';
import ModalBiometriaAndroid from '../../native/components/ModalBiometriaAndroid';
import AutenticacaoLocalService from '../../native/services/AutenticacaoLocalService';
import ModalAssinaturaDigitalCartaoPonto, {
  DadosModalMotivo
} from '../../shared/components/ModalAssinaturaDigitalCartaoPonto';
import { getPeriodoFormatado } from '../containers/AssinaturaDigitalCartaoPontoContainer';
import { ProcessandoContainer } from '../containers/ProcessandoContainer';
import Api from '../modules/api';
import { carregarDadosLoginAsync, DadosLogin } from '../modules/auth';
import {
  TipoAlteracaoAssinaturaDigitalCartaoPonto,
  AssinaturaDigitalCartaoPontoDados
} from '../modules/types';

interface State {
  dadosModalMotivo: DadosModalMotivo | null;
  dadosLogin: DadosLogin | null;
  acessarComBiometria: boolean | undefined;
  erros: { [key: string]: string };
}

interface Props {
  assinaturaDigitalCartaoPontoAtual: AssinaturaDigitalCartaoPontoDados | null;
  tipoAcao: TipoAlteracaoAssinaturaDigitalCartaoPonto | null;
  concluirEnvioSucesso: (
    assinaturaDigitalCartaoPonto: AssinaturaDigitalCartaoPontoDados
  ) => void;
  concluirEnvioErro: (erro: string) => void;
  finalizarProcessamento: () => void;
}

const DATE_FORMAT = 'dd/MM/yyyy'; // Criar um modulo com esses formatos?

class AssinaturaDigitalCartaoPontoProcessamento extends React.Component<
  Props,
  State
> {
  state: State = {
    dadosModalMotivo: null,
    dadosLogin: null,
    acessarComBiometria: undefined,
    erros: {}
  };

  componentDidMount() {
    this.processarAssinaturaDigitalCartaoPontoAtual();
    this.carregarDadosAsync();
  }

  componentDidUpdate(_: Props, prevState: State) {
    if (
      prevState.acessarComBiometria !== this.state.acessarComBiometria &&
      this.state.acessarComBiometria
    ) {
      this.autenticarUsuarioBiometriaAsync();
    }
  }

  carregarDadosAsync = async () => {
    const { tipoAcao } = this.props;

    const dadosLogin = await carregarDadosLoginAsync();

    if (dadosLogin) {
      this.setState({
        dadosLogin,
        acessarComBiometria:
          dadosLogin.acessaComBiometria === true &&
          tipoAcao === TipoAlteracaoAssinaturaDigitalCartaoPonto.Aprovar
      });
    }
  };

  processarAssinaturaDigitalCartaoPontoAtual = () => {
    const { assinaturaDigitalCartaoPontoAtual, tipoAcao } = this.props;

    if (!assinaturaDigitalCartaoPontoAtual || tipoAcao === null) {
      return;
    }

    this.setState({
      dadosModalMotivo: {
        id: assinaturaDigitalCartaoPontoAtual.id,
        descricao: assinaturaDigitalCartaoPontoAtual.descricao,
        tipoAcao: tipoAcao,
        periodo: getPeriodoFormatado(
          assinaturaDigitalCartaoPontoAtual,
          DATE_FORMAT
        )
      }
    });
  };

  enviarAssinaturaDigitalCartaoPontoAprovadaAsync = async (
    assinaturaDigitalCartaoPonto: AssinaturaDigitalCartaoPontoDados
  ) => {
    const api = new Api();

    await api.postAprovarAssinaturaDigitalCartaoPonto(
      assinaturaDigitalCartaoPonto,
      {
        onSuccess: retorno => this.props.concluirEnvioSucesso(retorno),
        onError: err => {
          this.props.concluirEnvioErro(err.message);
        },
        onValidationError: (errorList, errorObj) => {
          let mensagemErro = '';
          errorList.forEach(error => (mensagemErro += error.message + '\n'));
          this.props.concluirEnvioErro(mensagemErro);
        }
      }
    );
  };

  enviarAssinaturaDigitalCartaoPontoRejeitadaAsync = async (
    assinaturaDigitalCartaoPonto: AssinaturaDigitalCartaoPontoDados
  ) => {
    const api = new Api();

    await api.postReprovarAssinaturaDigitalCartaoPonto(
      assinaturaDigitalCartaoPonto,
      {
        onSuccess: retorno => this.props.concluirEnvioSucesso(retorno),
        onError: err => {
          this.props.concluirEnvioErro(err.message);
        },
        onValidationError: (errorList, errorObj) => {
          const mensagemErro = errorList.map(error => error.message).join('\n');
          this.props.concluirEnvioErro(mensagemErro);
        }
      }
    );
  };

  handleModalConcluirAsync = async (senha: string) => {
    const { assinaturaDigitalCartaoPontoAtual, tipoAcao } = this.props;
    const { dadosLogin } = this.state;

    if (!assinaturaDigitalCartaoPontoAtual) {
      return;
    }

    if (tipoAcao === TipoAlteracaoAssinaturaDigitalCartaoPonto.Rejeitar) {
      assinaturaDigitalCartaoPontoAtual.motivo = senha;

      await this.enviarAssinaturaDigitalCartaoPontoRejeitadaAsync(
        assinaturaDigitalCartaoPontoAtual
      );
    } else if (tipoAcao === TipoAlteracaoAssinaturaDigitalCartaoPonto.Aprovar) {
      if (!dadosLogin || dadosLogin.funcionarioSenha !== senha) {
        this.props.concluirEnvioErro('Senha inválida');

        return;
      }

      assinaturaDigitalCartaoPontoAtual.senha = senha;

      await this.enviarAssinaturaDigitalCartaoPontoAprovadaAsync(
        assinaturaDigitalCartaoPontoAtual
      );
    }
  };

  concluirComBiometriaAsync = async () => {
    const { assinaturaDigitalCartaoPontoAtual } = this.props;
    const { dadosLogin } = this.state;

    if (!assinaturaDigitalCartaoPontoAtual) {
      return;
    }

    if (!dadosLogin) {
      this.props.concluirEnvioErro('Senha inválida');

      return;
    }

    assinaturaDigitalCartaoPontoAtual.senha = dadosLogin.funcionarioSenha;

    await this.enviarAssinaturaDigitalCartaoPontoAprovadaAsync(
      assinaturaDigitalCartaoPontoAtual
    );
  };

  autenticarUsuarioBiometriaAsync = async () => {
    const result =
      await AutenticacaoLocalService.autenticarUsuarioBiometriaAsync();

    if (result.success) {
      await this.concluirComBiometriaAsync();
    } else {
      this.setState({
        erros: {
          modalBiometriaAndroid: result.error
        }
      });
    }
  };

  render() {
    const { dadosModalMotivo, erros, acessarComBiometria } = this.state;
    const abrirModal = dadosModalMotivo && acessarComBiometria !== undefined;

    return (
      <>
        <Subscribe to={[ProcessandoContainer]}>
          {(processando: ProcessandoContainer) => {
            if (abrirModal && processando.state.quantidade === 0) {
              return acessarComBiometria ? (
                <ModalBiometriaAndroid
                  exibir={true}
                  mensagemErro={erros.modalBiometriaAndroid}
                  onCancelar={this.props.finalizarProcessamento}
                  onUsarSenha={() =>
                    this.setState({ acessarComBiometria: false })
                  }
                />
              ) : (
                <ModalAssinaturaDigitalCartaoPonto
                  key={dadosModalMotivo.id}
                  dados={dadosModalMotivo}
                  onCancelar={this.props.finalizarProcessamento}
                  onConcluir={this.handleModalConcluirAsync}
                />
              );
            }
            return null;
          }}
        </Subscribe>
      </>
    );
  }
}

export default AssinaturaDigitalCartaoPontoProcessamento;
