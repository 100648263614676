import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { translate } from 'secullum-i18n';
import { Modal, ImageButton, Message } from 'secullum-react-native-ui';
import ArquivoDetalhes from '../../../shared/components/ArquivoDetalhes';
import { ArquivosContainer } from '../../../shared/containers/ArquivosContainer';
import { getTheme } from '../../../shared/modules/layout';
import { isHighResolution } from '../../../shared/modules/layout';
import { ArquivosDados } from '../../../shared/modules/types';
import { isFirefox } from '../../../shared/modules/utils';
import {
  BROWSER_NO_SUPPORT_GEOLOCATION,
  getGeolocationError
} from '../../modules/geolocation';

interface Props {
  arquivo: ArquivosDados;
  modalVisible: boolean;
  onClose: () => void;
  container: ArquivosContainer;
}

interface State {
  erroLocalizacao: string | null;
}

class ArquivosDetalhes extends React.Component<Props, State> {
  state: State = {
    erroLocalizacao: null
  };

  locationSubscription: number | null = null;
  navigatorIsFirefox = isFirefox();

  componentDidMount() {
    this.startGeolocationWatch();
  }

  startGeolocationWatch = () => {
    if (!navigator.geolocation) {
      this.setState({
        erroLocalizacao: translate(BROWSER_NO_SUPPORT_GEOLOCATION)
      });
      return;
    }

    this.locationSubscription = navigator.geolocation.watchPosition(
      position => {
        this.props.container.setState({
          coordenadas: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            accuracy: position.coords.accuracy
          }
        });
      },
      error => {
        this.setState({ erroLocalizacao: getGeolocationError(error) });
      },
      { enableHighAccuracy: true, timeout: 30000, maximumAge: 1000 }
    );

    // O Firefox não permite realizar watchPosition sem foco na tela, ocasionando timeout.
    // Com base nisso adicionamos um EventListener para parar o watchPosition quando a tela
    // perde foco e retomá-lo quando o foco é recuperado.
    if (this.navigatorIsFirefox) {
      document.addEventListener(
        'visibilitychange',
        this.handleVisibilityChange
      );
    }
  };

  handleVisibilityChange = () => {
    if (document.hidden) {
      if (this.navigatorIsFirefox && this.locationSubscription) {
        navigator.geolocation.clearWatch(this.locationSubscription);
        this.locationSubscription = null;
      }
    } else {
      this.startGeolocationWatch();
    }
  };

  componentWillUnmount() {
    if (this.locationSubscription) {
      navigator.geolocation.clearWatch(this.locationSubscription);
    }

    if (this.navigatorIsFirefox) {
      document.removeEventListener(
        'visibilitychange',
        this.handleVisibilityChange
      );
    }
  }

  closeRequest = () => {
    this.props.onClose();
  };

  render() {
    const { erroLocalizacao } = this.state;
    const { modalVisible, arquivo } = this.props;

    return (
      <Modal visible={modalVisible} overlayStyle={{ overflow: 'scroll' }}>
        <View style={styles.modalContainer}>
          <View style={styles.modalIcons}>
            <ImageButton
              style={styles.imageButtonLeft}
              icon="close"
              onPress={() => this.closeRequest()}
              nativeID="botao-fechar-detalhe"
            />
          </View>
          <View nativeID="solicitacao-detalhe" style={styles.modalSection}>
            <ArquivoDetalhes dados={arquivo} container={this.props.container} />
          </View>
          {erroLocalizacao ? (
            <Message
              type={'warning'}
              visible
              message={erroLocalizacao}
              onRequestClose={() => this.setState({ erroLocalizacao: null })}
            />
          ) : null}
        </View>
      </Modal>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  imageButtonLeft: {
    borderWidth: 0,
    marginLeft: 'auto'
  },
  modalSection: {
    minHeight: 50,
    width: '90%',
    alignSelf: 'center',
    marginBottom: 20
  },
  modalContainer: {
    margin: 'auto',
    width: '90%',
    maxWidth: !isHighResolution() ? 300 : 500,
    backgroundColor: theme.backgroundColor2,
    borderRadius: 10,
    justifyContent: 'center',
    marginBottom: 10,
    marginTop: 10
  },
  modalIcons: {
    flexDirection: 'row'
  }
});

export default ArquivosDetalhes;
