import * as React from 'react';
import { translate } from 'secullum-i18n';
import { TipoTelaIndicadorCustomizado } from '../../../shared/modules/types';
import CardIndicadores from '../../../shared/screens/indicadores/CardIndicadores';
import LoggedInScreen from '../../components/LoggedInScreen';
import { RouteComponentComBancoIdProps, navegar } from '../../modules/routes';

interface Props extends RouteComponentComBancoIdProps<{}> {}

class Indicadores extends React.Component<Props> {
  handleEditarIndicador = (indicadorId?: number) => {
    navegar({
      props: this.props,
      path: `/dados-indicador-customizado/${
        TipoTelaIndicadorCustomizado.Funcionario
      }/${indicadorId ? indicadorId : ''}`
    });
  };

  render() {
    return (
      <LoggedInScreen screenTitle={translate('Indicadores')}>
        <CardIndicadores onClickEditarIndicador={this.handleEditarIndicador} />
      </LoggedInScreen>
    );
  }
}

export default Indicadores;
