import { Container } from 'unstated';

export interface ProcessandoState {
  quantidade: number;
}

export class ProcessandoContainer extends Container<ProcessandoState> {
  state: ProcessandoState = {
    quantidade: 0
  };

  atualizarQuantidade = (promessa: Promise<any>) => {
    this.setState(prevState => ({
      quantidade: prevState.quantidade + 1
    }));

    const momentoSetouProcessando = new Date().getMilliseconds();

    const removerQuantidade = () => {
      // limpa depois de pelo menos 200 millissegundos depois de ser exibida,
      // pra não parecer que só "piscou" a tela
      const diferencaMillissegundos =
        new Date().getMilliseconds() - momentoSetouProcessando;

      setTimeout(() => {
        this.setState(prevState => ({
          quantidade: prevState.quantidade - 1
        }));
      }, 200 - diferencaMillissegundos);
    };

    promessa.then(removerQuantidade, removerQuantidade);
  };
}

export default new ProcessandoContainer();
