import * as base64 from 'base-64';
import * as React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Details,
  DetailsHeader,
  DetailsLine,
  Space,
  formatDate,
  Message
} from 'secullum-react-native-ui';
import { getTheme } from '../../shared/modules/layout';
import { isHighResolution } from '../../shared/modules/layout';
import { ArquivoDadosStatus } from '../../shared/modules/types';
import { ArquivosContainer } from '../containers/ArquivosContainer';
import { getUrlApi } from '../modules/api';
import { carregarDadosLoginAsync, DadosLogin } from '../modules/auth';
import { ArquivosDados } from '../modules/types';
import { ButtonBar } from './ButtonBar';
import FileViewer from './FileViewer';
import ModalAssinaturaDigitalArquivos from './ModalAssinaturaDigitalArquivos';

interface State {
  dadosLogin: DadosLogin | null;
  modalOpen: boolean;
}

interface Props {
  dados: ArquivosDados;
  container: ArquivosContainer;
}

class ArquivoDetalhes extends React.Component<Props, State> {
  state: State = {
    dadosLogin: null,
    modalOpen: false
  };

  async componentDidMount() {
    const { dados, container } = this.props;
    const dadosLogin = await carregarDadosLoginAsync();

    if (!dadosLogin) {
      return;
    }

    await container.setarEstadoInicialAssinaturaArquivoAsync(dadosLogin, dados);
    this.setState({ dadosLogin });
  }

  getFileUrl = () => {
    const { dados, container } = this.props;
    const { dadosLogin } = container.state;

    if (dadosLogin === null) {
      return null;
    }

    const user = base64.encode(
      dadosLogin.funcionarioNumero +
        ':' +
        dadosLogin.funcionarioSenha +
        ':' +
        dadosLogin.tipoUsuarioAutenticacao
    );

    const urlApiBase = getUrlApi();

    const url = `${dadosLogin.bancoId}/RepositorioArquivo/GetFile/${dados.id}`;
    const queryParams = `?axpw=${user}`;

    return `${urlApiBase}${url}${queryParams}`;
  };

  createLines = () => {
    const { dados, container } = this.props;
    const { repositorioArquivoAssinaturasDados } = container.state;

    if (!repositorioArquivoAssinaturasDados) {
      return [];
    }

    const lines: Array<DetailsLine> = [
      {
        title: translate('Tipo do Arquivo'),
        value: dados.tipoArquivo
      },
      {
        title: translate('Data do Arquivo'),
        value: formatDate(dados.dataArquivo, 'dd/MM/yyyy')
      },
      {
        title: translate('Descrição'),
        value: dados.descricao
      },
      {
        title: translate('Status'),
        value: this.renderStatusAssinaturaArquivo(),
        icons: [
          {
            name: this.getIconeStatus(
              repositorioArquivoAssinaturasDados.status
            ),
            color: this.getCorStatus(repositorioArquivoAssinaturasDados.status),
            size: 18
          }
        ]
      }
    ];

    if (dados.validadeArquivo) {
      lines.splice(2, 0, {
        title: translate('Validade do Arquivo'),
        value: formatDate(dados.validadeArquivo, 'dd/MM/yyyy')
      });
    }

    if (repositorioArquivoAssinaturasDados.dataResposta) {
      lines.push({
        title: translate('Respondido em'),
        value: formatDate(
          repositorioArquivoAssinaturasDados.dataResposta,
          'dd/MM/yyyy HH:mm:ss'
        )
      });
    }

    if (
      repositorioArquivoAssinaturasDados.status ===
        ArquivoDadosStatus.Descartada &&
      repositorioArquivoAssinaturasDados.motivo
    ) {
      lines.push({
        title: translate('Motivo'),
        value: repositorioArquivoAssinaturasDados.motivo
      });
    }

    if (
      dados.solicitarAssinaturaFuncionario &&
      !repositorioArquivoAssinaturasDados.dataResposta
    ) {
      lines.push({
        render: () => this.renderButtons()
      });
    }

    return lines;
  };

  getIconeStatus = (status: ArquivoDadosStatus | null) => {
    // Arquivos que não pedem assinatura, recebem o status "Arquivo Simples"
    if (!this.props.dados.solicitarAssinaturaFuncionario) {
      return 'file-text-o';
    }

    switch (status) {
      case ArquivoDadosStatus.Aceita:
        return 'thumbs-up';
      case ArquivoDadosStatus.Descartada:
        return 'thumbs-down';
      default:
        return 'hourglass-half';
    }
  };

  getCorStatus = (status: ArquivoDadosStatus | null) => {
    // Arquivos que não pedem assinatura, recebem o status "Arquivo Simples"
    if (!this.props.dados.solicitarAssinaturaFuncionario) {
      return theme.textColor3;
    }

    switch (status) {
      case ArquivoDadosStatus.Aceita:
        return theme.successColor;
      case ArquivoDadosStatus.Descartada:
        return theme.errorColor;
      default:
        return theme.warningColor;
    }
  };

  renderStatusAssinaturaArquivo = () => {
    const { dados, container } = this.props;
    const { repositorioArquivoAssinaturasDados } = container.state;

    if (!repositorioArquivoAssinaturasDados) {
      return '';
    }

    // Arquivos que não pedem assinatura, recebem o status "Arquivo Simples"
    if (!dados.solicitarAssinaturaFuncionario) {
      return translate('Arquivo Simples');
    }

    switch (repositorioArquivoAssinaturasDados.status) {
      case ArquivoDadosStatus.Aceita:
        return translate('Aceito');
      case ArquivoDadosStatus.Descartada:
        return translate('Rejeitado');
      default:
        return translate('Aguardando Retorno');
    }
  };

  renderFileViewer = () => {
    const { dados } = this.props;

    const url = this.getFileUrl();

    if (!url) {
      return null;
    }

    const app = ['android', 'ios'].includes(Platform.OS);

    return (
      <FileViewer
        src={url}
        width={300}
        fileId={dados.id}
        className="span1"
        fileName={dados.nomeArquivo}
        height={app ? 250 : isHighResolution() ? 300 : 165}
      />
    );
  };

  renderButtons = () => {
    const { dadosLogin } = this.state;
    const { container, dados } = this.props;

    return (
      <View style={styles.containerBotoesAssinatura}>
        <ButtonBar
          leftButton={{
            text: translate('Aprovar'),
            onPress: () => {
              container.abrirModalValidacao(
                ArquivoDadosStatus.Aceita,
                dados.nomeArquivo,
                dados.tipoArquivo,
                dadosLogin
              );
              this.setState({ modalOpen: true });
            },
            style: { backgroundColor: theme.successColor },
            nativeID: 'botao-aprovar-assinatura-digital-arquivos-detalhes'
          }}
          rightButton={{
            text: translate('Reprovar'),
            onPress: () => {
              container.abrirModalValidacao(
                ArquivoDadosStatus.Descartada,
                dados.nomeArquivo,
                dados.tipoArquivo,
                dadosLogin
              );
              this.setState({ modalOpen: true });
            },
            style: { backgroundColor: theme.errorColor },
            nativeID: 'botao-reprovar-assinatura-digital-arquivos-detalhes'
          }}
        />
      </View>
    );
  };

  render() {
    const { dados, container } = this.props;
    const { modalOpen } = this.state;

    return (
      <>
        <DetailsHeader text={dados.nomeArquivo} />
        <Space />
        <Details title={translate('Informações')} lines={this.createLines()} />
        <Space />
        <Details
          title={translate('Visualização')}
          lines={[{ render: this.renderFileViewer }]}
        />
        <Space />
        {container.state.retorno && (
          <Message
            nativeID="processar-assinatura-digital-arquivos-erro"
            type={container.state.retorno.tipoAviso}
            visible={container.state.retorno !== null}
            message={container.state.retorno?.mensagem ?? ''}
            onRequestClose={() => container.limparErro()}
          />
        )}
        {container.state.dadosModalAssinatura && modalOpen && (
          <ModalAssinaturaDigitalArquivos
            dadosAssinatura={container.state.dadosModalAssinatura}
            onConcluir={motivo => {
              container.concluirProcessamento(motivo, null, null);
              this.setState({ modalOpen: false });
            }}
            onCancelar={() => {
              container.handleCancelarModalValidacao();
              this.setState({ modalOpen: false });
            }}
          />
        )}
      </>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  containerBotoesAssinatura: {
    paddingHorizontal: 16,
    paddingVertical: 10
  }
});

export default ArquivoDetalhes;
