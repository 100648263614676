import * as React from 'react';
import { StyleSheet, View, Platform, FlatList } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Message,
  Modal,
  ImageButton,
  Card,
  DatePicker,
  ErrorMessage,
  Space
} from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import { BatidasContainer } from '../../../shared/containers/BatidasContainer';
import { FuncionarioContainer } from '../../../shared/containers/FuncionarioContainer';
import withContainer from '../../../shared/containers/withContainer';
import { getTheme, isHighResolution } from '../../../shared/modules/layout';
import DetalheDiaBatida, {
  TipoDeTela
} from '../../../shared/screens/cartao-ponto/DetalheDiaBatida';
import LoggedInScreen from '../../components/LoggedInScreen';
import CardDiaResumido from '../cartao-ponto/CardDiaResumido';
import HeaderDiaResumido from '../cartao-ponto/HeaderDiaResumido';

interface Props {
  container: BatidasContainer;
}

interface State {
  filtros: {
    data: Date;
  };
  erroGeral: string;
  chaveTabela: number;
  indice: number | null;
}

class PontoDiario extends React.Component<Props, State> {
  state: State = {
    filtros: {
      data: new Date()
    },
    erroGeral: '',
    chaveTabela: 0,
    indice: null
  };

  async componentDidMount() {
    await this.atualizarTabela();
  }

  handleDateChange = (value: Date, name: string) => {
    const novosFiltros = {
      ...this.state.filtros,
      [name]: value
    };

    this.setState(
      {
        ...this.state,
        filtros: novosFiltros,
        chaveTabela: this.state.chaveTabela + 1,
        erroGeral: ''
      },
      async () => {
        await this.atualizarTabela();
      }
    );
  };

  atualizarTabela = async () => {
    if (this.state.erroGeral.length > 0) {
      return;
    }

    const { data } = this.state.filtros;

    await this.props.container.loadPontoDiarioAsync({
      data: data
    });

    this.setState({
      chaveTabela: this.state.chaveTabela + 1
    });
  };

  render() {
    const listaOrdenada = this.props.container.state.dados.lista;
    const { indice, erroGeral } = this.state;

    const onLeftPress =
      indice != null && indice > 0
        ? () => this.setState({ indice: indice - 1 })
        : undefined;

    const onRightPress =
      listaOrdenada && indice != null && indice < listaOrdenada.length - 1
        ? () => this.setState({ indice: indice + 1 })
        : undefined;

    return (
      <LoggedInScreen screenTitle={translate('Acompanhamento de Ponto')}>
        <Card>
          <Card.Header title={translate('Filtrar')} />
          <Card.Section>
            <DatePicker
              style={[
                {
                  width:
                    Platform.OS === 'web' && isHighResolution() ? 342 : '100%'
                }
              ]}
              clearable={false}
              label={translate('Data')}
              value={this.state.filtros.data}
              onChange={date =>
                this.handleDateChange(date || new Date(), 'data')
              }
            />
            <ErrorMessage message={erroGeral} />
          </Card.Section>
        </Card>
        <View style={styles.view}>
          <Space height={10} />
          <FlatList
            ListHeaderComponent={
              isHighResolution() ? (
                <>
                  <Card style={styles.headerCard}>
                    <Card.Section style={styles.headerSection}>
                      <Subscribe to={[FuncionarioContainer]}>
                        {(funcionario: FuncionarioContainer) => (
                          <HeaderDiaResumido
                            tipoDeTela={TipoDeTela.PontoDiario}
                            usaPonto10Batidas={
                              funcionario.state.dados.usaPonto10Batidas
                            }
                            tipoPerfilFuncionario={
                              funcionario.state.dados.dadosPerfilFuncionario
                                ? funcionario.state.dados.dadosPerfilFuncionario
                                    .tipoPerfil
                                : funcionario.state.dados.perfil
                            }
                            tipoPlanoFuncionario={funcionario.state.dados.plano}
                          />
                        )}
                      </Subscribe>
                    </Card.Section>
                  </Card>
                  <Space />
                </>
              ) : null
            }
            key={this.state.chaveTabela}
            data={listaOrdenada}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) => (
              <>
                <CardDiaResumido
                  funcionarioNome={item.funcionarioNome}
                  indice={index}
                  data={item.data}
                  batidas={item.batidas}
                  saldo={item.saldo}
                  situacao={item.situacao}
                  registroPendente={item.registroPendente}
                  tipoDeTela={TipoDeTela.PontoDiario}
                  onDetalhesClick={index => {
                    this.setState({ indice: index });
                  }}
                />
                <Space height={10} />
              </>
            )}
          />
        </View>

        {indice != null && listaOrdenada && (
          <Modal visible={indice != null} overlayStyle={styles.modal}>
            <View style={styles.modalContainer}>
              <ImageButton
                nativeID="cartao-ponto-resumido-fechar"
                style={styles.imageButton}
                icon="close"
                onPress={() => this.setState({ indice: null })}
              />
              <View
                nativeID="cartao-ponto-resumido-dados"
                style={styles.modalSection}
              >
                <DetalheDiaBatida
                  dados={listaOrdenada[indice]}
                  tipoDeTela={TipoDeTela.PontoDiario}
                  onLeftPress={onLeftPress}
                  onRightPress={onRightPress}
                />
              </View>
            </View>
          </Modal>
        )}
        <Message
          type="warning"
          visible={this.props.container.state.erro !== ''}
          message={this.props.container.state.erro}
          onRequestClose={() => this.props.container.limparErro()}
        />
      </LoggedInScreen>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  view: {
    marginHorizontal: -3
  },
  cartaoPontoCard: {
    marginHorizontal: -3
  },
  headerCard: {
    marginHorizontal: 3
  },
  headerSection: {
    paddingVertical: 8
  },
  imageButton: {
    borderWidth: 0,
    marginLeft: 'auto'
  },
  modal: {
    overflow: 'scroll'
  },
  modalContainer: {
    margin: 'auto',
    width: '90%',
    maxWidth: 366,
    backgroundColor: theme.backgroundColor2,
    borderRadius: 10,
    justifyContent: 'center',
    marginBottom: 10,
    marginTop: 10
  },
  modalSection: {
    width: '90%',
    alignSelf: 'center',
    marginBottom: 25
  }
});

export default withContainer(BatidasContainer)(PontoDiario);
