import * as React from 'react';
import { StyleSheet, View, Dimensions, Platform } from 'react-native';
import { translate, translateFirstUpper } from 'secullum-i18n';
import {
  Card,
  Details,
  DetailsHeader,
  DetailsLine,
  Message,
  Space,
  Text,
  formatDate,
  isTablet
} from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import { FuncionarioContainer } from '../../shared/containers/FuncionarioContainer';
import {
  getCorStatus,
  getIconeStatus
} from '../../shared/containers/SolicitacoesContainer';
import { getTheme } from '../../shared/modules/layout';
import { primeiraLetraMaisculaCadaPalavra } from '../../shared/modules/string';
import {
  SolicitacoesDados,
  SolicitacoesStatus,
  BatidasItem,
  TipoSolicitacao,
  TipoAlteracaoFonteDados,
  SolicitacaoFoto,
  ConfiguracoesEspeciaisAcesso,
  TipoControleSolicitacoes,
  TipoPlano,
  DefaultList
} from '../../shared/modules/types';
import Api from '../modules/api';
import { ehHora, ehSolicitacaoAfastamento } from '../modules/utils';
import { ButtonBar } from './ButtonBar';
import SolicitacaoProcessamento from './SolicitacaoProcessamento';

interface Props {
  solicitacao: SolicitacoesDados;
  gerente: boolean;
  configuracoesEspeciaisAcesso: ConfiguracoesEspeciaisAcesso;
  onBatidaOriginalPress: (batida: BatidasItem) => void;
  onErroConexao?: (mensagem: string, onTentarNovamente: () => void) => void;
  renderFoto: (foto: string) => React.ReactNode;
}

interface State {
  larguraImagem: number;
  solicitacaoAtual: SolicitacoesDados | null;
  tipoAcao: TipoAlteracaoFonteDados | null;
  erros: string | null;
  solicitacaoFoto: SolicitacaoFoto | null;
  solicitacaoComprovanteEndereco: SolicitacaoFoto | null;
  solicitacaoEscolaridade: string | null;
}

class SolicitacaoDetalhes extends React.Component<Props, State> {
  state: State = {
    larguraImagem: Dimensions.get('window').width - 64, // 64 = paddingHorizontal
    solicitacaoAtual: null,
    tipoAcao: null,
    erros: null,
    solicitacaoFoto: null,
    solicitacaoComprovanteEndereco: null,
    solicitacaoEscolaridade: null
  };

  async componentDidMount() {
    const { solicitacao } = this.props;

    await this.carregarFotoSolicitacaoAsync();
    await this.carregarEscolaridadeAsync();

    this.setState({ solicitacaoAtual: solicitacao });
  }

  carregarFotoSolicitacaoAsync = async () => {
    const { solicitacao } = this.props;

    if (solicitacao.solicitacaoFotoId) {
      const api = new Api();

      await api.getSolicitacaoFoto(solicitacao.solicitacaoFotoId, {
        onSuccess: (resp: SolicitacaoFoto) => {
          this.setState({ solicitacaoFoto: resp });
        },
        onError: (err: Error) => {
          if (err.name === 'TimeoutError' && this.props.onErroConexao) {
            this.props.onErroConexao(err.message, () =>
              this.carregarFotoSolicitacaoAsync()
            );
          } else {
            this.setState({ erros: err.message });
          }
        }
      });
    } else if (solicitacao.dados) {
      const dados = JSON.parse(solicitacao.dados);

      if (dados.PossuiFoto) {
        const api = new Api();

        await api.getSolicitacaoFotoFuncionario(solicitacao.id, {
          onSuccess: (resp: SolicitacaoFoto) => {
            this.setState({ solicitacaoFoto: resp });
          },
          onError: (err: Error) => {
            if (err.name === 'TimeoutError' && this.props.onErroConexao) {
              this.props.onErroConexao(err.message, () =>
                this.carregarFotoSolicitacaoAsync()
              );
            } else {
              this.setState({ erros: err.message });
            }
          }
        });
      }

      if (dados.PossuiComprovanteEndereco) {
        const api = new Api();

        await api.getSolicitacaoComprovanteEndereco(solicitacao.id, {
          onSuccess: (resp: SolicitacaoFoto) => {
            this.setState({ solicitacaoComprovanteEndereco: resp });
          },
          onError: (err: Error) => {
            if (err.name === 'TimeoutError' && this.props.onErroConexao) {
              this.props.onErroConexao(err.message, () =>
                this.carregarFotoSolicitacaoAsync()
              );
            } else {
              this.setState({ erros: err.message });
            }
          }
        });
      }
    }
  };

  carregarEscolaridadeAsync = async () => {
    const { solicitacao } = this.props;

    if (!solicitacao.dados) {
      return;
    }

    const dados = JSON.parse(solicitacao.dados);

    if (!dados.EscolaridadeId) {
      return;
    }

    await new Api().getEscolaridade(dados.EscolaridadeId, {
      onSuccess: (resp: DefaultList) => {
        if (resp) {
          this.setState({ solicitacaoEscolaridade: resp.descricao });
        }
      },
      onError: (err: Error) => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () =>
            this.carregarEscolaridadeAsync()
          );
        } else {
          this.setState({ erros: err.message });
        }
      }
    });
  };

  finalizarProcessamento = () => {
    this.setState({ tipoAcao: null });
  };

  concluirProcessamentoComErro = (erros: string) => {
    const { solicitacaoAtual } = this.state;
    const alteracoesRollBack = solicitacaoAtual!.alteracoesFonteDados.map(
      alteracao => ({ ...alteracao, motivo: null })
    );

    this.setState({
      solicitacaoAtual: {
        ...solicitacaoAtual!,
        alteracoesFonteDados: alteracoesRollBack
      },
      tipoAcao: null,
      erros
    });
  };

  renderBotoes = () => {
    const { solicitacaoAtual } = this.state;

    return (
      <Subscribe to={[FuncionarioContainer]}>
        {(funcionario: FuncionarioContainer) => (
          <View style={styles.containerObservacoes}>
            <ButtonBar
              leftButton={
                funcionario.state.dados.plano >= TipoPlano.Pro &&
                funcionario.state.dados.dadosPerfilFuncionario
                  .controleSolicitacoes === TipoControleSolicitacoes.MarcarVisto
                  ? {
                      text:
                        solicitacaoAtual!.status === SolicitacoesStatus.Visto
                          ? primeiraLetraMaisculaCadaPalavra(
                              translate('Desmarcar Visto')
                            )
                          : primeiraLetraMaisculaCadaPalavra(
                              translate('Marcar Visto')
                            ),
                      onPress: () =>
                        this.setState({
                          tipoAcao:
                            solicitacaoAtual!.status ===
                            SolicitacoesStatus.Visto
                              ? TipoAlteracaoFonteDados.DesmarcarVisto
                              : TipoAlteracaoFonteDados.MarcarVisto
                        }),
                      style: { backgroundColor: theme.backgroundColor3 },
                      nativeID: 'botao-visto-solicitacao-detalhes'
                    }
                  : {
                      text: translate('Aprovar'),
                      onPress: () =>
                        this.setState({
                          tipoAcao: TipoAlteracaoFonteDados.Troca
                        }),
                      style: { backgroundColor: theme.successColor },
                      nativeID: 'botao-aprovar-solicitacao-detalhes'
                    }
              }
              rightButton={{
                text: translate('Reprovar'),
                onPress: () =>
                  this.setState({
                    tipoAcao: TipoAlteracaoFonteDados.ApagarFonteDados
                  }),
                style: { backgroundColor: theme.errorColor },
                nativeID: 'botao-reprovar-solicitacao-detalhes'
              }}
            />
          </View>
        )}
      </Subscribe>
    );
  };

  renderObservacao = (titulo: string, observacao: string) => {
    return (
      <View style={styles.containerObservacoes}>
        <Text style={styles.textObservacao}>{titulo}</Text>
        <Text style={styles.textObservacao}>{observacao}</Text>
      </View>
    );
  };

  getTextoStatus = () => {
    const { solicitacaoAtual } = this.state;

    switch (solicitacaoAtual!.status) {
      case SolicitacoesStatus.Aceita:
        return translate('Aceito');
      case SolicitacoesStatus.Descartada:
        return translate('Rejeitado');
      case SolicitacoesStatus.Visto:
        return translate('Visto');
      default:
        return translate('Aguardando Retorno');
    }
  };

  montarLinhasInformacoes = () => {
    const { solicitacaoAtual, solicitacaoFoto } = this.state;
    const { configuracoesEspeciaisAcesso } = this.props;

    if (!solicitacaoAtual) {
      return [];
    }

    const linhasInformacoes: Array<DetailsLine> = [
      {
        title: translate('Tipo'),
        value: solicitacaoAtual.tipo
      },
      {
        title: translate('Motivo'),
        value: solicitacaoAtual.motivo || '-'
      },
      {
        title: translate('Status'),
        value: this.getTextoStatus(),
        icons: [
          {
            name: getIconeStatus(solicitacaoAtual.status),
            color: getCorStatus(solicitacaoAtual.status),
            size: 18
          }
        ]
      }
    ];

    if (solicitacaoAtual.dataSolicitacao) {
      linhasInformacoes.push({
        title: translate('Solicitado em'),
        value: formatDate(solicitacaoAtual.dataSolicitacao, 'dd/MM/yyyy HH:mm')
      });
    }

    if (
      configuracoesEspeciaisAcesso &&
      configuracoesEspeciaisAcesso.tecpontoIntero &&
      solicitacaoAtual.tipoSolicitacao == TipoSolicitacao.AlteracaoDePonto
    ) {
      linhasInformacoes.push({
        title: translate('Centro de Custos'),
        value: solicitacaoAtual.filtro1Descricao
      });

      linhasInformacoes.push({
        title: translate('Serviços'),
        value: solicitacaoAtual.filtro2Descricao
      });

      linhasInformacoes.push({
        title: translate('Periculosidade'),
        value: solicitacaoAtual.periculosidade || ''
      });
    }
    if (
      this.props.gerente &&
      (solicitacaoAtual.status == SolicitacoesStatus.Pendente ||
        solicitacaoAtual.status == SolicitacoesStatus.Visto)
    ) {
      linhasInformacoes.push({
        render: () => this.renderBotoes()
      });
    }

    linhasInformacoes.push({
      render: () =>
        this.renderObservacao(
          translate('Observação'),
          solicitacaoAtual.observacao
        )
    });

    if (solicitacaoFoto) {
      linhasInformacoes.push({
        render: () => (
          <>
            <Text style={styles.textTituloFoto}>
              {translateFirstUpper('Foto do Atestado')}
            </Text>
            {this.props.renderFoto(solicitacaoFoto.foto)}
          </>
        )
      });
    }

    return linhasInformacoes;
  };

  montarLinhasInformacoesDadosCadastrais = () => {
    const { solicitacaoAtual } = this.state;

    if (!solicitacaoAtual) {
      return [];
    }

    const linhasInformacoes: Array<DetailsLine> = [
      {
        title: translate('Tipo'),
        value: solicitacaoAtual.tipo
      },
      {
        title: translate('Status'),
        value: this.getTextoStatus(),
        icons: [
          {
            name: getIconeStatus(solicitacaoAtual.status),
            color: getCorStatus(solicitacaoAtual.status),
            size: 18
          }
        ]
      }
    ];

    if (solicitacaoAtual.dataSolicitacao) {
      linhasInformacoes.push({
        title: translate('Solicitado em'),
        value: formatDate(solicitacaoAtual.dataSolicitacao, 'dd/MM/yyyy HH:mm')
      });
    }

    if (
      this.props.gerente &&
      (solicitacaoAtual.status == SolicitacoesStatus.Pendente ||
        solicitacaoAtual.status == SolicitacoesStatus.Visto)
    ) {
      linhasInformacoes.push({
        render: () => this.renderBotoes()
      });
    }

    return linhasInformacoes;
  };

  montarLinhasDadosCadastrais(solicitacao: SolicitacoesDados): DetailsLine[] {
    const {
      solicitacaoFoto,
      solicitacaoEscolaridade,
      solicitacaoComprovanteEndereco
    } = this.state;

    const dados = JSON.parse(solicitacao.dados);

    const linhasDadosCadastrais: Array<DetailsLine> = [
      { title: translate('Endereço'), value: dados.Endereco || '-' },
      { title: translate('Bairro'), value: dados.Bairro || '-' },
      { title: translate('Cidade'), value: dados.Cidade || '-' },
      { title: translate('Estado'), value: dados.Estado || '-' },
      { title: translate('CEP'), value: dados.Cep || '-' },
      { title: translate('Telefone'), value: dados.Telefone || '-' },
      { title: translate('Celular'), value: dados.Celular || '-' },
      { title: translate('Email'), value: dados.Email || '-' },
      { title: translate('RG'), value: dados.Rg || '-' },
      {
        title: translate('Expedição'),
        value: dados.ExpedicaoRg
          ? formatDate(dados.ExpedicaoRg, 'dd/MM/yyyy')
          : '-'
      },
      { title: translate('SSP'), value: dados.Ssp || '-' },
      { title: translate('CPF'), value: dados.Cpf || '-' },
      { title: translate('Pai'), value: dados.Pai || '-' },
      { title: translate('Mãe'), value: dados.Mae || '-' },
      {
        title: translate('Nascimento'),
        value: dados.Nascimento
          ? formatDate(dados.Nascimento, 'dd/MM/yyyy')
          : '-'
      },
      { title: translate('Nacionalidade'), value: dados.Nacionalidade || '-' },
      { title: translate('Naturalidade'), value: dados.Naturalidade || '-' },
      {
        title: translate('Escolaridade'),
        value: solicitacaoEscolaridade || '-'
      }
    ];

    if (solicitacaoFoto) {
      linhasDadosCadastrais.push({
        render: () => (
          <>
            <Text style={styles.textTituloFoto}>
              {translateFirstUpper('Foto do Funcionário')}
            </Text>
            {this.props.renderFoto(solicitacaoFoto.foto)}
          </>
        )
      });
    }

    if (solicitacaoComprovanteEndereco) {
      linhasDadosCadastrais.push({
        render: () => (
          <>
            <Text style={styles.textTituloFoto}>
              {translate('Comprovante de endereço')}
            </Text>
            {this.props.renderFoto(solicitacaoComprovanteEndereco.foto)}
          </>
        )
      });
    }

    return linhasDadosCadastrais;
  }

  montarLinhasRegistroPonto = (solicitacao: SolicitacoesDados) => {
    const linhasRegistroPonto: Array<DetailsLine> = solicitacao.batidas.map(
      batida => {
        const icons = [];

        if (
          batida.valor != null &&
          batida.valor.length > 0 &&
          ehHora(batida.valor) &&
          batida.valorOriginal != batida.valor
        ) {
          icons.push({
            name: 'pencil',
            color: theme.textColor3,
            size: isTablet() ? 22 : 18,
            onPress: () => this.props.onBatidaOriginalPress(batida)
          });
        }

        return {
          title: batida.nome,
          value: batida.valor || '-',
          icons
        };
      }
    );

    return linhasRegistroPonto;
  };

  render() {
    const { solicitacao, gerente } = this.props;
    const { solicitacaoAtual, tipoAcao, erros } = this.state;
    const exibirCampoMotivoRejeicao =
      solicitacao.motivoRejeicao && solicitacao.motivoRejeicao.length != 0
        ? true
        : false;

    const formatoData =
      Platform.OS === 'web' ? 'eeee, dd/MM/yyyy' : 'dd/MM/yyyy';

    const dataFormatada =
      ehSolicitacaoAfastamento(solicitacao.tipoSolicitacao) &&
      solicitacao.dataFim
        ? `${formatDate(solicitacao.data, formatoData)} ${translate(
            'Até'
          ).toLowerCase()} ${formatDate(solicitacao.dataFim, formatoData)}`
        : formatDate(solicitacao.data, formatoData);

    return (
      <>
        <DetailsHeader
          text={`${
            gerente ? solicitacao.funcionarioNome + '\n' : ''
          }${dataFormatada}`}
        />
        <Space />
        <Details
          title={translate('Informações')}
          lines={
            solicitacao.tipoSolicitacao !==
              TipoSolicitacao.AtualizacaoDadosCadastrais &&
            solicitacao.tipoSolicitacao !== TipoSolicitacao.ExclusaoDeConta
              ? this.montarLinhasInformacoes()
              : this.montarLinhasInformacoesDadosCadastrais()
          }
        />
        <Space />
        {solicitacao.dados ? (
          <>
            <Details
              title={translate('Dados Cadastrais')}
              lines={this.montarLinhasDadosCadastrais(solicitacao)}
              lineValueStyle={{ textAlign: 'right' }}
            />
            <Space />
          </>
        ) : null}
        {solicitacao.batidas.length > 0 &&
        !ehSolicitacaoAfastamento(solicitacao.tipoSolicitacao) ? (
          <>
            <Details
              title={
                solicitacao.tipo !== translate('Ajuste Parcial')
                  ? translate('Registro de Ponto')
                  : translate('Período')
              }
              lines={this.montarLinhasRegistroPonto(solicitacao)}
            />
            <Space />
          </>
        ) : null}
        {exibirCampoMotivoRejeicao && (
          <Card>
            <Card.Section style={styles.reprovacaoSection}>
              {this.renderObservacao(
                translate('Motivo Rejeição'),
                solicitacao.motivoRejeicao || '-'
              )}
            </Card.Section>
          </Card>
        )}

        {tipoAcao != null && solicitacaoAtual && (
          <SolicitacaoProcessamento
            solicitacaoAtual={solicitacaoAtual}
            tipoAcao={tipoAcao}
            concluirEnvioSucesso={solicitacao =>
              this.setState({
                tipoAcao: null,
                solicitacaoAtual: solicitacao
              })
            }
            concluirEnvioErro={erros =>
              this.concluirProcessamentoComErro(erros)
            }
            finalizarProcessamento={this.finalizarProcessamento}
          />
        )}

        <Message
          nativeID="processar-solicitacao-erro"
          type="warning"
          visible={erros !== null}
          message={erros || ''}
          onRequestClose={() => this.setState({ erros: null })}
        />
      </>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  containerObservacoes: {
    paddingHorizontal: 16,
    paddingVertical: 10
  },
  reprovacaoSection: {
    padding: 0
  },
  textObservacao: {
    fontSize: isTablet() ? 18 : 14,
    color: theme.textColor3
  },
  textTituloFoto: {
    paddingTop: 10,
    paddingHorizontal: 16,
    fontSize: isTablet() ? 18 : 14,
    fontFamily: theme.fontFamily2,
    color: theme.textColor1
  }
});

export default SolicitacaoDetalhes;
