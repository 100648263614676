import { CameraType } from 'expo-camera';
import * as React from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Button,
  Message,
  ImageButton,
  DimensionsMonitor,
  Loading
} from 'secullum-react-native-ui';
import { getTheme } from '../../shared/modules/layout';

interface Props {
  onConcluir: (face: string) => void;
  onFechar: () => void;
  larguraImagem: number;
  cameraPadrao: CameraType;
  permiteAlterarCamera: boolean;
  mensagemErroCameraNaoEncontrada: string;
}

interface State {
  hasCameraPermission: boolean | null;
  mensagemErro: string | null;
  processando: boolean;
}

class CapturarImagem extends React.Component<Props, State> {
  video = React.createRef<HTMLVideoElement>();

  static defaultProps = {
    larguraImagem: 240,
    cameraPadrao: 'front',
    permiteAlterarCamera: true
  };

  state: State = {
    hasCameraPermission: null,
    mensagemErro: null,
    processando: true
  };

  async componentDidMount() {
    const getUserMedia = this.getUserMediaFunction();

    if (!getUserMedia) {
      this.setState({
        hasCameraPermission: false,
        mensagemErro:
          translate(
            'Não foi possível iniciar a captura. Verifique se a webcam está conectada e se há permissão no navegador'
          ) + '.'
      });

      return;
    }

    getUserMedia(
      { video: true },
      //@ts-ignore
      stream => {
        this.setState({ hasCameraPermission: true }, () => {
          if (this.video.current) {
            this.video.current.srcObject = stream;
            this.video.current.play();
          }
        });
      },
      //@ts-ignore
      error =>
        this.setState({
          hasCameraPermission: false,
          processando: false,
          mensagemErro: this.buscarMensagemErro(error)
        })
    );
  }

  pararVideo = () => {
    //@ts-ignore
    this.video.current!.pause();
    (this.video.current!.srcObject as MediaStream).getTracks()[0].stop();
    this.video.current!.src = '';
  };

  getUserMediaFunction = () => {
    const nativeGetUserMedia =
      //@ts-ignore
      navigator.getUserMedia ||
      //@ts-ignore
      navigator.webkitGetUserMedia ||
      //@ts-ignore
      navigator.mozGetUserMedia ||
      //@ts-ignore
      navigator.msGetUserMedia;

    return nativeGetUserMedia ? nativeGetUserMedia.bind(navigator) : null;
  };

  takePicture = () => {
    const canvas = document.createElement('canvas');

    let largura = this.video.current!.videoWidth;
    let altura = this.video.current!.videoHeight;

    if (largura > 640) {
      const taxaDiferenca = 640 / largura;
      largura = 640;

      altura *= taxaDiferenca;
    }

    if (altura > 480) {
      const taxaDiferenca = 480 / altura;
      altura = 480;

      largura *= taxaDiferenca;
    }

    canvas.width = largura;
    canvas.height = altura;

    const ctx2d = canvas.getContext('2d');
    ctx2d!.drawImage(
      this.video.current!,
      0,
      0,
      this.video.current!.videoWidth,
      this.video.current!.videoHeight,
      0,
      0,
      largura,
      altura
    );

    const image = canvas.toDataURL('image/jpeg', 0.5);

    this.props.onConcluir(
      image.replace(/^data:image\/[a-z]+;base64,/, '') || ''
    );

    this.pararVideo();
  };

  buscarMensagemErro = (error: MediaStream) => {
    //@ts-ignore
    if (['NotFoundError', 'DevicesNotFoundError'].includes(error.name)) {
      return this.props.mensagemErroCameraNaoEncontrada;
    } else if (
      ['SecurityError', 'PermissionDeniedError', 'NotAllowedError'].includes(
        //@ts-ignore
        error.name
      )
    ) {
      return translate(
        'Não foi possível capturar a imagem. Verifique a permissão de acesso à câmera no seu navegador.'
      );
    } else {
      return translate('Houve um erro ao capturar a imagem.');
    }
  };

  render() {
    const { hasCameraPermission, mensagemErro, processando } = this.state;

    const mediumScreen = Dimensions.get('window').width <= 800;
    const smallScreen = Dimensions.get('window').width <= 500;

    return (
      <View style={styles.container}>
        {hasCameraPermission === null ? (
          <View />
        ) : hasCameraPermission === false || mensagemErro ? (
          <Message
            type="warning"
            message={mensagemErro || translate('Sem acesso à câmera')}
            visible
            onRequestClose={this.props.onFechar}
          />
        ) : (
          <View style={[styles.cameraContainer]}>
            {processando && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: 999,
                  backgroundColor: 'rgba(33, 33, 33, 0.7)'
                }}
              >
                <Loading />
              </div>
            )}
            <DimensionsMonitor onDimensionChange={this.props.onFechar} />
            <ImageButton
              nativeID="capturar-imagem-fechar"
              style={styles.imageButton}
              icon="close"
              onPress={() => {
                this.pararVideo();
                this.props.onFechar();
              }}
            />

            <video
              style={{
                marginLeft: 20,
                marginRight: 20,
                marginBottom: 20,
                border: '1px solid #0000003b',
                borderRadius: 6,
                width: smallScreen ? 260 : mediumScreen ? 420 : 640
              }}
              onCanPlay={() => this.setState({ processando: false })}
              ref={this.video}
              id="video"
            />

            <View style={styles.buttonContainer}>
              <Button
                nativeID="capturar-imagem-capturar"
                style={{ width: 166 }}
                onPress={this.takePicture}
                text="Capturar"
              />
            </View>
          </View>
        )}
      </View>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
    justifyContent: 'center'
  },
  imageButton: {
    borderWidth: 0,
    height: 30,
    marginLeft: 'auto'
  },
  cameraContainer: {
    backgroundColor: theme.backgroundColor1,
    alignSelf: 'center',
    alignItems: 'center',
    borderRadius: 6,
    paddingBottom: 20
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    width: '100%'
  }
});

export default CapturarImagem;
