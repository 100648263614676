import * as React from 'react';
import {
  View,
  StyleSheet,
  Dimensions,
  Platform,
  EmitterSubscription
} from 'react-native';
import RenderHtml, { defaultSystemFonts } from 'react-native-render-html';
import { translate } from 'secullum-i18n';
import {
  CheckBox,
  getTheme,
  Button,
  isTablet,
  Card
} from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import ViewPrincipal from '../../native/components/ViewPrincipal';
import AvisoTermosSensiveis, {
  AvisoDadosSensiveisLgpdContainer
} from '../containers/AvisoDadosSensiveisLgpdContainer';
import Api from '../modules/api';
import { isDesktop } from '../modules/layout';

interface State {
  aceitouTermos: boolean;
}

class AvisoDadosSensiveis extends React.Component {
  state: State = {
    aceitouTermos: false
  };

  screenChangeDimensionsSubscription: EmitterSubscription | undefined;

  componentDidMount() {
    this.screenChangeDimensionsSubscription = Dimensions.addEventListener(
      'change',
      this.handleDimensionsChange
    );

    AvisoTermosSensiveis.carregarTextoTermosDadosSensiveis();
  }

  componentWillUnmount() {
    if (this.screenChangeDimensionsSubscription) {
      this.screenChangeDimensionsSubscription.remove();
    }
  }

  handleDimensionsChange = () => {
    this.forceUpdate();
  };

  handleOnPressButtonAsync = async (
    avisoTermosLgpd: AvisoDadosSensiveisLgpdContainer
  ) => {
    const api = new Api();

    await api.salvarAceiteTermosLgpd({
      onSuccess: () => {
        avisoTermosLgpd.manterTelaFechada(true);
      }
    });
  };

  render() {
    const { aceitouTermos } = this.state;

    // Como usamos o componente RenderHtml, ele não permite usar as fonts padrões do sistema
    // Então precisamos incluir a nossa font, na lista de fontes do componente externo
    const systemFonts = [...defaultSystemFonts, 'Roboto', 'Roboto-Medium'];

    return (
      <ViewPrincipal>
        <Subscribe to={[AvisoDadosSensiveisLgpdContainer]}>
          {(avisoTermosLgpd: AvisoDadosSensiveisLgpdContainer) => {
            return (
              <Card
                nativeID="aviso-termos-lgpd-card"
                style={styles.containerPrincipal}
              >
                <RenderHtml
                  source={{
                    html: avisoTermosLgpd.state.textoTermo ?? ''
                  }}
                  // Foi necessário incluir o ignore, pois com a atualização do react-native para a versão 0.73
                  // o tipo de retorno de algumas propriedades dos estilos mudou, antes eram strings, agora são DimensionValue
                  // E a lib RenderHtml ainda não foi atualizada para usar essa nova tipagem.
                  // Por mais que o ts reclame, não dá nenhum erro continuar usando a tipagem antiga, pelo menos por enquanto.
                  // @ts-ignore
                  classesStyles={classStyles}
                  contentWidth={1}
                  systemFonts={systemFonts}
                />
                <View style={styles.containerCheckbox}>
                  <CheckBox
                    label={translate('Li e concordo com os termos')}
                    value={aceitouTermos}
                    onChange={aceitouTermos => this.setState({ aceitouTermos })}
                    nativeID="aviso-termos-lgpd-checkbox-li-concordo"
                  />
                </View>
                <Button
                  nativeID="aviso-termos-lgpd-button-continuar"
                  text={translate('Continuar')}
                  onPress={async () => {
                    await this.handleOnPressButtonAsync(avisoTermosLgpd);
                  }}
                  style={styles.botao}
                  disabled={!aceitouTermos}
                />
              </Card>
            );
          }}
        </Subscribe>
      </ViewPrincipal>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  containerPrincipal: {
    alignItems: 'flex-start',
    alignSelf: 'center',
    borderRadius: 6,
    fontFamily: theme.fontFamily1,
    justifyContent: 'flex-start',
    marginHorizontal: isDesktop() || isTablet() ? undefined : 10,
    marginVertical: 0,
    paddingHorizontal: 10,
    textAlign: 'left',
    width: isDesktop() || isTablet() ? 400 : undefined
  },
  containerCheckbox: {
    marginBottom: 5,
    fontFamily: theme.fontFamily3,
    marginLeft: Platform.OS === 'web' ? 10 : 0
  },
  botao: {
    alignSelf: 'flex-start',
    alignItems: 'center',
    borderColor: 'transparent',
    marginLeft: isDesktop() ? 10 : 0,
    marginVertical: 15,
    width: Platform.OS === 'web' ? 365 : 285
  }
});

const classStyles = {
  titulo: {
    color: '#555555',
    fontFamily: 'Roboto-Medium',
    fontSize: Platform.OS === 'web' ? 20 : 18,
    margin: '10px auto'
  },
  linkPoliticaPrivacidade: {
    color: theme.textColor3,
    fontFamily: 'Roboto',
    fontSize: 16,
    marginTop: -4
  },
  divMensagem: {
    marginLeft: Platform.OS === 'web' ? 10 : 0
  },
  divMensagemTexto: {
    color: theme.textColor3,
    fontFamily: 'Roboto',
    fontSize: isTablet() ? 25 : 16
  }
};

export default AvisoDadosSensiveis;
