import * as React from 'react';
import {
  StyleSheet,
  Pressable,
  ImageSourcePropType,
  Image,
  View,
  Dimensions
} from 'react-native';
import { translate } from 'secullum-i18n';
import { getTheme, Space, Text } from 'secullum-react-native-ui';
import {
  QualidadeVidaTrabalho,
  TipoRespostaQualidadeVidaTrabalho
} from '../../modules/types';

const imagemExelente = require('../../images/humor-excelente.png');
const imagemMuitoBem = require('../../images/humor-muito-bem.png');
const imagemBem = require('../../images/humor-bem.png');
const imagemNaoEstouBem = require('../../images/humor-nao-estou-bem.png');
const imagemMal = require('../../images/humor-mal.png');

interface QualidadeVidaTrabalhoOpcoesWebProps {
  dados: QualidadeVidaTrabalho;
  onConcluir: (resposta: TipoRespostaQualidadeVidaTrabalho) => void;
}

interface State {
  display: 'none' | 'flex';
  dadosDescricao: {
    cor: string;
    titulo: string;
    descricao: string;
  };
}

interface QualidadeVidaTrabalhoOpcaoProps {
  text: string;
  image: ImageSourcePropType;
  nativeID: string;
  onPress: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

function QualidadeVidaTrabalhoOpcao({
  text,
  image,
  nativeID,
  onPress,
  onMouseEnter,
  onMouseLeave
}: QualidadeVidaTrabalhoOpcaoProps) {
  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => [{ opacity: pressed ? 0.7 : 1 }]}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '0px 10px'
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Image
          nativeID={`qvt-resposta-${nativeID}-imagem`}
          style={styles.opcaoImagem}
          source={image}
        />
        <Text
          nativeID={`qvt-resposta-${nativeID}-texto`}
          style={styles.opcaoText}
        >
          {text}
        </Text>
      </div>
    </Pressable>
  );
}

class QualidadeVidaTrabalhoOpcoesWeb extends React.Component<QualidadeVidaTrabalhoOpcoesWebProps> {
  state: State = {
    display: 'none',
    dadosDescricao: {
      cor: '',
      descricao: '',
      titulo: ''
    }
  };

  handleLimparHover = () => {
    this.setState({
      display: 'none',
      dadosDescricao: {
        cor: '',
        descricao: '',
        titulo: ''
      }
    });
  };

  render() {
    const { dados, onConcluir } = this.props;
    const { dadosDescricao, display } = this.state;

    return (
      <View
        style={[
          styles.container,
          { marginTop: Dimensions.get('window').height / 2 - 500 / 2 }
        ]}
      >
        <Text
          nativeID="qvt-pergunta"
          size={30}
          align="center"
          style={styles.perguntaText}
        >
          {dados.pergunta}
        </Text>
        <Space />
        <View style={styles.opcoesContainer}>
          <QualidadeVidaTrabalhoOpcao
            nativeID="excelente"
            text={dados.respostaExcelente}
            image={imagemExelente}
            onPress={() =>
              onConcluir(TipoRespostaQualidadeVidaTrabalho.Excelente)
            }
            onMouseEnter={() =>
              this.setState({
                display: 'flex',
                dadosDescricao: {
                  cor: '#75d83c',
                  titulo: dados.respostaExcelente,
                  descricao: dados.respostaExcelenteDescricao
                }
              })
            }
            onMouseLeave={this.handleLimparHover}
          />
          <Space />
          <QualidadeVidaTrabalhoOpcao
            nativeID="muito-bem"
            text={dados.respostaMuitoBem}
            image={imagemMuitoBem}
            onPress={() =>
              onConcluir(TipoRespostaQualidadeVidaTrabalho.MuitoBem)
            }
            onMouseEnter={() =>
              this.setState({
                display: 'flex',
                dadosDescricao: {
                  cor: '#adda15',
                  titulo: dados.respostaMuitoBem,
                  descricao: dados.respostaMuitoBemDescricao
                }
              })
            }
            onMouseLeave={this.handleLimparHover}
          />
          <Space />
          <QualidadeVidaTrabalhoOpcao
            nativeID="bem"
            text={dados.respostaBem}
            image={imagemBem}
            onPress={() => onConcluir(TipoRespostaQualidadeVidaTrabalho.Bem)}
            onMouseEnter={() =>
              this.setState({
                display: 'flex',
                dadosDescricao: {
                  cor: '#c2abf3',
                  titulo: dados.respostaBem,
                  descricao: dados.respostaBemDescricao
                }
              })
            }
            onMouseLeave={this.handleLimparHover}
          />
          <Space />
          <QualidadeVidaTrabalhoOpcao
            nativeID="nao-estou-bem"
            text={dados.respostaNaoEstouBem}
            image={imagemNaoEstouBem}
            onPress={() =>
              onConcluir(TipoRespostaQualidadeVidaTrabalho.NaoEstouBem)
            }
            onMouseEnter={() =>
              this.setState({
                display: 'flex',
                dadosDescricao: {
                  cor: '#ffa003',
                  titulo: dados.respostaNaoEstouBem,
                  descricao: dados.respostaNaoEstouBemDescricao
                }
              })
            }
            onMouseLeave={this.handleLimparHover}
          />
          <Space />
          <QualidadeVidaTrabalhoOpcao
            nativeID="mal"
            text={dados.respostaMal}
            image={imagemMal}
            onPress={() => onConcluir(TipoRespostaQualidadeVidaTrabalho.Mal)}
            onMouseEnter={() =>
              this.setState({
                display: 'flex',
                dadosDescricao: {
                  cor: '#f87d87',
                  titulo: dados.respostaMal,
                  descricao: dados.respostaMalDescricao
                }
              })
            }
            onMouseLeave={this.handleLimparHover}
          />
        </View>
        <Space />
        <View
          style={[
            styles.imagemHover,
            { backgroundColor: dadosDescricao.cor, display: display }
          ]}
        >
          <Text
            nativeID="qvt-detalhe-resposta-titulo"
            size={20}
            align="center"
            bold
            color={theme.backgroundColor2}
          >
            {dadosDescricao.titulo}
          </Text>
          <Space />
          <Text
            nativeID="qvt-detalhe-resposta-descricao"
            size={15}
            align="center"
            color={theme.backgroundColor2}
          >
            {dadosDescricao.descricao}
          </Text>
        </View>
        <Space />
        <Pressable
          style={({ pressed }) => [
            { opacity: pressed ? 0.7 : 1 },
            styles.linkContainer
          ]}
          onPress={() =>
            onConcluir(TipoRespostaQualidadeVidaTrabalho.NaoRespondeu)
          }
        >
          <Text nativeID="qvt-nao-responder" style={styles.link}>
            {translate('Não responder')}
          </Text>
        </Pressable>
      </View>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'space-between',
    backgroundColor: theme.backgroundColor1,
    borderRadius: 6,
    maxWidth: '95%',
    minWidth: 900,
    height: 500
  },
  perguntaText: {
    paddingBottom: 20,
    paddingTop: 60,
    color: theme.textColor1,
    width: 800
  },
  linkContainer: {
    marginTop: 'auto'
  },
  link: {
    fontFamily: theme.fontFamily2,
    color: theme.textColor2,
    fontSize: 20,
    textDecorationLine: 'underline',
    textAlign: 'center',
    paddingBottom: 20
  },
  opcoesContainer: {
    flexDirection: 'row',
    width: '85%',
    justifyContent: 'space-around',
    paddingHorizontal: 10
  },
  opcaoText: {
    fontFamily: theme.fontFamily1,
    fontSize: 22,
    color: theme.textColor1
  },
  opcaoImagem: {
    width: 80,
    height: 80
  },
  imagemHover: {
    width: '100%',
    height: 150,
    justifyContent: 'center'
  }
});

export default QualidadeVidaTrabalhoOpcoesWeb;
