import * as React from "react";
import Svg, { Defs, ClipPath, Path, G } from "react-native-svg";
const PontoHorizontalWeb = (props) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 78.581248 13.49375"
    id="svg180"
    {...props}
  >
    <Defs id="defs174">
      <ClipPath clipPathUnits="userSpaceOnUse" id="clipPath198">
        <Path d="M 0,84.23 H 340.158 V 0 H 0 Z" id="path196" />
      </ClipPath>
      <ClipPath clipPathUnits="userSpaceOnUse" id="clipPath362">
        <Path d="M 0,37.752 H 222.562 V 0 H 0 Z" id="path360" />
      </ClipPath>
      <ClipPath clipPathUnits="userSpaceOnUse" id="clipPath526">
        <Path d="M 0,37.752 H 228.635 V 0 H 0 Z" id="path524" />
      </ClipPath>
    </Defs>
    <G inkscapelabel="Layer 1" inkscapegroupmode="layer" id="layer1">
      <G
        id="g358"
        clipPath="url(#clipPath362)"
        transform="matrix(0.35277777,0,0,-0.35277777,0,13.318066)"
      >
        <G id="g364" transform="translate(170.0789,10.4319)">
          <Path
            d="m 0,0 v 11.576 c 0,2.911 -2.355,5.267 -5.267,5.267 -2.816,0 -5.237,-1.667 -6.337,-4.068 -0.544,2.328 -2.636,4.068 -5.127,4.068 -2.62,0 -5.002,-1.461 -6.199,-3.76 v 3.18 h -3.081 V 0 h 3.081 v 8.137 c 0,3.259 2.652,5.911 5.911,5.911 1.364,0 2.473,-1.109 2.473,-2.472 V 0 h 3.081 v 8.137 c 0,3.267 2.645,5.911 5.912,5.911 1.366,0 2.472,-1.105 2.472,-2.472 V 0 Z"
            style={{
              fill: "#75787b",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path366"
          />
        </G>
        <G id="g368" transform="translate(138.0828,26.6946)">
          <Path
            d="m 0,0 v -7.846 c 0,-3.26 -2.652,-5.912 -5.912,-5.912 -1.363,0 -2.472,1.109 -2.472,2.472 V 0 h -3.081 v -11.286 c 0,-2.904 2.362,-5.266 5.267,-5.266 2.618,0 5.002,1.46 6.198,3.76 v -3.474 H 3.081 V 0 Z"
            style={{
              fill: "#75787b",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path370"
          />
        </G>
        <Path
          d="m 120.33,10.432 h 3.094 v 23.522 h -3.094 z"
          style={{
            fill: "#75787b",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          id="path372"
        />
        <Path
          d="m 113.07,10.432 h 3.093 v 23.522 h -3.093 z"
          style={{
            fill: "#75787b",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          id="path374"
        />
        <G id="g376" transform="translate(106.5047,26.6946)">
          <Path
            d="m 0,0 v -7.846 c 0,-3.26 -2.652,-5.912 -5.912,-5.912 -1.363,0 -2.473,1.109 -2.473,2.472 V 0 h -3.081 v -11.286 c 0,-2.904 2.363,-5.266 5.267,-5.266 2.619,0 5.003,1.46 6.199,3.76 v -3.474 H 3.081 V 0 Z"
            style={{
              fill: "#75787b",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path378"
          />
        </G>
        <G id="g380" transform="translate(87.5545,24.5609)">
          <Path
            d="M 0,0 C 2.124,0 3.727,-0.719 4.871,-1.439 V 1.439 C 3.89,1.962 2.354,2.714 -0.164,2.714 c -5.036,0 -8.535,-3.629 -8.535,-8.567 0,-4.937 3.499,-8.567 8.535,-8.567 2.467,0 4.048,0.736 5.035,1.258 v 2.836 c -1.023,-0.613 -2.687,-1.38 -4.871,-1.38 -3.304,0 -5.592,2.421 -5.592,5.853 C -5.592,-2.419 -3.271,0 0,0"
            style={{
              fill: "#75787b",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path382"
          />
        </G>
        <G id="g384" transform="translate(69.6991,24.7244)">
          <Path
            d="M 0,0 C -2.812,0 -4.657,-2.158 -4.919,-4.512 H 4.414 C 4.447,-2.158 2.91,0 0,0 M 7.423,-5.068 V -6.67 H -4.951 v -0.033 c 0.097,-3.204 2.401,-5.231 5.834,-5.231 2.223,0 4.545,0.653 5.918,1.536 v -2.779 c -1.275,-0.719 -3.466,-1.406 -6.114,-1.406 -5.166,0 -8.665,3.499 -8.665,8.567 0,4.937 3.499,8.567 8.011,8.567 4.807,0 7.39,-3.597 7.39,-7.619"
            style={{
              fill: "#75787b",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path386"
          />
        </G>
        <G id="g388" transform="translate(52.8338,17.5308)">
          <Path
            d="m 0,0 c -3.429,0.948 -5.816,2.191 -5.816,5.134 0,2.713 2.888,4.61 6.895,4.61 2.08,0 4.122,-0.458 5.432,-0.981 V 6.016 C 5.124,6.637 3.197,7.161 1.464,7.161 c -2.195,0 -4.074,-0.654 -4.074,-2.126 0,-1.307 1.493,-1.929 4.113,-2.648 4.083,-1.145 6.086,-2.42 6.086,-5.166 0,-2.976 -2.851,-4.611 -7.011,-4.611 -2.504,0 -4.738,0.458 -6.279,1.079 v 2.78 c 1.888,-0.785 4.353,-1.341 6.164,-1.341 2.003,0 3.958,0.589 3.958,2.125 C 4.421,-1.341 2.851,-0.785 0,0"
            style={{
              fill: "#75787b",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path390"
          />
        </G>
        <G id="g392" transform="translate(9.3553,2.4927)">
          <Path
            d="M 0,0 4.839,5.001 4.73,-1.935 C 5.866,-2.228 7.044,-2.416 8.252,-2.493 V 13.232 L -2.911,2.07 C -2.011,1.292 -1.036,0.599 0,0 m 23.539,3.711 c 1.063,1.175 1.98,2.484 2.725,3.898 l -3.179,3.019 4.411,-0.11 c 0.486,1.481 0.794,3.043 0.9,4.661 H 12.034 Z M 10.741,-2.49 c 1.618,0.107 3.18,0.416 4.661,0.904 l -0.11,4.42 3.023,-3.183 c 1.414,0.746 2.721,1.665 3.895,2.729 L 10.705,13.885 Z m 17.655,20.158 c -0.077,1.208 -0.267,2.386 -0.56,3.521 l -6.918,-0.109 4.986,4.826 c -0.598,1.035 -1.292,2.01 -2.069,2.91 L 12.688,17.668 Z"
            style={{
              fill: "#75787b",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path394"
          />
        </G>
        <G id="g396" transform="translate(0.3387,14.6981)">
          <Path
            d="M 0,0 8.752,0.102 2.513,-5.994 c 0.346,-0.541 0.72,-1.062 1.119,-1.563 l 9.449,9.449 H -0.33 C -0.252,1.251 -0.142,0.62 0,0 M 3.506,15.849 C 3.264,15.537 3.031,15.218 2.809,14.892 L 10.262,7.569 -0.163,7.656 C -0.233,7.271 -0.292,6.881 -0.339,6.487 h 13.207 z m 25.204,4.354 -6.105,-6.248 0.102,8.764 c -0.62,0.14 -1.251,0.25 -1.893,0.329 V 9.626 l 9.459,9.458 c -0.501,0.399 -1.022,0.772 -1.563,1.119 M 15.051,22.878 15.139,12.446 7.813,19.9 C 7.487,19.677 7.168,19.444 6.856,19.202 L 16.22,9.839 v 13.215 c -0.394,-0.047 -0.783,-0.106 -1.169,-0.176"
            style={{
              fill: "#53cbea",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path398"
          />
        </G>
        <G id="g400" transform="translate(186.0223,18.503)">
          <Path
            d="m 0,0 c 0,1.017 -0.217,1.819 -0.65,2.408 -0.434,0.589 -1.028,0.884 -1.784,0.884 -0.933,0 -1.633,-0.414 -2.099,-1.242 v -4.308 c 0.461,-0.822 1.166,-1.233 2.116,-1.233 0.739,0 1.326,0.293 1.763,0.879 C -0.219,-2.026 0,-1.155 0,0 M 1.541,-0.175 C 1.541,-1.547 1.228,-2.652 0.6,-3.491 -0.028,-4.33 -0.878,-4.75 -1.95,-4.75 c -1.095,0 -1.956,0.348 -2.583,1.042 V -8.05 H -6.075 V 4.434 h 1.408 l 0.075,-1 c 0.628,0.777 1.5,1.167 2.616,1.167 1.084,0 1.941,-0.41 2.572,-1.226 0.63,-0.816 0.945,-1.952 0.945,-3.408 z"
            style={{
              fill: "#53cbea",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path402"
          />
        </G>
        <G id="g404" transform="translate(190.8338,18.3282)">
          <Path
            d="m 0,0 c 0,-1 0.232,-1.803 0.696,-2.408 0.464,-0.606 1.085,-0.908 1.863,-0.908 0.783,0 1.405,0.306 1.867,0.92 0.461,0.614 0.691,1.474 0.691,2.58 0,0.988 -0.235,1.79 -0.704,2.404 C 3.943,3.201 3.319,3.509 2.542,3.509 1.781,3.509 1.167,3.205 0.7,2.6 0.233,1.994 0,1.128 0,0 m -1.55,0.184 c 0,0.882 0.174,1.677 0.522,2.382 0.346,0.706 0.83,1.25 1.449,1.634 0.62,0.383 1.326,0.575 2.121,0.575 C 3.77,4.775 4.763,4.35 5.521,3.5 6.279,2.65 6.659,1.52 6.659,0.108 V 0 C 6.659,-0.878 6.49,-1.665 6.154,-2.362 5.818,-3.06 5.338,-3.603 4.713,-3.991 4.088,-4.381 3.37,-4.575 2.559,-4.575 c -1.222,0 -2.212,0.426 -2.971,1.275 -0.758,0.85 -1.138,1.975 -1.138,3.375 z"
            style={{
              fill: "#53cbea",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path406"
          />
        </G>
        <G id="g408" transform="translate(201.0545,22.9366)">
          <Path
            d="m 0,0 0.05,-1.134 c 0.689,0.867 1.589,1.301 2.7,1.301 1.905,0 2.867,-1.075 2.884,-3.226 V -9.017 H 4.092 V -3.05 C 4.086,-2.4 3.938,-1.92 3.646,-1.608 3.354,-1.297 2.9,-1.142 2.283,-1.142 c -0.5,0 -0.938,-0.133 -1.316,-0.4 C 0.589,-1.809 0.295,-2.158 0.084,-2.592 V -9.017 H -1.458 V 0 Z"
            style={{
              fill: "#53cbea",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path410"
          />
        </G>
        <G id="g412" transform="translate(211.2752,25.1202)">
          <Path
            d="M 0,0 V -2.184 H 1.684 V -3.375 H 0 v -5.599 c 0,-0.362 0.075,-0.632 0.226,-0.813 0.149,-0.181 0.405,-0.272 0.766,-0.272 0.178,0 0.422,0.034 0.734,0.1 v -1.242 c -0.407,-0.111 -0.801,-0.166 -1.184,-0.166 -0.689,0 -1.208,0.209 -1.559,0.625 -0.349,0.417 -0.524,1.009 -0.524,1.775 v 5.592 h -1.642 v 1.191 h 1.642 V 0 Z"
            style={{
              fill: "#53cbea",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path414"
          />
        </G>
        <G id="g416" transform="translate(215.9041,18.3282)">
          <Path
            d="m 0,0 c 0,-1 0.231,-1.803 0.695,-2.408 0.464,-0.606 1.085,-0.908 1.864,-0.908 0.783,0 1.405,0.306 1.866,0.92 0.461,0.614 0.691,1.474 0.691,2.58 0,0.988 -0.234,1.79 -0.704,2.404 C 3.943,3.201 3.319,3.509 2.542,3.509 1.78,3.509 1.167,3.205 0.7,2.6 0.233,1.994 0,1.128 0,0 m -1.55,0.184 c 0,0.882 0.174,1.677 0.521,2.382 0.347,0.706 0.83,1.25 1.45,1.634 C 1.04,4.583 1.747,4.775 2.542,4.775 3.77,4.775 4.763,4.35 5.521,3.5 6.279,2.65 6.658,1.52 6.658,0.108 V 0 C 6.658,-0.878 6.49,-1.665 6.154,-2.362 5.817,-3.06 5.337,-3.603 4.712,-3.991 4.088,-4.381 3.369,-4.575 2.559,-4.575 c -1.223,0 -2.213,0.426 -2.971,1.275 -0.759,0.85 -1.138,1.975 -1.138,3.375 z"
            style={{
              fill: "#53cbea",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path418"
          />
        </G>
      </G>
    </G>
  </Svg>
);
export default PontoHorizontalWeb;