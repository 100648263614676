import * as React from 'react';
import {
  Dimensions,
  EmitterSubscription,
  StyleSheet,
  View
} from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Button,
  Card,
  ErrorMessage,
  Message,
  Modal,
  Space,
  Text,
  TextBox,
  isTablet
} from 'secullum-react-native-ui';
import Api from '../modules/api';
import { getTheme, isDesktop } from '../modules/layout';
import { DadosAdicionaisFuncionario, DadosFuncionario } from '../modules/types';

interface Props {
  dadosFuncionario: DadosFuncionario;
  onConcluir: () => void;
  onCancelar: () => void;
}

interface State {
  email: string;
  erroEmail: string;
  erroGeral: string;
}

class ModalConfirmarEmail extends React.Component<Props, State> {
  state: State = {
    email: '',
    erroEmail: '',
    erroGeral: ''
  };

  screenChangeDimensionsSubscription: EmitterSubscription | undefined;

  componentDidMount() {
    this.setState({ email: this.props.dadosFuncionario.email || '' });
    this.screenChangeDimensionsSubscription = Dimensions.addEventListener(
      'change',
      this.handleDimensionsChange
    );
  }

  componentWillUnmount() {
    if (this.screenChangeDimensionsSubscription) {
      this.screenChangeDimensionsSubscription.remove();
    }
  }

  handleDimensionsChange = () => {
    this.forceUpdate();
  };

  handleEmailChange = (email: string) => {
    this.setState({ email, erroEmail: '' });
  };

  handleConfirmarPress = async () => {
    const emailAnterior = this.props.dadosFuncionario.email || '';
    const emailNovo = this.state.email.trim();
    const regexEmail = /^[a-zA-Z0-9_\.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+$/;

    if (!emailNovo || !regexEmail.test(emailNovo)) {
      this.setState({
        erroEmail: translate('Preencha o campo corretamente') + '.'
      });
      return;
    }

    if (emailAnterior === emailNovo) {
      this.props.onConcluir();
      return;
    }

    const api = new Api();

    let dadosAdicionais: DadosAdicionaisFuncionario | null = null;

    await api.getDadosAdicionaisFuncionario(this.props.dadosFuncionario.id, {
      onSuccess: dados => {
        dadosAdicionais = {
          ...dados,
          email: emailNovo
        };
      },
      onError: err => {
        this.setState({ erroGeral: err.message });
      }
    });

    if (dadosAdicionais === null) {
      return;
    }

    await api.postSolicitacaoDadosCadastrais(dadosAdicionais, {
      onSuccess: () => {
        this.props.onConcluir();
      },
      onError: err => {
        this.setState({ erroGeral: err.message });
      }
    });
  };

  render() {
    const modalStyle = {
      marginTop:
        isDesktop() || isTablet()
          ? 250
          : Dimensions.get('window').height / 2 - 250 / 2, // metade do tamanho da tela - metade do tamanho do modal
      marginHorizontal: isDesktop() || isTablet() ? undefined : 16,
      width: isDesktop() || isTablet() ? 400 : undefined
    };

    return (
      <Modal visible>
        <Card
          nativeID="modal-confirmar-email"
          style={[styles.modal, modalStyle]}
        >
          <Card.Header title={translate('Informe seu email')} />
          <Card.Section>
            <TextBox
              label={translate('Email')}
              onChange={this.handleEmailChange}
              value={this.state.email}
              nativeID="modal-confirmar-email-campo-email"
              maxLength={100}
              keyboardType="email-address"
            />
            <ErrorMessage
              nativeID="modal-confirmar-email-erro-email"
              message={this.state.erroEmail}
            />
            <Space />
            <Text color={theme.textColor2} size={12}>
              {translate(
                'Ao alterar o seu email será gerada uma solicitação para alteração de dados cadastrais.'
              )}
            </Text>
            <Space />
            <View style={styles.botoes}>
              <Button
                text={translate('Cancelar')}
                onPress={this.props.onCancelar}
                nativeID="modal-confirmar-email-botao-cancelar"
                style={styles.botao}
                primary={false}
              />
              <Space width={16} />
              <Button
                text={translate('Confirmar')}
                onPress={this.handleConfirmarPress}
                nativeID="modal-confirmar-email-botao-confirmar"
                style={styles.botao}
              />
            </View>
          </Card.Section>
        </Card>
        <Message
          type="warning"
          visible={this.state.erroGeral !== ''}
          message={this.state.erroGeral}
          onRequestClose={() => this.setState({ erroGeral: '' })}
        />
      </Modal>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  modal: {
    borderRadius: 6,
    alignSelf: 'center'
  },
  botoes: {
    flexDirection: 'row'
  },
  botao: {
    flex: 1
  }
});

export default ModalConfirmarEmail;
