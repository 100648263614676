import * as React from 'react';
import { translate } from 'secullum-i18n';
import { Message } from 'secullum-react-native-ui';

function NaoEncontrada() {
  return (
    <Message
      message={translate('Página não encontrada')}
      type="warning"
      visible
    />
  );
}

export default NaoEncontrada;
