import * as React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { translate } from 'secullum-i18n';
import { Modal, Button, Card, isTablet, Space } from 'secullum-react-native-ui';
import { getTheme, isDesktop } from '../modules/layout';
import { TipoSolicitacao } from '../modules/types';

interface Props {
  visible: boolean;
  tipoAviso: TipoSolicitacao;
  onCancelar: () => void;
  onConcluir: () => void;
}

class ModalAvisoInclusaoPontoSuspeita extends React.Component<Props> {
  mensagemAviso = () => {
    const { tipoAviso } = this.props;

    switch (tipoAviso) {
      case TipoSolicitacao.UtilizaLocalizacaoFicticia:
        return translate(
          'O local da marcação pode estar sendo simulado pelo dispositivo.'
        );
      case TipoSolicitacao.ForaDoPerimetro:
        return (
          translate(
            'Você está tentando registrar o ponto em um local não permitido pela sua empresa'
          ) + '.'
        );
      case TipoSolicitacao.HoraFoiModificada:
        return translate(
          'A data e hora do dispositivo podem ter sido alteradas.'
        );
      default:
        return 'Tipo de solicitação inválido.';
    }
  };

  render() {
    const { visible, onCancelar, onConcluir } = this.props;

    return (
      <Modal visible={visible} onRequestClose={onCancelar}>
        <Card style={styles.modal}>
          <Card.Section>
            <View style={styles.viewMensagem}>
              <FontAwesome
                name="warning"
                color={theme.warningColor}
                size={isTablet() ? 52 : 42}
                style={{ textAlign: 'center' }}
                nativeID={'modal-inclusao-ponto-suspeita-icone'}
              />
              <Text
                style={styles.texto}
                nativeID={'modal-inclusao-ponto-suspeita-mensagem'}
              >
                {this.mensagemAviso()}
              </Text>
            </View>
            <Space height={5} />
            <View style={styles.botoes}>
              <Button
                primary={false}
                style={styles.botaoCancelar}
                text={translate('Cancelar')}
                onPress={onCancelar}
                nativeID={'modal-inclusao-ponto-suspeita-botao-cancelar'}
              />
              <Space width={20} />
              <Button
                style={styles.botaoContinuar}
                text={translate('Continuar')}
                onPress={onConcluir}
                nativeID={'modal-inclusao-ponto-suspeita-botao-concluir'}
              />
            </View>
          </Card.Section>
        </Card>
      </Modal>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  botoes: {
    flexDirection: 'row'
  },
  botaoCancelar: {
    flex: 1,
    backgroundColor: theme.backgroundColor1
  },
  botaoContinuar: {
    flex: 1
  },
  modal: {
    borderRadius: 6,
    alignSelf: 'center',
    marginTop: isTablet() ? 250 : 140,
    width: isDesktop() || isTablet() ? 400 : 300
  },
  viewMensagem: {
    flexDirection: 'column',
    paddingBottom: 10
  },
  texto: {
    color: theme.textColor3,
    fontFamily: theme.fontFamily1,
    fontSize: isTablet() ? 25 : 16,
    textAlign: 'center',
    paddingTop: 5
  }
});

export default ModalAvisoInclusaoPontoSuspeita;
