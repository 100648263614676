import * as React from 'react';
import { StyleSheet, Pressable, Platform, View } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import {
  Space,
  formatDate,
  Card,
  Text,
  CheckBox
} from 'secullum-react-native-ui';
import { getTheme, select } from '../modules/layout';
import { ArquivoDadosStatus, ArquivosDados } from '../modules/types';

interface Props {
  arquivo: ArquivosDados;
  exibirSeparador: boolean;
  checked: boolean;
  onNavigate: () => void;
  onChecked: () => void;
  showValidity?: boolean;
}

class ArquivoLinha extends React.Component<Props> {
  renderIconeStatusArquivo = () => {
    const { arquivo } = this.props;

    let nomeIcone = 'thumbs-down';
    let corIcone = theme.errorColor;

    // Arquivos que não pedem assinatura, recebem o status "Arquivo Simples"
    if (!arquivo.solicitarAssinaturaFuncionario) {
      nomeIcone = 'file-text-o';
      corIcone = theme.textColor3;
    } else if (arquivo.assinaturas.length === 0) {
      nomeIcone = 'hourglass-half';
      corIcone = theme.warningColor;
    } else if (arquivo.assinaturas[0].status === ArquivoDadosStatus.Aceita) {
      nomeIcone = 'thumbs-up';
      corIcone = theme.successColor;
    }

    return (
      <FontAwesome
        testID={`arquivo-status-${arquivo.id}`}
        name={nomeIcone}
        color={corIcone}
        style={styles.iconeStatusArquivo}
      />
    );
  };

  render() {
    const { arquivo, checked, exibirSeparador, onChecked, showValidity } =
      this.props;

    const fontSize = select({
      web: 15,
      tablet: 14,
      defaultValue: 12
    });

    const format = select({
      highResolution: 'eeee, dd/MM/yyyy',
      defaultValue: 'dd/MM/yy'
    });

    const valorCampoData = formatDate(arquivo.dataArquivo, format);
    const valorCampoValidade = arquivo.validadeArquivo
      ? formatDate(arquivo.validadeArquivo, format)
      : null;

    const mudarCorSeVencido = (estilo: any, vencido: boolean) => {
      return vencido ? { ...estilo, color: 'orange' } : estilo;
    };

    return (
      <>
        {exibirSeparador && <Space height={10} />}
        <Card nativeID={`linha-${arquivo.id}`}>
          <Card.Section>
            <Pressable
              style={({ pressed }) => [
                { opacity: pressed ? 0.7 : 1 },
                styles.linhaTouchable
              ]}
              onPress={() => this.props.onNavigate()}
            >
              {arquivo.solicitarAssinaturaFuncionario &&
              arquivo.assinaturas.length === 0 ? (
                <CheckBox
                  style={styles.checkBox}
                  nativeID={`assinatura-digital-arquivos-checkbox-${arquivo.id}`}
                  value={checked}
                  onChange={onChecked}
                />
              ) : (
                <View style={styles.placeholderCheckbox} />
              )}
              <Text
                bold
                flex={1}
                size={fontSize}
                style={mudarCorSeVencido(styles.linhaText, arquivo.vencido)}
              >
                {arquivo.nomeArquivo}
              </Text>
              <Text
                bold
                flex={1}
                size={fontSize}
                style={mudarCorSeVencido(styles.linhaText, arquivo.vencido)}
              >
                {arquivo.tipoArquivo}
              </Text>
              <Text
                bold
                style={mudarCorSeVencido(
                  showValidity
                    ? { ...styles.textData, marginRight: 25 }
                    : styles.textData,
                  arquivo.vencido
                )}
                size={fontSize}
              >
                {valorCampoData}
              </Text>
              {showValidity && (
                <Text
                  bold
                  style={mudarCorSeVencido(styles.textData, arquivo.vencido)}
                  size={fontSize}
                >
                  {valorCampoValidade}
                </Text>
              )}

              <Text
                align="center"
                bold
                style={styles.textStatus}
                size={fontSize}
              >
                {this.renderIconeStatusArquivo()}
              </Text>
            </Pressable>
          </Card.Section>
        </Card>
      </>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  linhaTouchable: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1
  },
  linhaText: {
    paddingRight: 5,
    ...(Platform.OS === 'web' ? { wordBreak: 'break-all' } : {}),
    color: theme.textColor1
  },
  iconeStatusArquivo: {
    fontSize: 25
  },
  textData: {
    width: Platform.OS === 'web' ? 185 : 60
  },
  textStatus: {
    width: Platform.OS === 'web' ? 150 : 50
  },
  checkBox: {
    marginTop: 3
  },
  placeholderCheckbox: {
    width: 24
  }
});

export default ArquivoLinha;
