import * as React from 'react';
import {
  Dimensions,
  EmitterSubscription,
  Keyboard,
  NativeScrollEvent,
  Platform,
  RefreshControlProps,
  View
} from 'react-native';
import {
  Header,
  KeyboardAvoidingView,
  StatusBar
} from 'secullum-react-native-ui';

interface Props {
  keyboardAvoidingViewRef?: React.RefObject<KeyboardAvoidingView>;
  refreshControl?: React.ReactElement<RefreshControlProps>;
  scrollEnabled?: boolean;
  onScroll?: (e: NativeScrollEvent) => void;
  children?: React.ReactNode;
}

interface State {
  keyboardShow: boolean;
}

class ViewPrincipal extends React.Component<Props, State> {
  static defaultProps = {
    scrollEnabled: true
  };

  state: State = {
    keyboardShow: false
  };

  screenChangeDimensionsSubscription: EmitterSubscription | undefined;
  keyboardDidShowSubscription?: EmitterSubscription | undefined;
  keyboardDidHideSubscription?: EmitterSubscription | undefined;

  componentDidMount() {
    this.screenChangeDimensionsSubscription = Dimensions.addEventListener(
      'change',
      this.handleDimensionsChange
    );
    if (Platform.OS === 'ios') {
      this.keyboardDidShowSubscription = Keyboard.addListener(
        'keyboardWillShow',
        e => this.handleKeyboardShow(e)
      );

      this.keyboardDidHideSubscription = Keyboard.addListener(
        'keyboardWillHide',
        this.handleKeyboardHide
      );
    }
  }

  componentWillUnmount() {
    this.screenChangeDimensionsSubscription!.remove();

    if (Platform.OS === 'ios') {
      this.keyboardDidShowSubscription!.remove();
      this.keyboardDidHideSubscription!.remove();
    }
  }

  handleDimensionsChange = () => {
    this.forceUpdate();
  };

  handleKeyboardShow = (e: any) => {
    this.setState({ keyboardShow: true });
  };

  handleKeyboardHide = () => {
    this.setState({
      keyboardShow: false
    });
  };

  render() {
    const {
      keyboardAvoidingViewRef,
      refreshControl,
      children,
      scrollEnabled,
      onScroll
    } = this.props;
    const { keyboardShow } = this.state;

    return (
      // ATENÇÃO!
      // O código pra fazer o teclado não ficar por cima dos campos é bem delicado.
      // Se você precisar mexer aqui, lembrar de testar, no mínimo:
      // * Android pelo APK (o comportamento no APK é diferente do Expo Client)
      // * iOS
      // * Abrir um campo no início do scroll da tela (ex: endereço dados cadastrais)
      // * Abrir um campo bem no final da tela (ex: naturalidade em dados cadastrais,
      //   testar com o campo bem no final do scroll, sem os botões salvar e cancelar
      //   aparecendo, e também com os botões aparecendo)
      // * Abrir campos no meio da tela, e verificar se só dá scroll quando o
      //   campo ficaria abaixo do teclado
      // * Não pode ficar um espaço vazio no final do scroll
      <KeyboardAvoidingView
        extraFieldHeight={16}
        extraWindowHeight={Header.height + StatusBar.height}
        refreshControl={refreshControl}
        ref={keyboardAvoidingViewRef}
        scrollEnabled={scrollEnabled}
        onScroll={onScroll}
        keyBoardShow={keyboardShow}
      >
        {({ availableHeight }) => (
          <View
            style={{
              padding: 16,
              minHeight: availableHeight
            }}
          >
            {children}
          </View>
        )}
      </KeyboardAvoidingView>
    );
  }
}

export default ViewPrincipal;
