import * as ImagePickerModule from 'expo-image-picker';
import * as React from 'react';
import {
  View,
  Pressable,
  StyleSheet,
  Dimensions,
  Platform,
  DimensionValue
} from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { translate } from 'secullum-i18n';
import {
  getTheme,
  Card,
  KeyboardAvoidingView,
  isTablet,
  Space,
  Modal,
  Text
} from 'secullum-react-native-ui';
import { manipulateImageAsync } from '../modules/image';
import { isDesktop } from '../modules/layout';

interface Props {
  onPressCamera?: () => void;
  onCancel: () => void;
  onSuccess: (base64: string) => void;
  width: number;
}

const ImagePicker: React.FC<Props> = props => {
  const [cameraGranted, setCameraGranted] = React.useState<boolean>();
  const [cameraRollGranted, setCameraRollGranted] = React.useState<boolean>();

  React.useEffect(() => {
    ImagePickerModule.getCameraPermissionsAsync().then(({ status }) => {
      if (status === ImagePickerModule.PermissionStatus.GRANTED) {
        setCameraGranted(true);
      }
    });

    ImagePickerModule.getMediaLibraryPermissionsAsync().then(({ status }) => {
      if (status === ImagePickerModule.PermissionStatus.GRANTED) {
        setCameraRollGranted(true);
      }
    });
  }, []);

  const pickMedia = async (type: 'camera' | 'files') => {
    const isCamera = type === 'camera';

    const granted = isCamera ? cameraGranted : cameraRollGranted;

    if (!granted) {
      const { status } = isCamera
        ? await ImagePickerModule.requestCameraPermissionsAsync()
        : await ImagePickerModule.requestMediaLibraryPermissionsAsync();

      if (status !== ImagePickerModule.PermissionStatus.GRANTED) {
        return;
      }
    }

    const options: ImagePickerModule.ImagePickerOptions = {
      quality: 1,
      allowsEditing: true,
      base64: false,
      exif: false,
      mediaTypes: ImagePickerModule.MediaTypeOptions.Images
    };

    const imageResult = isCamera
      ? await ImagePickerModule.launchCameraAsync(options)
      : await ImagePickerModule.launchImageLibraryAsync(options);

    if (imageResult.canceled) {
      props.onCancel();
    } else {
      const arquivo = await manipulateImageAsync(
        imageResult.assets[0].uri,
        props.width
      );

      props.onSuccess(arquivo);
    }
  };

  return (
    <Modal visible onRequestClose={props.onCancel}>
      <KeyboardAvoidingView>
        {() => (
          <View
            style={[
              styles.container,
              {
                width: isTablet()
                  ? 500
                  : isDesktop()
                  ? 650
                  : Dimensions.get('window').width
              }
            ]}
          >
            <Card style={{ marginHorizontal: 20 }}>
              <Card.Section>
                <View style={styles.optionClose}>
                  <Text style={styles.title}>
                    {translate('Selecione a origem')}
                  </Text>
                  {Platform.OS == 'web' && (
                    <FontAwesome
                      name="times"
                      color={theme.textColor1}
                      size={20}
                      onPress={props.onCancel}
                      nativeID="selecionar-origem-fechar"
                    />
                  )}
                </View>
                <View style={styles.options}>
                  <Pressable
                    style={({ pressed }) => [
                      { opacity: pressed ? 0.7 : 1 },
                      styles.optionContainer
                    ]}
                    onPress={() =>
                      props.onPressCamera
                        ? props.onPressCamera()
                        : pickMedia('camera')
                    }
                  >
                    <FontAwesome name="camera" color={'#9199a5'} size={30} />
                    <Text nativeID="selecionar-origem-camera">
                      {translate('Câmera')}
                    </Text>
                  </Pressable>

                  <Space width={10} />

                  <Pressable
                    style={styles.optionContainer}
                    onPress={() => pickMedia('files')}
                  >
                    <FontAwesome name="photo" color={'#9199a5'} size={30} />
                    <Text nativeID="selecionar-origem-galeria">
                      {translate('Galeria')}
                    </Text>
                  </Pressable>
                </View>
              </Card.Section>
            </Card>
          </View>
        )}
      </KeyboardAvoidingView>
    </Modal>
  );
};

const theme = getTheme();

const margem = (Platform.OS == 'web' ? '40vh' : 100) as DimensionValue;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: margem
  },
  title: {
    fontSize: isTablet() ? 28 : 18,
    color: theme.textColor3
  },
  options: {
    flexDirection: 'row',
    marginVertical: 10,
    width: '100%',
    justifyContent: 'space-between'
  },
  optionContainer: {
    backgroundColor: '#f2f2f2',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingVertical: 20,
    borderRadius: 3
  },
  optionClose: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  }
});

export default ImagePicker;
