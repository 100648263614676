import { translate } from 'secullum-i18n';
import { Container } from 'unstated';
import Api from '../modules/api';
import { DadosLogin } from '../modules/auth';
import { reverseGeocodeAsync } from '../modules/geolocation';
import {
  ArquivosDados,
  ArquivosFiltros,
  ArquivosFiltroData,
  RepositorioArquivoAssinaturasDados,
  ArquivoDadosStatus,
  RepositorioArquivoAssinaturasDadosAprovar,
  RepositorioArquivoAssinaturasDadosRejeitar,
  Coordenadas
} from '../modules/types';

export interface DadosModalMotivoAssinaturaArquivos {
  tipoAcao: ArquivoDadosStatus;
  nome: string | null;
  tipoArquivo: string | null;
  senha: string;
  efetuaLoginBiometria: boolean;
}

export interface State {
  lista: Array<ArquivosDados>;
  erro: string;
  erroConexao: boolean;
  onTentarNovamente: (() => void) | null;
  arquivo: ArquivosDados | null;
  retorno: { tipoAviso: 'info' | 'warning'; mensagem: string | null } | null;
  dadosLogin: DadosLogin | null;
  coordenadas: Coordenadas | null;
  repositorioArquivoId: number | null;
  dadosModalAssinatura: DadosModalMotivoAssinaturaArquivos | null;
  repositorioArquivoAssinaturasDados: RepositorioArquivoAssinaturasDados | null;
}

export class ArquivosContainer extends Container<State> {
  state: State = {
    lista: [],
    erro: '',
    erroConexao: false,
    onTentarNovamente: null,
    arquivo: null,
    retorno: null,
    dadosLogin: null,
    coordenadas: null,
    repositorioArquivoId: null,
    dadosModalAssinatura: null,
    repositorioArquivoAssinaturasDados: null
  };

  itensTipoFiltros = [
    {
      nativeID: 'arquivos-filtros-todos',
      value: ArquivosFiltroData.Todos,
      label: translate('Todos')
    },
    {
      nativeID: 'arquivos-filtros-por-periodo',
      value: ArquivosFiltroData.PorPeriodo,
      label: translate('Por período')
    }
  ];

  setarEstadoInicialAssinaturaArquivoAsync = async (
    dadosLogin: DadosLogin,
    dados: ArquivosDados
  ) => {
    let repositorioArquivoAssinaturasDados: RepositorioArquivoAssinaturasDados =
      {
        dataResposta: null,
        motivo: null,
        status: null
      };

    if (dados.assinaturas.length > 0) {
      // Pegamos o primeiro item do array, pois cada funcionário
      // possui 1 assinatura para cada arquivo.
      repositorioArquivoAssinaturasDados.dataResposta =
        dados.assinaturas[0].dataAssinatura;
      repositorioArquivoAssinaturasDados.motivo = dados.assinaturas[0].motivo;
      repositorioArquivoAssinaturasDados.status = dados.assinaturas[0].status;
    }

    this.setState({
      dadosLogin,
      repositorioArquivoAssinaturasDados,
      repositorioArquivoId: dados.id
    });
  };

  loadAsync = async (filtros: ArquivosFiltros) => {
    const api = new Api();

    await api.getArquivos(filtros, {
      onSuccess: async (lista: Array<ArquivosDados>) => {
        this.setState({ lista });
      },
      onError: (err: Error) => {
        this.setState({
          erro: err.message,
          erroConexao: err.name === 'TimeoutError',
          onTentarNovamente: () => this.loadAsync(filtros)
        });
      }
    });
  };

  carregarAsync = async (arquivoId: number) => {
    const api = new Api();

    await api.getArquivoNotificacao(arquivoId, {
      onSuccess: async (arquivo: ArquivosDados) => {
        this.setState({ arquivo });
      },
      onError: (err: Error) => {
        this.setState({
          erro: err.message,
          erroConexao: err.name === 'TimeoutError',
          onTentarNovamente: () => this.carregarAsync(arquivoId)
        });
      }
    });
  };

  concluirProcessamento = async (
    // Campo que representa o motivo da rejeição ou a senha do usuário caso seja uma aprovação
    campoValidacao: string,
    listaIdsSelecionados: Array<number> | null,
    callBackAsync: (() => Promise<void>) | (() => void) | null
  ) => {
    const { dadosModalAssinatura, repositorioArquivoId } = this.state;
    const api = new Api();

    if (
      dadosModalAssinatura &&
      dadosModalAssinatura.tipoAcao === ArquivoDadosStatus.Aceita
    ) {
      const { coordenadas } = this.state;
      let endereco: string | null = null;

      if (coordenadas && coordenadas.lat && coordenadas.lng) {
        endereco = await reverseGeocodeAsync(coordenadas.lat, coordenadas.lng);
      }

      const dadosAprovar: RepositorioArquivoAssinaturasDadosAprovar = {
        repositorioArquivoId,
        senha: campoValidacao,
        endereco,
        latitude: coordenadas?.lat,
        longitude: coordenadas?.lng,
        precisao: coordenadas?.accuracy,
        repositorioArquivosIds: listaIdsSelecionados
      };

      await api.postAprovarAssinaturaDigitalArquivos(dadosAprovar, {
        onSuccess: response => {
          if (repositorioArquivoId) {
            this.setState({
              repositorioArquivoAssinaturasDados: response,
              repositorioArquivoId: null
            });
          } else {
            this.setState({
              retorno: {
                tipoAviso: 'info',
                mensagem: translate('Arquivos assinados com sucesso')
              }
            });
          }
        },
        onError: err => {
          this.concluirProcessamentoComErro(err.message);
        },
        onValidationError: (errorList, errorObject) => {
          let mensagemErro = '';
          errorList.forEach(error => (mensagemErro += error.message + '\n'));
          this.concluirProcessamentoComErro(mensagemErro);
        }
      });
    } else {
      const dadosRejeitar: RepositorioArquivoAssinaturasDadosRejeitar = {
        repositorioArquivoId,
        motivo: campoValidacao,
        repositorioArquivosIds: listaIdsSelecionados
      };

      await api.postReprovarAssinaturaDigitalArquivos(dadosRejeitar, {
        onSuccess: response => {
          if (repositorioArquivoId) {
            this.setState({
              repositorioArquivoAssinaturasDados: response,
              repositorioArquivoId: null
            });
          } else {
            this.setState({
              retorno: {
                tipoAviso: 'info',
                mensagem: translate('Arquivos assinados com sucesso')
              }
            });
          }
        },
        onError: err => {
          this.concluirProcessamentoComErro(err.message);
        },
        onValidationError: (errorList, errorObject) => {
          let mensagemErro = '';
          errorList.forEach(error => (mensagemErro += error.message + '\n'));
          this.concluirProcessamentoComErro(mensagemErro);
        }
      });
    }

    this.setState({ dadosModalAssinatura: null });

    // Usamos o callback para atualizar os dados em tela para assinaturas de multiplos arquivos
    // assim o arquivo tá sempre atualizado ao final do processamento
    // e os controles são habilitados/desabilitados conforme função passada por parâmetro.
    // Assinatura de arquivos simples não necessitam de callback, então passamos null mesmo.
    if (callBackAsync) {
      await callBackAsync();
    }
  };

  concluirProcessamentoComErro = (erros: string) => {
    this.setState({
      dadosModalAssinatura: null,
      retorno: { tipoAviso: 'warning', mensagem: erros }
    });
  };

  abrirModalValidacao = (
    tipoAcao: ArquivoDadosStatus,
    nomeArquivo: string | null,
    tipoArquivo: string | null,
    dadosLogin: DadosLogin | null
  ) => {
    if (!dadosLogin) {
      return;
    }

    const dadosModalAssinatura: DadosModalMotivoAssinaturaArquivos = {
      tipoAcao: tipoAcao,
      nome: nomeArquivo,
      tipoArquivo: tipoArquivo,
      senha: dadosLogin.funcionarioSenha,
      efetuaLoginBiometria: dadosLogin.acessaComBiometria ?? false
    };

    this.setState({ dadosModalAssinatura });
  };

  handleCancelarModalValidacao = () => {
    this.setState({ dadosModalAssinatura: null });
  };

  limparErro = () => {
    this.setState({
      erro: '',
      erroConexao: false,
      onTentarNovamente: null,
      retorno: null
    });
  };
}
