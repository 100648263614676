import * as React from 'react';
import { View, StyleProp, TextStyle, StyleSheet } from 'react-native';
import { translate } from 'secullum-i18n';
import { Text } from 'secullum-react-native-ui';
import { getTheme } from '../../../shared/modules/layout';
import { TipoPerfil, TipoPlano } from '../../../shared/modules/types';
import {
  retornarDescricaoPropriedadeBatidas,
  criarArrayPropriedadesBatidas
} from '../../../shared/modules/utils';
import { TipoDeTela } from '../../../shared/screens/cartao-ponto/DetalheDiaBatida';

interface ListHeader {
  text: string;
  style?: StyleProp<TextStyle>;
}

interface Props {
  tipoDeTela: TipoDeTela;
  usaPonto10Batidas: boolean;
  tipoPerfilFuncionario: TipoPerfil;
  tipoPlanoFuncionario: TipoPlano;
}

class HeaderDiaResumido extends React.Component<Props> {
  render() {
    const {
      tipoDeTela,
      usaPonto10Batidas,
      tipoPerfilFuncionario,
      tipoPlanoFuncionario
    } = this.props;

    const listaColunas: ListHeader[] = [
      ...criarArrayPropriedadesBatidas({
        ponto10Batidas: usaPonto10Batidas
      }).map(x => ({
        text: retornarDescricaoPropriedadeBatidas(x)
      }))
    ];

    return (
      <View style={styles.container}>
        <Text bold color={theme.textColor3} flex={usaPonto10Batidas ? 0.5 : 1}>
          {translate('Status')}
        </Text>
        <View style={styles.batidasView}>
          {tipoDeTela != TipoDeTela.PontoDiario && (
            <Text
              bold
              color={theme.textColor3}
              flex={2}
              style={styles.batidasDataColumn}
            >
              {translate('Data')}
            </Text>
          )}
          {tipoDeTela == TipoDeTela.PontoDiario && (
            <Text
              bold
              color={theme.textColor3}
              flex={2}
              style={styles.batidasDataColumn}
            >
              {translate('Funcionário')}
            </Text>
          )}
          <View
            style={[
              styles.batidasSection,
              { flex: usaPonto10Batidas ? 10 : 6 }
            ]}
          >
            {listaColunas.map((column, index) => (
              <Text
                key={index}
                bold
                flex={1}
                color={theme.textColor3}
                style={column.style}
              >
                {translate(column.text)}
              </Text>
            ))}
          </View>
        </View>
        {(tipoPerfilFuncionario != TipoPerfil.FuncionarioRestrito ||
          tipoPlanoFuncionario <= TipoPlano.Basic) && (
          <Text bold color={theme.textColor3} flex={1}>
            {translate('Saldo')}
          </Text>
        )}
      </View>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  batidasView: {
    flexDirection: 'row',
    flex: 8,
    flexWrap: 'wrap'
  },
  batidasDataColumn: {
    alignSelf: 'center'
  },
  batidasSection: {
    flexDirection: 'row',
    flex: 6,
    width: 70
  }
});

export default HeaderDiaResumido;
