import * as ImageManipulator from 'expo-image-manipulator';

// Case-134338 - Tiago Soares - Foi aumentado a largura e a taxa de compactação do método para gerar imagens com melhor qualidade pois
// não ficavam legíveis os atestados medicos.
export const manipulateImageAsync = async (path: string, width = 650) => {
  const manipulateAsyncOptions: ImageManipulator.Action[] = [
    { resize: { width } }
  ];

  const arquivo = await ImageManipulator.manipulateAsync(
    path,
    manipulateAsyncOptions,
    {
      format: ImageManipulator.SaveFormat.JPEG,
      base64: true,
      compress: 0.8
    }
  );

  return arquivo.base64 || '';
};
