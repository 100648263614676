import AsyncStorage from '@react-native-async-storage/async-storage';

const CHAVE_MODIFICACAO_DATA_HORA = 'ModificacaoDataHora';
const TOLERANCIA_DIFERENCA = 5 * 60 * 1000; // 5 min em ms

interface DadosModificacaoDataHora {
  dataHoraServidorUtc: string;
  usuarioModificouDataHoraDispositivo: boolean;
}

export async function salvarDataHoraServidorAsync(dataHoraServidorUtc: string) {
  // Sempre que buscar a data e hora do servidor, limpa a propriedade que informa
  // se o usuário modificou a data e hora do dispositivo, já que na próxima vez que
  // for verificar vai ter a data e hora do servidor atualizada.
  const dados: DadosModificacaoDataHora = {
    dataHoraServidorUtc,
    usuarioModificouDataHoraDispositivo: false
  };

  await AsyncStorage.setItem(
    CHAVE_MODIFICACAO_DATA_HORA,
    JSON.stringify(dados)
  );
}

export async function verificarDataHoraModificadaUsuarioAsync(): Promise<boolean> {
  const jsonDados = await AsyncStorage.getItem(CHAVE_MODIFICACAO_DATA_HORA);

  if (jsonDados == null) {
    return false;
  }

  const dados: DadosModificacaoDataHora = JSON.parse(jsonDados);

  // Depois que o usuário tiver modificado a data e hora uma vez,
  // todos os registros deverão ser enviados como suspeitos até
  // que ele sincronize com o servidor novamente.
  if (dados.usuarioModificouDataHoraDispositivo) {
    return true;
  }

  const dataServidor = Date.parse(dados.dataHoraServidorUtc);
  const dataDispositivo = Date.now();

  // Verifica se a data do dispositivo é anterior à última data buscada do servidor,
  // considerando uma tolerância para evitar falsos positivos.
  if (dataServidor - dataDispositivo > TOLERANCIA_DIFERENCA) {
    dados.usuarioModificouDataHoraDispositivo = true;

    await AsyncStorage.setItem(
      CHAVE_MODIFICACAO_DATA_HORA,
      JSON.stringify(dados)
    );

    return true;
  }

  return false;
}
