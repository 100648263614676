import * as React from 'react';
import { StyleSheet } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Card,
  RangeDatePicker,
  ErrorMessage,
  DropDown,
  Space
} from 'secullum-react-native-ui';
import SolicitacaoHeader from '../../../shared/components/SolicitacaoHeader';
import SolicitacaoLinha from '../../../shared/components/SolicitacaoLinha';
import {
  SolicitacoesContainer,
  getCorStatus,
  getIconeStatus
} from '../../../shared/containers/SolicitacoesContainer';
import withContainer from '../../../shared/containers/withContainer';
import {
  SolicitacoesFiltros,
  SolicitacoesFiltrosStatus,
  TipoPerfil,
  SolicitacoesDados
} from '../../../shared/modules/types';
import { getDataInicioDiasAtras } from '../../../shared/modules/utils';
import LoggedInScreen from '../../components/LoggedInScreen';
import SolicitacoesDetalhes from './SolicitacoesDetalhes';

interface Props {
  container: SolicitacoesContainer;
}

interface State {
  filtros: SolicitacoesFiltros;
  erroGeral: string;
  solicitacao: SolicitacoesDados | null;
}

class Solicitacoes extends React.Component<Props, State> {
  state: State = {
    filtros: {
      dataInicio: getDataInicioDiasAtras(14),
      dataFinal: new Date(),
      status: SolicitacoesFiltrosStatus.Pendentes,
      perfil: TipoPerfil.Funcionario
    },
    erroGeral: '',
    solicitacao: null
  };

  async componentDidMount() {
    await this.props.container.loadAsync(this.state.filtros);
  }

  handleDateInicioChange = (value: Date, name: keyof State['filtros']) => {
    this.setState({
      ...this.state,
      filtros: { ...this.state.filtros, dataInicio: value, dataFinal: value },
      erroGeral: ''
    });
  };

  handleChange = (value: string | Date, name: keyof State['filtros']) => {
    const novosFiltros = {
      ...this.state.filtros,
      [name]: value
    };
    this.setState(
      {
        ...this.state,
        filtros: novosFiltros,
        erroGeral: ''
      },
      async () => {
        if (
          this.state.filtros.dataInicio &&
          this.state.filtros.dataFinal &&
          this.state.filtros.dataInicio > this.state.filtros.dataFinal
        ) {
          this.setState({
            ...this.state,
            erroGeral: translate('Selecione o período corretamente')
          });

          return;
        }
        await this.props.container.loadAsync(this.state.filtros);
      }
    );
  };

  render() {
    const { container } = this.props;
    const { filtros, erroGeral, solicitacao } = this.state;

    return (
      <LoggedInScreen screenTitle={translate('Minhas Solicitações')}>
        <Card>
          <Card.Header title={translate('Filtrar')} />
          <Card.Section style={styles.section}>
            <DropDown
              nativeID="solicitacoes-status"
              label={translate('Status da Solicitação')}
              items={container.itensStatusSolicitacoes}
              value={filtros.status}
              onChange={status => this.handleChange(status, 'status')}
              style={styles.status}
              inputStyle={styles.inputStatus}
            />
            <RangeDatePicker
              nativeID="solicitacoes-data"
              style={styles.datePicker}
              label={translate('Insira o Período')}
              startDate={filtros.dataInicio!}
              endDate={filtros.dataFinal!}
              onStartDateChange={date =>
                this.handleDateInicioChange(date, 'dataInicio')
              }
              onEndDateChange={date => this.handleChange(date, 'dataFinal')}
            />
            <ErrorMessage
              nativeID="solicitacoes-erro-geral"
              message={erroGeral}
            />
          </Card.Section>
        </Card>
        <Space height={10} />
        <Card>
          <Card.Section style={styles.headerSection}>
            <SolicitacaoHeader />
          </Card.Section>
        </Card>
        <Space height={10} />
        {container.state.lista.map((solicitacao, index) => (
          <SolicitacaoLinha
            solicitacaoNativeId={`soliticatao-${index + 1}`}
            key={solicitacao.id}
            exibirSeparador={index > 0}
            solicitacao={solicitacao}
            corSolicitacao={getCorStatus(solicitacao.status)}
            iconeSolicitacao={getIconeStatus(solicitacao.status)}
            onNavigate={() => {
              this.setState({ solicitacao });
            }}
          />
        ))}

        {solicitacao && (
          <SolicitacoesDetalhes
            modalVisible={solicitacao !== null}
            solicitacao={solicitacao}
            onClose={() => this.setState({ solicitacao: null })}
          />
        )}
      </LoggedInScreen>
    );
  }
}

const styles = StyleSheet.create({
  headerSection: {
    padding: 0
  },
  status: {
    flex: 1,
    minWidth: 250,
    maxWidth: 350,
    marginTop: 10,
    marginLeft: 16
  },
  inputStatus: {
    paddingTop: 4
  },
  datePicker: {
    flex: 1,
    minWidth: 250,
    maxWidth: 350,
    marginTop: 10,
    marginLeft: 16
  },
  section: {
    flexDirection: 'row',
    flex: 8,
    flexWrap: 'wrap',
    minHeight: 88,
    paddingTop: 6,
    paddingLeft: 0
  }
});

export default withContainer(SolicitacoesContainer)(Solicitacoes);
