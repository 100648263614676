import * as React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Modal,
  Space,
  ErrorMessage,
  Text,
  isTablet
} from 'secullum-react-native-ui';
import { ButtonBar } from '../../shared/components/ButtonBar';
import { getTheme, isHighResolution } from '../../shared/modules/layout';

interface Props {
  exibir: boolean;
  mensagemErro: string | undefined;
  onCancelar: () => void;
  onUsarSenha: () => void;
}

class ModalBiometriaAndroid extends React.Component<Props> {
  render() {
    const { exibir, mensagemErro, onCancelar, onUsarSenha } = this.props;

    return (
      <Modal visible={exibir} overlayStyle={styles.overlayBiometria}>
        <View style={styles.containerBiometria}>
          <Image source={require('../../shared/images/fingerprint.png')} />
          <Text style={styles.text}>
            {translate('Toque o dedo no sensor para continuar...')}
          </Text>

          {mensagemErro && <ErrorMessage message={mensagemErro} />}
          <Space />
          <ButtonBar
            leftButton={{
              text: translate('Cancelar'),
              onPress: onCancelar,
              primary: false
            }}
            rightButton={{
              text: translate('Usar Senha'),
              onPress: onUsarSenha
            }}
          />
        </View>
      </Modal>
    );
  }
}
const theme = getTheme();

const styles = StyleSheet.create({
  overlayBiometria: {
    alignItems: 'center',
    paddingTop: isHighResolution() ? 50 : 10
  },
  containerBiometria: {
    backgroundColor: theme.backgroundColor1,
    borderRadius: 6,
    padding: isTablet() ? 25 : 16,
    width: isTablet() ? 440 : 340,
    marginTop: isTablet() ? 250 : 140,
    alignItems: 'center'
  },
  text: {
    color: theme.textColor3,
    fontSize: isTablet() ? 25 : 16,
    textAlign: 'center',
    marginTop: isTablet() ? 15 : 5,
    marginBottom: 20,
    width: '100%'
  }
});

export default ModalBiometriaAndroid;
