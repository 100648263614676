export function comprimirImagem(imagemUrl: string): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const imagem = new Image();
    //Case-134338 - Tiago Soares - Foi aumentado a largura e a taxa de compactação do método para gerar imagens com melhor qualidade, pois
    //não ficavam legíveis os atestados medicos.
    imagem.onload = () => {
      let largura = 800;
      let altura = Math.floor((imagem.height / imagem.width) * largura);
      let taxaCompactacao = 0.7;
      const naoDeveRedimensionar = imagem.width < largura;

      const canvas = document.createElement('canvas');

      if (naoDeveRedimensionar) {
        altura = imagem.height;
        largura = imagem.width;
      }

      canvas.width = largura;
      canvas.height = altura;

      const context = canvas.getContext('2d');

      if (!context) {
        return reject();
      }

      context.drawImage(imagem, 0, 0, largura, altura);

      const imagemBase64: string = canvas
        .toDataURL('image/jpeg', taxaCompactacao)
        .replace(/^data:image\/(png|jpeg);base64,/, '');

      resolve(imagemBase64);
    };

    imagem.src = imagemUrl;
    imagem.onerror = reject;
  });
}
