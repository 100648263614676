import * as React from 'react';
import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native';
import { Button, Space, ButtonProperties } from 'secullum-react-native-ui';

export interface ButtonBarProperties {
  buttonBarStyle?: StyleProp<ViewStyle>;
  leftButton: ButtonProperties;
  rightButton: ButtonProperties | null;
}

export class ButtonBar extends React.Component<ButtonBarProperties> {
  render() {
    const { rightButton, leftButton, buttonBarStyle } = this.props;

    return (
      <View style={[styles.buttons, buttonBarStyle]}>
        <Button {...leftButton} style={[styles.button, leftButton.style]} />
        {rightButton && (
          <>
            <Space width={16} />
            <Button
              {...rightButton}
              style={[styles.button, rightButton.style]}
            />
          </>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  buttons: {
    flexDirection: 'row'
  },
  button: {
    flex: 1
  }
});
