import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { translate } from 'secullum-i18n';
import { Modal, ImageButton, Card, Text } from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import SolicitacaoDetalhes from '../../../shared/components/SolicitacaoDetalhes';
import { FuncionarioContainer } from '../../../shared/containers/FuncionarioContainer';
import { getTheme } from '../../../shared/modules/layout';
import { SolicitacoesDados, BatidasItem } from '../../../shared/modules/types';

interface Props {
  solicitacao: SolicitacoesDados;
  modalVisible: boolean;
  gerente: boolean;
  onClose: () => void;
}

interface State {
  batidaOriginalExibindo: { nome: string; valor: string } | null;
}

class SolicitacoesDetalhes extends React.Component<Props, State> {
  state: State = {
    batidaOriginalExibindo: null
  };

  static defaultProps = {
    gerente: false
  };

  closeRequest = () => {
    this.setState({ batidaOriginalExibindo: null });
    this.props.onClose();
  };

  renderFotoAtestado = (foto: string) => {
    return (
      <View style={styles.containerFoto}>
        <img
          src={
            foto.indexOf('data:image') == -1
              ? `data:image/jpeg;base64,${foto}`
              : foto
          }
          style={{ width: '100%' }}
        />
      </View>
    );
  };

  render() {
    const { modalVisible, solicitacao, gerente } = this.props;
    const { batidaOriginalExibindo } = this.state;

    return (
      <>
        <Modal visible={modalVisible} overlayStyle={{ overflow: 'scroll' }}>
          <View style={styles.modalContainer}>
            <View style={styles.modalIcons}>
              {batidaOriginalExibindo && (
                <ImageButton
                  style={styles.imageButtonRight}
                  icon="arrow-left"
                  onPress={() =>
                    this.setState({ batidaOriginalExibindo: null })
                  }
                />
              )}
              <ImageButton
                style={styles.imageButtonLeft}
                icon="close"
                onPress={() => this.closeRequest()}
                nativeID="botao-fechar-detalhe"
              />
            </View>
            <View nativeID="solicitacao-detalhe" style={styles.modalSection}>
              {batidaOriginalExibindo ? (
                <Card>
                  <Card.Header
                    title={translate('Registro de Ponto Original')}
                  />
                  <Card.Section style={styles.originalCard}>
                    <Text bold>
                      {batidaOriginalExibindo && batidaOriginalExibindo.nome}
                    </Text>
                    <Text bold>
                      {batidaOriginalExibindo && batidaOriginalExibindo.valor}
                    </Text>
                  </Card.Section>
                </Card>
              ) : (
                <Subscribe to={[FuncionarioContainer]}>
                  {(funcionario: FuncionarioContainer) => (
                    <SolicitacaoDetalhes
                      configuracoesEspeciaisAcesso={
                        funcionario.state.dados.configuracoesEspeciaisAcesso
                      }
                      solicitacao={solicitacao}
                      gerente={gerente}
                      renderFoto={(foto: string) =>
                        this.renderFotoAtestado(foto)
                      }
                      onBatidaOriginalPress={(batida: BatidasItem) =>
                        this.setState({
                          batidaOriginalExibindo: {
                            nome: batida.nome,
                            valor: batida.valorOriginal || '-'
                          }
                        })
                      }
                    />
                  )}
                </Subscribe>
              )}
            </View>
          </View>
        </Modal>
      </>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  imageButtonRight: {
    borderWidth: 0,
    marginRight: 'auto'
  },
  imageButtonLeft: {
    borderWidth: 0,
    marginLeft: 'auto'
  },
  modalSection: {
    minHeight: 730,
    width: '90%',
    alignSelf: 'center',
    marginBottom: 20
  },
  modalContainer: {
    margin: 'auto',
    width: '90%',
    maxWidth: 366,
    backgroundColor: theme.backgroundColor2,
    borderRadius: 10,
    justifyContent: 'center',
    marginBottom: 10,
    marginTop: 10
  },
  modalIcons: {
    flexDirection: 'row'
  },
  originalCard: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  containerFoto: {
    paddingHorizontal: 16,
    paddingVertical: 10
  }
});

export default SolicitacoesDetalhes;
