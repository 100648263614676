import * as React from 'react';
import { Platform, StyleSheet } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  formatDate,
  TableColumn,
  TableCellStyle,
  Space,
  Table,
  Card
} from 'secullum-react-native-ui';
import { getTheme } from '../../../shared/modules/layout';
import { TipoSituacao, BatidasDados, ValoresItem } from '../../modules/types';

interface Props {
  batidas: BatidasDados[] | null;
  totais: ValoresItem[] | null;
}

class CartaoPontoCompleto extends React.Component<Props> {
  render() {
    const lista = this.props.batidas;

    if (!lista || !this.props.totais) {
      return null;
    }

    const columns: TableColumn[] = [
      {
        key: 'icon',
        title: Platform.OS == 'web' ? 'Status' : '',
        style: styles.icon,
        headerStyle: { color: theme.textColor3 },
        type: 'icon'
      },
      {
        key: 'data',
        title: translate('Data'),
        style: styles.date,
        headerStyle: Platform.OS == 'web' && styles.header,
        type: 'text'
      }
    ];

    for (let i = 0; i < lista[0].batidas.length; i++) {
      columns.push({
        key: lista[0].batidas[i].nome,
        title: lista[0].batidas[i].nome,
        style: styles.column,
        headerStyle: Platform.OS == 'web' && styles.header,
        type: 'text'
      });
    }

    for (let i = 0; i < lista[0].valores.length; i++) {
      const usarLayoutColunaEspecial =
        lista[0].valores[i].nome == translate('Centro de Custos') ||
        lista[0].valores[i].nome == translate('Serviços') ||
        lista[0].valores[i].nome == translate('Periculosidade');

      columns.push({
        key: lista[0].valores[i].nome,
        title: lista[0].valores[i].nome,
        style: usarLayoutColunaEspecial ? styles.colunaEspecial : styles.column,
        headerStyle: Platform.OS == 'web' && styles.header,
        type: 'text'
      });
    }

    const subHeaderData: TableColumn[] = [
      {
        key: 'icon',
        title: '',
        style: styles.icon,
        headerStyle: { color: theme.textColor3 },
        type: 'text'
      },
      {
        key: 'data',
        title: '',
        style: styles.date,
        headerStyle: Platform.OS == 'web' && styles.header,
        type: 'text'
      }
    ];

    for (let i = 0; i < lista[0].batidas.length; i++) {
      subHeaderData.push({
        key: lista[0].batidas[i].nome,
        title: '',
        style: styles.column,
        headerStyle: Platform.OS == 'web' && styles.header,
        type: 'text'
      });
    }

    for (let i = 0; i < this.props.totais.length; i++) {
      subHeaderData.push({
        key: this.props.totais[i].nome,
        title: this.props.totais[i].valor,
        style: styles.column,
        headerStyle: Platform.OS == 'web' && styles.header,
        type: 'text'
      });
    }

    const data = [];
    const cellStyle: TableCellStyle = {};

    for (let i = 0; i < lista.length; i++) {
      const batidas = lista[i].batidas.reduce(
        (
          acc: { [key: string]: string },
          x: { nome: string; valor: string }
        ) => {
          return {
            ...acc,
            [x.nome]: x.valor
          };
        },
        {}
      );

      const valores = lista[i].valores.reduce(
        (
          acc: { [key: string]: string },
          x: { nome: string; valor: string }
        ) => {
          return {
            ...acc,
            [x.nome]: x.valor || '' // se o valor vier null, gera erro ao renderizar a tabela, então seto uma string vazia quando null.
          };
        },
        {}
      );

      let color = theme.successColor;
      let iconName = 'check-circle';

      if (lista[i].situacao === TipoSituacao.DiaComFaltas) {
        color = theme.errorColor;
        iconName = 'exclamation-circle';
      } else if (lista[i].situacao === TipoSituacao.DiaIncompleto) {
        color = theme.warningColor;
        iconName = 'exclamation-triangle';
      }

      data.push({
        id: i,
        icon: iconName,
        data: formatDate(lista[i].data, 'dd/MM/yyyy - EEEEEE'),
        saldo: lista[i].saldo,
        ...batidas,
        ...valores
      });

      cellStyle[i] = {
        icon: {
          color,
          textAlign: 'left',
          marginRight: Platform.OS == 'web' ? 10 : 0
        },
        data: { fontFamily: theme.fontFamily2 }
      };
    }

    return (
      <>
        <Space />
        <Card>
          <Card.Section style={styles.section}>
            <Table
              nativeID="cartao-ponto-completo-tabela"
              idAttribute="id"
              columns={columns}
              subHeaderData={subHeaderData}
              data={data}
              cellStyle={cellStyle}
            />
          </Card.Section>
        </Card>
      </>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  section: {
    padding: 0
  },
  icon: {
    minWidth: Platform.OS == 'web' ? 80 : 30
  },
  date: {
    minWidth: Platform.OS == 'web' ? 200 : 130
  },
  header: {
    color: theme.textColor3,
    paddingLeft: 10
  },
  column: {
    minWidth: 130
  },
  colunaEspecial: {
    width: 130
  }
});

export default CartaoPontoCompleto;
