import React from 'react';
import { Platform } from 'react-native';
import { Space } from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import { FuncionarioContainer } from '../../containers/FuncionarioContainer';
import { verificarExibirTela } from '../../modules/perfilFuncionario';
import { Telas } from '../../modules/telas';
import { ResumoDiarioResultado } from '../../modules/types';
import CardResumoDiario from './CardResumoDiario';
import CardSolicitacoesPendentes from './CardSolicitacoesPendentes';

interface ResumoDiarioProps {
  quantidadeSolicitacoesPendentes: number;
  resumoDiario: Array<ResumoDiarioResultado>;
  onClickSolicitacoes: () => void;
}

class ResumoDiario extends React.Component<ResumoDiarioProps> {
  render() {
    const { resumoDiario, quantidadeSolicitacoesPendentes } = this.props;

    return (
      <>
        <Subscribe to={[FuncionarioContainer]}>
          {(funcionario: FuncionarioContainer) => {
            return (
              <>
                {verificarExibirTela(
                  Telas.SolicitacoesGerente,
                  funcionario.state.dados.dadosPerfilFuncionario.telasOcultar
                ) && (
                  <>
                    <CardSolicitacoesPendentes
                      nativeID="indicadores-gerenciais-solicitacoes-pendentes"
                      quantidade={quantidadeSolicitacoesPendentes}
                      onClick={() => this.props.onClickSolicitacoes()}
                    />
                    <Space width={Platform.OS === 'web' ? 16 : undefined} />
                  </>
                )}
              </>
            );
          }}
        </Subscribe>
        {resumoDiario.map((dados, indice) => (
          <React.Fragment key={`container-indicador-gerente-key-${indice}`}>
            <CardResumoDiario
              nativeID={`indicadores-gerenciais-${indice}`}
              resumoDiario={dados}
            />
            <Space width={Platform.OS === 'web' ? 16 : undefined} />
          </React.Fragment>
        ))}
      </>
    );
  }
}

export default ResumoDiario;
