import * as React from 'react';
import { Platform, Image, View, StyleSheet, Dimensions } from 'react-native';
import { translate } from 'secullum-i18n';
import { getTheme, Modal } from 'secullum-react-native-ui';
import { ButtonBar } from '../../components/ButtonBar';
import CapturarImagem from '../../components/CapturarImagem';
import ImagePicker from '../../components/ImagePicker';

interface Props {
  onConcluir: (foto: string) => void;
  onCancelar: () => void;
}

interface State {
  passo: null | 'captura' | 'escolha' | 'resultado';
  foto: string | null;
}

class ModalCapturarFotoFuncionario extends React.Component<Props, State> {
  state: State = {
    passo: null,
    foto: null
  };

  async componentDidMount() {
    this.setState({ passo: 'escolha' });
  }

  render() {
    switch (this.state.passo) {
      case 'escolha':
        return this.renderEscolha();
      case 'captura':
        return this.renderCaptura();
      case 'resultado':
        return this.renderResultado();
      default:
        return null;
    }
  }

  renderEscolha() {
    const { onCancelar } = this.props;

    return (
      <ImagePicker
        onPressCamera={() => this.setState({ passo: 'captura' })}
        onCancel={onCancelar}
        onSuccess={foto => this.setState({ foto, passo: 'resultado' })}
        width={650}
      />
    );
  }

  renderCaptura() {
    const { onCancelar } = this.props;

    return (
      <Modal
        visible
        onRequestClose={Platform.OS === 'web' ? undefined : onCancelar}
        overlayStyle={styles.modal}
      >
        <View style={styles.modalCapturarFoto}>
          <CapturarImagem
            onConcluir={foto => this.setState({ foto, passo: 'resultado' })}
            onFechar={onCancelar}
            larguraImagem={642}
            cameraPadrao={'front'}
            permiteAlterarCamera={true}
            mensagemErroCameraNaoEncontrada={translate(
              'Não foi possível encontrar uma WebCam para realizar a captura.'
            )}
          />
        </View>
      </Modal>
    );
  }

  renderResultado() {
    const { foto } = this.state;
    const { onConcluir, onCancelar } = this.props;

    const mediumScreen = Dimensions.get('window').width <= 800;
    const smallScreen = Dimensions.get('window').width <= 500;

    return (
      <Modal visible overlayStyle={styles.modal}>
        <View
          style={[
            styles.modalContainer,
            Platform.OS === 'web'
              ? [
                  styles.modalCapturarFotoWeb,
                  {
                    height: smallScreen ? 300 : mediumScreen ? 427 : 590
                  }
                ]
              : styles.modalCapturarFoto
          ]}
        >
          <Image
            source={{
              uri:
                foto?.indexOf('data:image') == -1
                  ? `data:image/jpeg;base64,${foto}`
                  : foto?.toString()
            }}
            style={
              Platform.OS === 'web'
                ? [
                    styles.fotoWeb,
                    {
                      width: smallScreen ? 262 : mediumScreen ? 422 : 642,
                      height: smallScreen ? 196 : mediumScreen ? 320 : 482
                    }
                  ]
                : [
                    styles.foto,
                    { height: Dimensions.get('window').height - 120 }
                  ]
            }
          />
          <ButtonBar
            buttonBarStyle={styles.espaco}
            leftButton={{
              text: translate('Cancelar'),
              onPress: onCancelar,
              primary: false,
              nativeID: 'capturar-imagem-cancelar'
            }}
            rightButton={{
              text: translate('Continuar'),
              onPress: () => onConcluir(foto!),
              nativeID: 'capturar-imagem-continuar'
            }}
          />
        </View>
      </Modal>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: 'center'
  },
  modalContainer: {
    backgroundColor: theme.backgroundColor1,
    margin: 0,
    borderRadius: 5
  },
  modalExigirFoto: {
    margin: 16,
    paddingTop: 10,
    maxWidth: Platform.OS === 'web' ? 400 : undefined,
    alignSelf: Platform.OS === 'web' ? 'center' : undefined
  },
  modalCapturarFoto: {
    height: '100%',
    width: '100%'
  },
  modalCapturarFotoWeb: {
    alignSelf: 'center'
  },
  foto: {
    margin: 16,
    marginBottom: 0
  },
  fotoWeb: {
    alignSelf: 'center',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 30,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: '#0000003b'
  },
  espaco: {
    padding: 16
  },
  espacoCheckbox: {
    paddingLeft: 16
  },
  textoCheckbox: {
    fontSize: 14
  },
  buttonCancelar: {
    margin: 16
  }
});

export default ModalCapturarFotoFuncionario;
