import React, { Component } from 'react';
import {
  Animated,
  PanResponder,
  PanResponderInstance,
  RegisteredStyle,
  StyleProp,
  ViewStyle
} from 'react-native';
import { getTheme } from '../../../shared/modules/layout';

interface Props {
  marcarDroppableArea: (moveY: number, nomeCampoArrastado: string) => void;
  trocarValores: (moveY: number, nomeCampoArrastado: string) => void;
  checkDroppableArea: (moveY: number) => boolean;
  nomeCampoArrastado: string;
  onDragging: () => void;
  onStopDragging: () => void;
  style?: StyleProp<ViewStyle>;
  ativarDraggable: boolean;
  children: React.ReactNode;
}

export default class Draggable extends Component<Props> {
  pan = new Animated.ValueXY();
  panResponder: PanResponderInstance;

  constructor(props: Readonly<Props>) {
    super(props);

    this.panResponder = PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onStartShouldSetPanResponderCapture: () => true,
      onMoveShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponderCapture: () => true,
      onPanResponderGrant: () => {
        this.props.onDragging();
      },
      onPanResponderMove: (e: any, gestureState) => {
        this.props.onDragging();

        if (this.props.ativarDraggable) {
          this.props.marcarDroppableArea(
            this.calcularPosicaoAtual(e.nativeEvent.pageY),
            this.props.nomeCampoArrastado
          );
        } else {
          this.resetarCoordenadas();
        }

        this.pan.setValue({ x: 0, y: gestureState.dy });
      },
      onPanResponderTerminationRequest: () => {
        return false;
      },
      onPanResponderRelease: (e, gesture) => {
        const posicaoAtual = this.calcularPosicaoAtual(gesture.moveY);
        const isInDroppableArea = this.props.checkDroppableArea(posicaoAtual);

        if (isInDroppableArea) {
          this.props.trocarValores(posicaoAtual, this.props.nomeCampoArrastado);
        }

        this.resetarCoordenadas();

        this.props.onStopDragging();
      }
    });
  }

  calcularPosicaoAtual = (y: number) => {
    return y - 150;
  };

  resetarCoordenadas = () => {
    this.pan.setValue({ x: 0, y: 0 });
  };

  render() {
    const { style, ativarDraggable } = this.props;

    //@ts-ignore
    const panStyle: RegisteredStyle<ViewStyle> = {
      transform: this.pan.getTranslateTransform(),
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderColor: theme.disabledColor,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 3
    };

    const panHandlers = ativarDraggable ? this.panResponder.panHandlers : null;

    return (
      <Animated.View {...panHandlers} style={[style, panStyle]}>
        {this.props.children}
      </Animated.View>
    );
  }
}

const theme = getTheme();
