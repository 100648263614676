import * as base64 from 'base-64';
import * as React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Modal,
  Table,
  Card,
  CheckBox,
  formatDate,
  TableColumn,
  Message,
  Loading,
  ImageButton,
  isTablet
} from 'secullum-react-native-ui';
import { ButtonBar } from '../../../shared/components/ButtonBar';
import Api, { getUrlApi } from '../../../shared/modules/api';
import { carregarDadosLoginAsync } from '../../../shared/modules/auth';
import { getTheme, isDesktop } from '../../../shared/modules/layout';
import {
  BatidasResposta,
  OpcoesCartaoPonto,
  ConfiguracoesEspeciaisAcesso,
  TipoPlano
} from '../../../shared/modules/types';

interface Props {
  funcionarioId: number;
  filtros: {
    dataInicio: Date;
    dataFinal: Date;
  };
  dados: BatidasResposta;
  onRequestClose: (opcoes: OpcoesCartaoPonto) => void;
  configuracoesEspeciaisAcesso: ConfiguracoesEspeciaisAcesso;
  tipoPlano: TipoPlano;
}

interface State {
  carregando: boolean;
  dadosRelatorio: OpcoesCartaoPonto;
  colunasImpressao: any[];
  erroGeral: { mensagem: string; callback?: () => Promise<void> };
}

class ModalImprimir extends React.Component<Props> {
  state: State = {
    carregando: false,
    dadosRelatorio: {
      funcionarioId: 0,
      colunasSelecionadasImpressao: [],
      exibirEventos: false,
      exibirMiniaturaDoHorario: false,
      exibirTermosMTB: false,
      exibirTotaisNoRodape: false,
      modoPaisagem: false,
      exibirJustificativas: false,
      justificativasPreenchidasUsuario: false,
      exibirLegendasJustificativas: false,
      exibirAtividades: false
    },
    colunasImpressao: [],
    erroGeral: { mensagem: '' }
  };

  componentDidMount() {
    const { opcoesCartaoPonto, lista, totais } = this.props.dados;
    const colunasImpressao = [];

    for (let i = 1; i <= lista[0].batidas.length / 2; i++) {
      colunasImpressao.push({
        id: `Entrada ${i}`,
        description: `${translate('Entrada')} ${i}`,
        selected: opcoesCartaoPonto.colunasSelecionadasImpressao.includes(
          `Entrada ${i}`
        )
      });

      colunasImpressao.push({
        id: `Saída ${i}`,
        description: `${translate('Saída')} ${i}`,
        selected: opcoesCartaoPonto.colunasSelecionadasImpressao.includes(
          `Saída ${i}`
        )
      });
    }

    for (let i = 0; i < totais.length; i++) {
      colunasImpressao.push({
        id: totais[i].nomeOriginal,
        description: totais[i].nome,
        selected: opcoesCartaoPonto!.colunasSelecionadasImpressao.includes(
          totais[i].nomeOriginal
        )
      });
    }

    this.setState({
      colunasImpressao,
      dadosRelatorio: opcoesCartaoPonto
    });
  }

  handleChange = (name: string, value: any) => {
    const { dadosRelatorio } = this.state;

    this.setState({
      dadosRelatorio: { ...dadosRelatorio, [name]: value }
    });
  };

  handleSelectAll = (value: boolean) => {
    const { dadosRelatorio, colunasImpressao } = this.state;
    const colunasSelecionadas = colunasImpressao.map(item => {
      return {
        ...item,
        selected: value
      };
    });

    this.setState({
      colunasImpressao: colunasSelecionadas,
      dadosRelatorio: {
        ...dadosRelatorio,
        colunasSelecionadasImpressao: colunasSelecionadas
          .filter(x => x.selected)
          .map(x => x.id)
      }
    });
  };

  handleSelect = (name: string, value: boolean) => {
    const { dadosRelatorio, colunasImpressao } = this.state;
    const colunasSelecionadas = colunasImpressao.map(item => {
      if (item.id === name) {
        return {
          ...item,
          selected: value
        };
      }

      return item;
    });

    this.setState({
      colunasImpressao: colunasSelecionadas,
      dadosRelatorio: {
        ...dadosRelatorio,
        colunasSelecionadasImpressao: colunasSelecionadas
          .filter(x => x.selected)
          .map(x => x.id)
      }
    });
  };

  handlePrintPress = async () => {
    const { dadosRelatorio } = this.state;
    const { configuracoesEspeciaisAcesso } = this.props;
    let maximoColunas = dadosRelatorio.modoPaisagem ? 19 : 12;

    if (configuracoesEspeciaisAcesso.tecpontoIntero) {
      maximoColunas = dadosRelatorio.modoPaisagem ? 11 : 3;
    }

    if (dadosRelatorio.colunasSelecionadasImpressao.length > maximoColunas) {
      const mensagemColunas = `${translate('Foram selecionadas')} ${
        dadosRelatorio.colunasSelecionadasImpressao.length
      } ${translate(
        'colunas e neste modo de impressão cabem no máximo {0}. Algumas colunas não serão impressas.',
        maximoColunas.toString()
      )}`;

      this.setState({
        erroGeral: {
          mensagem: mensagemColunas,
          callback: this.handleEnviarOpcoesCartaoPonto
        }
      });

      return;
    }

    await this.handleEnviarOpcoesCartaoPonto();
  };

  handleEnviarOpcoesCartaoPonto = async () => {
    const { dadosRelatorio } = this.state;
    this.setState({ carregando: true });

    await new Api().postOpcoesCartaoPonto(dadosRelatorio, {
      onSuccess: async () => await this.handleReportGenerate(),
      onValidationError: errorList => {
        let mensagemErro = '';
        errorList.forEach(error => (mensagemErro += error.message + '\n'));

        this.setState({
          carregando: false,
          erroGeral: { mensagem: mensagemErro }
        });
      },
      onError: error =>
        this.setState({
          carregando: false,
          erroGeral: { mensagem: error.message }
        })
    });
  };

  handleReportGenerate = async () => {
    const { filtros, funcionarioId } = this.props;
    const dadosLogin = await carregarDadosLoginAsync();

    this.setState({ carregando: false });

    if (dadosLogin !== null) {
      const user = base64.encode(
        dadosLogin.funcionarioNumero +
          ':' +
          dadosLogin.funcionarioSenha +
          ':' +
          dadosLogin.tipoUsuarioAutenticacao
      );

      const urlApiBase = getUrlApi();

      const dataInicial = formatDate(filtros.dataInicio, 'yyyy-MM-dd');
      const dataFinal = formatDate(filtros.dataFinal, 'yyyy-MM-dd');

      const url = `${urlApiBase}${dadosLogin.bancoId}/RelatorioCartaoPonto?axpw=${user}&dataInicio=${dataInicial}&dataFim=${dataFinal}&funcionarioId=${funcionarioId}`;

      window.open(url, '_blank');
    }
  };

  render() {
    const { dados, onRequestClose, tipoPlano } = this.props;
    const { dadosRelatorio, colunasImpressao, erroGeral, carregando } = this.state;
    
    const colunas: TableColumn[] = [
      { key: 'id', title: '', type: 'checkbox' },
      {
        key: 'description',
        title: translate('Coluna'),
        type: 'text',
        style: { width: isDesktop() ? 232 : 600 }
      }
    ];

    return (
      <Modal visible={dados != null} overlayStyle={styles.modal}>
        {carregando && <Loading />}
        <View style={styles.container}>
          <ImageButton
            nativeID="cartao-ponto-resumido-fechar"
            style={styles.imageButton}
            icon="close"
            onPress={() => onRequestClose(dadosRelatorio)}
          />
          <View style={styles.containerPadding}>
            <Card style={styles.containerTitleCard}>
              <Card.Section style={styles.containerTitleScreenCard}>
                <Text style={styles.titleScreen}>{translate('Imprimir')}</Text>
              </Card.Section>
            </Card>
            <View style={styles.containerFiltrosRelatorio}>
              <Card style={styles.colunasImprimir}>
                <Card.Header
                  containerStyle={styles.containerColunasImprimir}
                  title={translate('Colunas para impressão')}
                />
                <Card.Section style={styles.imprimir}>
                  <Table
                    // @ts-ignore
                    heightContainer={310}
                    nativeID="modal-imprimir-tabela"
                    idAttribute="id"
                    columns={colunas}
                    onSelectAll={this.handleSelectAll}
                    onSelect={this.handleSelect}
                    data={colunasImpressao}
                  />
                </Card.Section>
              </Card>
              <Card style={styles.opcoes}>
                <Card.Header
                  containerStyle={styles.headerCardOpcoes}
                  title={translate('Opções')}
                />
                <Card.Section style={styles.sectionCardOpcoes}>
                  {tipoPlano >= TipoPlano.Pro && (
                    <CheckBox
                      nativeID="modal-imprimir-checkbox-eventos"
                      label={translate('Eventos')}
                      value={dadosRelatorio.exibirEventos}
                      style={styles.checkbox}
                      onChange={value =>
                        this.handleChange('exibirEventos', value)
                      }
                    />
                  )}
                  <CheckBox
                    nativeID="modal-imprimir-checkbox-totais-rodape"
                    label={translate('Totais no Rodapé')}
                    value={dadosRelatorio.exibirTotaisNoRodape}
                    style={styles.checkbox}
                    onChange={value =>
                      this.handleChange('exibirTotaisNoRodape', value)
                    }
                  />
                  <CheckBox
                    nativeID="modal-imprimir-checkbox-termos-mtb"
                    label={translate('Termos do MTB')}
                    value={dadosRelatorio.exibirTermosMTB}
                    style={styles.checkbox}
                    onChange={value =>
                      this.handleChange('exibirTermosMTB', value)
                    }
                  />
                  <CheckBox
                    nativeID="modal-imprimir-checkbox-miniatura-horario"
                    label={translate('Miniatura do Horário')}
                    value={dadosRelatorio.exibirMiniaturaDoHorario}
                    style={styles.checkbox}
                    onChange={value =>
                      this.handleChange('exibirMiniaturaDoHorario', value)
                    }
                  />
                  <CheckBox
                    nativeID="modal-imprimir-checkbox-legendas-justificativas"
                    label={translate('Legenda de Justificativas')}
                    value={dadosRelatorio.exibirLegendasJustificativas}
                    style={styles.checkbox}
                    onChange={value =>
                      this.handleChange('exibirLegendasJustificativas', value)
                    }
                  />
                  <CheckBox
                    nativeID="modal-imprimir-checkbox-exibir-justificativas"
                    label={translate('Exibir Justificativas de Alterações')}
                    value={dadosRelatorio.exibirJustificativas}
                    style={styles.checkbox}
                    onChange={value =>
                      this.handleChange('exibirJustificativas', value)
                    }
                  />
                  <CheckBox
                    nativeID="modal-imprimir-checkbox-justificativas-usuario"
                    label={translate('Somente Preenchidas pelo Usuário')}
                    value={dadosRelatorio.justificativasPreenchidasUsuario}
                    style={styles.checkboxSubOpcao}
                    disabled={!dadosRelatorio.exibirJustificativas}
                    onChange={value =>
                      this.handleChange(
                        'justificativasPreenchidasUsuario',
                        value
                      )
                    }
                  />
                  <CheckBox
                    nativeID="modal-imprimir-checkbox-modo-paisagem"
                    label={translate('Modo Paisagem')}
                    value={dadosRelatorio.modoPaisagem}
                    style={styles.checkbox}
                    onChange={value => this.handleChange('modoPaisagem', value)}
                  />
                  {tipoPlano >= TipoPlano.Ultimate && (
                    <CheckBox
                      nativeID="modal-imprimir-checkbox-atividades"
                      label={translate('Atividades')}
                      value={dadosRelatorio.exibirAtividades}
                      style={styles.checkbox}
                      onChange={value =>
                        this.handleChange('exibirAtividades', value)
                      }
                    />
                  )}
                </Card.Section>
              </Card>
            </View>

            <ButtonBar
              buttonBarStyle={styles.botaoOpcoes}
              leftButton={{
                nativeID: 'botao-cancelar-modal-imprimir',
                text: translate('Cancelar'),
                onPress: () => onRequestClose(dadosRelatorio),
                primary: false
              }}
              rightButton={{
                nativeID: 'botao-imprimir-modal-imprimir',
                text: translate('Imprimir'),
                onPress: this.handlePrintPress
              }}
            />
          </View>
          <Message
            nativeID="modal-imprimir-erro"
            type="warning"
            message={erroGeral.mensagem}
            visible={erroGeral.mensagem !== ''}
            onRequestClose={async () => {
              this.setState({ erroGeral: { mensagem: '' } });

              if (erroGeral.callback) {
                await erroGeral.callback();
              }
            }}
          />
        </View>
      </Modal>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  imageButton: {
    borderWidth: 0,
    marginLeft: 'auto'
  },
  modal: {
    zIndex: 100,
    overflow: 'scroll',
    justifyContent: isDesktop() ? 'center' : 'flex-start'
  },
  colunasImprimir: {
    marginVertical: 20,
    width: isDesktop() ? 290 : '100%',
    marginRight: isDesktop() ? 10 : 'auto'
  },
  opcoes: {
    marginBottom: 20,
    width: isDesktop() ? 'auto' : '100%'
  },
  imprimir: {
    padding: 0
  },
  checkbox: {
    paddingVertical: 5
  },
  checkboxSubOpcao: {
    paddingVertical: 5,
    paddingLeft: 26
  },
  containerPadding: {
    paddingHorizontal: 10,
    paddingBottom: 20
  },
  container: {
    margin: 'auto',
    width: isDesktop() ? 590 : '90%',
    backgroundColor: theme.backgroundColor2,
    borderRadius: 10,
    justifyContent: 'center',
    marginBottom: 10,
    marginTop: 10
  },
  containerFiltrosRelatorio: {
    flexDirection: isDesktop() ? 'row' : 'column',
    justifyContent: 'space-evenly',
    alignItems: 'baseline'
  },
  headerCardOpcoes: {
    padding: 8
  },
  sectionCardOpcoes: {
    padding: 8
  },
  containerColunasImprimir: {
    padding: 8
  },
  containerTitleCard: {
    marginHorizontal: isDesktop() ? 7 : 'auto',
    width: isDesktop() ? 'auto' : '100%'
  },
  titleScreen: {
    fontSize: isTablet() ? 22 : 18,
    color: theme.textColor3,
    textAlign: 'center',
    flex: 1,
    fontFamily: theme.fontFamily1
  },
  containerTitleScreenCard: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8
  },
  botaoOpcoes: {
    alignSelf: isDesktop() ? 'center' : 'auto',
    width: isDesktop() ? '50%' : 'auto'
  }
});

export default ModalImprimir;
