import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'secullum-react-native-ui';
import LogoVertical from '../../../shared/components/svg/SecullumPontoVertical.jsx';
import { getTheme } from '../../../shared/modules/layout';

interface Props {
  nomeEmpresa: string;
}

function Header(props: Props) {
  const theme = getTheme();

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexBasis: 'auto',
      alignItems: 'center',
      paddingTop: 30
    },
    logoContainer: {
      flexDirection: 'row',
      height: 80,
      width: 145,
      alignItems: 'center'
    },
    nomeEmpresaText: {
      paddingTop: 10,
      paddingBottom: 5
    },
    linhaEntreLogoEForm: {
      backgroundColor: theme.backgroundColor2,
      height: 2,
      width: 220,
      marginTop: 20
    }
  });

  return (
    <View style={styles.container}>
      <View style={styles.logoContainer}>
        <LogoVertical />
      </View>
      <View style={styles.linhaEntreLogoEForm} />
      <Text
        size={16}
        color={theme.textColor3}
        style={styles.nomeEmpresaText}
        nativeID="nomeEmpresa"
      >
        {props.nomeEmpresa}
      </Text>
    </View>
  );
}

export default Header;
