import * as React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { translate } from 'secullum-i18n';
import { isTablet, Modal, TextBox, Text } from 'secullum-react-native-ui';
import { ImageButton } from 'secullum-react-native-ui';
import { getTheme } from '../../shared/modules/layout';
import { ButtonBar } from '../components/ButtonBar';
import { TipoAlteracaoAssinaturaDigitalCartaoPonto } from '../modules/types';

export interface DadosModalMotivo {
  id: number;
  descricao: string;
  periodo: string;
  tipoAcao: TipoAlteracaoAssinaturaDigitalCartaoPonto;
}

interface Props {
  dados: DadosModalMotivo;
  onCancelar: () => void;
  onConcluir: (motivo: string) => void;
}

interface State {
  motivo: string;
}

class ModalAssinaturaDigitalCartaoPonto extends React.Component<Props, State> {
  state: State = {
    motivo: ''
  };

  render() {
    const { dados } = this.props;
    const { motivo } = this.state;

    const usaSenha =
      dados.tipoAcao === TipoAlteracaoAssinaturaDigitalCartaoPonto.Aprovar;
    const titulo = usaSenha
      ? translate('Informe sua senha para aprovação')
      : translate('Informe o motivo da reprovação');

    return (
      <Modal
        visible={dados !== undefined}
        onRequestClose={this.props.onCancelar}
        overlayStyle={Platform.OS === 'web' ? styles.modalWeb : styles.modal}
      >
        <View
          style={Platform.OS === 'web' ? styles.containerWeb : styles.container}
        >
          {Platform.OS === 'web' ? (
            <View style={styles.modalIcons}>
              <Text bold style={styles.title}>
                {titulo}
              </Text>
              <ImageButton
                style={styles.imageButtonLeft}
                icon="close"
                onPress={this.props.onCancelar}
              />
            </View>
          ) : (
            <Text bold style={styles.title}>
              {titulo}
            </Text>
          )}
          <TextBox
            label={translate('Descrição')}
            style={styles.bodyText}
            editable={false}
            value={dados.descricao}
            nativeID="input-funcionario"
          />
          <TextBox
            label={translate('Período')}
            style={styles.bodyText}
            editable={false}
            value={dados.periodo}
            nativeID="input-funcionario"
          />
          {usaSenha ? (
            <TextBox
              label={translate('Senha')}
              style={[styles.bodyText, Platform.OS === 'web' && styles.TextWeb]}
              value={motivo}
              secureTextEntry
              onChange={senha => this.setState({ motivo: senha })}
              nativeID="input-senha"
            />
          ) : (
            <TextBox
              label={translate('Motivo')}
              style={[styles.bodyText, Platform.OS === 'web' && styles.TextWeb]}
              multiline={true}
              value={motivo}
              onChange={motivo => this.setState({ motivo: motivo })}
              nativeID="input-motivo"
            />
          )}
          <ButtonBar
            buttonBarStyle={{ padding: 16 }}
            leftButton={{
              text: translate('Cancelar'),
              onPress: this.props.onCancelar,
              primary: false,
              nativeID: 'botao-modal-cancelar-assinatura-digital-cartao-ponto'
            }}
            rightButton={{
              text: translate('Enviar'),
              onPress: () => this.props.onConcluir(motivo),
              nativeID: 'botao-modal-enviar-assinatura-digital-cartao-ponto'
            }}
          />
        </View>
      </Modal>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: 'center'
  },
  modalWeb: {
    overflow: 'scroll'
  },
  modalIcons: {
    flexDirection: 'row'
  },
  container: {
    backgroundColor: theme.backgroundColor1,
    marginHorizontal: 16,
    borderRadius: 5
  },
  containerWeb: {
    margin: 'auto',
    width: '90%',
    maxWidth: 366,
    backgroundColor: theme.backgroundColor2,
    borderRadius: 10,
    justifyContent: 'center'
  },
  title: {
    padding: 16,
    color: theme.textColor3,
    fontSize: isTablet() ? 20 : 17
  },
  bodyText: {
    padding: 16,
    margin: 16,
    marginBottom: 5,
    marginTop: 5,
    height: 60
  },
  TextWeb: {
    backgroundColor: theme.backgroundColor1
  },
  imageButtonLeft: {
    borderWidth: 0,
    marginLeft: 'auto'
  }
});

export default ModalAssinaturaDigitalCartaoPonto;
