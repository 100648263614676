import * as React from 'react';
import { View, Pressable, StyleSheet, Platform } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { translate } from 'secullum-i18n';
import { Card, Text } from 'secullum-react-native-ui';
import { getTheme, isHighResolution } from '../../modules/layout';

interface Props {
  quantidade: number;
  nativeID?: string;
  onClick(): void;
}

class CardSolicitacoesPendentes extends React.Component<Props> {
  render() {
    const { quantidade, onClick, nativeID } = this.props;

    return (
      <Pressable
        onPress={onClick}
        style={({ pressed }) => [
          { opacity: pressed ? 0.7 : 1 },
          {
            width: Platform.OS === 'web' && isHighResolution() ? 360 : '100%',
            marginTop: Platform.OS === 'web' && isHighResolution() ? 12 : 0
          }
        ]}
      >
        <Card>
          <Card.Header
            nativeID={nativeID}
            title={translate('Solicitações Pendentes')}
            titleStyle={{ textAlign: 'center' }}
          />
          <Card.Section>
            <View style={styles.pendenteView}>
              {quantidade > 0 ? (
                <>
                  <Text color={theme.textColor3}>
                    {translate('Você possui')}
                  </Text>
                  <Text
                    nativeID={`${nativeID}-total`}
                    bold
                    color={theme.statusBarColor}
                    size={40}
                  >
                    {quantidade}
                  </Text>
                  <Text color={theme.textColor3}>
                    {translate('solicitações para responder')}
                  </Text>
                </>
              ) : (
                <>
                  <FontAwesome
                    name={'check'}
                    color={theme.successColor}
                    size={50}
                  />
                  <Text nativeID={`${nativeID}-total`} color={theme.textColor3}>
                    {translate('Não há solicitações pendentes')}
                  </Text>
                </>
              )}
            </View>
          </Card.Section>
        </Card>
      </Pressable>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  pendenteView: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 80
  }
});

export default CardSolicitacoesPendentes;
