import * as React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import { withRouter } from 'react-router-dom';
import { translate } from 'secullum-i18n';
import {
  Button,
  Card,
  CheckBox,
  DropDown,
  TextBox,
  ErrorMessage,
  Message,
  Space,
  Link
} from 'secullum-react-native-ui';
import { Form } from '@secullum/react-native-autofocus';
import { CardRecuperacaoSenhaProps } from '../../../shared/components/CardRecuperacaoSenha';
import Api from '../../../shared/modules/api';
import {
  carregarDadosLoginAsync,
  salvarDadosLoginAsync
} from '../../../shared/modules/auth';
import {
  DadosEmpresa,
  DadosFuncionario,
  TipoPlataformaLogin,
  TipoUsuarioAutenticacao
} from '../../../shared/modules/types';
import { navegar, RouteComponentComBancoIdProps } from '../../modules/routes';
import Footer from './Footer';
import Header from './Header';

interface Campos {
  banco: string;
  usuario: string;
  senha: string;
  continuarConectado: boolean;
  tipoLoginCentral: TipoUsuarioAutenticacao;
}

interface Props extends RouteComponentComBancoIdProps {
  dadosEmpresaPrincipal: DadosEmpresa;
  onSuccessoLogin: (dadosFuncionario: DadosFuncionario) => Promise<void>;
}

interface State {
  campos: Campos;
  erros: Partial<Campos>;
  erroGeral?: string;
}

class Login extends React.Component<Props, State> {
  state: State = {
    campos: {
      banco: '',
      usuario: '',
      senha: '',
      continuarConectado: false,
      tipoLoginCentral: TipoUsuarioAutenticacao.Folha
    },
    erros: {}
  };

  async componentDidMount() {
    const dadosLogin = await carregarDadosLoginAsync();

    if (dadosLogin != null) {
      this.setState({
        campos: {
          ...this.state.campos,
          usuario: dadosLogin.funcionarioNumero,
          continuarConectado: dadosLogin.continuarConectado || false,
          banco: dadosLogin.bancoId || '',
          tipoLoginCentral:
            dadosLogin.tipoUsuarioAutenticacao || TipoUsuarioAutenticacao.Folha
        }
      });
    }

    if (this.props.match.params.bancoId) {
      this.setState({
        campos: {
          ...this.state.campos,
          banco: this.props.match.params.bancoId
        }
      });
    }
  }

  handleFieldChange = (name: string, value: any) => {
    this.setState({
      campos: {
        ...this.state.campos,
        [name]: value
      }
    });
  };

  handleEntrarPress = async () => {
    const { campos } = this.state;
    const api = new Api();

    if (!campos.banco) {
      this.setState({
        erros: { banco: translate('Campo obrigatório.') }
      });
    } else if (isNaN(parseInt(campos.banco))) {
      this.setState({
        erros: { banco: translate('Banco de dados não encontrado') }
      });
    } else if (!campos.usuario || !campos.senha) {
      this.setState({
        erros: { usuario: translate('Usuário ou senha inválido.') }
      });
    } else {
      const bancovalido = await this.verificarBancoValidoAsync();

      if (!bancovalido) {
        return;
      }

      api.postLogin(
        {
          bancoId: campos.banco,
          usuario: campos.usuario,
          senha: campos.senha,
          plataformaLogin: TipoPlataformaLogin.Web,
          UsuarioAutenticacao: campos.tipoLoginCentral
        },
        {
          onSuccess: async (dadosFuncionario: DadosFuncionario) => {
            navegar({
              props: this.props,
              path: '/',
              bancoId: campos.banco,
              replace: true
            });

            await salvarDadosLoginAsync({
              funcionarioId: dadosFuncionario.id,
              funcionarioNumero: campos.usuario,
              funcionarioSenha: campos.senha,
              bancoId: campos.banco,
              continuarConectado: campos.continuarConectado,
              tipoUsuarioAutenticacao: campos.tipoLoginCentral
            });

            await this.props.onSuccessoLogin(dadosFuncionario);

            api.funcionario.setarDadosFuncionario(dadosFuncionario);
          },
          onError: err => {
            this.setState({
              erroGeral: err.message,
              erros: {}
            });
          },
          onValidationError: (_, errorObj) => {
            if (errorObj.plano) {
              this.setState({
                erroGeral: errorObj.plano
              });

              return;
            }

            this.setState({
              erros: errorObj
            });
          }
        }
      );
    }
  };

  verificarBancoValidoAsync = async () => {
    const api = new Api();
    const { campos } = this.state;
    let bancoValido;

    await api.getVerificarBancoValidoAsync(campos.banco, {
      onSuccess: (resp: boolean) => {
        bancoValido = resp;

        if (!resp) {
          this.setState({
            erros: {
              banco: translate('Banco de dados não encontrado')
            }
          });
        }
      },
      onError: err => {
        this.setState({
          erroGeral: err.message,
          erros: {}
        });
      }
    });

    return bancoValido;
  };

  handleModalRecuperarSenha = async () => {
    const { campos } = this.state;

    if (!campos.banco) {
      this.setState({ erros: { banco: translate('Campo obrigatório.') } });
      return;
    }

    if (isNaN(parseInt(campos.banco))) {
      this.setState({
        erros: { banco: translate('Banco de dados não encontrado') }
      });
      return;
    }

    if (!campos.usuario) {
      this.setState({ erros: { usuario: translate('Campo obrigatório.') } });
      return;
    }

    const api = new Api();

    const bancovalido = await this.verificarBancoValidoAsync();

    if (!bancovalido) {
      return;
    }

    await api.getEmailValidoBancoUsuarioAsync(
      {
        bancoId: campos.banco,
        usuario: campos.usuario,
        usuarioAutenticacao: campos.tipoLoginCentral.toString()
      },
      {
        onSuccess: async email => {
          if (!email) {
            this.setState({
              erroGeral: translate(
                'E-mail não encontrado. Por favor entre em contato com o seu RH.'
              ),
              erros: {}
            });

            return;
          }

          await salvarDadosLoginAsync({
            funcionarioId: 0,
            funcionarioNumero: campos.usuario,
            funcionarioSenha: '',
            bancoId: campos.banco,
            continuarConectado: campos.continuarConectado,
            tipoUsuarioAutenticacao: campos.tipoLoginCentral
          });

          const cardRecuperacaoSenhaProps: CardRecuperacaoSenhaProps = {
            emailFuncionario: email,
            bancoId: campos.banco,
            usuario: campos.usuario,
            tipoUsuarioAutenticacao: campos.tipoLoginCentral
          };

          this.props.history.push({
            pathname: '/RecuperarSenha',
            state: { cardRecuperacaoSenhaProps }
          });
        },
        onError: erro => {
          this.setState({
            erroGeral: erro.message,
            erros: {}
          });
        }
      }
    );
  };

  render() {
    const { dadosEmpresaPrincipal } = this.props;
    const { campos, erros, erroGeral } = this.state;

    return (
      <View style={styles.container}>
        <Card
          style={[
            styles.cardForm,
            {
              width: Math.min(345, Dimensions.get('window').width - 32)
            }
          ]}
        >
          <Card.Section>
            <Header nomeEmpresa={dadosEmpresaPrincipal.nome} />
            <Space />
            <Form focusOn={[TextBox]}>
              {!dadosEmpresaPrincipal.nome && (
                <>
                  <TextBox
                    nativeID="login-banco"
                    placeholder={translate('Banco')}
                    value={campos.banco}
                    icon={'database'}
                    onChange={(value: string) =>
                      this.handleFieldChange('banco', value)
                    }
                    maxLength={22}
                    returnKeyType="next"
                    autoFocus
                  />
                  <ErrorMessage
                    style={styles.error}
                    nativeID="login-erro-banco"
                    message={erros.banco}
                  />
                  <Space />
                </>
              )}
              <DropDown
                nativeID="dropdown-tipo-usuario-autenticacao"
                items={[
                  {
                    nativeID: 'n-folha',
                    value: TipoUsuarioAutenticacao.Folha,
                    label: translate('Nº Folha')
                  },
                  {
                    nativeID: 'n-identificador',
                    value: TipoUsuarioAutenticacao.Identificador,
                    label: translate('Nº Identificador')
                  }
                ]}
                value={campos.tipoLoginCentral}
                onChange={valor =>
                  this.handleFieldChange('tipoLoginCentral', valor)
                }
                icon={'id-card-o'}
              />
              <Space />
              <TextBox
                nativeID="login-numero-folha"
                placeholder={translate('Número')}
                value={campos.usuario}
                onChange={(value: string) =>
                  this.handleFieldChange('usuario', value)
                }
                maxLength={22}
                icon={'user-o'}
                returnKeyType="next"
                autoFocus={dadosEmpresaPrincipal.nome !== ''}
              />
              <ErrorMessage
                style={styles.error}
                nativeID="login-erro-usuario"
                message={erros.usuario}
              />
              <Space />
              <TextBox
                nativeID="login-senha"
                placeholder={translate('Senha')}
                value={campos.senha}
                icon={'key'}
                onChange={(value: string) =>
                  this.handleFieldChange('senha', value)
                }
                onSubmitEditing={this.handleEntrarPress}
                maxLength={20}
                returnKeyType="done"
                secureTextEntry
              />
              <ErrorMessage
                style={styles.error}
                nativeID="login-erro-senha"
                message={erros.senha}
              />
              <Space />
              <CheckBox
                label={translate('Continuar conectado')}
                value={campos.continuarConectado}
                onChange={value =>
                  this.handleFieldChange('continuarConectado', value)
                }
              />
              <Space />
              <Button
                nativeID="login-entrar"
                text={translate('Entrar')}
                onPress={this.handleEntrarPress}
              />
            </Form>
            <Space height={20} />
            <View nativeID="link-esqueci-minha-senha">
              <Link
                style={styles.linkAjuda}
                text={translate('Esqueci minha senha')}
                onPress={this.handleModalRecuperarSenha}
              />
            </View>
          </Card.Section>
        </Card>
        <Footer />
        <Message
          nativeID="login-erro"
          type="warning"
          visible={erroGeral !== undefined}
          message={erroGeral !== undefined ? erroGeral : ''}
          onRequestClose={() => this.setState({ erroGeral: undefined })}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: '#59CBE8',
    padding: 16,
    paddingBottom: 0,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  cardForm: {
    flexDirection: 'column',
    flexBasis: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  error: {
    textAlign: 'left'
  },
  linkAjuda: {
    textAlign: 'center',
    fontSize: 14
  }
});

export default withRouter(Login);
