import { translate } from 'secullum-i18n';

export const BROWSER_NO_SUPPORT_GEOLOCATION =
  'Navegador não possuí suporte a localização';

export const getGeolocationError = (error: GeolocationPositionError) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      return translate('Sem acesso à localização');
    case error.POSITION_UNAVAILABLE:
      return translate('Informações sobre a localização indisponíveis');
    case error.TIMEOUT:
      return translate('Tempo excedido');
    default:
      return translate('Erro desconhecido');
  }
};
