import * as React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { translate } from 'secullum-i18n';
import { isTablet, Modal, TextBox, Text } from 'secullum-react-native-ui';
import { ImageButton } from 'secullum-react-native-ui';
import ModalBiometriaAndroid from '../../native/components/ModalBiometriaAndroid';
import AutenticacaoLocalService from '../../native/services/AutenticacaoLocalService';
import { getTheme } from '../../shared/modules/layout';
import { ButtonBar } from '../components/ButtonBar';
import { DadosModalMotivoAssinaturaArquivos } from '../containers/ArquivosContainer';
import { ArquivoDadosStatus } from '../modules/types';

interface Props {
  dadosAssinatura: DadosModalMotivoAssinaturaArquivos;
  onCancelar: () => void;
  onConcluir: (motivo: string) => void;
}

interface State {
  motivo: string;
  exibirModalBiometriaAndroid: boolean;
  erros: { [key: string]: string };
}

class ModalAssinaturaDigitalArquivos extends React.Component<Props, State> {
  state: State = {
    motivo: '',
    exibirModalBiometriaAndroid: false,
    erros: {}
  };

  componentDidMount() {
    const { dadosAssinatura } = this.props;

    this.setState({
      exibirModalBiometriaAndroid:
        dadosAssinatura.efetuaLoginBiometria &&
        dadosAssinatura.tipoAcao === ArquivoDadosStatus.Aceita
    });
  }

  componentDidUpdate(_: Props, prevState: State) {
    if (
      prevState.exibirModalBiometriaAndroid !==
        this.state.exibirModalBiometriaAndroid &&
      this.state.exibirModalBiometriaAndroid
    ) {
      this.autenticarUsuarioBiometriaAsync();
    }
  }

  autenticarUsuarioBiometriaAsync = async () => {
    const result =
      await AutenticacaoLocalService.autenticarUsuarioBiometriaAsync();

    if (result.success) {
      this.props.onConcluir(this.props.dadosAssinatura.senha);
    } else {
      this.setState({
        erros: {
          erroModalBiometriaAndroid: result.error
        }
      });
    }
  };

  render() {
    const { dadosAssinatura } = this.props;
    const { motivo, exibirModalBiometriaAndroid, erros } = this.state;

    const aprovacao = dadosAssinatura.tipoAcao === ArquivoDadosStatus.Aceita;
    const titulo = aprovacao
      ? translate('Informe sua senha para aprovação')
      : translate('Informe o motivo da reprovação');

    return (
      <Modal
        visible={dadosAssinatura !== undefined}
        onRequestClose={this.props.onCancelar}
        overlayStyle={Platform.OS === 'web' ? styles.modalWeb : styles.modal}
      >
        <View
          style={Platform.OS === 'web' ? styles.containerWeb : styles.container}
        >
          {Platform.OS === 'web' ? (
            <View style={styles.modalIcons}>
              <Text bold style={styles.title}>
                {titulo}
              </Text>
              <ImageButton
                style={styles.imageButtonLeft}
                icon="close"
                onPress={this.props.onCancelar}
              />
            </View>
          ) : (
            <Text bold style={styles.title}>
              {titulo}
            </Text>
          )}
          {dadosAssinatura.nome && (
            <TextBox
              label={translate('Nome do Arquivo')}
              style={styles.bodyText}
              editable={false}
              value={dadosAssinatura.nome!}
              nativeID="input-funcionario-nome"
            />
          )}
          {dadosAssinatura.tipoArquivo && (
            <TextBox
              label={translate('Tipo de Arquivo')}
              style={styles.bodyText}
              editable={false}
              value={dadosAssinatura.tipoArquivo!}
              nativeID="input-funcionario-tipo"
            />
          )}
          {aprovacao ? (
            exibirModalBiometriaAndroid ? (
              <ModalBiometriaAndroid
                exibir={exibirModalBiometriaAndroid}
                mensagemErro={erros.errosModalBiometriaAndroid}
                onCancelar={this.props.onCancelar}
                onUsarSenha={() => {
                  this.setState({ exibirModalBiometriaAndroid: false });
                }}
              />
            ) : (
              <TextBox
                label={translate('Senha')}
                style={styles.bodyText}
                value={motivo}
                secureTextEntry
                onChange={senha => this.setState({ motivo: senha })}
                nativeID="input-senha"
              />
            )
          ) : (
            <TextBox
              label={translate('Motivo')}
              style={styles.bodyText}
              multiline={true}
              value={motivo}
              onChange={motivo => this.setState({ motivo: motivo })}
              nativeID="input-motivo"
            />
          )}
          <ButtonBar
            buttonBarStyle={{ padding: 16 }}
            leftButton={{
              text: translate('Cancelar'),
              onPress: this.props.onCancelar,
              primary: false,
              nativeID: 'botao-modal-cancelar-assinatura-digital-arquivos'
            }}
            rightButton={{
              text: translate('Enviar'),
              onPress: () => this.props.onConcluir(motivo),
              nativeID: 'botao-modal-enviar-assinatura-digital-arquivos'
            }}
          />
        </View>
      </Modal>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: 'center'
  },
  modalWeb: {
    overflow: 'scroll'
  },
  modalIcons: {
    flexDirection: 'row'
  },
  container: {
    backgroundColor: theme.backgroundColor1,
    marginHorizontal: 16,
    borderRadius: 5
  },
  containerWeb: {
    margin: 'auto',
    width: '90%',
    maxWidth: 366,
    backgroundColor: theme.backgroundColor2,
    borderRadius: 10,
    justifyContent: 'center'
  },
  title: {
    padding: 16,
    color: theme.textColor3,
    fontSize: isTablet() ? 20 : 17
  },
  bodyText: {
    padding: 16,
    margin: 16,
    marginBottom: 5,
    marginTop: 5,
    height: 60
  },
  imageButtonLeft: {
    borderWidth: 0,
    marginLeft: 'auto'
  }
});

export default ModalAssinaturaDigitalArquivos;
