import { ImagePickerErrorResult, ImagePickerResult } from 'expo-image-picker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Telas } from '../../shared/modules/telas';
import { JustificarAusenciaState } from '../../shared/screens/justificar-ausencia/CardJustificarAusencia';

const CHAVE_IMAGEM_PENDENTE = 'imagemPendente';
const CHAVE_IMAGEM_PENDENTE_TELA = 'imagemPendenteTela';
const CHAVE_ESTADO_JUSTIFICATIVAR_AUSENCIA = 'estadoJustificarAusencia';

export const salvarImagemPendenteAsync = async (
  dados: ImagePickerResult | ImagePickerErrorResult
) => {
  await AsyncStorage.setItem(CHAVE_IMAGEM_PENDENTE, JSON.stringify(dados));
};

export const buscarTelaComImagemPendente = async (): Promise<Telas | null> => {
  let imagemPendente, imagemPendenteTela;

  AsyncStorage.multiGet(
    [CHAVE_IMAGEM_PENDENTE, CHAVE_IMAGEM_PENDENTE_TELA],
    (_, data) => {
      if (data) {
        imagemPendente = data[0][1];
        imagemPendenteTela = data[1][1];
      }
    }
  );

  // se não existir imagem pendente, então não precisa definir a tela.
  if (!imagemPendente) {
    return null;
  }

  // se não existir o nome da tela, não precisa definir a tela.
  if (!imagemPendenteTela) {
    return null;
  }

  return imagemPendenteTela as Telas;
};

export const salvarEstadoJustificarAusenciaAsync = async (
  estado: JustificarAusenciaState
) => {
  await AsyncStorage.multiSet([
    [CHAVE_ESTADO_JUSTIFICATIVAR_AUSENCIA, JSON.stringify(estado)],
    [CHAVE_IMAGEM_PENDENTE_TELA, Telas.JustificarAusencia]
  ]);
};

export const carregarImagemPendenteEstadoJustificarAusencia =
  async (): Promise<{
    image: ImagePickerResult | ImagePickerErrorResult;
    justificarAusenciaEstado: JustificarAusenciaState;
  } | null> => {
    let estadoJustificarAusencia, imagemPendente;

    AsyncStorage.multiGet(
      [CHAVE_ESTADO_JUSTIFICATIVAR_AUSENCIA, CHAVE_IMAGEM_PENDENTE],
      (_, data) => {
        if (data) {
          estadoJustificarAusencia = data[0][1];
          imagemPendente = data[1][1];
        }
      }
    );

    if (!estadoJustificarAusencia) {
      return null;
    }

    if (!imagemPendente) {
      return null;
    }

    return {
      image: JSON.parse(imagemPendente),
      justificarAusenciaEstado: JSON.parse(estadoJustificarAusencia)
    };
  };

export const excluirEstadoJustificarAusenciaAsync = async () => {
  await AsyncStorage.multiRemove([
    CHAVE_ESTADO_JUSTIFICATIVAR_AUSENCIA,
    CHAVE_IMAGEM_PENDENTE,
    CHAVE_IMAGEM_PENDENTE_TELA
  ]);
};

export const verificarImagePickerErrorResult = (
  imagem: ImagePickerResult | ImagePickerErrorResult
): imagem is ImagePickerErrorResult => {
  return (imagem as ImagePickerErrorResult).message !== undefined;
};
