import * as React from 'react';
import { Platform } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Details,
  DetailsHeader,
  Space,
  formatDate
} from 'secullum-react-native-ui';
import ModalBatidaOriginal from '../../../native/components/ModalBatidaOriginal';
import { getTheme } from '../../../shared/modules/layout';
import CardAviso from '../../components/CardAviso';
import MapaBatida from '../../components/MapaBatida';
import Api from '../../modules/api';
import { gerarComprovante } from '../../modules/comprovatanteRegistro';
import { BatidasDados } from '../../modules/types';
import { ehHora } from '../../modules/utils';

export enum TipoDeTela {
  CartaoPonto = 0,
  PontoDiario = 1,
  CartaoPontoGerente = 2
}

interface State {
  showGeo: { [key: number]: boolean };
  batidaOriginalExibindo: { nome: string; valor: string } | null;
}

interface DiaBatidasProps {
  dados: BatidasDados;
  tipoDeTela: TipoDeTela;
  onLeftPress: (() => void) | undefined;
  onRightPress: (() => void) | undefined;
}

class DetalheDiaBatida extends React.Component<DiaBatidasProps, State> {
  state: State = {
    showGeo: {},
    batidaOriginalExibindo: null
  };

  ocultarTodosOsMapas = () => {
    this.setState({
      showGeo: {}
    });
  };

  montarLinhasBatidas = (dia: BatidasDados) => {
    const { showGeo } = this.state;
    const { dataAtualizacaoPortaria671 } = new Api().funcionario.state.dados;

    return dia.batidas
      .map((batida, index) => {
        const icons = [];

        if (
          batida.ehRepP &&
          batida.valor != '' &&
          dataAtualizacaoPortaria671 != null
        ) {
          icons.push({
            name: 'file-text-o',
            color: theme.textColor3,
            size: 22,
            onPress: async () => {
              await gerarComprovante(dia.id, batida.nome);
            }
          });
        }

        if (batida.geolocalizacao) {
          icons.push({
            name: 'map-marker',
            color: theme.textColor3,
            size: 22,
            onPress: () =>
              this.setState({
                showGeo: {
                  ...showGeo,
                  [index]: !showGeo[index]
                }
              })
          });
        }

        if (
          batida.valor != null &&
          batida.valor.length > 0 &&
          ehHora(batida.valor) &&
          batida.valorOriginal != batida.valor
        ) {
          icons.push({
            name: 'pencil',
            color: theme.textColor3,
            size: 18,
            onPress: () =>
              this.setState({
                batidaOriginalExibindo: {
                  nome: batida.nome,
                  valor: batida.valorOriginal || '-'
                }
              })
          });
        }

        const linhas = [];

        linhas.push({
          title: batida.nome,
          value: batida.valor || '-',
          icons
        });

        if (batida.geolocalizacao && this.state.showGeo[index]) {
          const geo = batida.geolocalizacao;

          linhas.push({
            render: () => (
              <MapaBatida
                coordenadas={{
                  latitude: geo.latitude,
                  longitude: geo.longitude,
                  accuracy: geo.precisao
                }}
                alturaMapa={Platform.OS == 'web' ? 250 : 295}
                nomeBatida={batida.nome}
                data={geo.dataHora}
                endereco={geo.endereco || translate('Endereço não disponível')}
                scrollEnabled={false}
                mostrarRelogio={false}
                mapId={'mapaDetalheBatida' + index.toString()}
              />
            )
          });
        }

        return linhas;
      })
      .reduce((acc, val) => acc.concat(val), []);
  };

  handleLeftPressDetailsHeader = () => {
    this.ocultarTodosOsMapas();

    const { onLeftPress } = this.props;

    if (onLeftPress) {
      onLeftPress();
    }
  };

  handleRightPressDetailsHeader = () => {
    this.ocultarTodosOsMapas();

    const { onRightPress } = this.props;

    if (onRightPress) {
      onRightPress();
    }
  };

  render() {
    const { batidaOriginalExibindo } = this.state;
    const { dados, tipoDeTela, onLeftPress, onRightPress } = this.props;

    let headerText: string;

    if (tipoDeTela === TipoDeTela.CartaoPonto) {
      headerText = formatDate(dados.data, 'eeee, dd/MM/yyyy');
    } else if (tipoDeTela === TipoDeTela.CartaoPontoGerente) {
      headerText =
        dados.funcionarioNome +
        '\n' +
        formatDate(dados.data, 'eeee, dd/MM/yyyy');
    } else {
      headerText = dados.funcionarioNome;
    }

    return (
      <>
        <DetailsHeader
          text={headerText}
          onLeftPress={onLeftPress && this.handleLeftPressDetailsHeader}
          onRightPress={onRightPress && this.handleRightPressDetailsHeader}
        />
        {dados.registroPendente && (
          <>
            <Space />
            <CardAviso
              nativeID="aviso-inclusoes-processamento"
              icone="hourglass-half"
              texto={`${translate(
                'Existem inclusões de ponto em processamento neste dia'
              )}.`}
            />
          </>
        )}
        <Space />
        <Details
          title={translate('Registro de Ponto')}
          lines={this.montarLinhasBatidas(dados)}
        />
        <Space />
        {dados.valores && dados.valores.length > 0 && (
          <Details
            title={translate('Cálculos')}
            lines={dados.valores.map(x => ({
              title: x.nome,
              value: x.valor
            }))}
          />
        )}

        <ModalBatidaOriginal
          dados={batidaOriginalExibindo}
          onRequestClose={() => this.setState({ batidaOriginalExibindo: null })}
        />
      </>
    );
  }
}

const theme = getTheme();

export default DetalheDiaBatida;
