import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { Modal, ImageButton } from 'secullum-react-native-ui';
import AssinaturaDigitalCartaoPontoDetalhe from '../../../shared/components/AssinaturaDigitalCartaoPontoDetalhe';
import { getTheme } from '../../../shared/modules/layout';
import { AssinaturaDigitalCartaoPontoDados } from '../../../shared/modules/types';

interface Props {
  assinaturaDigitalCartaoPonto: AssinaturaDigitalCartaoPontoDados;
  modalVisible: boolean;
  onClose: () => void;
}

class AssinaturasDigitaisCartaoPontoDetalhes extends React.Component<Props> {
  render() {
    const { modalVisible, assinaturaDigitalCartaoPonto } = this.props;

    return (
      <Modal visible={modalVisible} overlayStyle={{ overflow: 'scroll' }}>
        <View style={styles.modalContainer}>
          <View style={styles.modalIcons}>
            <ImageButton
              style={styles.imageButtonLeft}
              icon="close"
              onPress={this.props.onClose}
              nativeID="assinatura-digital-cartao-ponto-botao-fechar-detalhe"
            />
          </View>
          <View
            nativeID="assinatura-digital-cartao-ponto-detalhe"
            style={styles.modalSection}
          >
            <AssinaturaDigitalCartaoPontoDetalhe
              assinaturaDigitalCartaoPonto={assinaturaDigitalCartaoPonto}
            />
          </View>
        </View>
      </Modal>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  imageButtonRight: {
    borderWidth: 0,
    marginRight: 'auto'
  },
  imageButtonLeft: {
    borderWidth: 0,
    marginLeft: 'auto'
  },
  modalSection: {
    minHeight: 730,
    width: '90%',
    alignSelf: 'center',
    marginBottom: 20
  },
  modalContainer: {
    margin: 'auto',
    width: '90%',
    maxWidth: 600,
    backgroundColor: theme.backgroundColor2,
    borderRadius: 10,
    justifyContent: 'center',
    marginBottom: 10,
    marginTop: 10
  },
  modalIcons: {
    flexDirection: 'row'
  },
  originalCard: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  containerFoto: {
    paddingHorizontal: 16,
    paddingVertical: 10
  }
});

export default AssinaturasDigitaisCartaoPontoDetalhes;
