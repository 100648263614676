import * as LocalAuthentication from 'expo-local-authentication';
import { LocalAuthenticationResult } from 'expo-local-authentication';
import { Platform } from 'react-native';
import { translate } from 'secullum-i18n';

class AutenticacaoLocalService {
  static autenticarUsuarioBiometriaAsync =
    async (): Promise<LocalAuthenticationResult> => {
      let usuarioAutenticadoResult: LocalAuthenticationResult;

      if (Platform.OS === 'ios') {
        usuarioAutenticadoResult = await LocalAuthentication.authenticateAsync({
          promptMessage: translate('Toque o dedo no sensor para continuar...')
        });
      } else {
        usuarioAutenticadoResult =
          await LocalAuthentication.authenticateAsync();
      }

      // Este erro ('app_cancel') ocorre quando ainda tem uma processo de autenticação aguardando a digital e o app solicita a digital novamente
      // neste caso, nada deve ser feito pois o outro processo que já está em andamento irá responder
      if (
        !usuarioAutenticadoResult.success &&
        (usuarioAutenticadoResult.error === 'app_cancel' ||
          usuarioAutenticadoResult.error === 'user_cancel')
      ) {
        usuarioAutenticadoResult.error = translate(
          'A biometria não foi reconhecida, pressione Cancelar e tente novamente.'
        );
      } else if (
        !usuarioAutenticadoResult.success &&
        (usuarioAutenticadoResult.error === 'lockout' ||
          usuarioAutenticadoResult.error === 'unknown')
      ) {
        usuarioAutenticadoResult.error = translate(
          'Número de tentativas excedido, por favor, utilize sua senha.'
        );
      } else if (
        !usuarioAutenticadoResult.success &&
        usuarioAutenticadoResult.error === 'not_enrolled'
      ) {
        usuarioAutenticadoResult.error = translate(
          'Para utilizar o reconhecimento por biometria, é necessário possuir no mínimo uma digital cadastrada no dispositivo.'
        );
      }

      return usuarioAutenticadoResult;
    };
}

export default AutenticacaoLocalService;
