import * as React from 'react';
import { Platform, View, StyleSheet } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Card,
  TextBox,
  ErrorMessage,
  Space,
  DatePicker,
  Message,
  MaskOptions,
  TextBoxMask,
  FilePicker,
  DropDown
} from 'secullum-react-native-ui';
import { Form } from '@secullum/react-native-autofocus';
import { ButtonBar } from '../../components/ButtonBar';
import Api from '../../modules/api';
import { carregarDadosLoginAsync, DadosLogin } from '../../modules/auth';
import { DadosAdicionaisFuncionario, DefaultList } from '../../modules/types';
import ModalCapturarComprovanteEndereco from './ModalCapturarComprovanteEndereco';
import ModalCapturarFotoFuncionario from './ModalCapturarFotoFuncionario';

interface State {
  resultado: 'sucesso' | 'erro' | null;
  campos: DadosAdicionaisFuncionario;
  camposOriginais: DadosAdicionaisFuncionario;
  erroGeral: string;
  erros: { [key: string]: string };
  capturandoFoto: boolean;
  capturandoComprovanteEndereco: boolean;
  listaEscolaridade: DefaultList[];
}

interface Props {
  onSuccess(): void;
  onCancel(): void;
  onSubmit?(): void;
  onValidationError(): void;
  onErroConexao?: (mensagem: string, onTentarNovamente: () => void) => void;
  renderFoto(foto: string): JSX.Element;
}

const estadoInicialCampos: DadosAdicionaisFuncionario = {
  endereco: '',
  bairro: '',
  cidade: '',
  estado: '',
  cep: '',
  telefone: '',
  celular: '',
  email: '',
  rg: '',
  expedicaoRg: new Date(),
  ssp: '',
  cpf: '',
  pai: '',
  mae: '',
  nascimento: new Date(),
  nacionalidade: '',
  naturalidade: '',
  foto: '',
  comprovanteEndereco: '',
  escolaridadeId: null
};

class CardDadosCadastrais extends React.Component<Props, State> {
  state: State = {
    resultado: null,
    campos: estadoInicialCampos,
    camposOriginais: estadoInicialCampos,
    erroGeral: '',
    erros: {},
    listaEscolaridade: [],
    capturandoFoto: false,
    capturandoComprovanteEndereco: false
  };

  handleEnviar = () => {
    const { campos } = this.state;

    if (this.verificarMudancaEndereco() && !campos.comprovanteEndereco) {
      this.setState({ capturandoComprovanteEndereco: true });
      return;
    }

    if (this.props.onSubmit) {
      this.props.onSubmit();
    }

    const api = new Api();

    api.postSolicitacaoDadosCadastrais(campos, {
      onSuccess: () => {
        this.setState({
          resultado: 'sucesso'
        });
      },
      onError: err => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () => this.handleEnviar());
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      },
      onValidationError: (errorList, errorObj) => {
        this.setState({
          erros: errorObj
        });

        this.props.onValidationError();
      }
    });
  };

  handleChange = (name: string, value: any) => {
    this.setState({
      campos: {
        ...this.state.campos,
        [name]: value
      }
    });
  };

  handleSucesso() {
    this.setState({ resultado: null }, () => {
      this.props.onSuccess();
    });
  }

  handleConcluirCapturaFotoAsync = async (foto: string) => {
    this.setState({ capturandoFoto: false });
    this.handleChange('foto', foto);
  };

  handleExcluirCapturaFotoAsync = async () => {
    this.handleChange('foto', undefined);
  };

  async componentDidMount() {
    const dadosLogin = await carregarDadosLoginAsync();

    if (
      dadosLogin == null ||
      (dadosLogin.bancoId.length > 0 && dadosLogin.funcionarioId == 0)
    ) {
      return;
    }

    await this.carregarListaEscolaridadeAsync();
    await this.carregarDadosCadastraisAsync(dadosLogin);
  }

  carregarListaEscolaridadeAsync = async () => {
    const api = new Api();

    await api.getListaEscolaridade({
      onSuccess: resp => {
        this.setState({ listaEscolaridade: resp });
      },
      onError: err => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () =>
            this.carregarListaEscolaridadeAsync()
          );
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      }
    });
  };

  carregarDadosCadastraisAsync = async (dadosLogin: DadosLogin) => {
    const api = new Api();

    await api.getDadosAdicionaisFuncionario(dadosLogin.funcionarioId, {
      onSuccess: resp => {
        resp.expedicaoRg = resp.expedicaoRg
          ? new Date(resp.expedicaoRg)
          : undefined;
        resp.nascimento = resp.nascimento
          ? new Date(resp.nascimento)
          : undefined;

        this.setState({
          campos: resp,
          camposOriginais: resp
        });
      },
      onError: err => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () =>
            this.carregarDadosCadastraisAsync(dadosLogin)
          );
        } else {
          this.setState({
            resultado: 'erro',
            erroGeral: err.message
          });
        }
      }
    });
  };

  verificarMudancaEndereco = () => {
    const api = new Api();

    if (!api.funcionario.state.dados.usaExigenciaComprovanteEndereco) {
      return false;
    }

    const camposEndereco: Array<keyof DadosAdicionaisFuncionario> = [
      'endereco',
      'bairro',
      'cidade',
      'estado',
      'cep'
    ];

    const { campos, camposOriginais } = this.state;

    for (const campoEndereco of camposEndereco) {
      // Trata nulos como se fosse '', para que não haja mudança se o usuário só digitar algo e apagar
      const valorAtual = campos[campoEndereco] || '';
      const valorOriginal = camposOriginais[campoEndereco] || '';

      if (valorAtual != valorOriginal) {
        return true;
      }
    }

    return false;
  };

  render() {
    const {
      campos,
      resultado,
      erroGeral,
      erros,
      capturandoFoto,
      capturandoComprovanteEndereco
    } = this.state;

    const { renderFoto } = this.props;

    // Adicionei + 12 horas pois estava considerando um dia atrás (ex.: 30/05 - 29-05)
    if (campos.expedicaoRg) {
      campos.expedicaoRg.setUTCHours(12);
    }

    if (campos.nascimento) {
      campos.nascimento.setUTCHours(12);
    }

    const listaEscolaridade = this.state.listaEscolaridade.map(x => ({
      nativeID: 'escolaridade-' + x.descricao,
      label: x.descricao,
      value: x.id
    }));

    return (
      <Card>
        <Card.Section>
          <View style={Platform.OS === 'web' && styles.centralizarView}>
            <Form focusOn={[TextBox, TextBoxMask]}>
              <TextBox
                nativeID="dados-cadastrais-endereco"
                label={translate('Endereço')}
                value={campos.endereco || ''}
                maxLength={100}
                returnKeyType={'next'}
                onChange={endereco => this.handleChange('endereco', endereco)}
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-endereco"
                message={erros.endereco}
              />
              <Space />
              <TextBox
                nativeID="dados-cadastrais-bairro"
                label={translate('Bairro')}
                value={campos.bairro || ''}
                maxLength={30}
                returnKeyType={'next'}
                onChange={bairro => this.handleChange('bairro', bairro)}
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-bairro"
                message={erros.bairro}
              />
              <Space />
              <TextBox
                nativeID="dados-cadastrais-cidade"
                label={translate('Cidade')}
                value={campos.cidade || ''}
                maxLength={50}
                returnKeyType={'next'}
                onChange={cidade => this.handleChange('cidade', cidade)}
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-cidade"
                message={erros.cidade}
              />
              <Space />
              <TextBox
                nativeID="dados-cadastrais-estado"
                label={translate('Estado')}
                value={campos.estado || ''}
                maxLength={2}
                returnKeyType={'next'}
                onChange={estado => this.handleChange('estado', estado)}
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-estado"
                message={erros.estado}
              />
              <Space />
              <TextBoxMask
                nativeID="dados-cadastrais-cep"
                label={translate('CEP')}
                value={campos.cep}
                onChange={cep => this.handleChange('cep', cep)}
                type={'custom'}
                maxLength={9}
                keyboardType={'numeric'}
                returnKeyType={'next'}
                options={{ mask: '99999-999' } as MaskOptions}
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-cep"
                message={erros.cep}
              />
              <Space />
              <TextBox
                nativeID="dados-cadastrais-telefone"
                label={translate('Telefone')}
                value={campos.telefone || ''}
                maxLength={20}
                keyboardType={'phone-pad'}
                returnKeyType={'next'}
                onChange={telefone => this.handleChange('telefone', telefone)}
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-telefone"
                message={erros.telefone}
              />
              <Space />
              <TextBox
                nativeID="dados-cadastrais-celular"
                label={translate('Celular')}
                value={campos.celular || ''}
                maxLength={20}
                keyboardType={'phone-pad'}
                returnKeyType={'next'}
                onChange={celular => this.handleChange('celular', celular)}
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-celular"
                message={erros.celular}
              />
              <Space />
              <TextBox
                nativeID="dados-cadastrais-email"
                label={translate('Email')}
                value={campos.email || ''}
                maxLength={100}
                keyboardType={'email-address'}
                returnKeyType={'next'}
                onChange={email => this.handleChange('email', email)}
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-email"
                message={erros.email}
              />
              <Space />
              <TextBox
                nativeID="dados-cadastrais-rg"
                label={translate('RG')}
                value={campos.rg || ''}
                maxLength={14}
                keyboardType={'numeric'}
                returnKeyType={'next'}
                onChange={rg => this.handleChange('rg', rg)}
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-rg"
                message={erros.rg}
              />
              <Space />
              <DatePicker
                nativeID="dados-cadastrais-data-expedicaoRg"
                label={translate('Expedição')}
                value={campos.expedicaoRg}
                onChange={expedicaoRg =>
                  this.handleChange('expedicaoRg', expedicaoRg)
                }
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-expedicaoRg"
                message={erros.expedicaoRg}
              />
              <Space />
              <TextBox
                nativeID="dados-cadastrais-ssp"
                label={translate('SSP')}
                value={campos.ssp || ''}
                maxLength={2}
                returnKeyType={'next'}
                onChange={ssp => this.handleChange('ssp', ssp)}
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-ssp"
                message={erros.ssp}
              />
              <Space />
              <TextBoxMask
                nativeID="dados-cadastrais-cpf"
                label={translate('CPF')}
                value={campos.cpf}
                onChange={cpf => this.handleChange('cpf', cpf)}
                type={'custom'}
                maxLength={14}
                keyboardType={'numeric'}
                options={{ mask: '999.999.999-99' } as MaskOptions}
                returnKeyType={'next'}
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-cpf"
                message={erros.cpf}
              />
              <Space />
              <TextBox
                nativeID="dados-cadastrais-pai"
                label={translate('Pai')}
                value={campos.pai || ''}
                maxLength={100}
                returnKeyType={'next'}
                onChange={pai => this.handleChange('pai', pai)}
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-pai"
                message={erros.pai}
              />
              <Space />
              <TextBox
                nativeID="dados-cadastrais-mae"
                label={translate('Mãe')}
                value={campos.mae || ''}
                maxLength={100}
                returnKeyType={'next'}
                onChange={mae => this.handleChange('mae', mae)}
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-mae"
                message={erros.mae}
              />
              <Space />
              <DatePicker
                nativeID="dados-cadastrais-data-nascimento"
                label={translate('Nascimento')}
                value={campos.nascimento}
                onChange={nascimento =>
                  this.handleChange('nascimento', nascimento)
                }
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-nascimento"
                message={erros.nascimento}
              />
              <Space />
              <TextBox
                nativeID="dados-cadastrais-nacionalidade"
                label={translate('Nacionalidade')}
                value={campos.nacionalidade || ''}
                maxLength={50}
                returnKeyType={'next'}
                onChange={nacionalidade =>
                  this.handleChange('nacionalidade', nacionalidade)
                }
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-nacionalidade"
                message={erros.nacionalidade}
              />
              <Space />
              <TextBox
                nativeID="dados-cadastrais-naturalidade"
                label={translate('Naturalidade')}
                value={campos.naturalidade || ''}
                maxLength={50}
                returnKeyType={'done'}
                onChange={naturalidade =>
                  this.handleChange('naturalidade', naturalidade)
                }
              />
              <ErrorMessage
                nativeID="dados-cadastrais-erro-naturalidade"
                message={erros.naturalidade}
              />
              <Space />
              <DropDown
                nativeID="escolaridade"
                label={translate('Escolaridade')}
                items={listaEscolaridade}
                value={campos.escolaridadeId}
                onChange={escolaridadeId =>
                  this.handleChange('escolaridadeId', escolaridadeId)
                }
              />
              <ErrorMessage
                nativeID="escolaridade-erro"
                message={erros.escolaridadeId}
              />
              <Space />
              <>
                {campos.foto && (
                  <>
                    {renderFoto(campos.foto)}
                    <Space />
                  </>
                )}

                <FilePicker
                  label={
                    campos.foto
                      ? translate('Remover foto')
                      : translate('Enviar uma foto.')
                  }
                  icon={campos.foto ? 'remove' : 'camera'}
                  onPress={() =>
                    campos.foto
                      ? this.handleExcluirCapturaFotoAsync()
                      : this.setState({ capturandoFoto: true })
                  }
                  nativeID="dados-cadastrais-foto"
                />

                {capturandoFoto && (
                  <ModalCapturarFotoFuncionario
                    onConcluir={this.handleConcluirCapturaFotoAsync}
                    onCancelar={() => this.setState({ capturandoFoto: false })}
                  />
                )}
              </>
              <Space />
              <ButtonBar
                buttonBarStyle={Platform.OS === 'web' && styles.centralizarView}
                leftButton={{
                  nativeID: 'dados-cadastrais-cancelar',
                  text: translate('Cancelar'),
                  primary: false,
                  onPress: this.props.onCancel
                }}
                rightButton={{
                  nativeID: 'dados-cadastrais-enviar',
                  text: translate('Enviar'),
                  onPress: () => this.handleEnviar()
                }}
              />
              <Message
                nativeID="dados-cadastrais-sucesso"
                message={translate(
                  'Solicitação de alteração de dados cadastrais enviada com êxito'
                )}
                visible={resultado === 'sucesso'}
                onRequestClose={() => this.handleSucesso()}
              />
              <Message
                nativeID="dados-cadastrais-erro"
                type="warning"
                message={erroGeral}
                visible={resultado === 'erro'}
                onRequestClose={() =>
                  this.setState({ resultado: null, erroGeral: '' })
                }
              />
              {capturandoComprovanteEndereco && (
                <ModalCapturarComprovanteEndereco
                  renderFoto={renderFoto}
                  onConcluir={fotoComprovante =>
                    this.setState(
                      state => ({
                        campos: {
                          ...state.campos,
                          comprovanteEndereco: fotoComprovante
                        },
                        capturandoComprovanteEndereco: false
                      }),
                      () => {
                        this.handleEnviar();
                      }
                    )
                  }
                  onCancelar={() =>
                    this.setState({
                      capturandoComprovanteEndereco: false
                    })
                  }
                />
              )}
            </Form>
          </View>
        </Card.Section>
      </Card>
    );
  }
}

const styles = StyleSheet.create({
  centralizarView: {
    margin: 'auto',
    maxWidth: 350,
    width: '100%'
  }
});

export default CardDadosCadastrais;
