import * as React from 'react';
import {
  Dimensions,
  EmitterSubscription,
  StyleSheet,
  View
} from 'react-native';
import { NavigationStackScreenProps } from 'react-navigation-stack';
import { withRouter } from 'react-router-dom';
import { Loading, Message } from 'secullum-react-native-ui';
import { Subscribe } from 'unstated';
import { FuncionarioContainer } from '../../shared/containers/FuncionarioContainer';
import { ProcessandoContainer } from '../../shared/containers/ProcessandoContainer';
import Api from '../../shared/modules/api';
import { carregarDadosLoginAsync } from '../../shared/modules/auth';
import { loadExpressionsAsync } from '../../shared/modules/i18n';
import { getTheme } from '../../shared/modules/layout';
import '../../shared/modules/logbox';
import { verificarExibirTelaPorPath } from '../../shared/modules/perfilFuncionario';
import { Telas } from '../../shared/modules/telas';
import { carregarConfiguracaoTelaInicialAsync } from '../../shared/modules/telasIniciais';
import {
  DadosEmpresa,
  DadosFuncionario,
  TipoPlataformaLogin
} from '../../shared/modules/types';
import { ehIE } from '../modules/browser';
import { RouteComponentComBancoIdProps } from '../modules/routes';
import PublicRoutes from './PublicRoutes';
import Routes from './Routes';

interface State {
  dadosEmpresaPrincipal: DadosEmpresa;
  carregando: boolean;
  erro?: string;
  redirectToHome: boolean;
  bancoIdToRedirect: string;
}

class App extends React.Component<
  RouteComponentComBancoIdProps & NavigationStackScreenProps,
  State
> {
  state: State = {
    dadosEmpresaPrincipal: {
      nome: '',
      telefone: '',
      email: '',
      caminhoTermoPrivacidade: ''
    },
    carregando: true,
    redirectToHome: false,
    bancoIdToRedirect: ''
  };

  telaInicial = Telas.Indicadores;

  screenChangeDimensionsSubscription: EmitterSubscription | undefined;

  async componentDidMount() {
    await this.loadAsync();

    this.screenChangeDimensionsSubscription = Dimensions.addEventListener(
      'change',
      this.handleDimensionsChange
    );

    await this.checkIfShouldRedirectToHomeWithBancoIdAsync();

    this.setState({ carregando: false });
  }

  componentWillUnmount() {
    if (this.screenChangeDimensionsSubscription) {
      this.screenChangeDimensionsSubscription.remove();
    }
  }

  async componentDidUpdate(prevProps: RouteComponentComBancoIdProps) {
    if (this.props.match.params.bancoId !== prevProps.match.params.bancoId) {
      const api = new Api();
      const { bancoId } = this.props.match.params;

      await api.getDadosEmpresaPrincipal(bancoId, {
        onSuccess: dados => {
          this.setState({
            dadosEmpresaPrincipal: dados
          });
        },
        onValidationError: errorList => {
          this.setState({
            erro: errorList[0].message
          });
        },
        onError: error => {
          this.setState({
            erro: error.message
          });
        }
      });
    }
  }

  checkIfShouldRedirectToHomeWithBancoIdAsync = async () => {
    if (this.props.match.params.bancoId) {
      return;
    }

    const dadosLogin = await carregarDadosLoginAsync();

    if (!dadosLogin) {
      return;
    }

    const { bancoId, continuarConectado, funcionarioId } = dadosLogin;

    if (bancoId && continuarConectado && funcionarioId > 0) {
      await this.loadDadosFuncionarioAsync(dadosLogin.funcionarioId);

      this.setState({
        redirectToHome: true,
        bancoIdToRedirect: bancoId
      });
    }
  };

  loadDadosEmpresaAsync = async (bancoId: string) => {
    const api = new Api();
    let encontrouEmpresa = false;

    if (bancoId) {
      await api.getDadosEmpresaPrincipal(bancoId, {
        onSuccess: dados => {
          this.setState({
            dadosEmpresaPrincipal: dados
          });

          encontrouEmpresa = true;
        },
        onValidationError: errorList => {
          this.setState({
            erro: errorList[0].message
          });
        },
        onError: error => {
          this.setState({
            erro: error.message
          });
        }
      });
    }

    return encontrouEmpresa;
  };

  loadDadosFuncionarioAsync = async (funcionarioId: number) => {
    const api = new Api();

    const dados = {
      id: funcionarioId,
      plataformaLogin: TipoPlataformaLogin.Web,
      apelidoDispositivo: 'navegador', // Dados preenchidos para evitar a controller de compatibilidade
      identificacaoDispositivo: 'IP'
    };

    await api.getDadosFuncionario(dados, {
      onSuccess: async dados => {
        this.telaInicial = await carregarConfiguracaoTelaInicialAsync(dados);

        api.funcionario.setarDadosFuncionario(dados);
      },
      onError: () => {
        api.funcionario.limparDadosFuncionario();
      }
    });
  };

  loadAsync = async () => {
    await loadExpressionsAsync(navigator.language);

    const { bancoId } = this.props.match.params;
    const encontrouEmpresa = await this.loadDadosEmpresaAsync(bancoId);

    if (!encontrouEmpresa) {
      return;
    }

    const dadosLogin = await carregarDadosLoginAsync();

    if (
      dadosLogin === null ||
      dadosLogin.funcionarioId === 0 ||
      dadosLogin.bancoId !== bancoId
    ) {
      return;
    }

    await this.loadDadosFuncionarioAsync(dadosLogin.funcionarioId);
  };

  handleDimensionsChange = () => {
    this.forceUpdate();
  };

  render() {
    const {
      redirectToHome,
      bancoIdToRedirect,
      dadosEmpresaPrincipal,
      carregando,
      erro
    } = this.state;

    const { bancoId } = this.props.match.params;

    if (carregando) {
      return <Loading />;
    }

    if (erro) {
      return (
        <Message
          type="warning"
          visible={erro !== undefined}
          message={erro ?? ''}
        />
      );
    }

    return (
      <View
        style={[
          styles.container,
          { minHeight: Dimensions.get('window').height }
        ]}
      >
        <Subscribe to={[FuncionarioContainer]}>
          {(funcionario: FuncionarioContainer) => {
            const telasOcultar =
              funcionario.state.dados.dadosPerfilFuncionario &&
              funcionario.state.dados.dadosPerfilFuncionario.telasOcultar;

            // Quando o usuário acessa diretamente pela URL, ele pode recuperar telasOcultar que
            // não estão mais disponíveis para ele (devido ao fato dele guardar essa informação no state)
            // nessa situação, ele vai setar a initialRoute para uma tela que não tem acesso e vai ficar exibindo mensagem de página não encontrada para sempre.
            // Para evitar isso, caso ele não tenha acessao a tela inicial, ele é redirecionado para a tela de login, dessa forma,
            // o state será iniciado novamente e a informação da lista de telas a serem ocultadas também.
            const deveExibirTelaInicial = verificarExibirTelaPorPath(
              window.location.pathname,
              this.telaInicial,
              telasOcultar,
              !funcionario.state.dados.dadosPerfilFuncionario
                ? funcionario.state.dados.perfil
                : funcionario.state.dados.dadosPerfilFuncionario.tipoPerfil,
              !funcionario.state.dados.dadosPerfilFuncionario
                ? false
                : funcionario.state.dados.dadosPerfilFuncionario
                    .restringirAjustePontos,
              funcionario.state.dados.plano
            );

            if (funcionario.state.dados.id > 0 && deveExibirTelaInicial) {
              return (
                <Routes
                  redirectToHome={redirectToHome}
                  bancoIdToRedirect={bancoIdToRedirect}
                  initialRoute={this.telaInicial}
                />
              );
            }

            return (
              <PublicRoutes
                dadosEmpresaPrincipal={dadosEmpresaPrincipal}
                onSuccessoLogin={async (dados: DadosFuncionario) => {
                  this.telaInicial = await carregarConfiguracaoTelaInicialAsync(
                    dados
                  );
                }}
                onNaoEncontrado={() => {
                  this.props.history.push('/' + (bancoId ?? ''));
                }}
              />
            );
          }}
        </Subscribe>
        <Subscribe to={[ProcessandoContainer]}>
          {(processando: ProcessandoContainer) =>
            processando.state.quantidade > 0 ? <Loading /> : null
          }
        </Subscribe>
      </View>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: ehIE() ? 0 : 'auto',
    backgroundColor: theme.backgroundColor2
  }
});

export default withRouter(App);
