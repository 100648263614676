import * as React from 'react';
import { StyleSheet, Platform, View } from 'react-native';
import { translate, translateFirstUpper } from 'secullum-i18n';
import { Message, Button } from 'secullum-react-native-ui';
import Api from '../../../shared/modules/api';
import { isHighResolution } from '../../modules/layout';
import {
  TotalSolicitacoesPendentes,
  IndicadorCustomizadoGerenteResultado,
  ResumoDiarioResultado
} from '../../modules/types';
import IndicadorCalculo from './IndicadorCalculo';
import ResumoDiario from './ResumoDiario';

export type Visualizacao = 'diario' | 'calculos';

interface Props {
  visualizacao?: Visualizacao;
  onClickSolicitacoes: () => void;
  onClickEditarIndicador: (idIndicador?: number) => void;
  onErroConexao?: (mensagem: string, onTentarNovamente: () => void) => void;
}

interface State {
  visualizacao: Visualizacao;
  quantidadeSolicitacoesPendentes: number;
  carregouQuantidadeNotificacao: boolean;
  carregouResumoDiario: boolean;
  carregouCalculo: boolean;
  resumoDiario: Array<ResumoDiarioResultado>;
  listaDadosIndicadorCustomizado: Array<IndicadorCustomizadoGerenteResultado>;
  erro: string;
}

class CardIndicadoresGerente extends React.Component<Props, State> {
  state: State = {
    carregouCalculo: false,
    carregouQuantidadeNotificacao: false,
    carregouResumoDiario: false,
    visualizacao: 'diario',
    quantidadeSolicitacoesPendentes: 0,
    resumoDiario: [],
    listaDadosIndicadorCustomizado: [],
    erro: ''
  };

  async componentDidMount() {
    let carregar: Visualizacao = 'diario';
    if (this.props.visualizacao) {
      carregar = this.props.visualizacao;
    }

    this.setState(
      { visualizacao: carregar },
      async () => await this.buscarIndicadores()
    );
  }

  handleRefresh = async () => {
    this.setState(
      {
        carregouCalculo: false,
        carregouQuantidadeNotificacao: false,
        carregouResumoDiario: false
      },
      async () => await this.buscarIndicadores()
    );
  };

  buscarIndicadores = async () => {
    const { visualizacao } = this.state;

    if (visualizacao === 'diario') {
      await this.buscarIndicadoresQuantidadeNotificacoes();
      await this.buscarResumoDiario();
    } else {
      await this.buscarIndicadoresGerente();
    }
  };

  buscarIndicadoresQuantidadeNotificacoes = async () => {
    const { carregouQuantidadeNotificacao } = this.state;

    if (carregouQuantidadeNotificacao) {
      return;
    }

    const api = new Api();

    await api.getTotalSolicitacoesPendentes({
      onSuccess: (resp: TotalSolicitacoesPendentes) => {
        this.setState({
          quantidadeSolicitacoesPendentes: resp.quantidade,
          carregouQuantidadeNotificacao: true
        });
      },
      onError: (err: Error) => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () => this.buscarIndicadores());
        } else {
          this.setState({ erro: err.message });
        }
      }
    });
  };

  buscarResumoDiario = async () => {
    const { carregouResumoDiario } = this.state;

    if (carregouResumoDiario) {
      return;
    }

    const api = new Api();

    await api.getResumoDiario({
      onSuccess: (resp: Array<ResumoDiarioResultado>) => {
        this.setState({
          resumoDiario: resp,
          carregouResumoDiario: true
        });
      },
      onError: (err: Error) => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () => this.buscarIndicadores());
        } else {
          this.setState({ erro: err.message });
        }
      }
    });
  };

  buscarIndicadoresGerente = async () => {
    const { carregouCalculo } = this.state;

    if (carregouCalculo) {
      return;
    }

    const api = new Api();

    await api.getDadosIndicadoresGerente({
      onSuccess: (resp: Array<IndicadorCustomizadoGerenteResultado>) => {
        this.setState({
          listaDadosIndicadorCustomizado: resp,
          carregouCalculo: true
        });
      },
      onError: (err: Error) => {
        if (err.name === 'TimeoutError' && this.props.onErroConexao) {
          this.props.onErroConexao(err.message, () => this.buscarIndicadores());
        } else {
          this.setState({ erro: err.message });
        }
      }
    });
  };

  handleModeChange = async (mode: Visualizacao) => {
    this.setState(
      { visualizacao: mode },
      async () => await this.buscarIndicadores()
    );
  };

  render() {
    const { visualizacao } = this.state;
    return (
      <>
        <View style={styles.buttonContainer}>
          <Button
            nativeID="indicadores-gerenciais-botao-resumo-diario"
            text={`${translateFirstUpper('Resumo')} ${translate('Diário')}`}
            onPress={() => this.handleModeChange('diario')}
            primary={visualizacao === 'diario'}
            style={styles.buttonDiario}
          />
          <Button
            nativeID="indicadores-gerenciais-botao-calculo"
            text={translate('Cálculos')}
            onPress={() => this.handleModeChange('calculos')}
            primary={visualizacao === 'calculos'}
            style={styles.buttonCalculos}
          />
        </View>
        <View style={styles.container}>
          {visualizacao === 'diario' ? (
            <ResumoDiario
              onClickSolicitacoes={this.props.onClickSolicitacoes}
              resumoDiario={this.state.resumoDiario}
              quantidadeSolicitacoesPendentes={
                this.state.quantidadeSolicitacoesPendentes
              }
            />
          ) : (
            <IndicadorCalculo
              onClickEditarIndicador={this.props.onClickEditarIndicador}
              indicadoresCostomizados={
                this.state.listaDadosIndicadorCustomizado
              }
            />
          )}
          <Message
            type="warning"
            message={this.state.erro}
            visible={this.state.erro !== ''}
            onRequestClose={() => this.setState({ erro: '' })}
          />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: Platform.OS === 'web' ? 'row' : 'column',
    flexWrap: 'wrap',
    overflow: 'hidden',
    alignItems: 'center',
    padding: Platform.OS === 'web' ? 16 : undefined,
    margin: Platform.OS === 'web' ? -16 : undefined
  },
  buttonContainer: {
    flexDirection: 'row',
    width: isHighResolution() ? 270 : undefined,
    paddingBottom: 15
  },
  buttonDiario: {
    flex: 1,
    borderBottomEndRadius: 0,
    borderTopEndRadius: 0
  },
  buttonCalculos: {
    flex: 1,
    borderBottomStartRadius: 0,
    borderTopStartRadius: 0
  }
});

export default CardIndicadoresGerente;
