import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { translate } from 'secullum-i18n';
import {
  Card,
  Button,
  Space,
  Message,
  isTablet,
  Text,
  TextBox,
  Link,
  ErrorMessage
} from 'secullum-react-native-ui';
import Api from '../modules/api';
import { getTheme } from '../modules/layout';
import { ButtonBar } from './ButtonBar';
import LogoVertical from './svg/SecullumPontoVertical.jsx';

export interface CardRecuperacaoSenhaProps {
  emailFuncionario: string;
  bancoId: string;
  usuario: string;
  tipoUsuarioAutenticacao: number;
  onNavegar?: () => void;
}

const CardRecuperacaoSenha: React.FC<CardRecuperacaoSenhaProps> = ({
  emailFuncionario,
  bancoId,
  usuario,
  tipoUsuarioAutenticacao,
  onNavegar
}) => {
  enum EtapasResetarSenhaUsuario {
    SolicitarConfirmacaoEmail,
    SolicitarConfirmacaoEmailCodigo,
    ResetarSenhaUsuario
  }

  const [etapaAtual, setEtapaAtual] = useState(
    EtapasResetarSenhaUsuario.SolicitarConfirmacaoEmail
  );
  const [emailBloqueado] = useState(emailFuncionario);
  const [emailConfirmacao, setEmailConfirmacao] = useState('');
  const [codigoConfirmacao, setCodigoConfirmacao] = useState('');
  const [novaSenha, setSenha] = useState('');
  const [confirmarSenha, setConfirmacaoSenha] = useState('');
  const [erros, setErros] = useState<{ [key: string]: string }>({});
  const [erroGeral, setErroGeral] = useState('');

  const enviarCodigoAsync = async () => {
    if (!emailConfirmacao) {
      setErros({ emailConfirmacao: translate('Campo obrigatório.') });
      return;
    }

    const api = new Api();

    await api.postEnviarTokenAsync(
      {
        bancoId,
        usuario,
        tipoUsuarioAutenticacao,
        email: emailConfirmacao
      },
      {
        onSuccess: () => {
          setEtapaAtual(
            EtapasResetarSenhaUsuario.SolicitarConfirmacaoEmailCodigo
          );
        },
        onValidationError: (_, errorObj) => {
          setErros(errorObj);
        },
        onError: erro => {
          setErroGeral(erro.message);
        }
      }
    );
  };

  const buscarConfirmacaoCodigoAsync = async () => {
    const api = new Api();

    await api.postConfirmarCodigoAsync(
      {
        bancoId,
        usuario,
        tipoUsuarioAutenticacao,
        codigo: codigoConfirmacao
      },
      {
        onSuccess: () => {
          setEtapaAtual(EtapasResetarSenhaUsuario.ResetarSenhaUsuario);
        },
        onValidationError: (_, errorObj) => {
          setErros(errorObj);
        },
        onError: erro => {
          setErroGeral(erro.message);
        }
      }
    );
  };

  const trocarSenhaAsync = async () => {
    if (!novaSenha) {
      setErros({ novaSenha: translate('Campo obrigatório.') });
      return;
    }

    if (!confirmarSenha) {
      setErros({ confirmarSenha: translate('Campo obrigatório.') });
      return;
    }

    if (novaSenha !== confirmarSenha) {
      setErros({
        confirmarSenha: translate('As senhas informadas não correspondem.')
      });
      return;
    }

    const api = new Api();

    await api.postTrocarSenhaAsync(
      {
        bancoId,
        usuario,
        tipoUsuarioAutenticacao,
        codigo: codigoConfirmacao,
        novaSenha,
        confirmarSenha
      },
      {
        onSuccess: () => {
          onNavegar && onNavegar();
        },
        onValidationError: (_, errorObj) => {
          setErros(errorObj);
        },
        onError: erro => {
          setErroGeral(erro.message);
        }
      }
    );
  };

  const renderEtapaSolicitarConfirmacaoEmail = () => {
    return (
      <>
        <View nativeID="texto-recuperacao">
          <Text style={styles.text}>
            {translate(
              'Para obter seu código de segurança, será enviado um e-mail para'
            )}
          </Text>
          <Space height={8} />
          <Text style={styles.text} bold>
            {emailBloqueado}
          </Text>
          <Space height={8} />
          <Text style={styles.text}>
            {translate(
              'Para validar seu e-mail, digite novamente, completando a parte oculta e clique em Enviar Código.'
            )}
          </Text>
        </View>
        <Space height={30} />
        <TextBox
          nativeID="text-input-email"
          value={emailConfirmacao}
          label={translate('E-mail')}
          keyboardType={'email-address'}
          returnKeyType={'done'}
          onChange={setEmailConfirmacao}
        />
        <ErrorMessage
          nativeID="mensagem-erro-email"
          message={erros.emailConfirmacao}
        />
        <Space height={30} />
        <Button
          nativeID="botao-confirmar-email"
          text={translate('Enviar Código')}
          onPress={enviarCodigoAsync}
        />
      </>
    );
  };

  const renderEtapaSolicitarConfirmacaoEmailCodigo = () => {
    return (
      <>
        <View nativeID="texto-orientacao-email">
          <Text style={styles.text}>
            {translate('Digite o código de segurança enviado para o e-mail')}
          </Text>
          <Space height={8} />
          <Text style={styles.text} bold>
            {emailConfirmacao}
          </Text>
        </View>
        <Space height={30} />
        <TextBox
          nativeID="text-input-codigo"
          value={codigoConfirmacao}
          label={translate('Código de segurança')}
          keyboardType={'numeric'}
          maxLength={6}
          returnKeyType={'done'}
          onChange={setCodigoConfirmacao}
        />
        <ErrorMessage
          nativeID="mensagem-erro-codigo"
          message={erros.codigoConfirmacao}
        />
        <Space height={30} />
        <Button
          nativeID="botao-confirmar-codigo"
          text={translate('Enviar Código')}
          onPress={buscarConfirmacaoCodigoAsync}
        />
        <Space height={50} />
        <View nativeID="link-solicitar-novo">
          <Text style={styles.text}>{translate('Não recebeu o código?')}</Text>
          <Link
            style={styles.link}
            text={translate('Solicitar novo')}
            onPress={enviarCodigoAsync}
          />
        </View>
      </>
    );
  };

  const renderEtapaResetarSenhaUsuario = () => {
    return (
      <View>
        <TextBox
          nativeID="input-text-nova-senha"
          value={novaSenha}
          maxLength={20}
          label={translate('Nova Senha')}
          keyboardType={'default'}
          secureTextEntry
          returnKeyType={'next'}
          onChange={setSenha}
        />
        <ErrorMessage nativeID="erro-nova-senha" message={erros.novaSenha} />
        <Space />
        <TextBox
          nativeID="input-text-confirmacao-senha"
          value={confirmarSenha}
          maxLength={20}
          label={translate('Confirmar Senha')}
          keyboardType={'default'}
          secureTextEntry
          returnKeyType={'done'}
          onChange={setConfirmacaoSenha}
        />
        <ErrorMessage
          nativeID="erro-nova-senha"
          message={erros.confirmarSenha}
        />
        <Space />
        <ButtonBar
          leftButton={{
            nativeID: 'btnCancelar',
            text: translate('Cancelar'),
            onPress: () => {
              onNavegar && onNavegar();
            },
            primary: false
          }}
          rightButton={{
            nativeID: 'btnSalvar',
            text: translate('Salvar'),
            onPress: () => trocarSenhaAsync()
          }}
        />
      </View>
    );
  };

  const renderPassosResetarSenha = () => {
    switch (etapaAtual) {
      case EtapasResetarSenhaUsuario.SolicitarConfirmacaoEmail:
        return renderEtapaSolicitarConfirmacaoEmail();
      case EtapasResetarSenhaUsuario.SolicitarConfirmacaoEmailCodigo:
        return renderEtapaSolicitarConfirmacaoEmailCodigo();
      case EtapasResetarSenhaUsuario.ResetarSenhaUsuario:
        return renderEtapaResetarSenhaUsuario();
    }
  };

  return (
    <>
      <Card>
        <Card.Section style={{ padding: 20 }}>
          <View style={styles.logoContainer}>
            <LogoVertical />
          </View>
          {renderPassosResetarSenha()}
        </Card.Section>
      </Card>
      <Message
        type="warning"
        visible={erroGeral.length > 0}
        message={erroGeral}
        onRequestClose={() => setErroGeral('')}
      />
    </>
  );
};

const styles = StyleSheet.create({
  text: {
    justifyContent: 'center',
    alignContent: 'center',
    fontSize: 16,
    color: getTheme().textColor3,
    textAlign: 'center'
  },
  link: {
    justifyContent: 'center',
    fontSize: 16,
    textDecorationLine: 'underline',
    textAlign: 'center'
  },
  logoContainer: {
    alignItems: 'center',
    height: isTablet() ? 200 : 170,
    paddingVertical: 20
  }
});

export default CardRecuperacaoSenha;
