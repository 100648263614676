import { Dimensions, Platform } from 'react-native';
import * as SecullumReactNativeUI from 'secullum-react-native-ui';
import { isTablet } from 'secullum-react-native-ui';

export const isHighResolution = () =>
  Dimensions.get('window').height >= 600 &&
  Dimensions.get('window').width >= 600;

export const isDesktop = () => Dimensions.get('window').width >= 1024;

export const COR_BOTAO_CINZA = '#75787b';

export const getTheme = () => {
  SecullumReactNativeUI.setTheme({
    textColor1: '#555',
    textColor2: '#59CBE8',
    textColor3: '#555',
    backgroundColor1: '#ffffff',
    backgroundColor2: '#faf8f9',
    backgroundColor3: '#59CBE8',
    borderColor1: '#c2c2c2',
    statusBarColor: '#59CBE8',
    successColor: '#78D648',
    warningColor: '#FF9E1B',
    errorColor: '#F14D52',
    disabledColor: '#dddddd'
  });

  return SecullumReactNativeUI.getTheme();
};

export const select = ({
  web,
  tablet,
  defaultValue,
  highResolution
}: SelectOptions) => {
  const webPlatform = Platform.OS === 'web';

  if (highResolution && webPlatform && isHighResolution()) {
    return highResolution;
  }

  if (web && webPlatform) {
    return web;
  }

  if (tablet && isTablet()) {
    return tablet;
  }

  return defaultValue;
};

interface SelectOptions {
  web?: any;
  tablet?: any;
  defaultValue?: any;
  highResolution?: any;
}
